import * as React from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import AccountCircle from '@mui/icons-material/AccountCircle';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKey';
import Container from '@mui/material/Container';
import CopytRightIcon from '@mui/icons-material/Copyright';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import IgIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
// import YoutubeIcon from '@mui/icons-material/Youtube';
import ComputerIcon from './images/computer.svg';
import Slider from './slider';
import Loginmain from './loginmain';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LogIcon from './images/login.svg';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import CircularProgress from '@mui/material/CircularProgress';
import Computer from './images/computer.svg'
import Addinfo from './images/addinfo.svg'
import Finance from './images/finance.svg'
import Statistics from './images/login.svg'
import Mark from './images/mark.svg'
import Marksheet from './images/marksheet.svg'
import Teacherapp from './images/new/profile-pic-animate.svg';



import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Grid, Button } from '@mui/material';

function Profile() {
    const [values, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });
    const [opensnack, setOpensnack] = React.useState(false);
    const [loading, setloading] = React.useState(false)
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const [message, setmessage] = React.useState("");
    const [stated, setstated] = React.useState("");
    const handleClicksnack = () => {
        setOpensnack(true);
    };

    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };

    return (<div style={{ margin: 0, padding: 0 }}>
          <br/>
        <h2 className='sizer' style={{ fontFamily: "monospace", color: "#1976d2", textAlign: "center", fontWeight: "lighter", margin:0, paddingTop:70 }}>WELCOME TO YOUR SCHOOL'S INFORMATION SYSTEM <br /> (SIS REVOLUTION)</h2>

        <div style={{ backgroundColor: "white", height: "100%" }}>
          
          


            <Container maxWidth="lg">

                <Grid container  >
                    <Grid item xs={12} lg={6} >
                        <div align="center" style={{ marginTop:"-50px"}} >

                            <Slider  />
                        </div>

                    </Grid>
                    <Grid item xs={12} lg={6} style={{ textAlign: "center" }}>
                        <br />
                        <br />
                        <br />
                        <br />
                       
                       

                        {/* <Box sx={{ boxShadow: 3 }} style={{ cursor:"pointer", width:"80%", backgroundColor:"red", marginLeft:"5%", padding:18, borderRadius:3,backgroundColor:" #e0eff9" , color:"#1976d2"}}>Login as a school <img src={LogIcon} width="17%"  style={{float:"right", marginTop:"-14px"}} alt="" /></Box> */}

                        {/* <h2>Login</h2> */}

                        <Loginmain />



                    </Grid>


                </Grid>
                <br />
                <br />
                <Divider />
                <br />
                <h1 style={{ fontFamily: "monospace", color: "#1976d2", textAlign: "center", fontWeight: "lighter" }}>FEATURES OF THE SIS REVOLUTION </h1 >
                <br />
                <Container maxWidth="lg">


                    <Grid container spacing={4}>
                        <Grid item xs={12} lg={6} align="left">
                            <h3 style={{ color: "#1976d2" }}>Administrative System</h3>
                            <div style={{ color: "gray" }}>
                                <li>Management of student personal information</li>
                                <li>Management of student academic records</li>
                                <li>Generates automated academic reports</li>
                                <li>Management of financial records, including school fees, canteen fees, and other schools expenses</li>
                                <li>Mark and keeping attendance records</li>
                                <li>Management of Teachers personal data</li>
                                <li>Management of Teachers salary records and payslip</li>
                                <li>Academic timetable management </li>
                                <li>Send academic records to guardians via text messages and email</li>
                                <li>Send bulk messages to guardians via email or text messages (sms) </li>
                            </div>
                        </Grid>


                        <Grid item xs={12} lg={6}>
                            <img src={Computer} width="100%" />
                        </Grid>
                    </Grid>


                    <br />
                    <br />
                    <br />
                    <Grid container spacing={4}>
                        <Grid item xs={12} lg={6}>

                            <img src={Teacherapp} width="80%" style={{marginTop:"-20px"}} />

                        </Grid>


                        <Grid item xs={12} lg={6} align="left">
                            <h3 style={{ color: "#1976d2" }}>Teachers' Mobile Application</h3>
                            <div style={{ color: "gray" }}>
                                <li>Mark student attendance</li>
                                <li>Management of students academic records</li>
                                <li>Management of students personal information</li>
                                <li>Development of academic timetable </li>
                                <li>Manages Teachers personal information </li>
                                <li>Academic timetable management </li>
                                <li>Send academic records to guardians via text messages and email </li>
                                <li>Send bulk messages to guardians via email or text messages (sms) </li>
                                <li>Mark MCQs with our AI powered system and gives instant results</li>
                            </div>
                        </Grid>
                    </Grid>


                </Container>
                <br />
                <br />
                <br />


                <Divider />
                <br />
                <h1 style={{ fontFamily: "monospace", color: "#1976d2", textAlign: "center", fontWeight: "lighter" }}>JOIN THE SCHOOLS' INFORMATION SYSTEM (SIS REVOLUTION)</h1 >
                <br />
                <Grid container spacing={0}>
                    <Grid item lg={5} sm={12} >


                        <Box sx={{ boxShadow: 3 }} style={{ paddingTop: 10, paddingBottom: 30 }}>
                            <form align="center"  >
                                <p style={{ textAlign: "center", color: "gray" }}>Fill this form and We will get back to you</p>
                                <FormControl variant="standard">

                                    <TextField
                                        required
                                        id="personname"
                                        placeholder="Full Name"
                                        type="name"
                                        className="credentials"
                                        // variant="standard"
                                        size='small'
                                        InputProps={{
                                            startAdornment:
                                                <InputAdornment position="start">
                                                    {/* <PersonOutlineIcon style={{ marginRight: 4, color:"#1976d2" }} />   | */}

                                                </InputAdornment>
                                        }}


                                    />
                                </FormControl>
                                <br />
                                <br />
                                <FormControl variant="standard">

                                    <TextField
                                        required
                                        id="personcontact"
                                        placeholder="Contact"
                                        type="tel"
                                        className="credentials"
                                        // variant="standard"
                                        size='small'
                                        InputProps={{
                                            startAdornment:
                                                <InputAdornment position="start">
                                                    {/* <PersonOutlineIcon style={{ marginRight: 4, color:"#1976d2" }} />   | */}

                                                </InputAdornment>
                                        }}


                                    />
                                </FormControl>
                                <br />
                                <br />
                                <FormControl variant="standard">

                                    <TextField
                                        required
                                        id="personemail"
                                        placeholder="Email"
                                        type="email"
                                        className="credentials"
                                        // variant="standard"
                                        size='small'
                                        InputProps={{
                                            startAdornment:
                                                <InputAdornment position="start">
                                                    {/* <PersonOutlineIcon style={{ marginRight: 4, color:"#1976d2" }} />   | */}

                                                </InputAdornment>
                                        }}


                                    />
                                </FormControl>
                                <br />
                                <br />

                                <FormControl variant="standard">

                                    <TextField
                                        required
                                        id="schoolname"
                                        placeholder="Name of School"
                                        type="type"
                                        className="credentials"
                                        // variant="standard"
                                        size='small'
                                        InputProps={{
                                            startAdornment:
                                                <InputAdornment position="start">
                                                    {/* <PersonOutlineIcon style={{ marginRight: 4, color:"#1976d2" }} />   | */}

                                                </InputAdornment>
                                        }}


                                    />
                                </FormControl>
                                <br />
                                <br />

                                <FormControl variant="standard">

                                    <TextField
                                        required
                                        id="schooladdress"
                                        placeholder="School's Address"
                                        type="address"
                                        className="credentials"
                                        // variant="standard"
                                        size='small'
                                        InputProps={{
                                            startAdornment:
                                                <InputAdornment position="start">
                                                    {/* <PersonOutlineIcon style={{ marginRight: 4, color:"#1976d2" }} />   | */}

                                                </InputAdornment>
                                        }}


                                    />
                                </FormControl>
                                <br />
                                <br />

                                <FormControl variant="standard">

                                    <textarea
                                        required
                                        id="others"
                                        placeholder="Other Information"
                                        type="address"
                                        className="credentials2"
                                        // variant="standard"
                                        size='small'
                                        rows={5}
                                        style={{ border: "solid", borderWidth: "0.1px", borderColor: "lightgray", borderRadius: "5px", padding: 15, fontFamily: "sans-serif" }}
                                        InputProps={{
                                            startAdornment:
                                                <InputAdornment position="start">
                                                    {/* <PersonOutlineIcon style={{ marginRight: 4, color:"#1976d2" }} />   | */}

                                                </InputAdornment>
                                        }}


                                    />
                                </FormControl>
                                <br />
                                <br />


                                {loading === false ?
                                    <input type="submit" value="SUBMIT" style={{ backgroundColor: " #e0eff9", color: "#1976d2", cursor: "pointer", padding: "15px", borderRadius: "5px", border: "none", outline: "none" }} size="large" />

                                    :
                                    <Button variant="contained" disabled={loading} size="large">

                                        <CircularProgress size={20} />  Loading...

                                    </Button>
                                }



                                <br />
                            </form>
                        </Box>


                    </Grid>

                </Grid>

            </Container>
          


        </div>

    </div>
    );
}

export default Profile;