import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Avatar from '@mui/material/Avatar';
import CloseIcon from '@mui/icons-material/Close';
import SupplyProduct from '@mui/icons-material/LocalShipping';
import AddSRV from '@mui/icons-material/PostAdd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Search from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Show from './tableshow';
import FormLabel from '@mui/material/FormLabel';


import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';


import TextField from '@mui/material/TextField';
import Menustyle from './menusalaries';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import CircularProgress from '@mui/material/CircularProgress';
import ImageIcon from '@mui/icons-material/Image';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import Container from '@mui/material/Container';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import axios from 'axios';
import ReactToPrint from 'react-to-print';

import Badge from '@mui/material/Badge';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Person from './images/Ellipse77.png';

import Stack from '@mui/material/Stack';
import Snack from './snack';
import Tablebonus from './tablebonus';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { seedetails, getproducts } from './redux/todoSlice';
import { admin } from './redux/admin';
import { schooldetails } from './redux/schooldetails';
import { loin, logout, updateschooldata, getdata } from './redux/schools';
import {  updateteacherdata, getdatateacher } from './redux/teachers';
import { BrowserRouter as Router, Routes, Route, Link, useLocation, BrowserRouter } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



function createData(_id,ID, lastname, othernames, image, contact, position, positiondescription, gender, dob, hometown, residence, religion, email, startingdate, resignation, salary, finance, status, bank, snnit, accountnumber) {
    return {
        _id,
        ID,
        lastname,
        othernames,
        image,
        contact,
        position,
        positiondescription,
        gender,
        dob,
        hometown,
        residence,
        religion,
        email, 
        startingdate,
        resignation,
        salary,
        finance,
        status, bank, snnit, accountnumber

    };
}

const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Donut', 452, 25.0, 51, 4.9),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Honeycomb', 408, 3.2, 87, 6.5),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Jelly Bean', 375, 0.0, 94, 0.0),
    createData('KitKat', 518, 26.0, 65, 7.0),
    createData('Lollipop', 392, 0.2, 98, 0.0),
    createData('Marshmallow', 318, 0, 81, 2.0),
    createData('Nougat', 360, 19.0, 9, 37.0),
    createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [



    {
        id: 'id',
        numeric: false,
        disablePadding: !true,
        label: 'ID',
    },

    {
        id: 'name',
        numeric: !true,
        disablePadding: false,
        label: 'Name',
    },
    {
        id: 'status',
        numeric: !true,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'position',
        numeric: !true,
        disablePadding: false,
        label: 'Position',
    },
    {
        id: 'gender',
        numeric: !true,
        disablePadding: false,
        label: 'Net Salary',
    },
    {
        id: 'contact',
        numeric: !true,
        disablePadding: false,
        label: 'Earnings',
    }, {
        id: 'email',
        numeric: !true,
        disablePadding: false,
        label: 'Deductions',
    },
  
    {
        id: '',
        numeric: true,
        disablePadding: false,
        label: '',
    }
];


function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell align="center">
                    <b>
                        No.
                    </b>
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <b>{headCell.label}</b>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >

            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                SALARIES
            </Typography>



        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {



    const [opensnack, setOpensnack] = React.useState(false);
    const [loadpage, setloadpage] = React.useState(true);
    const [activeworker, setactiveworker] = React.useState(true);
    const [inactiveworker, setinactiveworker] = React.useState(false);
    const [gettingdatatype, setgettingdatatype] = React.useState("Active");
     
    const handleClicksnack = () => {
        setOpensnack(true);
    };

    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };

    const dispatch = useDispatch()
    const schools = useSelector((state) => state.teachers)

    const admin = useSelector((state) => state.admin)
    const schooldetails_ = useSelector((state) => state.schooldetails)
    const schooldata= async () => {
        await axios.post("https://servermain.sisrevolution.com/api/schools/getschooldata/",
            {
                schoolID:admin.schoolID

            }).then(res => {

                if (res.status === 200) {

                    dispatch(schooldetails({

                        data: res.data

                    }))


                }

            })

    }



    const gettingdata = async () => {
        await axios.post("https://servermain.sisrevolution.com/api/teachers/getworkersdata/",
            {
                status:gettingdatatype,
                schoolID:admin.schoolID

            }).then(res => {

                if (res.status === 200) {

                    dispatch(getdatateacher({

                        data: res.data

                    }))


                }

            })

    }

    useEffect(()=>{
         schooldata()
         gettingdata()
    },[loadpage, gettingdatatype])
   


    const data = [schools.map((list, index) => createData(list._id, list.ID, list.lastname, list.othernames, list.image, list.contact, list.position,list.positiondescription, list.gender, list.dob, list.hometown, list.residence, list.religion,list.email,list.startingdate, list.resignation, list.salary, list.finance, list.status, list.bank, list.snnit, list.accountnumber))]


    const dt = new Date()
    const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
    const day = dt.getUTCDate();
    const month = String(dt.getMonth() + 1).padStart(2, '0');
    const year = dt.getFullYear();
    const date = year + "-" + month + "-" + day;
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]


    var rows = data[0]


    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl_, setAnchorEl_] = React.useState(null);
    const open = Boolean(anchorEl);
    const open_ = Boolean(anchorEl_);
    const [opensupply, setOpensupply] = React.useState(!true);
    const [name, setname] = React.useState(!true);
    const [location, setlocation] = React.useState(!true);
    const [allocation, setallocation] = React.useState(!true);
    const [contact, setcontact] = React.useState(!true);
    const [message, setmessage] = React.useState("");
    const [stated, setstated] = React.useState("");
    const [earnings, setearnings] = React.useState(0);


    const [trialmode, settrialmode] = React.useState(!true);
    const [openadd, setOpenadd] = React.useState(!true);
    const [openview, setOpenview] = React.useState(!true);
    const [openpaysalary, setOpenpaysalary] = React.useState(!true);
    const [openpayslip, setOpenpayslip] = React.useState(!true);
    const [opendeduction, setOpendeduction] = React.useState(!true);
    const [openbonus, setOpenbonus] = React.useState(!true);
    const [openbase, setOpenbase] = React.useState(!true);
    const [opensrv, setOpensrv] = React.useState(!true);
    const [openedit, setOpenedit] = React.useState(!true);
    const [openimage, setOpenimage] = React.useState(!true);
    const [opendelete, setOpendelete] = React.useState(!true);
    const [age, setAge] = React.useState('');
    const [sorter, setsorter] = React.useState("none");
    const [sortermonth, setsortermonth] = React.useState("none");
    const [paymonth, setpaymonth] = React.useState("none");
    const [chosen, setchosen] = React.useState('');
    const [loading, setloading] = React.useState(false)
    const [loadingdelete, setloadingdelete] = React.useState(false)
    const [search, setsearch] = React.useState("")
    const [selectedclass, setselectedclass] = React.useState("")
    const [selectedgender, setselectedgender] = React.useState("")
    const [selectedreligion, setselectedreligion] = React.useState("")
    const [person, setperson] = React.useState(Person)
    const componentRefreceipt = React.useRef()

    var totalearned=0
    var totaldeduct=0
    var netsalary=0

    const searching = (e) => {
        setsearch(e.target.value)
    }

const calcnet=(data_)=>{
    netsalary+=parseFloat(data_)
}

    if (search !== "") {
        var listed = rows.filter(bug => bug.status.toLowerCase().includes(search.toLowerCase()) ||bug.lastname.toLowerCase().includes(search.toLowerCase()) || bug.id.toLowerCase().includes(search.toLowerCase()) || bug.othernames.toLowerCase().includes(search.toLowerCase()) || bug.contact.includes(search) )
      
        rows = listed
        
    }

 if (sorter !== "none" && sorter !== "All" ) {
    if(sorter==="Teaching Staff"){
        var listed = rows.filter(bug => bug.position === sorter )
      
        rows = listed
    }
    else if(sorter==="Non-Teaching Staff" ){
        var listed = rows.filter(bug => bug.position !== "Teaching Staff" )
        rows = listed
    }
        
    }else{
        rows = data[0]
    }
    

    //actions
    const addtolist = (data) => {
        setloading(true)
        console.log(data)
        var quantity = document.getElementById("Quantity").value
        var SRV = document.getElementById("SRVrec").value
        var By = document.getElementById("supplier").value
        var term = age
        var done = [...data.data, { date: date, sem: term, quantity: quantity, SRVrec: SRV, SRVsent: 0, SRVcon: 0, SRVpaid: 0, suppliedby: By }]

        const doer = async () => {
            try{
            await axios.post("https://servermain.sisrevolution.com/api/school/updateschooldata/",
                {
                    id: data.id,
                    data: done,
                    schoolID:admin.schoolID



                }).then(res => {

                    if (res.status === 200) {
                        gettingdata()

                        setloading(!true)
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("You have successfully added to supply list")
                        setstated("success")

                    } else {
                        setloading(!true)
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("Error adding to supply list")
                        setstated("error")

                    }

                })

        
    }catch(err){
        setloading(!true)
        handleClicksnack()
        setmessage("Network Error")
        setstated("error")
    }

        doer()

    }
}


    const addschool = (data) => {
        setloading(true)
        var name = document.getElementById("schoolname").value
        var location = document.getElementById("schoollocation").value
        var allocation = document.getElementById("schoolallocation").value
        var contact = document.getElementById("schoolcontact").value

        const doer = async () => {
            try{
            await axios.post("https://servermain.sisrevolution.com/api/school/addschool/",
                {
                    school: name,
                    location: location,
                    allocation: allocation,
                    contact: contact,
                    schoolID:admin.schoolID



                }).then(res => {

                    if (res.status === 200) {

                       gettingdata()
                     setchosen(rows.filter(bug=>bug._id===chosen._id)[0])

                        setloading(!true)
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("You have added to school list")
                        setstated("success")

                    } else {
                        setloading(!true)
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("Error adding to school list")
                        setstated("error")
                    }

                })

        }
        catch(err){
            setloading(!true)
            handleClicksnack()
            setmessage("Network error")
            setstated("error")
        }
    }

        doer()

    }

    const deleter = (data) => {

        setloading(true)

        const doer = async () => {
            try{
            await axios.post("https://servermain.sisrevolution.com/api/school/deleteschool/",
                {
                    id: chosen._id,
                    schoolID:admin.schoolID,
                    status:gettingdatatype,
                }).then(res => {

                    if (res.status === 200) {

                       gettingdata()
                                             setchosen(rows.filter(bug=>bug._id===chosen._id)[0])

                        setloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Data has been deleted")
                        setstated("success")

                    } else {
                        setloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Error deleting data")
                        setstated("error")
                    }

                })

        }catch(err){
            setloading(!true)
            handleClicksnack()
            setmessage("Network Error")
            setstated("error")
        }
    }

        doer()

    }

     const addbasesalary = () =>{
         setloading(true)
        const base = document.getElementById("basesalary").value
       const salary = { basesalary: base, salarybonuses: chosen.finance[0].salarybonuses, deductions: chosen.finance[0].deductions, payment: chosen.finance[0].payment }
     
          const doer = async () => {
            try{
            await axios.post("https://servermain.sisrevolution.com/api/teachers/updatesalary/",
                {
                    id: chosen._id,
                    salary: salary,
                    status:gettingdatatype,
                    schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {
                    gettingdata()
                                           setchosen(rows.filter(bug=>bug._id===chosen._id)[0])

                        setloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Base salary added")
                        setstated("success")

                    } else {
                        setloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Error adding base salary")
                        setstated("error")
                    }

                })

            }catch(err){
                setloading(!true)
                handleClicksnack()
                setmessage("Network Error")
                setstated("error")
            }

        }

        doer()

    }

     const addbonussalary = () =>{
         setloading(true)
         console.log(chosen)
       const bonus = document.getElementById("bonussalary").value
       const description = document.getElementById("bonussalarydes").value
       var salary ={}
       if(chosen.finance[0].salarybonuses.length!==0){
       salary = { basesalary: chosen.finance[0].basesalary, salarybonuses: [...chosen.finance[0].salarybonuses, {amount:bonus, description:description, date:date, time:time, id:new Date()}], deductions: chosen.finance[0].deductions, payment: chosen.finance[0].payment }
       }else{
               salary = { basesalary: chosen.finance[0].basesalary, salarybonuses: [ {amount:bonus, description:description, date:date, time:time}], deductions: chosen.finance[0].deductions, payment: chosen.finance[0].payment }

       }
          const doer = async () => {
            try{
            await axios.post("https://servermain.sisrevolution.com/api/teachers/updatesalary/",
                {
                    id: chosen._id,
                    salary: salary,
                    status:gettingdatatype,
                    schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {
                     dispatch(getdatateacher({

                        data: res.data

                    }))
                     setchosen(res.data.filter(bug=>bug._id===chosen._id)[0])

                        setloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Bonus salary added")
                        setstated("success")

                    } else {
                        setloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Error adding bonus salary")
                        setstated("error")
                    }

                })

        }
        catch(err){
            setloading(!true)
                        // handleClosedelete()
                        handleClicksnack()
                        setmessage("Network Error")
                        setstated("error")

        }
    }

        doer()

    }


     const deletebonus = (data) =>{
                  setloading(true)
        console.log(chosen)
        console.log(data)
        var l = chosen.finance[0].salarybonuses
       const newbonus =  l.filter(bug=>bug.id !== data.id )
       console.log(newbonus)
       const salary = { basesalary: chosen.finance[0].basesalary, salarybonuses: [...newbonus], deductions: chosen.finance[0].deductions, payment: chosen.finance[0].payment }
     
          const doer = async () => {
            try{
            await axios.post("https://servermain.sisrevolution.com/api/teachers/updatesalary/",
                {
                    id: chosen._id,
                    status:gettingdatatype,
                    salary: salary,
                    schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {

                        dispatch(getdatateacher({

                        data: res.data

                    }))

                     setchosen(res.data.filter(bug=>bug._id===chosen._id)[0])

                        setloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Bonus salary deleted")
                        setstated("success")

                    } else {
                        setloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Error deleting bonus salary")
                        setstated("error")
                    }

                })

        }catch(err){
            setloading(!true)
            // handleClosedelete()
            handleClicksnack()
            setmessage("Network Error")
            setstated("error")
        }
    }

        doer()

    }

     const salarydeduction = () =>{
                  setloading(true)

        const deductions = document.getElementById("deductionsalary").value
        const description = document.getElementById("deductionsalarydes").value
       const salary = { basesalary: chosen.finance[0].basesalary, salarybonuses: chosen.finance[0].salarybonuses, deductions: [...chosen.finance[0].deductions, {amount:deductions, description:description, date:date, time:time, id:new Date()}], payment: chosen.finance[0].payment }
     
          const doer = async () => {
            try{
            await axios.post("https://servermain.sisrevolution.com/api/teachers/updatesalary/",
                {
                    id: chosen._id,
                    salary: salary,
                    status:gettingdatatype,
                    schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {
    dispatch(getdatateacher({

                        data: res.data

                    }))

                                           setchosen(res.data.filter(bug=>bug._id===chosen._id)[0])

                        setloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Salary deduction added")
                        setstated("success")

                    } else {
                        setloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Error deleting salary deduction")
                        setstated("error")
                    }

                })

        }catch(err){
            setloading(!true)
            // handleClosedelete()
            handleClicksnack()
            setmessage("Network Error")
            setstated("error")
        }
    }

        doer()

    }
     const paysalary = () =>{
                  setloading(true)

        const amount = (parseFloat(chosen.finance[0].basesalary) + earning(chosen.finance[0].salarybonuses)-earning(chosen.finance[0].deductions))

        const salary = { basesalary: chosen.finance[0].basesalary, salarybonuses: chosen.finance[0].salarybonuses, deductions:chosen.finance[0].deductions, payment: [ {amount:amount, paymonth:paymonth, deductions:chosen.finance[0].deductions, bonuses:chosen.finance[0].salarybonuses, date:date, time:time, id:new Date()}] }
        
        const doer = async () => {
            try{
            await axios.post("https://servermain.sisrevolution.com/api/teachers/updatesalary/",
                {
                    id: chosen._id,
                    salary: salary,
                    status:gettingdatatype,
                    schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {
                    dispatch(getdatateacher({

                        data: res.data

                    }))

                                           setchosen(res.data.filter(bug=>bug._id===chosen._id)[0])

                        setloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Salary  Paid Successfully")
                        setstated("success")

                    } else {
                        setloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Error deleting salary deduction")
                        setstated("error")
                    }

                })

        }catch(err){
            setloading(!true)
            // handleClosedelete()
            handleClicksnack()
            setmessage("Network Error")
            setstated("error")
        }
    }

        doer()

    }


     const deletedeductions = (data) =>{
                  setloading(true)

       const newdeduction =  chosen.finance[0].deductions.filter(bug=>bug.id !== data.id)
       const salary = { basesalary: chosen.finance[0].basesalary, salarybonuses: chosen.finance[0].salarybonuses, deductions:newdeduction , payment: chosen.finance[0].payment }
     
          const doer = async () => {
            try{
            await axios.post("https://servermain.sisrevolution.com/api/teachers/updatesalary/",
                {
                    id: chosen._id,
                    salary: salary,
                    status:gettingdatatype,
                    schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {
    dispatch(getdatateacher({

                        data: res.data

                    }))

                                           setchosen(res.data.filter(bug=>bug._id===chosen._id)[0])

                        setloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Salary deduction deleted")
                        setstated("success")

                    } else {
                        setloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Error deleting salary deduction")
                        setstated("error")
                    }

                })

        }catch(err){
            setloading(!true)
            // handleClosedelete()
            handleClicksnack()
            setmessage("Network error")
            setstated("error")
        }
    }

        doer()

    }


    const handleClickOpenedit = (data) => {
        console.log(data)
        setchosen(data)
        setOpenedit(true);
    };

    const handleCloseedit = () => {
        setOpenedit(false);
    };

    const handleClickOpenpaysalary = (data) => {
        if(schooldetails_[0].payment.length===0 || schooldetails_[0].payment[schooldetails_[0].payment.length-1].amount==="trial" ){

            settrialmode(true)

        }else{
        setchosen(data)
        setOpenpaysalary(true);
        }
    };
    const handleClosepaysalary = () => {
        setOpenpaysalary(false);
    };

    const handleClickOpenimage = (data) => {
        console.log(data)
        setchosen(data)
        setOpenimage(true);
    };
    const handleCloseimage = () => {
        setOpenimage(false);
    };

    const handleClickOpendelete = (data) => {
        console.log(data)
        setchosen(data)
        setOpendelete(true);
    };
    const handleClosedelete = () => {
        setOpendelete(false);
    };

    const handleClickOpenadd = (data) => {
        setchosen(data)
        setOpenadd(true);
    };
    const handleCloseadd = () => {
        setOpenadd(false);
    };

    const handleClickOpensrv = (data) => {
        console.log(data)
        setchosen(data)
        setOpensrv(true);
    };
    const handleClosesrv = () => {
        setOpensrv(false);
    };

    const handleClickOpenview = (data) => {
        console.log(data)
        setchosen(data)
        setOpenview(true);
    };

    const handleCloseview = () => {
        setOpenview(false);
    };


    const handleClickOpendeduction = (data) => {
        console.log(data)
        setchosen(data)
        setOpendeduction(true);
    };

    const handleClosededuction = () => {
        setOpendeduction(false);
    };


    const handleClickOpenbase = (data) => {
        console.log(data)
        setchosen(data)
        setOpenbase(true);
    };

    const handleClosebase = () => {
        setOpenbase(false);
    };




    const handleClickOpensupply = (data) => {
        console.log(data)
        setchosen(data)
        setOpensupply(true);
    };
    const handleClosesupply = () => {
        setOpensupply(false);
    };


    const handleClickOpenpayslip = (data) => {
        console.log(data)
        setchosen(data)
        setOpenpayslip(true);
    };
    const handleClosepayslip = () => {
        setOpenpayslip(false);
    };


    const handleClickOpenbonus = (data) => {
        console.log(data)
        setchosen(data)
        setOpenbonus(true);
    };
    const handleClosebonus = () => {
        setOpenbonus(false);
    };

  

    const imagehandler = (e) => {
        const reader = new FileReader()
        reader.onload = () => {
            if (reader.readyState === 2) {
                setperson(reader.result)
            }
        }

        reader.readAsDataURL(e.target.files[0])
    }


    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


 
    const handleChange_gender = (e) => {
        setselectedgender(e.target.value)
    }
 
    const handleChangepaymonth = (e) => {
        setpaymonth(e.target.value)
    }
    const handleChange_religion = (e) => {
        setselectedreligion(e.target.value)
    }
    const handleChange_class = (e) => {
        setselectedclass(e.target.value)
    }

  
    const sorting = (e) => {

        setsorter(e.target.value)
    }
    const sortingmonth = (e) => {

        setsortermonth(e.target.value)
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

   const earning  =(data)=>{
    var total = 0
    for(var i in data){
            total+= parseFloat(data[i].amount)
        }
        return total
   }


   const deduction  =(data)=>{
    var total = 0
    for(var i in data){
            total+= parseFloat(data[i].amount)
        }
        return total
   }

   
        
   if(activeworker===false && inactiveworker===false){

    setactiveworker(true)
    setgettingdatatype("Active")

}

const changestudenttype=(e, name)=>{
    console.log(e.target.checked)
    if(name==="Active"){
        setactiveworker(e.target.checked)
        setinactiveworker(false)
        setgettingdatatype("Active")
    }
     else if(name==="Inactive"){
        setactiveworker(false)
        setinactiveworker(e.target.checked)
        setgettingdatatype("Inactive")

    }   
   

    

}

const settrialmode_=()=>{
    settrialmode(false)
}


   


    return (

        <Box sx={{ width: '100%' }}>



            <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>

            <Dialog maxWidth="lg" onClose={handleClosedelete} aria-labelledby="customized-dialog-title" open={opendelete}>

                <DialogContent dividers>

                    <p>
                        Are you sure you want to delete this ?
                    </p>

                </DialogContent>
                <DialogActions>

                    {loading === false ?
                        <Button autoFocus onClick={() => deleter(chosen)} color="primary">
                            Yes
                        </Button>
                        :
                        <Button disabled={loading} >

                            <CircularProgress size={20} /> Loading...

                        </Button>
                    }


                    <Button autoFocus onClick={handleClosedelete} color="primary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog maxWidth="lg" onClose={settrialmode_} aria-labelledby="customized-dialog-title" open={trialmode}>
 <DialogTitle id="customized-dialog-title" onClose={settrialmode_}  style={{ backgroundColor: "gray", color: "white" }}>
                    Trial Mode
                    <IconButton onClick={settrialmode_}  style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>

                    <p>

                    You are on a trial mode, go to pricing and settle payment in order to use this function.

                    </p>

                </DialogContent>
                <DialogActions>




                 

                   <Link to="/pricing"><Button autoFocus  style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                        Pricing
                    </Button>
                    </Link> 
                </DialogActions>
            </Dialog>

            <Dialog fullWidth width="sm" onClose={handleCloseview} aria-labelledby="customized-dialog-title" open={openview}>
                 <DialogTitle id="customized-dialog-title" onClose={handleCloseview} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   Staff Information
                    <IconButton onClick={handleCloseview} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <div style={{textAlign:"center", marginLeft:"40%"}}>
                    <Avatar style={{ width: 100, height: 100, border: "solid", borderColor: "#f0f0f0" }} alt="Travis Howard" src={person} />
                    </div>

                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <ListItem>
                            <ListItemText primary="Name here" secondary="Name" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.location} secondary="Contact" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.location} secondary="Email" />
                        </ListItem> 
                        <ListItem>
                            <ListItemText primary={chosen.snnit} secondary="SNNIT Number" />
                        </ListItem> 
                        <ListItem>
                            <ListItemText primary={chosen.accountnumber} secondary="Bank Account Number" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={chosen.Bank} secondary="Bank Name" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.contact} secondary="Residence Address" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.contact} secondary="Gender" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.contact} secondary="Date of Birth" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.contact} secondary="Religion" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.contact} secondary="Status" />
                        </ListItem>
                        <Divider />



                    </List>

                    <br />

              
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCloseview} color="primary">
                        close
                    </Button>
                </DialogActions>
            </Dialog>




{/* base slary */}
            <Dialog fullWidth width="sm" onClose={handleClosebase} aria-labelledby="customized-dialog-title" open={openbase}>
                <DialogTitle id="customized-dialog-title" onClose={handleClosebase} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                  Base Salary 
                    <IconButton onClick={handleClosebase} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>
                                    
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <ListItem>
                            <ListItemText primary={chosen !==""? chosen.lastname+" "+chosen.othernames:""} secondary="Name" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                        {console.log(chosen)}
                            <ListItemText primary={chosen && rows.filter(bug=>bug._id===chosen._id)[0].finance[0].basesalary} secondary="Base Salary " />
                        </ListItem>

<Divider/>
<br/>
                    </List>
                    <div style={{textAlign:"center"}}>
                    <div style={{border:"solid", width:"70%", marginLeft:"15%", borderWidth:"0.5px", borderColor:"grey", borderRadius:"5px"}}>
                    <p>CHANGE BASE SALARY</p>
                    <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="basesalary"
    placeholder="Base Salary"
    type="number"
    variant="outlined"
    size="small"
 InputProps={{
            startAdornment: <InputAdornment position="start">GHC </InputAdornment>,
          }}


/>
</FormControl>
<br/>
  {loading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
        style={{marginTop:10}}

      >
        Loading...
      </LoadingButton>:
                           
                    <Button  onClick={()=>addbasesalary()}  style={{marginTop:10,backgroundColor:" #e0eff9" , color:"#1976d2"}}>Change Base Salary</Button>

                            }
                   

                    <br /> <br />

              </div>
              </div>
              <br />
              
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClosebase} color="primary">
                        close
                    </Button>
                </DialogActions>
            </Dialog>


{/* pay slary */}
            <Dialog fullWidth width="sm" onClose={handleClosepaysalary} aria-labelledby="customized-dialog-title" open={openpaysalary}>
                <DialogTitle id="customized-dialog-title" onClose={handleClosepaysalary} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   Pay Salary
                    <IconButton onClick={handleClosepaysalary} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>
                                    
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <ListItem>
                            <ListItemText primary={chosen.lastname+" "+chosen.othernames} secondary="Employer's Name" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen!==""? "GHC " + chosen.finance[0].basesalary :0} secondary="Base Salary " />
                        </ListItem><Divider />
                        <ListItem>
                            <ListItemText primary={chosen!==""? "GHC " + earning(chosen.finance[0].salarybonuses) :0} secondary="Net Earnings " />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen!==""? "GHC " + earning(chosen.finance[0].deductions) :0} secondary="Net Deductions " />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.length!==0 ? "GHC "+ (parseFloat(chosen.finance[0].basesalary) + earning(chosen.finance[0].salarybonuses)-earning(chosen.finance[0].deductions)):0}secondary="Net Salary" />
                        </ListItem>

<Divider/>
                    </List>

           
                    <div align="center">  


                                      <FormControl style={{ marginRight: 20, marginTop:10, color:"white" }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Month</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            
                            value={paymonth}
                            style={{ minWidth: 200, padding: 0, margin: 0 ,textAlign:"left" }}
                            autoWidth
                            label="Month"
                            size="small"
                            onChange={handleChangepaymonth}
                        >
                            <MenuItem value="none">
                                <em>Choose Month</em>
                            </MenuItem>
                            <MenuItem value={"January "+year }>{"January "+year }</MenuItem>
                            <MenuItem value={"February "+year }>{"February "+year }</MenuItem>
                            <MenuItem value={"March "+year }>{"March "+year }</MenuItem>
                            <MenuItem value={"April "+year }>{"April "+year }</MenuItem>
                            <MenuItem value={"May "+year }>{"May "+year }</MenuItem>
                            <MenuItem value={"June "+year }>{"June "+year }</MenuItem>
                            <MenuItem value={"July "+year }>{"July "+year }</MenuItem>
                            <MenuItem value={"August "+year }>{"August "+year }</MenuItem>
                            <MenuItem value={"September "+year }>{"September "+year }</MenuItem>
                            <MenuItem value={"October "+year }>{"October "+year }</MenuItem>
                            <MenuItem value={"November "+year }>{"November "+year }</MenuItem>
                            <MenuItem value={"December "+year }>{"December "+year }</MenuItem>
                         
                        </Select>
                    </FormControl>
                     {loading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
        style={{marginTop:10}}
        
      >
        Loading...
      </LoadingButton>:
                           
                    <Button  style={{marginTop:10, backgroundColor:" #e0eff9" , color:"#1976d2"}} onClick={paysalary}>Make payment</Button>

                            }
                    
                    </div>
              <br />
              
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClosepaysalary} color="primary">
                        close
                    </Button>
                </DialogActions>
            </Dialog>


{/* bonuses */}
            <Dialog fullWidth width="sm" onClose={handleClosebonus} aria-labelledby="customized-dialog-title" open={openbonus}>
                <DialogTitle id="customized-dialog-title" onClose={handleClosebonus} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   Salary Bonus
                    <IconButton onClick={handleClosebonus} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>
                                    
                 

                    <div style={{textAlign:"center"}}>
                    <div style={{border:"solid", width:"70%", marginLeft:"15%", borderWidth:"0.5px", borderColor:"grey", borderRadius:"5px"}}>
                    <p>ADD SALARY BONUS</p>
                    <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="bonussalary"
    placeholder="Salary Bonus"
    type="number"
    variant="outlined"
    size="small"
 InputProps={{
            startAdornment: <InputAdornment position="start">GHC </InputAdornment>,
          }}


/>
</FormControl>                    
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="bonussalarydes"
    placeholder="Salary Description"
    type="text"
    variant="outlined"
    size="small"

/>
</FormControl>
<br/>

 {loading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
        style={{marginTop:10}}
      >
        Loading...
      </LoadingButton>:
                           
                    <Button  onClick={()=>addbonussalary()} style={{marginTop:10, backgroundColor:" #e0eff9" , color:"#1976d2"
}}>ADD BONUS</Button>

                            }
                    


                    <br /> <br />

              </div>
              </div>
              <br />
              <Divider/>
              <p style={{textAlign:"center"}}>BONUSES EARNED</p>
<TableContainer component={Paper}>
      <Table sx={{ minWidth: 550 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Date</TableCell>
            <TableCell align="left">Amount</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="left"></TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
  
          {chosen !==""?  rows.filter(bug=>bug._id===chosen._id)[0].finance[0].salarybonuses.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
             
              <TableCell align="left">{months[parseInt((row.date.split("-"))[1])-1] +" "+(row.date.split("-"))[2]+", "+(row.date.split("-"))[0] }</TableCell>
              <TableCell align="left">{row.amount}</TableCell>
              <TableCell align="left">{row.description}</TableCell>
              <TableCell align="left"><Button onClick={()=>deletebonus(row)}>Delete</Button></TableCell>
            </TableRow>
          )):""}
        </TableBody>
      </Table>
    </TableContainer>



                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClosebonus} color="primary">
                        close
                    </Button>
                </DialogActions>
            </Dialog>


{/* deductions */}
            <Dialog fullWidth width="sm" onClose={handleClosededuction} aria-labelledby="customized-dialog-title" open={opendeduction}>
                <DialogTitle id="customized-dialog-title" onClose={handleClosededuction} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                  Deduction
                    <IconButton onClick={handleClosededuction} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
               <DialogContent dividers>
                                    
                 

                    <div style={{textAlign:"center"}}>
                    <div style={{border:"solid", width:"70%", marginLeft:"15%", borderWidth:"0.5px", borderColor:"grey", borderRadius:"5px"}}>
                    <p>ADD SALARY DEDUCTIONS</p>
                    <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="deductionsalary"
    placeholder="Salary DEDUCTION"
    type="number"
    variant="outlined"
    size="small"
 InputProps={{
            startAdornment: <InputAdornment position="start">GHC </InputAdornment>,
          }}


/>
</FormControl>                    
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="deductionsalarydes"
    placeholder="Deduction Description"
    type="text"
    variant="outlined"
    size="small"

/>
</FormControl>
<br/>
                    

                    
 {loading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
        style={{marginTop:10, padding:5}}
      >
        Loading...
      </LoadingButton> :
                           
                    <Button  onClick={()=>salarydeduction()} style={{marginTop:10, backgroundColor:" #e0eff9" , color:"#1976d2"
}}>ADD Deduction</Button>

                            }
            
                    <br /> <br />

              </div>
              </div>
              <br />
              <Divider/>
              <p style={{textAlign:"center"}}>LIST OF DEDUCTIONS</p>
<TableContainer component={Paper}>
      <Table sx={{ minWidth: 550 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Date</TableCell>
            <TableCell align="left">Amount</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="left"></TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
  
          {chosen !==""?  rows.filter(bug=>bug._id===chosen._id)[0].finance[0].deductions.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
             
              <TableCell align="left">{months[parseInt((row.date.split("-"))[1])-1] +" "+(row.date.split("-"))[2]+", "+(row.date.split("-"))[0]}</TableCell>
              <TableCell align="left">{row.amount}</TableCell>
              <TableCell align="left">{row.description}</TableCell>
              <TableCell align="left"><Button onClick={()=>deletedeductions(row)}>Delete</Button></TableCell>
            </TableRow>
          )):""}
        </TableBody>
      </Table>
    </TableContainer>



                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClosededuction} color="primary">
                        close
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog maxWidth="lg" onClose={handleClosesupply} aria-labelledby="customized-dialog-title" open={opensupply}>
                <DialogTitle id="customized-dialog-title" onClose={handleClosesupply} style={{ backgroundColor: "darkblue", color: "white" }}>
                    Add to Supply List
                </DialogTitle>

                <DialogContent dividers style={{ textAlign: "center" }}>


                    <FormControl variant="standard" style={{ margin: 20 }}>

                        <Input
                            id="Quantity"
                            placeholder="Quantity Supplied"
                            type="number"
                            className="signup"


                        />
                    </FormControl>
                    <FormControl variant="standard" style={{ margin: 20 }}>

                        <Input
                            id="SRVrec"
                            placeholder="SRVs Received"
                            type="number"
                            className="signup"


                        />
                    </FormControl>
                    <br />
                    <FormControl variant="standard" style={{ margin: 20 }}>

                        <Input
                            id="supplier"
                            placeholder="Supplied By..."
                            type="text"
                            className="signup"


                        />
                    </FormControl>

                    <FormControl style={{ margin: 20 }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Semester</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={age}
                            style={{ minWidth: 200, padding: 0, margin: 0 }}
                            onChange={handleChange}
                            autoWidth
                            label="Semester"
                        >
                            <MenuItem value="">
                                <em>Choose semester</em>
                            </MenuItem>
                            <MenuItem value={year + "/Semester 1"}>{year + "/Semester 1"}</MenuItem>
                            <MenuItem value={year + "/Semester 2"}>{year + "/Semester 2"}</MenuItem>

                        </Select>
                    </FormControl>

                    <br />


                </DialogContent>

                <DialogActions>
                    {loading === false ?
                        <Button autoFocus onClick={() => addtolist(chosen)} color="primary">
                            Submit
                        </Button>
                        :
                        <Button disabled={loading} >

                            <CircularProgress size={20} /> Loading...

                        </Button>
                    }

                    <Button autoFocus onClick={handleClosesupply} color="primary">
                        close
                    </Button>
                </DialogActions>
            </Dialog>


{/* payslip */}
            <Dialog fullWidth maxWidth="md" onClose={handleClosepayslip} aria-labelledby="customized-dialog-title" open={openpayslip}>
               
                <DialogTitle id="customized-dialog-title" onClose={handleClosepayslip} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Payslip
                     
                    <FormControl style={{ marginLeft: 20, color:"white" }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Month</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            
                            value={age}
                            style={{ minWidth: 200, padding: 0, margin: 0 , color:"white"}}
                            onChange={handleChange}
                            autoWidth
                            label="Month"
                            size='small'
                        >
                            <MenuItem value="">
                                <em>Choose Month</em>
                            </MenuItem>
                           
                            {chosen!==""?chosen.finance[0].payment.map((list,index)=>
                            <MenuItem value={list.paymonth}>{list.paymonth}</MenuItem>

                            ):""}

                        </Select>
                    </FormControl>
                                        <IconButton onClick={handleClosepayslip} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>

                </DialogTitle>
                              
                <DialogContent dividers style={{ textAlign: "center" }}>

                <Container>
             
                <ReactToPrint
             pageStyle='@page { size: A4; margin: 0mm; } @media print { body { scale:1; font-size:8pt; font-family:georgia, times, serif; } }'
           
           trigger={()=><Button>Print Payslip</Button>}
            content ={()=>componentRefreceipt.current}
            />
            <br/>

<div ref={componentRefreceipt} >
<div style={{textAlign:"center"}}>
<img src={schooldetails_.length!==0 && "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/"+schooldetails_[0].image} width="80px" alt="schoologo"/> <br/>
    <h4 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].schoolname).toUpperCase()} </h4>
    <h5 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].address).toUpperCase()} </h5>
    <h3 style={{margin:4}}>PAYSLIP</h3>
</div>
{/* <p style={{fontSize:"20px", fontWeight:"bolder"}}>{schooldetails_.length!==0 && schooldetails_[0].schoolname.toUpperCase()}</p> */}
<p><b> Payslip for {age}</b></p>
<Divider/>
<Grid container align="left" mt={2} mb={2}>

<Grid xs={6}> <b>Employee ID : </b> {chosen.ID} </Grid>
<Grid xs={6}> <b>Name : </b> {chosen.lastname+" "+chosen.othernames} </Grid>
<Grid xs={6}> <b>Department : </b> {chosen.position} </Grid>
<Grid xs={6}> <b>Designation : </b>{chosen.positiondescription} </Grid>

<Grid xs={6}> <b>Date of Birth : </b> { chosen.length!==0 && months[parseInt((chosen.dob.split("-"))[1])-1] +" "+(chosen.dob.split("-"))[2]+", "+(chosen.dob.split("-"))[0] 
} </Grid>
<Grid xs={6}> <b>SNNIT Number : </b>{chosen.snnit} </Grid>
<Grid xs={6}> <b>Bank : </b>{chosen.bank} </Grid>
<Grid xs={6}> <b>Bank Account No. : </b>{chosen.accountnumber} </Grid>
<Grid xs={6}> <b>Pay Date : </b> {chosen!=="" && age!==""? months[parseInt(( chosen.finance[0].payment.filter(bug=>bug.paymonth===age)[0].date.split("-"))[1])-1] +" "+(chosen.finance[0].payment.filter(bug=>bug.paymonth===age)[0].date.split("-"))[2]+", "+(chosen.finance[0].payment.filter(bug=>bug.paymonth===age)[0].date.split("-"))[0]:""} </Grid>

</Grid>

<TableContainer >
<Table className="table1" style={{width:"100%",borderCollapse: "collapse", textAlign:"left", border:"solid", borderWidth:"0.5px"}} >

<thead style={{fontWeight:"bolder", textAlign:"center",borderCollapse: "collapse",}}>
<tr className='tr1' style={{borderCollapse: "collapse"}}>
    <th  className='td1' colSpan={2}>Earnings</th>
    
    <th  className='td1' colSpan={2}>Deductions</th>
</tr>
   
</thead>
<tbody style={{borderCollapse: "collapse"}}>


{/* {chosen!==""?chosen.finance.map((list, index)=>
    <tr>
        <td className='td1'>Base Salary </td>
        <td  className='td1'>GHC {list.basesalary}</td>
    </tr>
):""}  */}

{/* {chosen!==""?chosen.finance[0].salarybonuses.map((list, index)=>
    <tr>
        <td className='td1'>{list.description}</td>
        <td  className='td1'>GHC {list.amount}</td>
    </tr>
):""}  */}
    
    <tr >
        <td colspan={2} style={{borderCollapse: "collapse", margin:0, padding:0,  border:"solid", borderWidth:"0.5px",   borderColor:" rgba(0, 0, 56, 0.1)"
}} >


{console.log(chosen.length)}

       {/* {totalearned = chosen!=="" && age !==""? parseFloat(chosen.finance[0].basesalary)  : 0 } */}
       <table style={{width:"100%", margin:0, padding:0,borderCollapse: "collapse",}}>
<tr colspan={2} style={{width:"100%",  padding:0,borderCollapse: "collapse",}}>
              <td style={{width:"50%",borderCollapse: "collapse", border:"solid",    borderColor: "rgba(0, 0, 56, 0.1)", borderWidth:"0.5px", margin:"-2px", padding:5}}>Base Salary</td>
              <td style={{width:"100%",borderCollapse: "collapse",  border:"solid",    borderColor: "rgba(0, 0, 56, 0.1)", borderWidth:"0.5px", margin:"-2px", padding:5}}>GHC {totalearned = chosen!=="" && age !==""? parseFloat(chosen.finance[0].basesalary)  : 0}  {calcnet(parseFloat(totalearned = chosen!=="" && age !==""? parseFloat(chosen.finance[0].basesalary)  : 0))}</td>
        
          </tr>
{chosen!=="" && age !==""? chosen.finance[0].payment.filter(bug=>bug.paymonth===age)[0].bonuses.map((list, index)=>
          <tr colspan={2} style={{width:"100%",  padding:0}}>
              <td style={{width:"50%",borderCollapse: "collapse", border:"solid",    borderColor: "rgba(0, 0, 56, 0.1)", borderWidth:"0.5px", margin:"-2px", padding:5}}>{list.description}</td>
              {/* <td style={{width:"100%",borderCollapse: "collapse",  border:"solid",    borderColor: "rgba(0, 0, 56, 0.1)", borderWidth:"0.5px", margin:"-2px", padding:5}}>GHC {totalearned += parseFloat(list.amount)}</td> */}
              <td style={{width:"100%",borderCollapse: "collapse",  border:"solid",    borderColor: "rgba(0, 0, 56, 0.1)", borderWidth:"0.5px", margin:"-2px", padding:5}}>GHC {parseFloat(list.amount)} {calcnet(parseFloat(list.amount))}</td>
        
          </tr>
 ) :""        
}

 <tr colspan={2} style={{width:"100%",  padding:0,borderCollapse: "collapse",}}>
        
              <td style={{width:"50%",borderCollapse: "collapse", border:"solid",    borderColor: "rgba(0, 0, 56, 0.1)", borderWidth:"0.5px", margin:"-2px", padding:5}}><b>Total Earnings</b></td>
              <td style={{width:"100%",borderCollapse: "collapse",  border:"solid",    borderColor: "rgba(0, 0, 56, 0.1)", borderWidth:"0.5px", margin:"-2px", padding:5}}>GHC {netsalary}</td>
        
          </tr>
         
      </table>
     
        </td>
        <td colspan={2} style={{ margin:0,borderCollapse: "collapse", padding:0, border:"solid", borderWidth:"0.5px",   borderColor:" rgba(0, 0, 56, 0.1)" }}>



  <table style={{width:"100%", margin:0, padding:0, borderCollapse: "collapse", }}>

{chosen!=="" && age !==""? chosen.finance[0].payment.filter(bug=>bug.paymonth===age)[0].deductions.map((list, index)=>
          <tr colspan={2} style={{width:"100%",  padding:0,borderCollapse: "collapse",}}>
              <td style={{width:"50%",borderCollapse: "collapse", border:"solid",    borderColor: "rgba(0, 0, 56, 0.1)", borderWidth:"0.5px", margin:"-2px", padding:5}}>{list.description}</td>
              <td style={{width:"100%",borderCollapse: "collapse",  border:"solid",    borderColor: "rgba(0, 0, 56, 0.1)", borderWidth:"0.5px", margin:"-2px", padding:5}}>GHC {totaldeduct += parseFloat(list.amount)}</td>
        
          </tr>
 ) :""        
}

 <tr colspan={2} style={{width:"100%",  padding:0,borderCollapse: "collapse",}}>
        
              <td style={{width:"50%",borderCollapse: "collapse", border:"solid",    borderColor: "rgba(0, 0, 56, 0.1)", borderWidth:"0.5px", margin:"-2px", padding:5}}><b>Total Deductions</b></td>
              <td style={{width:"100%",borderCollapse: "collapse",  border:"solid",    borderColor: "rgba(0, 0, 56, 0.1)", borderWidth:"0.5px", margin:"-2px", padding:5}}>GHC {totaldeduct}</td>
        
          </tr>
         
      </table>



        </td>
        
        
    </tr> 
 
   
</tbody>
   <tr style={{borderCollapse: "collapse"}}>
        
              <td colSpan={3} style={{ border:"solid",borderCollapse: "collapse", textAlign:"center",   borderColor: "rgba(0, 0, 56, 0.1)", borderWidth:"0.5px", margin:"-2px", padding:5}}><b>Net Salary: GHC {netsalary-totaldeduct}</b></td>
              {/* <td colSpan={2} style={{  border:"solid",borderCollapse: "collapse",    borderColor: "rgba(0, 0, 56, 0.1)", borderWidth:"0.5px", margin:"-2px", padding:5}}><b></b></td> */}
        
          </tr>
</Table>
</TableContainer>
<Grid container  mt={6} mb={2}>

<Grid xs={6} align="left"> .............................................. <br/> Employer's Signature</Grid>
<Grid xs={6} align="right"> ...............................................  <br/> Employee's Signature</Grid>

</Grid>
</div>

             </Container>





                </DialogContent>

                <DialogActions>
                         
                         

                </DialogActions>
            </Dialog>

            <Dialog fullWidth maxWidth="sm" onClose={handleCloseimage} aria-labelledby="customized-dialog-title" open={openimage}>
                 <DialogTitle id="customized-dialog-title" onClose={handleCloseimage} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   {chosen.lastname+" "+chosen.othernames}
                    <IconButton onClick={handleCloseimage} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>

                <DialogContent dividers style={{ textAlign: "center" }}>


                 <img src={'https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/'+chosen.image} width="100%"/>

                </DialogContent>

            </Dialog>


            <div>

                <Dialog maxWidth="lg" onClose={handleCloseadd} aria-labelledby="customized-dialog-title" open={openadd}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseadd} style={{ backgroundColor: "darkblue", color: "white" }}>
                        Add Staff
                    </DialogTitle>

                    <DialogContent dividers>



                        <div style={{ width: "100%", textAlign: "center" }}>



                            <input

                                style={{ display: "none" }}
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={(e) => imagehandler(e)}
                            />
                            <label htmlFor="contained-button-file">
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <CameraAltIcon style={{ cursor: "pointer", backgroundColor: "darkblue", padding: 6, borderRadius: "100%", color: "white", fontSize: "26px" }} />
                                    }
                                >
                                    <Avatar style={{ width: 100, height: 100, border: "solid", borderColor: "#f0f0f0" }} alt="Travis Howard" src={person} />

                                </Badge>

                            </label>


                        </div>

                        <br />
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="surname"
                                placeholder="Surname"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Surname"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="othernames"
                                placeholder="Other name(s)"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Othername(s)"
                                size="small"



                            />
                        </FormControl>
                        <br />

                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="date"
                                label="Date o Birth"
                                type="date"
                                defaultValue="2017-05-24"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="signup"
                                size="small"
                            />
                        </FormControl>

                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="residence"
                                placeholder="Residence Address"
                                type="address"
                                className="signup"
                                label="Residence Address"
                                size="small"



                            />
                        </FormControl>

                        <br />
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="hometown"
                                placeholder="Hometown"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Hometown"
                                size="small"



                            />
                        </FormControl>

                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Religion</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={selectedreligion}
                                label="Religion"
                                className="signup"
                                variant="outlined"
                                size="small"

                                onChange={handleChange_religion}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>

                            </Select>


                        </FormControl>

                        <br />

                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Gender</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={selectedgender}
                                label="Gender"
                                className="signup"
                                variant="outlined"
                                size="small"
                                style={{ padding: 0 }}

                                onChange={handleChange_gender}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>

                            </Select>


                        </FormControl>


                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Position</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={selectedclass}
                                label="Position"
                                className="signup"
                                variant="outlined"
                                size="small"

                                onChange={handleChange_class}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="Class 1">Class 1</MenuItem>
                                <MenuItem value="Class 2">Class 2</MenuItem>
                                <MenuItem value="Class 3">Class 3</MenuItem>
                                <MenuItem value="Class 4">Class 4</MenuItem>
                                <MenuItem value="Class 5">Class 5</MenuItem>


                            </Select>


                        </FormControl>
<br/>
                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Position Description</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={selectedclass}
                                label="Position Description"
                                className="signup"
                                variant="outlined"
                                size="small"

                                onChange={handleChange_class}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="Class 1">Class 1</MenuItem>
                                <MenuItem value="Class 2">Class 2</MenuItem>
                                <MenuItem value="Class 3">Class 3</MenuItem>
                                <MenuItem value="Class 4">Class 4</MenuItem>
                                <MenuItem value="Class 5">Class 5</MenuItem>


                            </Select>


                        </FormControl>

                        
                      

                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="contact"
                                placeholder="Contact"
                                type="tel"
                                className="signup"
                                variant="outlined"
                                label="Contact"
                                size="small"



                            />
                        </FormControl>

<br/>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="email"
                                placeholder="Email"
                                type="email"
                                className="signup"
                                variant="outlined"
                                label="Email"
                                size="small"



                            />
                        </FormControl>

                        
                       



                        <FormControl style={{ marginLeft: 10 }}>
                            <FormLabel id="demo-row-radio-buttons-group-label">Marital Status</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value="Single" control={<Radio />} label="Single" />
                                <FormControlLabel value="Married" control={<Radio />} label="Married" />
                                
                            </RadioGroup>
                        </FormControl>

                        <br />
                        <div style={{ textAlign: "center" }}>

                            {loading === false ?
                                <Button autoFocus onClick={() => addschool()} color="primary">
                                    Submit
                                </Button>
                                :
                                <Button disabled={loading} >

                                    <CircularProgress size={20} /> Loading...

                                </Button>
                            }


                        </div>
                    </DialogContent>

                    <DialogActions>
                        <Button autoFocus onClick={handleCloseadd} color="primary">
                            close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>


            <div>

                <Dialog  maxWidth="lg" onClose={handleCloseedit} aria-labelledby="customized-dialog-title" open={openedit}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseedit} style={{ backgroundColor: "darkblue", color: "white" }}>
                        Edit Staff
                    </DialogTitle>

                    <DialogContent dividers>



<div style={{ width: "100%", textAlign: "center" }}>



    <input

        style={{ display: "none" }}
        id="contained-button-file"
        multiple
        type="file"
        onChange={(e) => imagehandler(e)}
    />
    <label htmlFor="contained-button-file">
        <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
                <CameraAltIcon style={{ cursor: "pointer", backgroundColor: "darkblue", padding: 6, borderRadius: "100%", color: "white", fontSize: "26px" }} />
            }
        >
            <Avatar style={{ width: 100, height: 100, border: "solid", borderColor: "#f0f0f0" }} alt="Travis Howard" src={person} />

        </Badge>

    </label>


</div>

<br />
<FormControl variant="standard" style={{ margin: 10 }}>

    <TextField
        id="surname"
        placeholder="Surname"
        type="text"
        className="signup"
        variant="outlined"
        label="Surname"
        size="small"



    />
</FormControl>
<FormControl variant="standard" style={{ margin: 10 }}>

    <TextField
        id="othernames"
        placeholder="Other name(s)"
        type="text"
        className="signup"
        variant="outlined"
        label="Othername(s)"
        size="small"



    />
</FormControl>
<br />

<FormControl variant="standard" style={{ margin: 10 }}>

    <TextField
        id="date"
        label="Date o Birth"
        type="date"
        defaultValue="2017-05-24"

        InputLabelProps={{
            shrink: true,
        }}
        className="signup"
        size="small"
    />
</FormControl>

<FormControl variant="standard" style={{ margin: 10 }}>

    <TextField
        id="residence"
        placeholder="Residence Address"
        type="address"
        className="signup"
        label="Residence Address"
        size="small"



    />
</FormControl>

<br />
<FormControl variant="standard" style={{ margin: 10 }}>

    <TextField
        id="hometown"
        placeholder="Hometown"
        type="text"
        className="signup"
        variant="outlined"
        label="Hometown"
        size="small"



    />
</FormControl>

<FormControl style={{ margin: 10 }}>
    <InputLabel id="demo-simple-select-helper-label">Religion</InputLabel>
    <Select
        id="demo-simple-select-helper"
        value={selectedreligion}
        label="Religion"
        className="signup"
        variant="outlined"
        size="small"

        onChange={handleChange_religion}
    >
        <MenuItem value="">
            <em>None</em>
        </MenuItem>
        <MenuItem value="Male">Male</MenuItem>
        <MenuItem value="Female">Female</MenuItem>

    </Select>


</FormControl>

<br />

<FormControl style={{ margin: 10 }}>
    <InputLabel id="demo-simple-select-helper-label">Gender</InputLabel>
    <Select
        id="demo-simple-select-helper"
        value={selectedgender}
        label="Gender"
        className="signup"
        variant="outlined"
        size="small"
        style={{ padding: 0 }}

        onChange={handleChange_gender}
    >
        <MenuItem value="">
            <em>None</em>
        </MenuItem>
        <MenuItem value="Male">Male</MenuItem>
        <MenuItem value="Female">Female</MenuItem>

    </Select>


</FormControl>


<FormControl style={{ margin: 10 }}>
    <InputLabel id="demo-simple-select-helper-label">Position</InputLabel>
    <Select
        id="demo-simple-select-helper"
        value={selectedclass}
        label="Position"
        className="signup"
        variant="outlined"
        size="small"

        onChange={handleChange_class}
    >
        <MenuItem value="">
            <em>None</em>
        </MenuItem>
        <MenuItem value="Class 1">Class 1</MenuItem>
        <MenuItem value="Class 2">Class 2</MenuItem>
        <MenuItem value="Class 3">Class 3</MenuItem>
        <MenuItem value="Class 4">Class 4</MenuItem>
        <MenuItem value="Class 5">Class 5</MenuItem>


    </Select>


</FormControl>
<br/>
<FormControl style={{ margin: 10 }}>
    <InputLabel id="demo-simple-select-helper-label">Position Description</InputLabel>
    <Select
        id="demo-simple-select-helper"
        value={selectedclass}
        label="Position Description"
        className="signup"
        variant="outlined"
        size="small"

        onChange={handleChange_class}
    >
        <MenuItem value="">
            <em>None</em>
        </MenuItem>
        <MenuItem value="Class 1">Class 1</MenuItem>
        <MenuItem value="Class 2">Class 2</MenuItem>
        <MenuItem value="Class 3">Class 3</MenuItem>
        <MenuItem value="Class 4">Class 4</MenuItem>
        <MenuItem value="Class 5">Class 5</MenuItem>


    </Select>


</FormControl>




<FormControl variant="standard" style={{ margin: 10 }}>

    <TextField
        id="contact"
        placeholder="Contact"
        type="tel"
        className="signup"
        variant="outlined"
        label="Contact"
        size="small"



    />
</FormControl>

<br/>
<FormControl variant="standard" style={{ margin: 10 }}>

    <TextField
        id="email"
        placeholder="Email"
        type="email"
        className="signup"
        variant="outlined"
        label="Email"
        size="small"



    />
</FormControl>






<FormControl style={{ marginLeft: 10 }}>
    <FormLabel id="demo-row-radio-buttons-group-label">Marital Status</FormLabel>
    <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
    >
        <FormControlLabel value="Single" control={<Radio />} label="Single" />
        <FormControlLabel value="Married" control={<Radio />} label="Married" />
        
    </RadioGroup>
</FormControl>

<br />
<div style={{ textAlign: "center" }}>

    {loading === false ?
        <Button autoFocus onClick={() => addschool()} color="primary">
            Submit
        </Button>
        :
        <Button disabled={loading} >

            <CircularProgress size={20} /> Loading...

        </Button>
    }


</div>
</DialogContent>


                    <DialogActions>
                        <Button autoFocus onClick={handleCloseedit} color="primary">
                            close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>



            <Paper sx={{ width: '100%', mb: 2 }}>

                <EnhancedTableToolbar numSelected={selected.length} />

                <Grid container>
                
                    <Grid lg={3} xs={3} md={6} style={{ textAlign: "center" }}>
                        <FormControl style={{ marginRight: 2, minWidth: 80, padding: 0, marginTop: 10, borderColor: "#2196f3" }}>
                            <Select
                                id="demo-simple-select-autowidth"
                                value={sorter}
                                onChange={sorting}
                                autoWidth
                                style={{ height: "38px", width: "100%", padding: 0, color: "grey", borderColor: "#2196f3", outline: "none" }}
                            >
                                      <Container>
                               <p align="center" style={{margin:5}} ><b>Student Type</b></p>
                               <Divider/>
                               <FormGroup style={{marginTop:10, marginBottom:10}}>
                                    <FormControlLabel control={<Switch checked={activeworker} onChange={(e)=>changestudenttype(e,"Active")} />} label="Active" />
                                    <FormControlLabel control={<Switch checked={inactiveworker} onChange={(e)=>changestudenttype(e,"Inactive")}/>} label="Inactive" />
                                    </FormGroup>
                                 
                                    <p align="center" style={{margin:5}} ><b>Sort by class</b></p>
                                    <Divider/>
                               </Container>


                                <MenuItem value="none">Sort Staff</MenuItem>
                                <MenuItem value="All">All</MenuItem>
                                <MenuItem value="Teaching Staff">Teaching Staff</MenuItem>
                                <MenuItem value="Non-Teaching Staff">Non-Teaching Staff</MenuItem>


                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid lg={4} xs={10} md={6}>
                        <Search id="searchbox" style={{ position: "absolute", marginTop: "18px", marginLeft: "8px", color: "grey" }} /> <input type="search" onChange={(e) => searching(e)} placeholder="Search" style={{ padding: 10, paddingLeft: "38px", marginTop: 10, width: "100%", border: "solid", borderRadius: "5px", borderWidth: "0.5px", backgroundColor: "rgba(65,105,225, 0.05) ", borderColor: "#2196f3" }} />
                    </Grid>

                    <Grid lg={5} sm={12} md={12}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
                <br />
                <Divider />
                <TableContainer>
                    <Table

                        sx={{ minWidth: "100%" }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >

                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />

                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover

                                        >
                                            <TableCell style={{ textAlign: "center" }}>
                                                <span>
                                                    {(index + 1 + (page * rowsPerPage)) + "."}
                                                </span>
                                            </TableCell>
                                            <TableCell align="left">{row.ID}</TableCell>

                                            <TableCell component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                                align="left"
                                            >

                                                <ListItem align="left">
                                                    <ListItemAvatar>
                                                        <Avatar src={'https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/'+row.image} size="small" alt={row.lastname+" "+row.othernames} onClick={() => handleClickOpenimage(row)} style={{ cursor: "pointer" }}>
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary={row.lastname+" "+row.othernames} />
                                                </ListItem>

                                            </TableCell>
                                            <TableCell align="left">{row.status==="Active" ? <span style={{padding:8, borderRadius:"20px", backgroundColor:"lightgreen"}}>{row.status}</span>: <span style={{padding:8, borderRadius:"20px", backgroundColor:"pink"}}>{row.status}</span>}</TableCell>
                                            <TableCell align="left">{row.position}</TableCell>
                                            <TableCell align="left">GHC {(parseFloat(row.finance[0].basesalary) + earning(row.finance[0].salarybonuses)-earning(row.finance[0].deductions))}</TableCell>
                                            <TableCell align="left">GHC {earning(row.finance[0].salarybonuses)}</TableCell>
                                            <TableCell align="left">GHC {earning(row.finance[0].deductions)}</TableCell>

                                            <TableCell align="left">{<Menustyle data={row} base={(sender) => handleClickOpenbase(sender)} bonus={(sender) => handleClickOpenbonus(sender)} deduction={(sender) => handleClickOpendeduction(sender)} payslip={() => handleClickOpenpayslip(row)} paysalary={(sender) => handleClickOpenpaysalary(sender)} edit={(sender) => handleClickOpenedit(sender)} delete={(sender) => handleClickOpendelete(sender)} />}</TableCell>

                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>

                    </Table>
                </TableContainer>

            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />
        </Box>

    );
}
