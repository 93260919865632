import React, { Component } from "react";
import Chart from 'react-apexcharts'

function PartialLoadingIndicatorStory(props) {

    var male = props.male
    var female = props.female

    var maindata = [100,100]

    if (props.length !== 0) {

        maindata = [male, female]
    } else {
        maindata = [10, 50]
    }
    const names = ["Male", "Female"]

    const state = {
        series: maindata,

        options: {
            chart: {
                width: 380,
                type: 'donut',
            },
            plotOptions: {
                pie: {
                    startAngle: -90,
                    endAngle: 270
                }
            },
            dataLabels: {
                enabled: true
            },
            fill: {
                type: 'gradient',
            },
            legend: {
                formatter: function(val, opts) {
                    return names[opts.seriesIndex] + " - " + opts.w.globals.series[opts.seriesIndex]
                }
            },
            title: {
                text: ''
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: "100%"
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        }
    }

    return ( <Chart options = { state.options }
        series = { state.series }
        type = "donut"
        width = "100%"
        height = "100%"
        />
    )

}

export default PartialLoadingIndicatorStory;