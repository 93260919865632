import { createSlice } from "@reduxjs/toolkit"
let data = []

const Insider = createSlice({

    name: "todos",
    initialState: data,
    reducers: {
        announcement_: (state, action) => {
            state = action.payload.announcement
            return state
        }


    },

})

export const { announcement_ } = Insider.actions;
export default Insider.reducer;