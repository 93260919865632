import { createSlice } from "@reduxjs/toolkit"


var data=[]


if (localStorage.getItem("product") !== null && localStorage.getItem("product") !== undefined && localStorage.getItem("product") !== "") {

    data = JSON.parse(localStorage.getItem("product"))

}


const Insider = createSlice({

    name: "todos",
    initialState: data,
    reducers: {
        getproducts: (state, action) => {
            localStorage.setItem("product", JSON.stringify(action.payload.data))
            state = action.payload.data
            return state
        
          
        },

    },

})

export const { seedetails, home, getproducts } = Insider.actions;
export default Insider.reducer;