import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}



export default function BasicTable(props) {
    const rows = props.data
    console.log(rows)


    const remarks = (data) => {

        if (data === "Excellent" || data === "Distinction") {
            return (<span style={{ color: "darkgreen" }}>{data}</span>)

        }
        else if (data === "Very Good") {
            return (<span style={{ color: "darkblue" }}>{data}</span>)

        }
        else if (data === "Good" || data === "High Average") {
            return (<span style={{ color: "Black" }}>{data}</span>)

        }
        else if (data === "Credit" || data === "Avereage") {
            return (<span style={{ color: "grey" }}>{data}</span>)

        }
        else if (data === "Pass" || data === "Low Avereage") {
            return (<span style={{ color: "gray" }}>{data}</span>)

        }
        else {
            return (<span style={{ color: "red" }}>{data}</span>)

        }
    }



    return (
        <>
        <TableContainer >
            <br />
            <Divider />
            <Table sx={{ minWidth: "100%", }} aria-label="simple table">
                <TableHead >
                    <TableRow>
                        <TableCell align="left" style={{ fontWeight: "bolder",padding:2, margin:2 }}>SUBJECT</TableCell>
                        <TableCell align="left" style={{ fontWeight: "bolder",padding:2, margin:2 }}>CLASS SCORE (50%)</TableCell>
                        <TableCell align="left" style={{ fontWeight: "bolder",padding:2, margin:2 }}>EXAM SCORE (50%)</TableCell>
                        <TableCell align="left" style={{ fontWeight: "bolder",padding:2, margin:2 }}>TOTAL (100%)</TableCell>
                        <TableCell align="left" style={{ fontWeight: "bolder",padding:2, margin:2 }}>GRADE</TableCell>
                        <TableCell align="left" style={{ fontWeight: "bolder",padding:2, margin:2 }}>REMARKS</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {rows.length !== 0 ? rows[0].results.map((row) => (
                        <TableRow
                        
                            key={row.subject}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell style={{padding:2, margin:2}} component="th" scope="row">
                                {(row.subject).toUpperCase()}
                            </TableCell>
                            <TableCell  style={{padding:2, margin:2}} align="left">{row.class_scorre}</TableCell>
                            <TableCell  style={{padding:2, margin:2}} align="left">{row.exam_score}</TableCell>
                            <TableCell  style={{padding:2, margin:2}} align="left">{row.total}</TableCell>
                            <TableCell  style={{padding:2, margin:2}} align="left">{row.grade}</TableCell>
                            <TableCell  style={{padding:2, margin:2,  fontWeight: "bolder"}} align="left" >  {remarks(row.remarks)}  </TableCell>

                        </TableRow>
                    )) : ""}
                </TableBody>
            </Table>
        </TableContainer>
        </>
    );
}
