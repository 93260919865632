import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Imagereplace from './images/Ellipse77.png';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}



export default function BasicTable(props) {
  console.log(props)
  var lister=[]
    const rows = props.data
    const subjects = props.subjects
    const term = props.term
 rows.sort((a,b)=>a.lastname - b.lastname)
  return (
    <TableContainer>
      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>

                      <TableCell style={{ fontWeight:"bolder"}}>No.</TableCell>
                      <TableCell style={{ fontWeight:"bolder"}}>Student Name</TableCell>
                      {subjects[0].map((list,index)=>
                      <TableCell align="left" style={{fontWeight:"bolder"}}>{list.subject}</TableCell>
                      )}
                      <TableCell align="left" style={{color:"blue" , fontWeight:"bolder"}}>Total Score</TableCell>
                      <TableCell align="left" style={{color:"green" , fontWeight:"bolder"}}>Aggregate</TableCell>
                      <TableCell align="left" style={{color:"red" , fontWeight:"bolder"}}>Position</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, indexer) => (

 <TableRow
                  key={row.date}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                      {indexer+1 +"."}
              </TableCell> <TableCell component="th" scope="row">
                      {row.lastname+" "+row.othernames}
              </TableCell>
              
           { row.academics.filter(bug=>bug.term === term).length !==0 &&
            row.academics.filter(bug=>bug.term === term)[0].results.map((list,index)=>
           
                  <TableCell align="left">{list.total}</TableCell>
                  

            
            )
            }
             <TableCell align="left" style={{color:"blue", fontWeight:"bolder"}}>{row.academics.filter(bug=>bug.term === term).length !== 0 ? row.academics.filter(bug=>bug.term === term)[0].totalscore :""}</TableCell>
             <TableCell align="left" style={{color:"green", fontWeight:"bolder"}}>{row.academics.filter(bug=>bug.term === term).length !== 0 ? row.academics.filter(bug=>bug.term === term)[0].aggregate :""}</TableCell>
             <TableCell align="left" style={{color:"red", fontWeight:"bolder"}}>{row.academics.filter(bug=>bug.term === term).length !== 0 ? row.academics.filter(bug=>bug.term === term)[0].position:""}</TableCell>
             <TableCell align="left" style={{color:"red", fontWeight:"bolder"}}></TableCell>
          </TableRow>))} 
        </TableBody>
      </Table>
    </TableContainer>
  );
}
