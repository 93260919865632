import React, {useRef} from 'react';
import ReactToPrint from 'react-to-print';
import Button from '@mui/material/Button';

import Printpage  from "./printpage"

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

const Profile=(props)=>{
    // const pageStyle = `{ size: 2.5in 4in }`;
    console.log(props)
    const componentRef = useRef()

    return (
        <div >
           <ReactToPrint
             pageStyle='@page { size: A4; margin: 0mm; } @media print { body { scale:1; font-size:1pt; font-family:georgia, times, serif; } }'
           
           trigger={()=><Button>Print Results</Button>}
            content ={()=>componentRef.current}
            />
            <Printpage   ref={componentRef} data={props.data} term={props.term} Class_={props.Class_} schooldata={props.schooldata} students={props.students} />
        </div>
    );
}

export default Profile;
