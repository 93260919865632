import React, { useState, useRef } from 'react';
import Logo from './images/blue transparent2_.png';
import Spinner from './images/load.gif';


function Loader() {
      
    return (
        <div style={{position:"fixed", backgroundColor:"white", width:"100%", height:"100%", paddingTop:"20%"}}>

<img src={Logo} width="100px" /><br/>
<img src={Spinner} style={{marginTop:"-30px"}} width="85px"/>

        </div>
    );
}

export default Loader;
