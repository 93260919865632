import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';

import Snackbar from '@mui/material/Snackbar';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { seedetails, getproducts } from './redux/todoSlice';
import { user } from './redux/admin';
import { loin, logout, updateteacherdata, getdatateacher } from './redux/teachers';
import { admin } from './redux/admin';
import { schooldetails } from './redux/schooldetails';

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import axios from 'axios'

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import MuiAlert from '@mui/material/Alert';



const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const options = [
  'submitted',
  'approved',
  'cancelled',
  'error noticed',

];

function ConfirmationDialogRaw(props) {
    const chosennotes = props.chosennotes
    const chosenteacher = props.chosenteacher
    const { onClose, value: valueProp, open, ...other } = props;
    const [value, setValue] = React.useState(valueProp);
    const [opensnack, setOpensnack] = React.useState(false);
    const [message, setmessage] = React.useState("");
    const [stated, setstated] = React.useState("");
    const dispatch = useDispatch()

    const admin = useSelector((state) => state.admin)
    const schooldetails_ = useSelector((state) => state.schooldetails)
    const schooldata= async () => {
        await axios.post("https://servermain.sisrevolution.com/api/schools/getschooldata/",
            {
                schoolID:admin.schoolID

            }).then(res => {

                if (res.status === 200) {

                    dispatch(schooldetails({

                        data: res.data

                    }))


                }

            })

    }





  const radioGroupRef = React.useRef(null);

  const handleClicksnack = () => {
    setOpensnack(true);
};

  const handleClosesnack = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setOpensnack(false);
};

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

//   const handleOk = () => {
//     onClose(value);
//   };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleOk=()=>{

    // loading(true)
       
   
    var index = chosenteacher.lessonnotes.findIndex(bug=>bug===chosennotes)
    console.log(index)
    var convertdata = [...chosenteacher.lessonnotes]
    var convertdata_main = {...convertdata[index]}
    convertdata_main.status=value
    convertdata[index]=convertdata_main
  

    const uploading = async() => {
        await axios.post("https://servermain.sisrevolution.com/api/teachers/addcomment/", {
            lessonnotes: convertdata,
            _id:chosenteacher._id,
            schoolID:admin.schoolID

        }).then(res => {

            if (res.status === 200) {
               
              dispatch(getdatateacher({

                data: res.data

            }))
       
        handleClicksnack()
        setmessage("Lesson note status updated")
        setstated("success")
        handleCancel()
            // setsmsloading(!true)
            }
            if (res.status === 204) {
              
        handleClicksnack()
        setmessage("Error updating status")
        setstated("success")
            // setsmsloading(!true)
            }
        })
    }
    uploading()
}


  return (
    
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
          <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    { message }
                </Alert>
            </Snackbar>

      <DialogTitle>Lesson Note Status</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel
              value={option}
              key={option}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export default function ConfirmationDialog(props) {
    console.log(props)
    const chosennotes = props.chosennotes
    const chosenteacher = props.chosenteacher
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('Dione');

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };

  return (
  
<span>        <IconButton     aria-haspopup="true"
            aria-controls="ringtone-menu"
            aria-label="phone ringtone"
            onClick={handleClickListItem} style={{marginLeft:10}} ><SpellcheckIcon />  
            
          
            
            </IconButton>
            <ConfirmationDialogRaw
              id="ringtone-menu"
              keepMounted
              open={open}
              chosennotes={chosennotes}
              chosenteacher={chosenteacher}
              onClose={handleClose}
              value={value}
            />
</span>
   
  );
}
