import * as React from 'react';
import './App.css';
import Login from './landingpage/login';
import Imager from './images/imager.jpg';
import Menu from './menu';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import PrintButton from './printbutton';

import AvatarGroup from '@mui/material/AvatarGroup';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Close from '@mui/icons-material/Close';

import {Offline, Online} from "react-detect-offline";

import Snack from './snack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import SchoolIcon from '@mui/icons-material/School';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import ClassIcon from '@mui/icons-material/Class';

import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';


import Studentchart1 from './chart/studentchart1'
import Studentchart2 from './chart/studentchart2'
import Studentchart3 from './chart/studentchart3'
import Activestaff from './chart/staff/activestaff'
import Position from './chart/staff/position'
import Demography from './chart/staff/demography'

import DatePicker from 'react-date-picker'

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { admin } from './redux/admin';
import { getchat } from './redux/chat';
import { schooldetails } from './redux/schooldetails';
import { announcement_ } from './redux/announcement';
import { loin, logout, updatestudentdata, getdata } from './redux/students';
import { updateteacherdata, getdatateacher } from './redux/teachers';
import { getdata_ } from './redux/class';
import { capitalize } from '@mui/material';


interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function App() {
    const [term, setterm] = React.useState("All")
    const dispatch = useDispatch()
    const [opensnack, setOpensnack] = React.useState(false);

    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [preload, setpreload] = React.useState(true);
    const [openview, setOpenview] = React.useState(!true);
    const [openloader, setopenloader] = React.useState(false);
    const [chosen, setchosen] = React.useState([]);
    const [message, setmessage] = React.useState("");
    const [stated, setstated] = React.useState("");

    const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const admin = useSelector((state) => state.admin)
  const schooldetails_ = useSelector((state) => state.schooldetails)[0]
  const announcement = useSelector((state) => state.announcement_)


  const dt = new Date()
  const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
  const day = dt.getUTCDate();
  const month = String(dt.getMonth() + 1).padStart(2, '0');
  const year = dt.getFullYear();
  const date = year + "-" + month + "-" + day;
  
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const [dated_, setdated__] = React.useState(date);

  const handleChangeIndex = (index) => {
    setValue(index);
  };
   
  var da =[2,3,4,5,6,7,8]
 
  const stud = useSelector((state) => state.students)
 const students = admin.class_===undefined? stud:stud.filter(bug=>bug.class_===admin.positiondescription_)
 const classer = useSelector((state) => state.Class_)
  var Class_ = admin.class_===undefined? classer:classer.filter(bug=>bug.classname===admin.positiondescription_)

  const teachers = useSelector((state) => state.teachers)

  var classlist=[]
  var classdata=[]

  const handleClicksnack = () => {
    setOpensnack(true);
};

const handleClosesnack = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setOpensnack(false);
};


  const handleClickOpenview = (data) => {
    setchosen(data)
     setOpenview(true);
 };

 const handleCloseview = () => {
     setOpenview(false);
 };



  for(var i in Class_){
    var data_=students.filter(bug=>bug.class_ === Class_[i].classname).length
    classdata.push(data_)
  }
 for(var j in Class_){
  // console.log(Class_[j].className)
    classlist.push(Class_[j].classname)
  }

 
  const schooldata= async () => {
      await axios.post("http://localhost:3001/api/schools/getschooldata/",
          {
              schoolID:admin.schoolID

          }).then(res => {

              if (res.status === 200) {

                  dispatch(schooldetails({

                      data: res.data

                  }))

                  dispatch(getchat({

                    data: res.data[0].chat

                }))


              }

          })

  }


  const gettingdatat = async () => {
        await axios.post("https://servermain.sisrevolution.com/api/teachers/getworkersdata/",
            {

              status:"Active",
              schoolID:admin.schoolID

            }).then(res => {

                if (res.status === 200) {

                    dispatch(getdatateacher({

                        data: res.data

                    }))


                }

            })

    }

    
      const gettingdata_ = async () => {
        await axios.post("https://servermain.sisrevolution.com/api/class/getclassdata/",
            {

              status:"Active",
              schoolID:admin.schoolID

            }).then(res => {

                if (res.status === 200) {

                    dispatch(getdata_({

                        data: res.data

                    }))


                }

            })

    }

 

    const gettingdata = async () => {
      setopenloader(true)
      try{
        await axios.post("https://servermain.sisrevolution.com/api/students/getstudentdata/",
            {
              status:"Active",
              schoolID:admin.schoolID


            }).then(res => {

                if (res.status === 200) {

                    dispatch(getdata({

                        data: res.data

                    }))

                    setopenloader(!true)

                }else{
                  setopenloader(!true)

                }

            })

          }catch(err){
            setopenloader(!true)
            handleClicksnack()
            setmessage("Network Error")
            setstated("error")

          }
          }
 

    
  const announcementdata = async () => {
    await axios.post("https://servermain.sisrevolution.com/api/announcement/getannouncement/",
        {
            schoolID:admin.schoolID

        }).then(res => {

            if (res.status === 200) {

                dispatch(announcement_({

                  announcement: res.data.filter(bug=>bug.to===undefined || bug.to==="All")

                }))


            }

        })

}



    React.useEffect(()=>{

      // schooldata()
      // announcementdata()
      // gettingdata_()
      // gettingdatat()
      // if(students.length===0){
      // gettingdata()
      // }


    },[preload])
    
    const mainterm = schooldetails_!==undefined && schooldetails_.length!==0 && schooldetails_.terms.length!==0?schooldetails_.terms[schooldetails_.terms.length-1]:"none"


    // const setdated_=(e)=>{
    //   setdated(e.target.value)
    // }

    const fullpayment =()=>{
      var total = 0
      var data =  students.filter(bug=>bug.finance.length!==0? bug.finance[bug.finance.length-1].payment.length!==0 && bug.finance[bug.finance.length-1].payment[bug.finance[bug.finance.length-1].payment.length-1].balance ===0 : "")
    if(data.length !==0){
      for(var i in data){
      total +=  parseFloat( data[i].finance[data[i].finance.length-1].totalfees) + parseFloat( data[i].finance[data[i].finance.length-1].Arrears)
      }
    }
      return total   
    }

    const fullpaymentstud =()=>{
      var data =  students.filter(bug=>bug.finance.length!==0?bug.finance[bug.finance.length-1].payment.length!==0 && bug.finance[bug.finance.length-1].payment[bug.finance[bug.finance.length-1].payment.length-1].balance ===0 && bug.finance[bug.finance.length-1].Arrears ===0:"")
      return data.length    
    }


    const calcoutstanding = () =>{
      var total = 0
      for(var i in students){
        total+=parseFloat(Class_!==undefined && students[i].finance.length !== 0 && students[i].finance[students[i].finance.length-1].term === mainterm ? (parseFloat(students[i].finance[students[i].finance.length-1].balance).toFixed(2)) : students[i].finance.length !== 0 ?  students[i].finance[students[i].finance.length-1].Arrears !==null ? ((parseFloat(Class_.filter(bug=>bug.classname===students[i].class_).length!==0 && Class_.filter(bug=>bug.classname===students[i].class_)[0].classfees) + parseFloat(students[i].finance[students[i].finance.length-1].balance)+ parseFloat(students[i].finance[students[i].finance.length-1].Arrears)).toFixed(2)):(parseFloat(Class_.filter(bug=>bug.classname===students[i].class_)[0].classfees).toFixed(2)) :(parseFloat(Class_.filter(bug=>bug.classname===students[i].class_).length!==0 && (Class_.filter(bug=>bug.classname===students[i].class_)[0].classfees)).toFixed(2))  )

      }
      return total
  } 
  const totalamountpaid=(input)=>{
    var result = 0
    for(var i in input){
        result+=parseFloat(input[i].amountpaid)

    }
    return result
}

  
   
  const calcdue = () =>{
    var total = 0
    var rows = students
    for(var i in rows){

        
      total += parseFloat(Class_.length!==0 && rows[i].finance.length !== 0 ?  rows[i].finance[rows[i].finance.length-1].Arrears !==null && rows[i].finance[rows[i].finance.length-1].term === mainterm ? ((parseFloat(totalamountpaid(rows[i].finance[rows[i].finance.length-1].payment)) + parseFloat(rows[i].finance[rows[i].finance.length-1].balance)).toFixed(2)): ((parseFloat(Class_.filter(bug=>bug.classname===rows[i].class_)[0].classfees)+parseFloat(rows[i].finance[rows[i].finance.length-1].Arrears)+parseFloat(rows[i].finance[rows[i].finance.length-1].balance)).toFixed(2)) : Class_.length!==0 && (parseFloat(Class_.filter(bug=>bug.classname===rows[i].class_).length!==0 && rows[i] !==null && rows[i].length!==0 && (parseFloat(Class_.filter(bug=>bug.classname===rows[i].class_)[0].classfees)).toFixed(2))))

    }
    return total
} 
    const totalpaid =()=>{
      var total = 0
      var data =  students.filter(bug=>bug.finance.length!==0?bug.finance[bug.finance.length-1].payment.length !==0:0 )
    
      for(var i in students){
        if(students[i].finance.length !== 0){
           total+= (parseFloat(students[i].finance[students[i].finance.length-1].totalfees) + parseFloat(students[i].finance[students[i].finance.length-1].Arrears) )
        }else{
            total+=parseFloat(Class_.filter(bug=>bug.classname===students[i].class_).length!==0 && Class_.filter(bug=>bug.classname===students[i].class_)[0].classfees)
        }
    }
    
      // for(var i in data){
      //   for(var k in data[i].finance[data[i].finance.length-1].payment){
      //         total += parseFloat(data[i].finance[data[i].finance.length-1].payment[k].amountpaid)
      //   }
      // }
      return total   
    }

    const totalpaidstud =()=>{
      var total = 0
      var data =  students.filter(bug=>bug.finance.length!==0?bug.finance[bug.finance.length-1].payment.length !==0:0 )
      return data.length    
    }


    const fullowing =(students_)=>{
      var total = 0
      var data =  students_.filter(bug=>bug.finance.length!==0?bug.finance[bug.finance.length-1].payment.length!==0 && bug.finance[bug.finance.length-1].payment[bug.finance[bug.finance.length-1].payment.length-1].balance !== 0 :"" )
      var data2 =  students_.filter(bug=>bug.finance.length===0)
    if(data.length !==0){
      for(var i in data){
      total +=  parseFloat( data[i].finance[data[i].finance.length-1].payment[data[i].finance[data[i].finance.length-1].payment.length-1].balance) 
      }
    } 
    if(data2.length !==0){
      for(var i in data2){
      total +=  parseFloat(Class_.filter(bug=>bug.classname===data2[i].class_).length!==0 && Class_.filter(bug=>bug.classname===data2[i].class_)[0].classfees) 
      }
    }
      return total   
    }



 const owingstudents =(students_)=>{
      var total = 0
      var data =  students_.filter(bug=>bug.finance.length!==0?bug.finance[bug.finance.length-1].payment.length!==0 && bug.finance[bug.finance.length-1].payment[bug.finance[bug.finance.length-1].payment.length-1].balance !== 0 :"" )
      var data2 =  students_.filter(bug=>bug.finance.length===0)
    if(data.length !==0){
      total+=data.length
    } 
    if(data2.length !==0){
      total+=data2.length

    }
      return total   
    }

    const feesdata =()=>{
    var total=[]
      for (var i in classlist){
       total.push(fullowing(students.filter(bug=>bug.class_===classlist[i])))
      }

      return total

    }
 

    const feesdatabynumber =()=>{
    var total=[]
      for (var i in classlist){
       total.push(owingstudents(students.filter(bug=>bug.class_===classlist[i])))
      }

      return total

    }
 

    const fullowingstud =()=>{
      var total = 0
      var data =  students.filter(bug=>bug.finance.length!==0?bug.finance[bug.finance.length-1].payment.length!==0 && bug.finance[bug.finance.length-1].payment[bug.finance[bug.finance.length-1].payment.length-1].balance === bug.finance[bug.finance.length-1].balance:"")
      return data.length    
    }


    const classattendance=(list)=>{
      if(schooldetails_!==undefined){
var data=0
     var useing = students.filter(bug=>bug.class_===list.classname && bug.attendance.length!==0)
    for(var i in useing){
           var li = useing[i].attendance[schooldetails_.terms[schooldetails_.terms.length-1]] !==undefined && useing[i].attendance[schooldetails_.terms[schooldetails_.terms.length-1]]
for(var k in li){
  if(li[k].date===dated_ && li[k].attendance==="present"){
    data+=1
  }

}


    }

return data
  }
    }


    
    const totalattendance=()=>{
var data=0
     var useing = students.filter(bug=> bug.attendance.length!==0)
    for(var i in useing){
      console.log(schooldetails_)
      if(schooldetails_!==undefined){
           var li = useing[i].attendance[schooldetails_.terms && schooldetails_.terms[schooldetails_.terms.length-1]] !==undefined && useing[i].attendance[schooldetails_.terms[schooldetails_.terms.length-1]]
for(var k in li){
  if(li[k].date===dated_ && li[k].attendance==="present"){
    data+=1
  }

}
      }

    }

return data
    }


    
    const dailycharges=(list)=>{
      if(schooldetails_!==undefined){
var data=0
     var useing = students.filter(bug=>bug.class_===list.classname && bug.attendance.length!==0)
    for(var i in useing){
           var li = useing[i].attendance[schooldetails_.terms && schooldetails_.terms[schooldetails_.terms.length-1]] !==undefined && useing[i].attendance[schooldetails_.terms[schooldetails_.terms.length-1]]
for(var k in li){
  if(li[k].date===dated_ && li[k].attendance==="present"){
    data+=parseFloat(useing[i].dailyfee)
  }

}

    }

return data
  }
    }


    
    const totaldailycharges=()=>{
      if(schooldetails_!==undefined){
var data=0
     var useing = students.filter(bug=> bug.attendance.length!==0)
    for(var i in useing){
           var li = useing[i].attendance[schooldetails_.terms[schooldetails_.terms.length-1]] !==undefined && useing[i].attendance[schooldetails_.terms[schooldetails_.terms.length-1]]
for(var k in li){
  if(li[k].date===dated_ && li[k].attendance==="present"){
    data+=parseFloat(useing[i].dailyfee)
  }

}


    }

return data
  }
    }


    

    const partialpayment =()=>{
      var total = 0
      var data =  students.filter(bug=>bug.finance.length!==0? bug.finance[bug.finance.length-1].payment.length!==0 && bug.finance[bug.finance.length-1].payment[bug.finance[bug.finance.length-1].payment.length-1].balance !==0 : "")

      // var data =  students.filter(bug=> bug.finance.length!==0? (parseFloat(bug.finance[bug.finance.length-1].balance) !==0) && (parseFloat(bug.finance[bug.finance.length-1].balance) !== (parseFloat(bug.finance[bug.finance.length-1].totalfees) )):"")
    if(data.length !==0){
      for(var i in data){

        for(var k in data[i].finance[data[i].finance.length-1].payment){
              total += parseFloat(data[i].finance[data[i].finance.length-1].payment[k].amountpaid)
        }
      }
    }
      return total   
    }
    
    const partialpaymentstud =()=>{
      var total = 0
      var data =  students.filter(bug=>bug.finance.length!==0? (parseFloat(bug.finance[bug.finance.length-1].balance) !==0) && (parseFloat(bug.finance[bug.finance.length-1].balance) !== (parseFloat(bug.finance[bug.finance.length-1].totalfees) )):"")
      return data.length    
    }


    return ( 
    <Box sx={{ bgcolor: 'background.paper', width: "100%" }}>
    {/* <PrintButton/> */}
    <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    { message }
                </Alert>
            </Snackbar>
    <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={openloader}
//   onClick={handleClose}
>
  <CircularProgress color="inherit" />
</Backdrop>

    <Dialog fullWidth maxWidth="sm" onClose={handleCloseview} aria-labelledby="customized-dialog-title" open={openview}>
            <DialogTitle id="customized-dialog-title" >
            <span style={{fontSize:20, marginLeft:30, color:"grey"}}>Announcement views</span>
                    <IconButton aria-label="" onClick={handleCloseview} style={{float:"right"}} >
                      <Close/>
                    </IconButton>
            </DialogTitle>
            <DialogContent>

                 {chosen.map((list,index)=>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt={list.name} src={"https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/"+list.image} />
        </ListItemAvatar>
        <ListItemText
          primary={list.name}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {months[parseInt((list.date.split("-"))[1])-1] +" "+(list.date.split("-"))[2]+", "+(list.date.split("-"))[0] }

              </Typography>
              {" - "+list.time}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
   
    </List>
                 )
}

                </DialogContent>
                <DialogActions style={{textAlign:"center"}}>


                </DialogActions>
            </Dialog>
            


         <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Students" {...a11yProps(0)} />
          <Tab label="Staff" {...a11yProps(1)} />
          {(admin.positiondescription==="Accountant" || admin.smsID!==undefined) && <Tab label="Finance" {...a11yProps(2)} />}
        </Tabs>
      </Box>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction} >
          

        <Grid container spacing={3}>
          <Grid item xs={3}>
            <div style={{ borderRadius:"10px", height:"150px", border:"solid", background: "#3A27CE",  borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
           <div style={{textAlign:"right"}}><IconButton style={{marginTop:7, marginRight:15, marginBottom:"-5px", backgroundColor:"rgba(0,0,0,0.1)"}}><SchoolIcon  style={{color:"white", fontSize:"25px"}} /></IconButton></div> 
            <h1 align="center" style={{color:"white",fontSize:"47px", marginTop:"-6px", marginBottom:8}}>{Intl.NumberFormat('en-US').format(students.length)}</h1>
                        <small align="center" style={{ color:"white", marginBottom:4, marginTop:"-12px"}}> Total Number Of students</small>

            
            </div>
          </Grid>

   <Grid item xs={3}>
            <div style={{ borderRadius:"10px", height:"150px", border:"solid",borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px",/* Created with https://www.css-gradient.com */
/* Created with https://www.css-gradient.com */
background: "#D10F3D",}}> 
                       <div style={{textAlign:"right"}}><IconButton style={{marginTop:7, marginRight:15, marginBottom:"-5px", backgroundColor:"rgba(0,0,0,0.1)"}}><MaleIcon  style={{color:"white", fontSize:"25px"}} /></IconButton></div> 

            <h1 align="center" style={{color:"white",fontSize:"47px", marginTop:"-6px", marginBottom:8}}>{Intl.NumberFormat('en-US').format(students.filter(bug=>bug.gender==="Male").length)}</h1>
            <small align="center"  style={{ color:"white", marginBottom:4, marginTop:"-12px"}}> Number Of Male Students</small>

            </div>
          </Grid>

   <Grid item xs={3}>
            <div style={{ borderRadius:"10px", height:"150px", border:"solid", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px",/* Created with https://www.css-gradient.com */
/* Created with https://www.css-gradient.com */
background: "#13A518",}}> 
 <div style={{textAlign:"right"}}><IconButton style={{marginTop:7, marginRight:15, marginBottom:"-5px", backgroundColor:"rgba(0,0,0,0.1)"}}><FemaleIcon  style={{color:"white", fontSize:"25px"}} /></IconButton></div> 

            <h1 align="center" style={{color:"white",fontSize:"47px", marginTop:"-6px", marginBottom:8}}>{Intl.NumberFormat('en-US').format(students.filter(bug=>bug.gender==="Female").length)}</h1>
            <small align="center"  style={{ color:"white", marginBottom:4, marginTop:"-12px"}}> Number Of Female students</small>

            
            </div>
          </Grid>

   <Grid item xs={3}>
            <div style={{ borderRadius:"10px", height:"150px", border:"solid", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px", background: "#3A27CE",
/* Created with https://www.css-gradient.com */
background: "orange"}}> 
 <div style={{textAlign:"right"}}><IconButton style={{marginTop:7, marginRight:15, marginBottom:"-5px", backgroundColor:"rgba(0,0,0,0.1)"}}><ClassIcon  style={{color:"white", fontSize:"25px"}} /></IconButton></div> 

            <h1 align="center" style={{color:"white",fontSize:"47px", marginTop:"-6px", marginBottom:8}}>{Intl.NumberFormat('en-US').format(Class_.length)}</h1>
            <small align="center"  style={{ color:"white", marginBottom:4, marginTop:"-12px"}}>  Number Of Classes</small>
            
            </div>
          </Grid>


        </Grid>

        <br/>
        <Grid container spacing={3}>

      <Grid item xs={9}>

 <div style={{padding:10, borderRadius:"10px", height:"420px", border:"solid", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p><b>A Graphical Representation of the Number of Students in Each Class</b></p>

            <Studentchart1 data={classdata} categories={classlist}/>
           
            </div>

      </Grid>

      <Grid item xs={3}>

 <div style={{ borderRadius:"10px", height:"420px", border:"solid", backgroundColor:"#e0eff9", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <div style={{padding:"10px", textAlign:"center"}} align="left">
            <b style={{color:"#1976d2"}}>Class Attendance</b>
           
                       <input type="date"  value={dated_} style={{fontSize:"16px", color:"#1976d2" , backgroundColor:"#e0eff9", border:"solid", borderWidth:"0.1px", borderColor:"lightgray",  padding:5, margin:5, borderRadius:5}} onChange={(e)=>setdated__(e.target.value)} />
                         
            </div>

            <Divider/>

            <h1 align="center" style={{margin:"1px", color:"#1976d2"}}>{ Intl.NumberFormat('en-US').format(totalattendance()) }</h1>
            <p style={{marginTop:"-22px", fontSize:"12px", color:"#1976d2", margin:"1px", }}>{months[parseInt((dated_.split("-"))[1])-1] +" "+(dated_.split("-"))[2]+", "+(dated_.split("-"))[0] }</p>
<Divider/>
<div style={{paddingLeft:5,paddingRight:5}}>
<div className='scroller3' style={{height:"255px"}}>
       <List sx={{ width: '100%', maxWidth: 360,  margin:0, padding:1 }}>
     {Class_.map((list,index)=>
        <div>
        <ListItem
        sx={{padding:0}}
          disableGutters
          secondaryAction={
          //  <b>{students.filter(bug=>bug.class_===list.classname && bug.attendance.length!==0?bug.attendance[schooldetails_.terms[schooldetails_.terms.length-1]] !==undefined && bug.attendance[schooldetails_.terms[schooldetails_.terms.length-1]][bug.attendance[schooldetails_.terms[schooldetails_.terms.length-1]].length-1].attendance==="present":0 ).length}</b>
           <b>{Intl.NumberFormat('en-US').format(classattendance(list))}</b>
          }
        >
          <ListItemText primary={list.classname} secondary={<small> {}  {dated_ && months[parseInt((dated_.split("-"))[1])-1] +" "+(dated_.split("-"))[2]+", "+(dated_.split("-"))[0] }</small>} />
        </ListItem>
        <Divider/>
        </div>
     )}
    </List>

    </div>
</div>
        
            </div>

             {/* <Studentchart2/> */}

      </Grid>

        </Grid>
      
        <br/>
   
        </TabPanel>

 <TabPanel value={value} index={1} dir={theme.direction} >
          

         <Grid container spacing={3}>
          <Grid item xs={4}>
            <div style={{ borderRadius:"10px", height:"150px", border:"solid", backgroundColor:"#e9543c", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{marginBottom:"-14px", color:"white"}}> Total Number Of staff</p>
            <h1 align="center" style={{color:"white", fontSize:"45px"}}>{teachers.length}</h1>
            
            </div>
          </Grid>

   <Grid item xs={4}>
            <div style={{ borderRadius:"10px", height:"150px", border:"solid",backgroundColor:"#27948f", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{marginBottom:"-14px", color:"white"}}> Number Of Teaching Staff</p>
            <h1 align="center" style={{color:"white", fontSize:"45px"}}>{teachers.filter(bug=>bug.position==="Teaching Staff").length}</h1>
            
            </div>
          </Grid>

   <Grid item xs={4}>
            <div style={{ borderRadius:"10px", height:"150px", border:"solid",backgroundColor:"#89358a", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{marginBottom:"-14px", color:"white"}}> Number Of nonteaching Staff</p>
            <h1 align="center" style={{color:"white", fontSize:"45px"}}>{teachers.filter(bug=>bug.position==="Non-Teaching Staff").length}</h1>
            
            </div>
          </Grid>



        </Grid>

       
      

        <br/>
        <Grid container spacing={3}>

      <Grid item xs={4}>

 <div style={{padding:10, borderRadius:"10px", height:"300px", border:"solid", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
           <p align="center"><b>Number of Non-Teaching staff against Teacing staff</b></p>

            <Activestaff  active={teachers.filter(bug=>bug.position==="Teaching Staff").length}  inactive={teachers.filter(bug=>bug.position !=="Teaching Staff").length} />
            </div>

      </Grid>



      <Grid item xs={4}>

 <div style={{padding:10, borderRadius:"10px", height:"300px", border:"solid", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
             <p align="center"><b>Number of Male staff against Female staff</b></p>
              <Demography male={teachers.filter(bug=>bug.gender==="Male").length}  female={teachers.filter(bug=>bug.gender ==="Female").length}/>
            </div>

      </Grid>


      
      <Grid item xs={4}>

 <div  style={{ borderRadius:"10px", height:"300px", border:"solid", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{ color:"gray", width:"100%", zIndex:9, margin:5}}> Notice board</p>
            <Divider/>

            <div>

{/* <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Oui Oui"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Sandra Adams
              </Typography>
              {' — Do you have Paris recommendations? Have you ever…'}
              <br/>
              
            <p  style={{marginTop:10, marginBottom:"-8px"}}><small >1st June, 2022</small></p>
            </React.Fragment>
          }
        />
      </ListItem> */}
<div className='scroller3' style={{height:"260px"}}>

{announcement.map((list, index)=>

<div align="left" style={{margin:8}}>
    <div style={{border:"solid", borderWidth:"0.1px", borderColor:"lightgrey", borderRadius:"10px", padding:5, marginTop:8}}>
        <p style={{margin:3}}>{capitalize(list.subject)} <br/> <small>{months[parseInt((list.date.split("-"))[1])-1] +" "+(list.date.split("-"))[2]+", "+(list.date.split("-"))[0]}</small></p>
        <p style={{color:"grey", fontSize:"13px", textOverflow:'inherit'}}> {list.message} </p>
    <Grid container>


                            <Grid item xs={12}> 
                            <AvatarGroup max={2}>
                                {list.views.length!==0?
                                list.views.map((lister,idexer)=>
                                  
      <Avatar alt="Remy Sharp" src={"https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/"+list.views[0].image} onClick={()=>handleClickOpenview(list.views)} />

     ):<p align="right">No Viewer    </p>
                                }
                                </AvatarGroup>
                             </Grid>

    </Grid>
    </div>
</div>

)
}
</div>



       

            </div>
          
            </div>

      </Grid>

        </Grid>
      


        </TabPanel>



 <TabPanel value={value} index={2} dir={theme.direction} >
          
<div style={{height:"900px"}}>
       <Grid container spacing={3}>
          <Grid item xs={3}>
            <div style={{ borderRadius:"10px", height:"150px",backgroundColor:"#e9543c", border:"solid", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{color:"white"}}> Total Fees Due </p>
            <h1 align="center"  style={{color:"white", fontSize:"32px",  margin:"-13px"}}><span style={{fontSize:20}}>GHC </span> {Intl.NumberFormat('en-US').format(parseFloat(calcdue()).toFixed(2))}</h1>
            <p style={{color:"white"}}> { students.length } student(s)</p>
            </div>
          </Grid>

   <Grid item xs={3}>
              <div style={{ borderRadius:"10px", height:"150px", border:"solid",backgroundColor:"#27948f", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{ color:"white"}}> Partial Payment </p>
            <h1 align="center" style={{color:"white", fontSize:"32px", margin:"-13px"}}><span style={{fontSize:20}}>GHC </span>{Intl.NumberFormat('en-US').format(parseFloat(partialpayment()).toFixed(2))}</h1>
            <p style={{color:"white"}}> {partialpaymentstud()} student(s)</p>
            </div>
          </Grid>

   <Grid item xs={3}>
              <div style={{ borderRadius:"10px", height:"150px", border:"solid",backgroundColor:"#89358a", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{ color:"white"}}> Outstanding Dept </p>
            <h1 align="center" style={{color:"white", fontSize:"32px", margin:"-13px"}}><span style={{fontSize:20}}>GHC </span> {Intl.NumberFormat('en-US').format(parseFloat(calcoutstanding()).toFixed(2))}</h1>
            <p style={{color:"white"}}> {owingstudents(students)} student(s)</p>
            </div>
          </Grid>

   <Grid item xs={3}>
              <div style={{ borderRadius:"10px", height:"150px", border:"solid",backgroundColor:"#31a0be", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{ color:"white"}}> Total Paid </p>
            <h1 align="center" style={{color:"white", fontSize:"32px",margin:"-13px"}}><span style={{fontSize:20}}>GHC </span> {Intl.NumberFormat('en-US').format((parseFloat(calcdue())-calcoutstanding()).toFixed(2))}</h1>
            <p style={{color:"white"}}>{totalpaidstud()} student(s)</p>
            </div>
          </Grid>



        </Grid>
       


        <br/>
        <Grid container spacing={3}>

      <Grid item xs={9}>

 <div style={{ borderRadius:"10px", height:"290px", border:"solid", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{marginBottom:"-8px", color:"black"}}> <b>A Graphical Representation of Class Against Amount Owing by Students</b></p>
            <Studentchart3   classes={classlist}  data={feesdata()}/>

            
            </div>
<br/>
<br/>
            <div style={{ borderRadius:"10px", height:"290px", border:"solid", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{marginBottom:"-8px", color:"black"}}><b>Class vs Number of Students Owing</b></p>
            <Studentchart3   classes={classlist}  data={feesdatabynumber()}/>

            
            </div>



      </Grid>
      <Grid item xs={3}>

      <div style={{ borderRadius:"10px", height:"420px", border:"solid", backgroundColor:"#e0eff9", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <div style={{padding:"10px", textAlign:"center"}} align="left">
            <b style={{color:"#1976d2"}}>Daily Charges</b>
           
                       <input type="date"  value={dated_} style={{fontSize:"16px", color:"#1976d2" , backgroundColor:"#e0eff9", border:"solid", borderWidth:"0.1px", borderColor:"lightgray",  padding:5, margin:5, borderRadius:5}} onChange={(e)=>setdated__(e.target.value)} />
                         
            </div>

            <Divider/>

            <h1 align="center" style={{margin:"1px", color:"#1976d2"}}><span style={{fontSize:20}}>GHC </span>{Intl.NumberFormat('en-US').format(parseFloat(totaldailycharges()).toFixed(2)) }</h1>
            <p style={{marginTop:"-22px", fontSize:"12px", color:"#1976d2", margin:"1px", }}>{months[parseInt((dated_.split("-"))[1])-1] +" "+(dated_.split("-"))[2]+", "+(dated_.split("-"))[0] }</p>
<Divider/>
<div style={{paddingLeft:5,paddingRight:5}}>
<div className='scroller3' style={{height:"255px"}}>
       <List sx={{ width: '100%', maxWidth: 360,  margin:0, padding:1 }}>
     {Class_.map((list,index)=>
        <div>
        <ListItem
        sx={{padding:0}}
          disableGutters
          secondaryAction={
          //  <b>{students.filter(bug=>bug.class_===list.classname && bug.attendance.length!==0?bug.attendance[schooldetails_.terms[schooldetails_.terms.length-1]] !==undefined && bug.attendance[schooldetails_.terms[schooldetails_.terms.length-1]][bug.attendance[schooldetails_.terms[schooldetails_.terms.length-1]].length-1].attendance==="present":0 ).length}</b>
           <b>{"GHC "+Intl.NumberFormat('en-US').format(parseFloat(dailycharges(list)).toFixed(0))}</b>
          }
        >
          <ListItemText primary={list.classname} secondary={<small> {}  {dated_ && months[parseInt((dated_.split("-"))[1])-1] +" "+(dated_.split("-"))[2]+", "+(dated_.split("-"))[0] }</small>} />
        </ListItem>
        <Divider/>
        </div>
     )}
    </List>

    </div>
</div>
        
            </div>

      </Grid>

        </Grid>
      
</div>

        </TabPanel>






        
        <TabPanel value={value} index={9} dir={theme.direction}>
          


           
              

        <Grid container spacing={3}>
          <Grid item xs={3}>
            <div style={{ borderRadius:"10px", height:"150px",backgroundColor:"#e9543c", border:"solid", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{color:"white"}}> Full Payment </p>
            <h1 align="center"  style={{color:"white", fontSize:"32px",  margin:"-13px"}}>GHC {Intl.NumberFormat('en-US').format(fullpayment())}</h1>
            <p style={{color:"white"}}> {fullpaymentstud()} student(s)</p>
            </div>
          </Grid>

   <Grid item xs={3}>
              <div style={{ borderRadius:"10px", height:"150px", border:"solid",backgroundColor:"#27948f", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{ color:"white"}}> Partial Payment </p>
            <h1 align="center" style={{color:"white", fontSize:"32px", margin:"-13px"}}>GHC {Intl.NumberFormat('en-US').format(partialpayment())}</h1>
            <p style={{color:"white"}}> {partialpaymentstud()} student(s)</p>
            </div>
          </Grid>

   <Grid item xs={3}>
              <div style={{ borderRadius:"10px", height:"150px", border:"solid",backgroundColor:"#89358a", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{ color:"white"}}> Outstanding Dept </p>
            <h1 align="center" style={{color:"white", fontSize:"32px", margin:"-13px"}}>GHC {Intl.NumberFormat('en-US').format(fullowing(students))}</h1>
            <p style={{color:"white"}}> {fullowingstud()} student(s)</p>
            </div>
          </Grid>

   <Grid item xs={3}>
              <div style={{ borderRadius:"10px", height:"150px", border:"solid",backgroundColor:"#31a0be", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{ color:"white"}}> Total Paid </p>
            <h1 align="center" style={{color:"white", fontSize:"32px",margin:"-13px"}}>GHC {Intl.NumberFormat('en-US').format(totalpaid())}</h1>
            <p style={{color:"white"}}>{totalpaidstud()} student(s)</p>
            </div>
          </Grid>



        </Grid>
       
        <br/>
        <Grid container spacing={3}>

      <Grid item xs={7}>

 <div style={{ borderRadius:"10px", height:"290px", border:"solid", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{marginBottom:"-8px", color:"gray"}}> class and number students owing</p>
           <Studentchart3   classes={classlist}  data={feesdata()}/>
            
            </div>
<br/>
            <div style={{ borderRadius:"10px", height:"290px", border:"solid", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{marginBottom:"-8px", color:"gray"}}> class and number o students paid</p>
            <Studentchart3   classes={classlist}  data={feesdata()}/>
            
            </div>

      </Grid>

      <Grid item xs={5}>

 <div style={{ borderRadius:"10px", height:"97%", border:"solid", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{marginBottom:"-8px", color:"gray"}}> pie chart of owing against paid</p>
            <h1 align="center">100</h1>
            <p>total due</p>
            <p>total paid</p>
            <p>outstanding</p>
            </div>

      </Grid>

        </Grid>
      
        </TabPanel>
      
      </SwipeableViews>
      
    </Box>
    );
}

export default App;