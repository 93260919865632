import React, {Component} from "react";
import Chart from 'react-apexcharts'

function PartialLoadingIndicatorStory(props){

  console.log(props)
 
  var categories=["Class 1","Class 2","Class 3","Class 4"]
  var data=[100,40,20,60]

  if(props.categories.length!==0 && props.data.length!==0){

    categories = props.categories
    data = props.data

  }
    const state = {
      options: {
        chart: {
          id: 'apexchart-example'
        },
        xaxis: {
          categories: props.categories
        }
      },
      series: [{
        name: 'Students',
        data: props.data
      }]
    }

 
    return (
      <Chart options={state.options} series={state.series} type="area" width="100%" height="85%" />
    )
 
}

export default PartialLoadingIndicatorStory;