import React, {useEffect, useState} from 'react';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Close from '@mui/icons-material/Close';
import Backimage from './images/background.jpg';
// import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';


import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import Snack from './snack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Badge from '@mui/material/Badge';


import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { seedetails, getproducts } from './redux/todoSlice';
import { user } from './redux/admin';
import { loin, logout, updateteacherdata, getdatateacher} from './redux/teachers';
import {  getchat } from './redux/chat';
import {  schooldetails } from './redux/schooldetails';


import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton'
import axios from 'axios'
import Lessonstatus from './lessonstatus'
import Lessonn from './images/lessonnotes.svg'
import EmojiPicker from 'emoji-picker-react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import HourglassTop from '@mui/icons-material/HourglassTop';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import PersonIcon from './images/Ellipse77.png';
import io from "socket.io-client";

import { format } from 'timeago.js';

import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { MainContainer,Loader, ChatContainer, MessageList, Message, MessageInput,Avatar, TypingIndicator, Sidebar, Conversation, ConversationList, Search, MessageSeparator } from '@chatscope/chat-ui-kit-react';
 

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { Person } from '@mui/icons-material';

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));




const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function Profile(props) {

  const socket = io.connect("https://servermain.sisrevolution.com")
  console.log("conneted "+socket)
   


    const dt = new Date()
    const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
    const day = dt.getUTCDate();
    const month = String(dt.getMonth() + 1).padStart(2, '0');
    const year = dt.getFullYear();
    const date = year + "-" + month + "-" + day;

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

  const [opensnack, setOpensnack] = React.useState(false);
  const [preloading, setpreloading] = React.useState(true);
  const [loading, setloading] = React.useState(false);
  const [starter, setstarter] = React.useState(0);
  const [searching, setsearching] = React.useState("");

  const dispatch = useDispatch()
  const teachers__ = useSelector((state) => state.teachers)
  var teachers = teachers__


  


  const searcher=(e)=>{
    setsearching(e.target.value)
    }
    if(searching!==""){
     var newer = teachers.filter(bug=>(bug.lastname).toUpperCase().includes(searching.toUpperCase()) || (bug.othernames).toUpperCase().includes(searching.toUpperCase()) || (bug.othernames).toUpperCase().includes(searching.toUpperCase()))
     teachers=newer
    }
    
    
  const [chat, setchat] = React.useState([]);
  const [prechat, setprechat] = React.useState([]);

  const [username, setusername]= React.useState("Joseph")
  const [room, setroom]= React.useState("room1")
  const [smsloading, setsmsloading]= React.useState(!true)
  const [typer, settyper]= React.useState("")

  const admin = useSelector((state) => state.admin)
  const message = useSelector((state) => state.chat)
  const schooldetails_ = useSelector((state) => state.schooldetails)


  const viewer=async(chatter)=>{
    var chating=[...chatter]
    // const viewlist = chatter.filter(bug=> bug.seen && bug.seen.length!==0 ? bug.seen.filter(lister=>lister===admin._id).length===0:bug.seen.length===0 )
    const viewlist = chatter.filter(bug=> bug.seen.length===0 || bug.seen.filter(lister=>lister===admin._id).length===0)
   
    for(var i in viewlist){

        var indexer = chating.findIndex(bug=>bug.id===viewlist[i].id)
        var listing = {...viewlist[i]}
        listing.seen=[...listing.seen,admin._id]
        chating[indexer]=listing

    }

    try{
      dispatch(getchat({
  
        data: chating

    }))
    
        await axios.post("https://servermain.sisrevolution.com/api/schools/viewchat/",
            {
                _id: schooldetails_[0]._id,
                userid: admin._id,
                data: viewlist,
            }).then(res => {
            // dispatch(getchat({

            //           data: res.data[0].chat

            //       }))
            

            })
        }catch(err){
        }



}


  const schooldata= async () => {
    setloader_(true)
      await axios.post("https://servermain.sisrevolution.com/api/schools/getschooldata/",
          {
              schoolID:admin.schoolID

          }).then(res => {

              if (res.status === 200) {

                // setchat(res.data[0].chat)
                  // dispatch(schooldetails({

                  //     data: res.data

                  // })) 
                  dispatch(getchat({

                      data: res.data[0].chat

                  }))
                  setloader_(false)


              }

          })

  }


  const doer = async (data, schoolid) => {

    await axios.post("https://servermain.sisrevolution.com/api/schools/addchat/",
        {
            _id: schoolid,
            data: data,
        }).then(res => {

        console.log(res)

        })

}


  const gettingdata = async () => {
    await axios.post("https://servermain.sisrevolution.com/api/teachers/getworkersdata/",
        {
          status:"Active",
          schoolID:admin.schoolID

        }).then(res => {

            if (res.status === 200) {

                dispatch(getdatateacher({

                    data: res.data

                }))


            }

        })

}

useEffect(()=>{
  if(message.length===0){
    schooldata()
  }else{
    viewer(message)
  }
    // gettingdata()
   
},[preloading])




    const [message_, setmessage_] = React.useState("");
    const [onlinelist, setonlinelist] = React.useState([]);

    const [stated, setstated] = React.useState("");

    const [openannounce, setOpenannounce] = React.useState(!true);

    const [openbulk, setOpenbulk] = React.useState(!true);
    const [loader, setloader] = React.useState(true)

    const [expanded, setExpanded] = React.useState(false);
    const [loader_, setloader_] = React.useState(false);
    const [chosenteacher, setchosenteacher] = React.useState([]);
    const [chosennotes, setchosennotes] = React.useState([]);
    const [loadingMore, setLoadingMore] = useState(false);
    const [loadedMessages, setLoadedMessages] = useState([]);
    const [counter, setCounter] = useState(message.length-1);
    var starting=0

  
    // useEffect(() => {
    //     var msg = [...message]
    //     socket.on("receive_teachermessage", (data) => {
    //         msg=([...msg,data])
          
    //         dispatch(getchat({
    //             data:msg
    //         }))
    //         viewer([data])

            
    //     })
        
     
        
    // },[socket])

    const setstarting=()=>{
      starting++
      // console.log("starting "+starting)
    }

    useEffect(()=>{
        
      socket.emit("join_room",[admin.schoolID, admin._id])

    },[])
 
      const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };


    useEffect(() => {
       
      if(message.length==0){
         schooldata()
      }
            
     },[loader])
 
  
 


     
    const onYReachStart = () => {
        
      if(counter>1 && counter !==0){
      setLoadingMore(true);
      }
      if (loadingMore === true) {
          return;
        }
      /* Fake fetch from API */
      // setCounter(message.length-1)
      setTimeout(() => {
        const messages = [];
        /* Add 10 messages */
 
      const maxCounter = (counter-80)<=0 ? 0 : counter-80;
      let index = (counter-80)<0  ? counter<=0?0:counter : counter;
      
 

        for (;maxCounter < index ; index--) {
        if(index-starting<starting){
          messages.push(index ===0 ? message[index].date!==message[index!==0?index-1:index].date && <MessageSeparator> {(months[parseInt((message[index].date.split("-"))[1]) - 1] + " " + (message[index].date.split("-"))[2] + ", " + (message[index].date.split("-"))[0])}</MessageSeparator> : <MessageSeparator> {(months[parseInt((message[index].date.split("-"))[1]) - 1] + " " + (message[index].date.split("-"))[2] + ", " + (message[index].date.split("-"))[0])}</MessageSeparator> && message[index].senderid===admin.ID  || message[index].sender==="School Admin"?

                  <Message   avatarSpacer={index===0?true:index===message.length-1?false:message[index].senderid!==message[index+1].senderid?false:true}  style={{textAlign:"left"}} model={{...message[index],direction:"outgoing", position:index===0?"first":index===message.length-1?"single":message[index].senderid!==message[index+1].senderid?"single":"normal"}}>
                   
              {index===0?"first":index===message.length-1? < Avatar src={message[index].image===undefined? PersonIcon:message[index].senderid==="School Admin"? "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/"+message[index].image : "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/"+message[index].image} name={message[index].sender}  />: message[index].senderid!==message[index+1].senderid? < Avatar src={message[index].image===undefined? PersonIcon:message[index].senderid==="School Admin"? "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/"+message[index].image : "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/"+message[index].image} name={message[index].sender}  />:"normal"}
                    <Message.Footer  sentTime={format(message[index].sentTime)} sender={message[index].sender}  />
                  </Message>
                  :
                  <Message    avatarSpacer={index===0?true:index===message.length-1?false:message[index].senderid!==message[index+1].senderid?false:true} style={{textAlign:"left"}} model={{...message[index],direction:"incoming",  position:index===0?"first":index===message.length-1?"single":message[index].senderid!==message[index+1].senderid?"single":"normal" }}>
                            {/* <Avatar src={<PersonAdd/>} name="Joe" /> */}
                            {index===0?"first":index===message.length-1?<Avatar src={message[index].image===undefined? PersonIcon:"https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/"+message[index].image} name={message[index].sender} />:message[index].senderid!==message[index+1].senderid?<Avatar src={message[index].image===undefined? PersonIcon:"https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/"+message[index].image} name={message[index].sender} />:"normal"}
                            <Message.Footer  sentTime={format(message[index].sentTime)} sender={message[index].sender} />
                          </Message>
                        
      );

        }
    
        }
  
        setLoadedMessages(messages.reverse().concat(loadedMessages));
        setCounter(index);         
        setLoadingMore(false);
      }, 1500);
    };
  



  
 
 
 

    const sendreply=()=>{
    var msg = [...message]
  
      var msg=([...message, {message:message_, schoolID:admin.schoolID,image:admin.image,  senderid:admin.ID, sender:admin.lastname !==undefined?admin.lastname:"School Admin", reply:[], seen:[admin._id], date:date, time:time, sentTime:new Date().getTime()}])
          
    dispatch(getchat({
        data:msg
    }))

    socket.emit("send_message_toteacher", 
    {message:message_, schoolID:admin.schoolID, senderid:admin.ID, image:admin.image, sender:admin.lastname !==undefined?admin.lastname:"School Admin", reply:[], seen:[admin._id], date:date, time:time, id:new Date()+""+ admin.ID ,  sentTime:new Date().getTime()}
    )

    doer({message:message_, schoolID:admin.schoolID, id:new Date()+""+ admin.schoolID , senderid:admin._id, sender:admin.lastname !==undefined?admin.lastname:"School Admin", reply:[], seen:[admin._id], date:date, time:time, image:admin.image, sentTime:new Date().getTime()}, schooldetails_[0]._id)


}
 

    return (
        <div style={{height:"60%", marginLeft:"10%", marginRight:"10%", backgroundColor:"white", marginTop:"-10px", paddingTop:"10px"}} >


            <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    { message }
                </Alert>
            </Snackbar>
            <Typography
                sx={{ flex: '1 1 100%', }}
                style={{marginBottom:"10px"}}
                variant="h6"
                id="tableTitle"
                component="div"
            >
               CHAT ROOM
            </Typography>
            <div style={{ position:"relative", height: "78vh" }}>
  <MainContainer>

  {/* <Sidebar position="left" scrollable={false}>
                <Search placeholder="Search..." />
                <ConversationList>                                                     
                  <Conversation name="Lilly" info="Online">
                    <Avatar src={""} name="Lilly" status="available" />
                  </Conversation>
                                                                                   
                </ConversationList>
              </Sidebar> */}
    
    <ChatContainer> 

    { loader_===false?   
    <MessageList loadingMore={loadingMore}  onYReachStart={onYReachStart}>
      {loadedMessages}
     
        {
            message.length!==0?
            message.map((list,index)=>
            0<81 ? index>=81 &&
                      <>
                       {setstarting()}

            {index !==0 ? list.date!==message[index!==0?index-1:index].date && <MessageSeparator> {(months[parseInt((list.date.split("-"))[1]) - 1] + " " + (list.date.split("-"))[2] + ", " + (list.date.split("-"))[0])}</MessageSeparator> : <MessageSeparator> {(months[parseInt((list.date.split("-"))[1]) - 1] + " " + (list.date.split("-"))[2] + ", " + (list.date.split("-"))[0])}</MessageSeparator>}
            {  list.sender==="School Admin" || list.senderid===admin.ID?

                    <Message style={{textAlign:"left"}} model={{...list,direction:"outgoing", position:index===0?"first":index===message.length-1?"single":list.senderid!==message[index+1].senderid?"single":"normal" }}   avatarSpacer={index===0?true:index===message.length-1?false:list.senderid!==message[index+1].senderid?false:true}>
                
                      {/* <Avatar src={<PersonAdd/>} name="Joe" /> */}
                     
                      {index===0?"first":index===message.length-1? < Avatar src={list.image===undefined? PersonIcon:list.senderid==="School Admin"? "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/"+list.image : "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/"+list.image} name={list.sender}  />:list.senderid!==message[index+1].senderid? < Avatar src={list.image===undefined? PersonIcon:list.senderid==="School Admin"? "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/"+list.image : "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/"+list.image} name={list.sender}  />:"normal"}
                      <Message.Footer  sentTime={format(list.sentTime)} sender={list.sender}  />
                    </Message>
                    :
                    
                    // <Message style={{textAlign:"left"}} model={{...list,direction:"incoming",  position:index!==0?list.senderid!==message[index-1].senderid?"last":list.senderid!==message[index!==0?index+1:index].senderid?"single":"first":"first" }}>
                    <Message   avatarSpacer={index===0?true:index===message.length-1?false:list.senderid!==message[index+1].senderid?false:true} style={{textAlign:"left"}} model={{...list,direction:"incoming",  position:index===0?"first":index===message.length-1?"single":list.senderid!==message[index+1].senderid?"single":"normal" }}>
                              {/* <Avatar src={<PersonAdd/>} name="Joe" /> */}
                              {/* {console.log(list)} */}

                      {index===0?"first":index===message.length-1?<Avatar src={list.image===undefined? PersonIcon:"https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/"+list.image} name={list.sender} />:list.senderid!==message[index+1].senderid?<Avatar src={list.image===undefined? PersonIcon:"https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/"+list.image} name={list.sender} />:"normal"}
                              <Message.Footer  sentTime={format(list.sentTime)} sender={list.sender} />
                            </Message>
                        
        }
        </>
        :
         index<81 &&
        <>

{index !==0 ? list.date!==message[index!==0?index-1:index].date && <MessageSeparator> {(months[parseInt((list.date.split("-"))[1]) - 1] + " " + (list.date.split("-"))[2] + ", " + (list.date.split("-"))[0])}</MessageSeparator> : <MessageSeparator> {(months[parseInt((list.date.split("-"))[1]) - 1] + " " + (list.date.split("-"))[2] + ", " + (list.date.split("-"))[0])}</MessageSeparator>}
{  list.sender==="School Admin" || list.senderid===admin.ID?

      <Message    avatarSpacer={index===0?true:index===message.length-1?false:list.senderid!==message[index+1].senderid?false:true} style={{textAlign:"left"}} model={{...list,direction:"outgoing", position:index===0?"first":index===message.length-1?"single":list.senderid!==message[index+1].senderid?"single":"normal"}}>
  
        {/* <Avatar src={<PersonAdd/>} name="Joe" /> */}
        {index===0?"first":index===message.length-1? < Avatar src={list.image===undefined? PersonIcon:list.senderid==="School Admin"? "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/"+list.image : "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/"+list.image} name={list.sender}  />:list.senderid!==message[index+1].senderid?<Avatar src={list.image===undefined? PersonIcon:list.senderid!=="School Admin"? "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/"+list.image : "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/"+list.image} name={list.sender} />:"normal"}
        <Message.Footer  sentTime={format(list.sentTime)} sender={list.sender}  />
      </Message>
      :
      // <Message style={{textAlign:"left"}} model={{...list,direction:"incoming",  position:index!==0?list.senderid!==message[index-1].senderid?"last":list.senderid!==message[index!==0?index+1:index].senderid?"single":"first":"first" }}>
      <Message    avatarSpacer={index===0?true:index===message.length-1?false:list.senderid!==message[index+1].senderid?false:true} style={{textAlign:"left"}} model={{...list,direction:"incoming",  position:index===0?"first":index===message.length-1?"single":list.senderid!==message[index+1].senderid?"single":"normal" }}>
                {/* <Avatar src={<PersonAdd/>} name="Joe" /> */}
                {/* {console.log(list)} */}
                {index===0?"first":index===message.length-1?<Avatar src={list.image===undefined? PersonIcon:"https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/"+list.image} name={list.sender} />:list.senderid!==message[index+1].senderid?<Avatar src={list.image===undefined? PersonIcon:"https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/"+list.image} name={list.sender} />:"normal"}
                <Message.Footer  sentTime={format(list.sentTime)} sender={list.sender} />
              </Message>
          
}
</>
                                            
            )
            :

            <div style={{position:"fixed", width:"100%", height:"100%", marginTop:"48%"}}>
            <p style={{textAlign:"center", color:"grey"}}>No Chat Found</p>
        </div>
        }
    


        </MessageList>

:
<MessageList style={{marginTop:"20%"}}>
<Loader>Loading...</Loader>
</MessageList>
      }

        <MessageInput attachButton={false} fancyScroll={true} activateAfterChange={true} sendOnReturnDisabled={false} onChange={(value)=>setmessage_(value)} onSend={sendreply} style={{textAlign:"left"}} placeholder="Type message here" />        
        <EmojiPicker/>
    </ChatContainer>
  </MainContainer>
</div>



        </div>
    );
}

export default Profile;
