import React, {useEffect} from 'react';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Search from '@mui/icons-material/Search';
import Close from '@mui/icons-material/Close';
import Backimage from './images/background.jpg';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';


import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import Snack from './snack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Badge from '@mui/material/Badge';


import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { seedetails, getproducts } from './redux/todoSlice';
import { user } from './redux/admin';
import { loin, logout, updateteacherdata, getdatateacher } from './redux/teachers';
import {  admin } from './redux/admin';
import {  schooldetails } from './redux/schooldetails';


import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton'
import axios from 'axios'
import Lessonstatus from './lessonstatus'
import Lessonn from './images/lessonnotes.svg'

import io from "socket.io-client";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import HourglassTop from '@mui/icons-material/HourglassTop';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));
// const socket = io.connect("https://servermain.sisrevolution.com")




const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function Profile() {

    
    const dt = new Date()
    const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
    const day = dt.getUTCDate();
    const month = String(dt.getMonth() + 1).padStart(2, '0');
    const year = dt.getFullYear();
    const date = year + "-" + month + "-" + day;

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

  const [opensnack, setOpensnack] = React.useState(false);
  const [preloading, setpreloading] = React.useState(true);
  const [loading, setloading] = React.useState(false);
  const [searching, setsearching] = React.useState("");

  const dispatch = useDispatch()
  const teachers__ = useSelector((state) => state.teachers)
  var teachers = teachers__


  const searcher=(e)=>{
    setsearching(e.target.value)
    }
    if(searching!==""){
     var newer = teachers.filter(bug=>(bug.lastname).toUpperCase().includes(searching.toUpperCase()) || (bug.othernames).toUpperCase().includes(searching.toUpperCase()) || (bug.othernames).toUpperCase().includes(searching.toUpperCase()))
     teachers=newer
    }
    
    
  const [chat, setchat] = React.useState([]);
  const [prechat, setprechat] = React.useState([]);

  const [username, setusername]= React.useState("Joseph")
  const [room, setroom]= React.useState("room1")
  const [smsloading, setsmsloading]= React.useState(!true)
  const [typer, settyper]= React.useState("")

  const admin = useSelector((state) => state.admin)
  const schooldetails_ = useSelector((state) => state.schooldetails)
  const schooldata= async () => {
      await axios.post("https://servermain.sisrevolution.com/api/schools/getschooldata/",
          {
              schoolID:admin.schoolID

          }).then(res => {

              if (res.status === 200) {

                  dispatch(schooldetails({

                      data: res.data

                  }))


              }

          })

  }


  const gettingdata = async () => {
    await axios.post("https://servermain.sisrevolution.com/api/teachers/getworkersdata/",
        {
          status:"Active",
          schoolID:admin.schoolID

        }).then(res => {

            if (res.status === 200) {

                dispatch(getdatateacher({

                    data: res.data

                }))


            }

        })

}

useEffect(()=>{
    schooldata()
    gettingdata()
},[preloading])



    const [message, setmessage] = React.useState("");
    const [stated, setstated] = React.useState("");

    const [openannounce, setOpenannounce] = React.useState(!true);

    const [openbulk, setOpenbulk] = React.useState(!true);

    const [expanded, setExpanded] = React.useState(false);
    const [chosenteacher, setchosenteacher] = React.useState([]);
    const [chosennotes, setchosennotes] = React.useState([]);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleClickOpenannounce = (data) => {
       
        setOpenannounce(true);
    };

      const handleClicksnack = () => {
        setOpensnack(true);
    };

      const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };

    const handleCloseannounce = () => {
        setOpenannounce(false);
    };

    const handlebulksms=()=>{

                    setsmsloading(true)
                       
                    const message = document.getElementById("smsdata").value
                   
                
                    const fd = new FormData()
                  
               
                    fd.append("schoolID",admin.schoolID)
                    fd.append("message", message)
                    const uploading = async() => {
                        await axios.post("https://servermain.sisrevolution.com/api/students/bulksms/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }


                        }).then(res => {

                            if (res.status === 200) {
                              
                               
                        handleClosebulk()
                        handleClicksnack()
                        setmessage("SMS sent")
                        setstated("success")
                                setsmsloading(!true)

                            }
                            if (res.status === 204) {
                                handleClosebulk()
                        handleClicksnack()
                        setmessage("Error Sending SMS")
                        setstated("success")
                                setsmsloading(!true)
                            }

                        })

                    }


                    uploading()



                

    }

    const addcomment=()=>{

                    // loading(true)
                       
                    const message = document.getElementById("comment").value
                   
                    var index = chosenteacher.lessonnotes.findIndex(bug=>bug===chosennotes)
                    console.log(index)
                    var convertdata = [...chosenteacher.lessonnotes]
                    var convertdata_main = {...convertdata[index]}
                    var lastcomment = [...chosenteacher.lessonnotes[index].comment,{comment:message, date:date, time:time}]
                    convertdata_main.comment=lastcomment
                    convertdata[index]=convertdata_main
                                  
               
                    const uploading = async() => {
                        await axios.post("https://servermain.sisrevolution.com/api/teachers/addcomment/", {
                            lessonnotes: convertdata,
                            _id:chosenteacher._id,
                            schoolID:admin.schoolID

                        }).then(res => {

                            if (res.status === 200) {
                              dispatch(getdatateacher({

                                data: res.data
            
                            }))



                        handleClosebulk()
                        handleClicksnack()
                        setmessage("Comment sent")
                        setstated("success")
                                setsmsloading(!true)
                            }
                            if (res.status === 204) {
                                handleClosebulk()
                        handleClicksnack()
                        setmessage("Error Sending Comment")
                        setstated("success")
                            setsmsloading(!true)
                            }
                        })
                    }
                    uploading()
    }

  const handleClickOpenbulk = (data) => {
       
        setOpenbulk(true);
    };

    const handleClosebulk = () => {
        setOpenbulk(false);
    };

    const setchosen=(cteacher,cnotes)=>{

        setchosenteacher(cteacher)
        setchosennotes(cnotes)

    }


    return (
        <div style={{height:"100%"}} >


            <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    { message }
                </Alert>
            </Snackbar>


            <Dialog fullWidth maxWidth="sm" onClose={handleCloseannounce} aria-labelledby="customized-dialog-title" open={openannounce}>
            <DialogTitle id="customized-dialog-title" >
               
            <span style={{fontSize:20, marginLeft:30, color:"grey"}}> Add Comment</span>
                    <IconButton aria-label="" onClick={handleCloseannounce} style={{float:"right"}} >
                      <Close/>
                    </IconButton>
            </DialogTitle>
            <DialogContent>

                 
                 
<div align="center">

  
 <FormControl variant="standard" style={{ margin: 10, width:"90%" }}>

                            <textarea
                                id="comment"
                                placeholder="Comment"
                                rows={5}
                                style={{width:"100%", fontFamily:"unset", padding:8, borderRadius:5, border:"solid", borderColor:"lightgrey", fontSize:18, borderWidth:"0.2px"}}
                                variant="outlined"
                                label="Comment"
                               



                            />
                        </FormControl>

</div>



                </DialogContent>
                <Divider/>
                <DialogActions style={{textAlign:"center"}}>

 {loading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => addcomment()} color="primary">
                                    Submit
                                </Button>
                            }



                   
                </DialogActions>
            </Dialog>
            

            <Dialog fullWidth maxWidth="sm" onClose={handleClosebulk} aria-labelledby="customized-dialog-title" open={openbulk}>
            <DialogTitle id="customized-dialog-title" >
            <span style={{fontSize:20, marginLeft:30, color:"grey"}}>Bulk SMS to Guardians</span>
                    <IconButton aria-label="" onClick={handleClosebulk} style={{float:"right"}} >
                      <Close/>
                    </IconButton>
            </DialogTitle>
            <DialogContent>

                 
                 
<div align="center">



 <FormControl variant="standard" style={{ margin: 10, width:"90%" }}>

                            <textarea
                                id="smsdata"
                                placeholder="Enter message"
                                rows={5}
                                style={{width:"100%", padding:8, borderRadius:5, border:"solid", borderColor:"lightgrey", fontSize:18, borderWidth:"0.2px"}}
                                variant="outlined"
                                label="message"
                               



                            />
                        </FormControl>

</div>



                </DialogContent>
                <Divider/>
                <DialogActions style={{textAlign:"center"}}>

                 
 <Button autoFocus onClick={handlebulksms} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                        Submit
                    </Button>
                   
                </DialogActions>
            </Dialog>
            


           <Container maxWidth="lg" style={{backgroundColor:"white", height:"85vh"}}>
            <br/>
           <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                LESSON NOTES
            </Typography>

<Grid container spacing={2} >
 
<Grid item xs={8}>
    {/* <div style={{border:"solid", borderWidth:"0.1px", borderColor:"lightgrey", borderRadius:"10px"}}> */}

{chosennotes.length!==0?

<div >
 
<div className='scroller3'  style={{overflowY:"scroll", height:"72vh",border:"solid", borderWidth:"0.1px", backgroundColor:chosennotes.status==="cancelled"?"rgba(255,0,0,0.2)":chosennotes.status==="approved"?"rgba(0,255,0,0.2)":chosennotes.status==="submitted"?"rgba(0,0,255,0.2)":"white", borderColor:"lightgray", marginTop:15, padding:10, borderRadius:10, color:"gray" }}>
 
 {/* <p><span>{chosennotes.status}</span></p> */}

<IconButton onClick={handleClickOpenannounce}  style={{marginLeft:10}} ><ModeCommentIcon /></IconButton>
<Lessonstatus  chosennotes={chosennotes} chosenteacher={chosenteacher}/>

<Divider/>
<p style={{marginBottom:"-10px"}}><b>{(chosennotes.class_).toUpperCase()} </b></p>
<p><b>WEEK ENDING :  {(chosennotes.weekending)} </b><br/>
<span><b>DATE : </b>{(months[parseInt((chosennotes.date.split("-"))[1]) - 1] + " " + (chosennotes.date.split("-"))[2] + ", " + (chosennotes.date.split("-"))[0]) }</span> </p>
<Divider/>

<p><b>PERIOD :  {(chosennotes.period)} </b><br/></p>
<p><b>STRAND :  {(chosennotes.strand)} </b><br/></p>
<p><b>SUB-STRAND :  {(chosennotes.substrand)} </b><br/></p>

<Divider/>

<p><b>INDICATOR</b></p>
<div align="left">
<div style={{width:"100%", overflow:"auto"}}>
<pre style={{width:"100%", wordWrap:"normal", whiteSpace:"pre-wrap", fontStyle:"normal",  fontFamily:"unset", }}>{chosennotes.indicator}</pre>
</div>

</div>
<Divider/>

<p><b>CONTENT STANDARD</b></p>
<div align="left">
<div style={{width:"100%", overflow:"auto"}}>
<pre style={{width:"100%", wordWrap:"normal", whiteSpace:"pre-wrap", fontStyle:"normal",  fontFamily:"unset", }}>{chosennotes.contentstandard}</pre>
</div>

</div>
<Divider/>
<p><b>PERFORMANCE</b></p>
<div align="left">
<div style={{width:"100%", overflow:"auto"}}>
<pre style={{width:"100%", wordWrap:"normal", whiteSpace:"pre-wrap", fontStyle:"normal",  fontFamily:"unset", }}>{chosennotes.performance}</pre>
</div>

</div>
<Divider/>
<p><b>CORE COMPETENCIES</b></p>
<div align="left">
<div style={{width:"100%", overflow:"auto"}}>
<pre style={{width:"100%", wordWrap:"normal", whiteSpace:"pre-wrap", fontStyle:"normal",  fontFamily:"unset", }}>{chosennotes.core}</pre>
</div>

</div>
<Divider/>

<p><b>KEYWORDS</b></p>
<div align="left">
<div style={{width:"100%", overflow:"auto"}}>
<pre style={{width:"100%", wordWrap:"normal", whiteSpace:"pre-wrap", fontStyle:"normal",  fontFamily:"unset", }}>{chosennotes.keyword}</pre>
</div>

</div>
<Divider/>
<p><b>T.L.R. (s)</b></p>
<div align="left">
<div style={{width:"100%", overflow:"auto"}}>
<pre style={{width:"100%", wordWrap:"normal", whiteSpace:"pre-wrap", fontStyle:"normal",  fontFamily:"unset", }}>{chosennotes.tlms}</pre>
</div>

</div>

<Divider/>

<p><b>REF</b></p>
<div align="left">
<div style={{width:"100%", overflow:"auto"}}>
<pre style={{width:"100%", wordWrap:"normal", whiteSpace:"pre-wrap", fontStyle:"normal",  fontFamily:"unset", }}>{chosennotes.ref}</pre>
</div>

</div>
<Divider/>
<p><b>MAIN</b></p>
<div align="left">
<div style={{width:"100%", overflow:"auto"}}>
<pre style={{width:"100%", wordWrap:"normal", whiteSpace:"pre-wrap", fontStyle:"normal",  fontFamily:"unset", }}>{chosennotes.main}</pre>
</div>

</div>






</div>
    
    </div>
:
<div  style={{height:"72vh",border:"solid", borderWidth:"0.1px", backgroundColor:chosennotes.status==="cancelled"?"rgba(255,0,0,0.2)":chosennotes.status==="approved"?"rgba(0,255,0,0.2)":chosennotes.status==="submitted"?"rgba(0,0,255,0.2)":"white", borderColor:"lightgray", marginTop:15, padding:10, borderRadius:10, color:"gray" }}>
<p align="center" style={{fontFamily:"cursive"}}><b>Choose a lesson from the list</b></p>
<img  src={Lessonn} width="61%" />

</div>
}
</Grid>

<Grid item xs={4}>
   
    <div  style={{border:"solid",marginTop:16, borderWidth:"0.1px", borderColor:"lightgrey", borderRadius:"10px", marginBottom:40}}>
   <Search style={{marginRight:"-40px", paddingLeft:10, marginTop:5, position:"absolute", fontSize:"30px", color:"gray" }}/><input onChange={searcher} type="search" placeholder='search' style={{padding:10,paddingLeft:40, fontSize:"16px", color:'gray', borderRadius:"10px",border:"none", borderWidth:"0.1px", borderColor:"lightgrey", width:"90%" }}/>
    <Divider/>


    <div className='scroller3' style={{overflowY:"scroll", height:"67vh"}}>

{teachers.length!==0?
teachers.map((list, index)=>
    <Accordion expanded={expanded === list._id } onChange={handleChange(list._id)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
           <List button style={{margin:0, padding:0, marginBottom:"-10px", width:"100%"}}>
            
                <ListItem style={{width:"100%"}}
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                       <Badge badgeContent={list.lessonnotes.filter(bug=>bug.status==="submitted").length} color="primary">
                      <NoteAltIcon color="action" />
                    </Badge>
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar src={'https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/'+list.image} alt={list.lastname+" "+list.othernames} >
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={list.lastname+" "+list.othernames}
                    // secondary={secondary ? 'Secondary text' : null}
                  />
                </ListItem>
            
            </List>
        </AccordionSummary>

        {list.lessonnotes.length!==0?
        list.lessonnotes.map((lister,inder)=>
        <AccordionDetails style={{padding:0}}>
         
            <Divider/>
          <ListItem button onClick={()=>setchosen(list,lister)} style={{width:"100%"}}
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                   {lister.status==="cancelled"?<ClearIcon style={{color:"red"}}/>:lister.status==="approved"?<CheckIcon style={{color:"green"}}/>:<HourglassTop style={{color:"blue"}}/>}
  
                    </IconButton>
                  }
                >
                
                  <ListItemText
                    primary={(lister.subject)}
                    secondary={(months[parseInt((lister.date.split("-"))[1]) - 1] + " " + (lister.date.split("-"))[2] + ", " + (lister.date.split("-"))[0]) }
                  />
                </ListItem>
         
        </AccordionDetails>
        )
        :""
}
      </Accordion>
)
      :""
}

  
    </div>

    </div>
</Grid>

</Grid>

</Container>
        </div>
    );
}

export default Profile;
