import { createSlice } from "@reduxjs/toolkit"
//import axios from 'axios';


const dt = new Date()
const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
const day = dt.getUTCDate();
const month = String(dt.getMonth() + 1).padStart(2, '0');
const year = dt.getFullYear();
const date = year + "-" + month + "-" + day;

let data = [{ _id: "111", school: "Sir Joe Williams", allocation: 43, location: "kumasi", contact: "0243366865", data: [{ date: "24th", term: "first", quantity: 20, SRVrec: 10, SRVsent: 3, SRVcon: 2, SRVpaid: 5, suppliedby: "kksarpong" }, { date: "20th", term:"second", quantity: 21, SRVrec: 10, SRVsent: 3, SRVcon: 2, SRVpaid: 5 }] }, { _id: "1111", school: "John Williams", location:"kumasi", contact:"0243366865", data:[]}]

if (localStorage.getItem("schools") !== null && localStorage.getItem("schools") !== undefined && localStorage.getItem("schools") !== "") {

    data = [JSON.parse(localStorage.getItem("schools"))]
    
}


const Insider = createSlice({

    name: "todos",
    initialState:data,
    reducers: {

        getdata: (state, action) => {

            state = action.payload.data
            return state
                                  
        },

        logout_: (state, action) => {

         
                localStorage.clear()
                state = []
                return state
            
                         
        },

        updateschooldata: (state, action) => {

            
            state= action.payload.data
            return state
                                               
        },


    }
})

export const {logout_ , updateschooldata , login, getdata } = Insider.actions;
export default Insider.reducer;