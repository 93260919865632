import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import Homelogo from '@mui/icons-material/Home';
import Schoollogo from '@mui/icons-material/School';
import Supplylogo from '@mui/icons-material/LocalShipping';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import SRVadd from '@mui/icons-material/PostAdd';
import Logo from './images/logo.png';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ScrollToTop from './scrollToTop';
import Home from './home';
import Livechat from './livechat';
import Cloud from './cloud';
import Pricing from './pricing';
import Login from './login';
import Students from './students';
import Lessonnotes from './lessonnotes';
import Staff from './staff';
import Password from './forgottenpassword';
import SRV from './srv';
import Academics from './academics';
// import Offlinedata from './data.json';
import Settings from './settings';
import Classes from './class';
import Badge from '@mui/material/Badge';
import CampaignIcon from '@mui/icons-material/Campaign';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import Staffer from '@mui/icons-material/Engineering';
import Student from '@mui/icons-material/Person';
import PaymentsIcon from '@mui/icons-material/Payments';
import Payment from '@mui/icons-material/Payment';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import CalculateIcon from '@mui/icons-material/Calculate';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

import ListItemButton from '@mui/material/ListItemButton';

import LogoutIcon from '@mui/icons-material/Logout';

import Collapse from '@mui/material/Collapse';
import DraftsIcon from '@mui/icons-material/Drafts';
import SettingsIcon from '@mui/icons-material/Settings';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Classgroup from '@mui/icons-material/Groups';
import {Offline, Online} from "react-detect-offline";

import CloudSyncIcon from '@mui/icons-material/CloudSync';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import MailIcon from '@mui/icons-material/Mail';
import SellIcon from '@mui/icons-material/Sell'
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Person from './images/Ellipse77.png';
import Sislogo from './images/sislogo.png';
import ForumIcon from '@mui/icons-material/Forum';
import Modifyvehicle from './vehiclemodify';
import Timetable from './timetable';
import Schoolfees from './schoolfees';
import Canteenfees from './canteenfees';
import Miscellaneous from './miscellaneous';
import Salaries from './salaries';
import Expenses from './expenses';
import Messages from './messages';
import Circular from './circular';
import Announcement from './announcement';
// import { useRoute } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import "./index.css"
import axios from 'axios';

import io from "socket.io-client";
import Updatehome from './canteenfees';
import {BrowserRouter as Router, Routes, Route, Link, useLocation, HashRouter, useNavigate} from "react-router-dom";
import { BusAlertOutlined } from '@mui/icons-material';
import {  getchat } from './redux/chat';
import { schooldetails } from './redux/schooldetails';
import { getofflinedata } from './redux/offline';
import { announcement_ } from './redux/announcement';
import { loin, logout, updatestudentdata, getdata } from './redux/students';
import { updateteacherdata, getdatateacher } from './redux/teachers';
import { getdata_ } from './redux/class';
import { updateexpensesdata, getdataexpenses } from './redux/expenses';

import EmojiPicker from 'emoji-picker-react';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const drawerWidth = 230;

function ResponsiveDrawer(props) {
    // const {getCurrentWindow, globalShortcut} = require('electron').remote;
    // const [socket, setsocket] = React.useState(false);

    const offlinedata = useSelector((state) => state.offline)
    var socket = io.connect("https://servermain.sisrevolution.com")

//     console.log("offlinedata "+offlinedata)
// if(offlinedata){
//     // setsocket(socketer)
 
//     console.log("connecttt "+socketer)
// }
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { window } = props;
    const location = useLocation()
    const [openloader, setopenloader] = React.useState(false);
    const [messager, setmessager] = React.useState("");
    const [stated, setstated] = React.useState("");
    const [opensnack, setOpensnack] = React.useState(false);

    const [onlinecheck, setonlinecheck] = React.useState(true);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [unseen, setunseen] = React.useState(0);
    const [clicked, setclicked] = React.useState(location.pathname);
    const [preload, setpreload] = React.useState(true);
    const [newmessage, setnewmessage] = React.useState([]);

    const admin = useSelector((state) => state.admin)
    const Offlinedata = useSelector((state) => state.offline)

    const schooldetails_ = useSelector((state) => state.schooldetails)
    const message = useSelector((state) => state.chat)
    const stud = useSelector((state) => state.students)
    const students = admin.class_===undefined? stud:stud.filter(bug=>bug.class_===admin.positiondescription_)
  
    const classer = useSelector((state) => state.Class_)
    var Class_ = admin.class_===undefined ? classer:classer.filter(bug=>bug.classname===admin.positiondescription_)
    const expenses = useSelector((state) => state.expenses)
    const announcement = useSelector((state) => state.announcement_)

    // var Class_ = useSelector((state) => state.Class_)

    const teachers = useSelector((state) => state.teachers)

    // const chats = [message.length!==0 && message.filter(bug=>bug.senderid!==admin.ID)]
   
    const gettingdataexp = async () => {
      await axios.post("https://servermain.sisrevolution.com/api/expenditure/getexpensesdata/",
          {
              schoolID:admin.schoolID

          }).then(res => {

              if (res.status === 200) {

                  dispatch(getdataexpenses({

                      data: res.data

                  }))


              }

          })

  }

    const schooldata= async () => {
        await axios.post("https://servermain.sisrevolution.com/api/schools/getschooldata/",
            {
                schoolID:admin.schoolID
  
            }).then(res => {
  
              console.log(res.data)
                if (res.status === 200) {
  
                    dispatch(schooldetails({
  
                        data: res.data
  
                    }))
  
                    dispatch(getchat({
  
                      data: res.data[0].chat
  
                  }))
  
  
                }
  
            })
  
    }
  
  
    const gettingdatat = async () => {
          await axios.post("https://servermain.sisrevolution.com/api/teachers/getworkersdata/",
              {
  
                status:"Active",
                schoolID:admin.schoolID
  
              }).then(res => {
  
                  if (res.status === 200) {
  
                      dispatch(getdatateacher({
  
                          data: res.data
  
                      }))
  
  
                  }
  
              })
  
      }
  
      
        const gettingdata_ = async () => {
          await axios.post("https://servermain.sisrevolution.com/api/class/getclassdata/",
              {
  
                status:"Active",
                schoolID:admin.schoolID
  
              }).then(res => {
  
                  if (res.status === 200) {
  
                      dispatch(getdata_({
  
                          data: res.data
  
                      }))
  
  
                  }
  
              })
  
      }
  
   
  
      const gettingdata = async () => {
        setopenloader(true)
        try{
          await axios.post("https://servermain.sisrevolution.com/api/students/getstudentdata/",
              {
                status:"Active",
                schoolID:admin.schoolID
  
  
              }).then(res => {
  
                  if (res.status === 200) {
  
                      dispatch(getdata({
  
                          data: res.data
  
                      }))
  
                      setopenloader(!true)
  
                  }else{
                    setopenloader(!true)
  
                  }
  
              })
  
            }catch(err){
              setopenloader(!true)
              handleClicksnack()
              setmessager("Network Error, please connect to the internet")
              setstated("error")
  
            }
            }
   
  
      
    const announcementdata = async () => {
      await axios.post("https://servermain.sisrevolution.com/api/announcement/getannouncement/",
          {
              schoolID:admin.schoolID
  
          }).then(res => {
  
              if (res.status === 200) {
  
                  dispatch(announcement_({
  
                    announcement: res.data.filter(bug=>bug.to===undefined || bug.to==="All")
  
                  }))
  
  
              }
  
          })
  
  }
  

  React.useEffect(()=>{
    <Online onChange={setonlinecheck(false)}></Online>

    if(schooldetails_.length===0){
    schooldata()
    }
    if(announcement.length===0){
    announcementdata()
    }
    if(Class_.length===0){
    gettingdata_()
    }
    if(teachers.length===0){
    gettingdatat()
    } 
    if(expenses.length===0){
      gettingdataexp()
    }

    if(students.length===0){
      if(navigator.onLine===true){
        gettingdata()
      }else{
        setonlinecheck(false)
        setopenloader(!true)
        handleClicksnack()
        setmessager("Network Error, Please connect to the internet")
        setstated("error")
      }
    }
    
   
//  return(
//   <div>
//     <Online>{setonlinecheck(true)}</Online>
// <Offline>{setonlinecheck(false)}</Offline>
//   </div>
  
//  )


  },[preload])

  const handleClicksnack = () => {
    setOpensnack(true);
};

const handleClosesnack = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setOpensnack(false);
};

const viewer=async(chatter)=>{
  var chating={...chatter}
  // const viewlist = chatter.filter(bug=> bug.seen && bug.seen.length!==0 ? bug.seen.filter(lister=>lister===admin._id).length===0:bug.seen.length===0 )
  const viewlist = chating
      viewlist.seen=[...viewlist.seen,admin._id]
      chating=viewlist

 



  try{
      dispatch(getchat({

          data: [...message, chating]

      }))
      
      await axios.post("https://servermain.sisrevolution.com/api/schools/viewchat/",
          {
              _id: schooldetails_[0]._id,
              data: viewlist,
              userid: admin._id
          }).then(res => {

              // dispatch(getchat({

              //     data: res.data[0].chat

              // }))

          })
      }catch(err){
          console.log(err)
       console.log("error")
      }



}

useEffect(()=>{
        
        socket.emit("join_room",[admin.schoolID, admin._id])
  
      },[])
    

      var user = useLocation()
useEffect(() => {
 
  console.log(user)
  var currentlocation = user.pathname 

  if(currentlocation !=="#/livechat"){
  if(newmessage.length!==0){
      if(newmessage.senderid!==admin.ID && newmessage.senderid!=="School Admin" ){
        var msg__=[...message, newmessage]
        dispatch(getchat({
          data:msg__
      })) 
      setnewmessage([]) 

    }
  }
}
else{
  if(newmessage.length!==0){
  viewer(newmessage)
  setnewmessage([]) 

  }
}

},[newmessage]) 


  useEffect(() => {

      socket.on("receive_teachermessage", (data) => {
     
      setnewmessage(data)

        })
   
},[]) 



var Alldata = [['Dashboard', "/", <Homelogo />], ["Students", "/students", <Student />], ['Staff', "/staff", <Staffer />], ['Class', "/class", <Classgroup />] ,  ['Announcement / Bulk SMS', "/announcement", <CampaignIcon />], ['Circular Notification', "/circular", <TextSnippetIcon />],['Time Table', "/timetable", <EventAvailableIcon />],['Lesson Notes', "/lessonnotes", <NoteAltIcon />]]

if(admin.positiondescription==="Accountant"){
  Alldata=[['Dashboard', "/", <Homelogo />], ['Class', "/class", <Classgroup />] ]
}
if(admin.positiondescription==="Administrator" || admin.positiondescription==="School Head"){
  Alldata=[['Dashboard', "/", <Homelogo />], ["Students", "/students", <Student />], ['Staff', "/staff", <Staffer />], ['Class', "/class", <Classgroup />] ,  ['Announcement / Bulk SMS', "/announcement", <CampaignIcon />],['Circular Notification', "/circular", <TextSnippetIcon />],['Time Table', "/timetable", <EventAvailableIcon />],['Lesson Notes', "/lessonnotes", <NoteAltIcon />]]
}
if(admin.positiondescription==="Class Teacher" || admin.positiondescription==="Class and Subject Teacher"){
  Alldata=[['Dashboard', "/", <Homelogo />], ["Students", "/students", <Student />], ['Class', "/class", <Classgroup />] , ['Announcement / Bulk SMS', "/announcement", <CampaignIcon />],['Time Table', "/timetable", <EventAvailableIcon />]]
}


useEffect(() => {

    const viewlist = message.filter(bug=> bug.seen.length===0 || bug.seen.filter(lister=>lister===admin._id).length===0)

 setunseen(viewlist.length)
  },[message]) 
  


  const syncall=()=>{
  
      if(navigator.onLine===true){ 
      for(var i in offlinedata){
  
          const doer = async () => {
              await axios.post(offlinedata[i].link,
                  offlinedata[i].data).then(res => {
  
                      if (res.status === 200) {
  
                          var main = offlinedata.filter(bug=>bug.id!==offlinedata[i].id)
                       
                          localStorage.setItem("offline", JSON.stringify(main))
  
                          dispatch(getofflinedata({
                  
                              data: main
                  
                          }))
                          handleClicksnack()
                          setmessager("Data syncing successful")
                          setstated("success")

                        } else {
                        
                        handleClicksnack()
          setmessager("error syncing data")
          setstated("error")

                      }
  
                  })
  
          }
  
          doer()
  
      }
  }
  else{
  
                      handleClicksnack()
                      setmessager("Please connetct to the internet ant try again")
                      setstated("error") 
  
  }
  
  
  }
  
  
  if(offlinedata.length!==0){
    if(navigator.onLine===true){
    syncall()
    }
  }



    const logout = () => {
      if(Offlinedata.length===0){
        setopenloader(true)
        localStorage.clear()
        navigate("/")
        document.location.reload();
      }else{

        handleClicksnack()
        setmessager("You cannot sign out at this time, please sync all data to the cloud first")
        setstated("warning")

      }
       
        // window.location.reload()
    }

    const clicker=(data)=>{
       setclicked(data)
    }

    const handleDrawerToggle = (e) => {
        setMobileOpen(!mobileOpen);
    };

    const [open, setOpen] = React.useState(!true);

    const handleClick = () => {
      setOpen(!open);
    };

    const drawer = (
        <div   className='scroller2' style={{
            backgroundColor: "white", color: "gray", height:"100%",paddingBottom:40
        }}>

       

            <div style={{ textAlign: "center", position:"sticky", backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)", width:"100%" }}>
              <img src={Sislogo} width="85%" style={{marginBottom:"-20px"}}/>

                <label htmlFor="contained-button-file">
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}


                    >
                        <Avatar style={{ width:100, height:100, border: "solid", borderWidth:"0.5px", borderColor: "#1976d2", marginTop:12 }} alt="Image" src={schooldetails_.length!==0 && 'https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/'+schooldetails_[0].image} />

                    </Badge>
                    <p  style={{margin:4}}> <b style={{color:"white"}}> {admin.lastname && (admin.lastname).toUpperCase() +" "+admin.othernames && (admin.othernames).toUpperCase()} </b> </p>
                    <p style={{margin:4}}> <small style={{color:"white"}}> {admin.schoolID} </small> </p>

                </label>    

                <br/>
                   </div>
           
            
            <List sx={{ mr: 2, display: { sm: 'none'} }} >
                {Alldata.map((text, index) => (
                    <Link  to={text[1]}  onClick={handleDrawerToggle} style={{ textDecoration: "none", color:"gray"  }}>
                    <ListItem className={clicked===text[1]?"menulinkclick" : "button"} onClick={()=>clicker(text[1])}   key={text[0]} >
                        <ListItemIcon style={{
                                color:"gray"
                        }}>
                            {text[2]}
                        </ListItemIcon>
                        <ListItemText primary={text[0]} />
                        </ListItem>
                    </Link>
                ))}
{admin.positiondescription==="Accountant" || admin.smsID!==undefined?

     
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
        <PaymentsIcon  style={{color:"white"}}/>
        </ListItemIcon>
        <ListItemText primary="Finance" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
 :""
                      }

                <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>

        <Link to="/schoolfees"   onClick={handleDrawerToggle} style={{ textDecoration: "none", color:"gray" }}>
          <ListItem sx={{ pl: 4 }} className={clicked==="/schoolfees"?"menulinkclick" : "button"} onClick={()=>clicker("/schoolfees")}>
            <ListItemIcon>
              <PaymentsIcon style={{color:"gray"}} />
            </ListItemIcon>
            <ListItemText primary="Schools Fees" />
          </ListItem> 
          </Link>

          {admin.positiondescription!=="Administrator and Teller" ?
<>
          <Link to="/accounts"  className="menulink" onClick={handleDrawerToggle} style={{ textDecoration: "none", color:"gray" }}>
          <ListItem sx={{ pl: 4 }} className={clicked==="/accounts"?"menulinkclick" : "button"} onClick={()=>clicker("/accounts")}>
            <ListItemIcon>
              <Payment style={{color:"gray"}} />
            </ListItemIcon>
            <ListItemText primary="Accounts" />
          </ListItem>
          </Link>

          
          
          
          <Link to="/salaries"  className="menulink" onClick={handleDrawerToggle} style={{ textDecoration: "none", color:"gray" }}>
           <ListItem sx={{ pl: 4 }} className={clicked==="/salaries"?"menulinkclick" : "button"} onClick={()=>clicker("/salaries")}>
            <ListItemIcon>
              <AccountBalanceWalletIcon style={{color:"gray"}} />
            </ListItemIcon>
            <ListItemText primary="Staff Salaries" />
          </ListItem>
          </Link>
          </>
          :""}

          <Link to="/expenses"  className="menulink" onClick={handleDrawerToggle} style={{ textDecoration: "none", color:"gray" }}>
           <ListItem sx={{ pl: 4 }} className={clicked==="/expenses"?"menulinkclick" : "button"} onClick={()=>clicker("/expenses")}>
            <ListItemIcon>
              <CalculateIcon style={{color:"gray"}} />
            </ListItemIcon>
            <ListItemText primary="Expenditure" />
          </ListItem>
            </Link>

        </List>
      </Collapse>
      
     
      <Divider  style={{padding:6}} />
      {[['Settings', "/", <Homelogo />], ["Logout", "/students", <Student />] ].map((text, index) => (
                    <Link  to={text[1]}  onClick={handleDrawerToggle} style={{ textDecoration: "none", color:"gray"  }}>
                    <ListItem className={clicked===text[1]?"menulinkclick" : "button"} onClick={()=>clicker(text[1])}   key={text[0]} >
                        <ListItemIcon style={{
                                color:"gray"
                        }}>
                            {text[2]}
                        </ListItemIcon>
                        <ListItemText primary={text[0]} />
                        </ListItem>
                    </Link>
                ))}
            
            </List>

            <List sx={{ mr: 2, display: { xs: 'block', lg: "block" , md: "block" } }} style={{width:"100%"}}>
                {Alldata.map((text, index) => (
                    <Link to={text[1]}  style={{ textDecoration: "none", color:"gray" }}>
                    <ListItem className= {clicked===text[1]?"menulinkclick" : "button"}  onClick={()=>clicker(text[1])}   key={text[0]} >
                        <ListItemIcon 
                               style={{color: clicked===text[1]? "#1976d2":"gray"}}
                       >

                            {text[2]}
                        </ListItemIcon>
                        <ListItemText primary={text[0]} />
                        </ListItem>
                    </Link>
                ))}
{admin.positiondescription==="Accountant" || admin.positiondescription==="Administrator and Teller" || admin.smsID!==undefined?
                <ListItemButton onClick={handleClick}>
        <ListItemIcon>
        <PaymentsIcon  style={{color:"gray"}}/>
        </ListItemIcon>
        <ListItemText primary="Finance" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      :""}

      
                <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>

        <Link to="/schoolfees" className="menuclick" style={{ textDecoration: "none", color:"gray" }}>
          <ListItem sx={{ pl: 4 }} className={clicked==="/schoolfees"?"menulinkclick" : "button"} onClick={()=>clicker("/schoolfees")}>
            <ListItemIcon>
              <PaymentsIcon style={{color: clicked==="/schoolfees"? "#1976d2":"gray"}} />
            </ListItemIcon>
            <ListItemText primary="Schools Fees" />
          </ListItem> 
          </Link>
{admin.positiondescription!=="Administrator and Teller"?
<>
          <Link to="/accounts" className="menuclick" style={{ textDecoration: "none", color:"gray" }}>
          <ListItem sx={{ pl: 4 }} className={clicked==="/accounts"?"menulinkclick" : "button"} onClick={()=>clicker("/accounts")} >
            <ListItemIcon>
              <Payment style={{color: clicked==="/accounts"? "#1976d2":"gray"}} />
            </ListItemIcon>
            <ListItemText primary="Accounts" />
          </ListItem>
          </Link>       
          
          <Link to="/salaries" className="menuclick" style={{ textDecoration: "none", color:"gray" }}>
           <ListItem sx={{ pl: 4 }} className={clicked==="/salaries"?"menulinkclick" : "button"} onClick={()=>clicker("/salaries")}>
            <ListItemIcon>
              <AccountBalanceWalletIcon style={{color: clicked==="/salaries"? "#1976d2":"gray"}} />
            </ListItemIcon>
            <ListItemText primary="Staff Salaries" />
          </ListItem>
          </Link>
          </>
          :""
}

          <Link to="/expenses" className="menuclick" style={{ textDecoration: "none", color:"gray" }}>
           <ListItem sx={{ pl: 4 }} className={clicked==="/expenses"?"menulinkclick" : "button"} onClick={()=>clicker("/expenses")}>
            <ListItemIcon>
              <CalculateIcon style={{color: clicked==="/expenses"? "#1976d2":"gray"}} />
            </ListItemIcon>
            <ListItemText primary="Expenditure" />
          </ListItem>
            </Link>

        </List>
      </Collapse>

      <Divider/>
      {admin.positiondescription!=="Administrator and Teller" && admin.positiondescription!=="Class Teacher" && admin.positiondescription!=="Class and Subject Teacher"?
      <>
      <Link  to="/settings"   style={{ textDecoration: "none", color:"gray"  }}>
                    <ListItem className={clicked==="/settings" ?"menulinkclick" : "button"} onClick={()=>clicker("/settings")}   key="Settings">
                        <ListItemIcon style={{
                                color:"gray"
                        }}>
                            <SettingsIcon style={{color: clicked==="/settings"? "#1976d2":"gray"}} />
                        </ListItemIcon>
                        <ListItemText primary="Settings" />
                        </ListItem>
                    </Link>
             
           
      <Link  to="/pricing"   style={{ textDecoration: "none", color:"gray"  }}>
                    <ListItem className={clicked==="/pricing" ?"menulinkclick" : "button"} onClick={()=>clicker("/pricing")}   key="Pricing">
                        <ListItemIcon style={{
                                color:"gray"
                        }}>
                            <SellIcon style={{color: clicked==="/pricing"? "#1976d2":"gray"}} />
                        </ListItemIcon>
                        <ListItemText primary="Pricing" />
                        </ListItem>
                    </Link>
             
           </>
:""
}
            
            <Link  to="/"  onClick={()=>logout()} style={{ textDecoration: "none", color:"red"  }}>
                    <ListItem  onClick={()=>clicker("/")}   key="/">
                        <ListItemIcon style={{
                                color:"red"
                        }}>
                            <LogoutIcon/>
                        </ListItemIcon>
                        <ListItemText primary="LogOut" />
                        </ListItem>
                    </Link>
             
            </List>


           
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }} >



<Snackbar open={opensnack} autoHideDuration={8000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    { messager }
                </Alert>
            </Snackbar>

             <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={openloader}
//   onClick={handleClose}
>
  <CircularProgress color="inherit" />
</Backdrop>



            <CssBaseline  />
            <AppBar
                style={{
                    /* Created with https://www.css-gradient.com */
color: "#1976d2", backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)" }}
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon  />
                    </IconButton>
                   
                    <div style={{width:"100%", margin:0, padding:0 }}>
                    <p style={{float:"left", fontWeight:"bolder", color:"white"}}>  {schooldetails_.length!==0 && schooldetails_[0].schoolname.toUpperCase()}</p>
                        <span style={{float:"right"}}>



                        <Link  to="/cloudsync">
                        <Badge badgeContent={Offlinedata.length} color="error"  style={{marginTop:"8px", marginRight:"30px"}}>
                                                <IconButton
                                                    color="inherit"
                                                    aria-label="open drawer"
                                                    edge="end"
                                                    style={{backgroundColor:"rgba(0,0,0,0.1)"}}>
                                                    

                                                    <CloudSyncIcon style={{color:"white"}}/>

                                                </IconButton>
                                                </Badge>
                        </Link>

                        <Link to="/livechat"  >
                        <Badge badgeContent={unseen} color="error"  style={{marginTop:"8px", marginRight:"30px"}}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            style={{backgroundColor:"rgba(0,0,0,0.1)"}}
                            
                        >
                            

                            <ForumIcon style={{color:"white"}}/>
                            {/* <Avatar alt="r" size="small" /> <small style={{ fontSize: "13px", marginLeft:"5px" }}>Hello, Kwame</small> */}

                        </IconButton>
                        </Badge>
                        </Link>



                        <Link to="/messages">
                        <Badge badgeContent={schooldetails_!==undefined && schooldetails_[0] !==undefined && schooldetails_[0].messages !==undefined && schooldetails_.length!==0 ? schooldetails_[0].messages.filter(bug=>bug.view===undefined || (bug.view!==undefined && bug.view.length===0)).length:0} color="error"  style={{marginTop:"8px", marginRight:"15px"}}>
                        {/* <Badge badgeContent={3} color="error"  style={{marginTop:"8px", marginRight:"15px"}}> */}
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            style={{backgroundColor:"rgba(0,0,0,0.1)", color:"white"}}
                            
                        >
                            
                            <MailIcon style={{color:"white"}}/>
                            {/* <Avatar alt="r" size="small" /> <small style={{ fontSize: "13px", marginLeft:"5px" }}>Hello, Kwame</small> */}

                        </IconButton>
                        </Badge>
                        </Link>

 



                        </span>

                    </div>

                </Toolbar>
            </AppBar>
            <Box
                component="nav"
               
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                   
                    sx={{
                        display: { xs: 'block', sm:'block', md:'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                  
                    sx={{
                        display: { xs: 'none', sm: 'block', md:'block',lg:'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >

                <Toolbar/>

                <ScrollToTop />
                
                <Routes>
               
                
                    <Route path="/" element={<Home/>} />
                    <Route path="/login" element={<Login/>} />
                    <Route path="/cloudsync" element={<Cloud/>} />
                    <Route path="/livechat" element={<Livechat Socket={socket} />} />
                    <Route path="/students" element={<Students />} />
                    <Route path="/staff" element={<Staff/>} />
                    <Route path="/academics" element={<Academics />} />
                    <Route path="/lessonnotes" element={<Lessonnotes />} />
                    <Route path="/class" element={<Classes />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/circular" element={<Circular />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/announcement" element={<Announcement />} />
                    <Route path="/schoolfees" element={<Schoolfees />} />
                    <Route path="/messages" element={<Messages />} />
                    <Route path="/accounts" element={<Canteenfees />} />
                    <Route path="/miscellaneous" element={<Miscellaneous />} />
                    <Route path="/salaries" element={<Salaries />} />
                    <Route path="/expenses" element={<Expenses />} />
                    <Route path="/timetable" element={<Timetable />} />
                
              
                </Routes>
            





            </Box>
        </Box>
    );
}

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default ResponsiveDrawer;
