import { configureStore } from "@reduxjs/toolkit"
import admin from "./admin"
import schools from "./schools"
import students from "./students"
import teachers from "./teachers"
import expenses from "./expenses"
import schooldetails from "./schooldetails"
import Class_ from "./class"
import offline from "./offline"
import announcement_ from "./announcement"
import messages from "./messages"
import chat from "./chat"


export default configureStore({

    reducer: {

        admin: admin,
        schools: schools,
        students: students,
        teachers: teachers,
        expenses: expenses,
        offline: offline,
        schooldetails: schooldetails,
        Class_: Class_,
        announcement_: announcement_,
        messages: messages,
        chat: chat


    },

})