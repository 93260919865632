import { createSlice } from "@reduxjs/toolkit"
//import axios from 'axios';


const dt = new Date()
const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
const day = dt.getUTCDate();
const month = String(dt.getMonth() + 1).padStart(2, '0');
const year = dt.getFullYear();
const date = year + "-" + month + "-" + day;

let data = []

if (localStorage.getItem("offline") !== null && localStorage.getItem("offline") !== undefined && localStorage.getItem("offline") !== "") {

    data = JSON.parse(localStorage.getItem("offline"))

}


const Insider = createSlice({

    name: "todos",
    initialState: data,
    reducers: {

        getofflinedata: (state, action) => {

            state = action.payload.data
            return state

        },

    

    }
})

export const { getofflinedata } = Insider.actions;
export default Insider.reducer;