import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Imagereplace from './images/Ellipse77.png';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}



export default function BasicTable(props) {
    const rows = props.data
    console.log(rows)


  const remarks=(data)=>{
       
        if(data==="Excellent"){
            return (<span style={{color:"darkgreen"}}>{data}</span>)
            
        } 
        else if(data=== "Very Good"){
            return(<span style={{color:"darkblue"}}>{data}</span>)
           
        }
         else if(data==="Good"){
           return (<span style={{color:"Black"}}>{data}</span>)
            
        }  
         else if(data==="Credit"){
           return(<span style={{color:"grey"}}>{data}</span>)
            
        }    
        else if(data==="Pass"){
           return (<span style={{color:"gray"}}>{data}</span>)
            
        }   
       else{
            return (<span style={{color:"red"}}>{data}</span>)
            
        }
    }



  return (
    <TableContainer >
    <br/>
    <Divider/>
      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
        <TableHead >
          <TableRow >
                      <TableCell align="left" style={{fontWeight:"bolder", color:"black"}}>SUBJECT</TableCell>
                      <TableCell align="left" style={{fontWeight:"bolder"}}>CLASS SCORE (50%)</TableCell>
                      <TableCell align="left" style={{fontWeight:"bolder"}}>EXAM SCORE (50%)</TableCell>
                      <TableCell align="left" style={{fontWeight:"bolder"}}>TOTAL (100%)</TableCell>
                      <TableCell align="left" style={{fontWeight:"bolder"}}>GRADE</TableCell>
                      <TableCell align="left" style={{fontWeight:"bolder"}}>REMARKS</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          { rows.length !==0 ? rows[0].results.map((row) => (
            <TableRow
                  key={row.subject}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                      {row.subject}
              </TableCell>
                  <TableCell align="left">{row.class_scorre}</TableCell>
                  <TableCell align="left">{row.exam_score}</TableCell>
                  <TableCell align="left">{row.total}</TableCell>
                  <TableCell align="left">{row.grade}</TableCell>
                  <TableCell align="left" style={{fontWeight:"bolder"}}>  {remarks(row.remarks)}  </TableCell>

            </TableRow>
          )): "" } 
        </TableBody>
      </Table>
    </TableContainer>
  );
}
