import  React, {useState} from 'react';
import './App.css';
import Menu from './menu';
import Login from './login';
import Date from './date';
import Loader from './loader';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { login } from './redux/admin';

import { HashRouter as Router, Routes, Route, Link } from "react-router-dom";


function App() {
    const [loading, setLoading] = useState(true);

    setTimeout(() => setLoading(false), 3000);
    const dispatch = useDispatch()
    console.log(localStorage.getItem("user_sis"))
   const loaders = () => {
       if (localStorage.getItem("user_sis") === "null" || localStorage.getItem("user_sis") === null || localStorage.getItem("user_sis") === undefined) {
           return (
               <Login/>
           )

       } else {

               return (
                   <Menu/>
                   )
       
           

       }
   }
   return (
       <div className="App" >
           <Router>
               {loading?<Loader/>:loaders()}

           </Router>

   </div>
 );
}
export default App;
