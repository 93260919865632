import React, {Component} from "react";
import Chart from 'react-apexcharts'

function PartialLoadingIndicatorStory(props){
  console.log(props)
 
    const state = {
      options: {
        chart: {
          id: 'apexchart-example'
        },
        xaxis: {
          categories: props.classes
        }
      },
      series: [{
        name: 'Amount Owing',
        data: props.data
      }]
    }

 
    return (
      <Chart options={state.options} series={state.series} type="area" width="100%" height="100%" />
    )
 
}

export default PartialLoadingIndicatorStory;