import { createSlice } from "@reduxjs/toolkit"
let data = []

const Insider = createSlice({

    name: "todos",
    initialState: data,
    reducers: {
        messages: (state, action) => {
            state = action.payload.announcement
            return state
        }


    },

})

export const { messages } = Insider.actions;
export default Insider.reducer;