import * as React from 'react';
import './App.css';
import Login from './landingpage/login';
import Imager from './images/imager.jpg';
import Menu from './menu';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import PrintButton from './printbutton';
import PropTypes from 'prop-types';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import {visuallyHidden} from '@mui/utils';
import Paper from '@mui/material/Paper';
import { alpha } from '@mui/material/styles';
import { JsonToExcel } from 'react-json-to-excel';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import AvatarGroup from '@mui/material/AvatarGroup';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Close from '@mui/icons-material/Close';

import {Offline, Online} from "react-detect-offline";
import DeleteIcon from '@mui/icons-material/Delete';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { getofflinedata } from './redux/offline';

import Snack from './snack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import FormControlLabel from '@mui/material/FormControlLabel';

import SchoolIcon from '@mui/icons-material/School';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import ClassIcon from '@mui/icons-material/Class';

import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';


import Studentchart1 from './chart/studentchart1'
import Studentchart2 from './chart/studentchart2'
import Studentchart3 from './chart/studentchart3'
import Activestaff from './chart/staff/activestaff'
import Position from './chart/staff/position'
import Demography from './chart/staff/demography'

import DatePicker from 'react-date-picker'

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { admin } from './redux/admin';
import { getchat } from './redux/chat';
import { schooldetails } from './redux/schooldetails';
import { announcement_ } from './redux/announcement';
import { loin, logout, updatestudentdata, getdata } from './redux/students';
import { updateteacherdata, getdatateacher } from './redux/teachers';
import { getdata_ } from './redux/class';
import { capitalize } from '@mui/material';


interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}



function createData(_id,id,lastname,othernames,status,image, class_, gender, dob,hometown,residence, religion, motherlastname, motherothernames, motheroccupation, mothercontact, motheremail, motherresidence, fatherlastname,fatherothername, fatheroccupation, fathercontact, fatheremail, fatherresidence, guardianlastname,guardianothername, guardianoccupation, guardiancontact, guardianemail, guardianresidence, guardianrelationship,  academic ,finance, attendance, Awards, behaviour, others, charges, specialID, nextclass, housing) 
{
    return {
        _id,
        id,
        lastname,
        othernames,
        status,
        image,
        class_,
        gender,
        dob,
        hometown,
        residence,
        religion,
        motherlastname,
        motherothernames,
        motheroccupation,
        mothercontact,
        motheremail,
        motherresidence,
        fatherlastname,
        fatherothername,
        fatheroccupation,
        fathercontact,
        fatheremail,
        fatherresidence, 
        guardianlastname,
        guardianothername,
        guardianoccupation,
        guardiancontact,
        guardianemail,
        guardianresidence,
        guardianrelationship,
        academic,
        finance,
        attendance,
        Awards,
        behaviour,
        others,
        charges,
        specialID,
        nextclass,
        housing
        
    };
}




function createData2(_id,ID,lastname,othernames,status,image, class_, gender, dob,hometown,residence, religion, motherlastname, motherothernames, motheroccupation, mothercontact, motheremail, motherresidence, fatherlastname,fatherothername, fatheroccupation, fathercontact, fatheremail, fatherresidence, guardianlastname,guardianothernames, guardianoccupation, guardiancontact, guardianemail, guardianresidence, guardianrelationship,  academics ,finance, attendance, awards, behaviour, others, charges, specialID, nextclass, housing) 
{
    return {
        _id,
        ID,
        lastname,
        othernames,
        status,
        image,
        class_,
        gender,
        dob,
        hometown,
        residence,
        religion,
        motherlastname,
        motherothernames,
        motheroccupation,
        mothercontact,
        motheremail,
        motherresidence,
        fatherlastname,
        fatherothername,
        fatheroccupation,
        fathercontact,
        fatheremail,
        fatherresidence, 
        guardianlastname,
        guardianothernames,
        guardianoccupation,
        guardiancontact,
        guardianemail,
        guardianresidence,
        guardianrelationship,
        academics,
        finance,
        attendance,
        awards,
        behaviour,
        others,
        charges,
        specialID,
        nextclass,
        housing
        
    };
}







const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Donut', 452, 25.0, 51, 4.9),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Honeycomb', 408, 3.2, 87, 6.5),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Jelly Bean', 375, 0.0, 94, 0.0),
    createData('KitKat', 518, 26.0, 65, 7.0),
    createData('Lollipop', 392, 0.2, 98, 0.0),
    createData('Marshmallow', 318, 0, 81, 2.0),
    createData('Nougat', 360, 19.0, 9, 37.0),
    createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [

  
  
    {
        id: 'id',
        numeric: false,
        disablePadding: !true,
        label: 'ID',
    },
  
    {
        id: 'name',
        numeric: !true,
        disablePadding: false,
        label: 'Name',
    }, {
        id: 'status',
        numeric: !true,
        disablePadding: false,
        label: 'Date',
    },
   
    {
        id: 'class',
        numeric: !true,
        disablePadding: false,
        label: 'Amount Paid',
    },
    {
        id: 'fees',
        numeric: !true,
        disablePadding: false,
        label: 'Description',
    },  {
        id: 'fees',
        numeric: !true,
        disablePadding: false,
        label: '',
    },
    {
        id: '',
        numeric: true,
        disablePadding: false,
        label: '',
    }
];


function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell align="center">
                    <b>
                        No.
                    </b>
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <b>{headCell.label}</b>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >

            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
            SCHOOL FEES
            </Typography>



        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};



function App() {
    const [term, setterm] = React.useState("All")
    const dispatch = useDispatch()
    const [opensnack, setOpensnack] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [dense, setDense] = React.useState(true);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [selected, setSelected] = React.useState([]);
    const [search, setsearch] = React.useState("")
    const [exportdata, setexportdata] = React.useState([]);
    const [openimage, setOpenimage] = React.useState(!true);

    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [preload, setpreload] = React.useState(true);
    const [openview, setOpenview] = React.useState(!true);
    const [openloader, setopenloader] = React.useState(false);
    const [chosen, setchosen] = React.useState([]);
    const [message, setmessage] = React.useState("");
    const [stated, setstated] = React.useState("");


    const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const dt = new Date()
  const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
  const day = dt.getUTCDate();
  const month = String(dt.getMonth() + 1).padStart(2, '0');
  const year = dt.getFullYear();
  const date = year + "-" + month + "-" + day;
  
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const [dated_, setdated__] = React.useState(date);

  const handleChangeIndex = (index) => {
    setValue(index);
  };
   
  var da =[2,3,4,5,6,7,8]
 
  

 const students = useSelector((state) => state.students)
 const offlinedata = useSelector((state) => state.offline)
    var Class_ = useSelector((state) => state.Class_)

  const teachers = useSelector((state) => state.teachers)


  const searching = (e) => {
    setsearch(e.target.value)
}
var bill = "" 

if (search !== "") {
    console.log(search)
    var listed = rows.filter(bug => bug.class_.toLowerCase().includes(search.toLowerCase()) || bug.status.toLowerCase().includes(search.toLowerCase()) || bug.othernames.toLowerCase().includes(search.toLowerCase()) || bug.lastname.toLowerCase().includes(search.toLowerCase()) || bug.id.toLowerCase().includes(search.toLowerCase())|| (bug.specialID !==undefined && bug.specialID.toLowerCase().includes(search.toLowerCase())) )
   rows=listed
}




  var classlist=[]
  var classdata=[]

  const handleClicksnack = () => {
    setOpensnack(true);
};

const handleClosesnack = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setOpensnack(false);
};


const handleChangePage = (event, newPage) => {
    setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
};

const handleChangeDense = (event) => {
    setDense(event.target.checked);
};

const isSelected = (name) => selected.indexOf(name) !== -1;


  const handleClickOpenview = (data) => {
    setchosen(data)
     setOpenview(true);
 };

 const handleCloseview = () => {
     setOpenview(false);
 };


 const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;



  for(var i in Class_){
    var data_=students.filter(bug=>bug.class_ === Class_[i].classname).length
    classdata.push(data_)
  }
 for(var j in Class_){
  // console.log(Class_[j].className)
    classlist.push(Class_[j].classname)
  }

  const admin = useSelector((state) => state.admin)
  const schooldetails_ = useSelector((state) => state.schooldetails)[0]
  const announcement = useSelector((state) => state.announcement_)

  const schooldata= async () => {
      await axios.post("http://localhost:3001/api/schools/getschooldata/",
          {
              schoolID:admin.schoolID

          }).then(res => {

              if (res.status === 200) {

                  dispatch(schooldetails({

                      data: res.data

                  }))

                  dispatch(getchat({

                    data: res.data[0].chat

                }))


              }

          })

  }


  const gettingdatat = async () => {
        await axios.post("https://servermain.sisrevolution.com/api/teachers/getworkersdata/",
            {

              status:"Active",
              schoolID:admin.schoolID

            }).then(res => {

                if (res.status === 200) {

                    dispatch(getdatateacher({

                        data: res.data

                    }))


                }

            })

    }

    
      const gettingdata_ = async () => {
        await axios.post("https://servermain.sisrevolution.com/api/class/getclassdata/",
            {

              status:"Active",
              schoolID:admin.schoolID

            }).then(res => {

                if (res.status === 200) {

                    dispatch(getdata_({

                        data: res.data

                    }))


                }

            })

    }

 

    const gettingdata = async () => {
      setopenloader(true)
      try{
        await axios.post("https://servermain.sisrevolution.com/api/students/getstudentdata/",
            {
              status:"Active",
              schoolID:admin.schoolID


            }).then(res => {

                if (res.status === 200) {

                    dispatch(getdata({

                        data: res.data

                    }))

                    setopenloader(!true)

                }else{
                  setopenloader(!true)

                }

            })

          }catch(err){
            setopenloader(!true)
            handleClicksnack()
            setmessage("Network Error")
            setstated("error")

          }
          }
 

    
  const announcementdata = async () => {
    await axios.post("https://servermain.sisrevolution.com/api/announcement/getannouncement/",
        {
            schoolID:admin.schoolID

        }).then(res => {

            if (res.status === 200) {

                dispatch(announcement_({

                  announcement: res.data.filter(bug=>bug.to===undefined || bug.to==="All")

                }))


            }

        })

}



    React.useEffect(()=>{

      // schooldata()
      // announcementdata()
      // gettingdata_()
      // gettingdatat()
      // if(students.length===0){
      // gettingdata()
      // }


    },[preload])
    
    const mainterm = schooldetails_!==undefined && schooldetails_.length!==0 && schooldetails_.terms.length!==0?schooldetails_.terms[schooldetails_.terms.length-1]:"none"


    // const setdated_=(e)=>{
    //   setdated(e.target.value)
    // }

    const fullpayment =()=>{
      var total = 0
      var data =  students.filter(bug=>bug.finance.length!==0? bug.finance[bug.finance.length-1].payment.length!==0 && bug.finance[bug.finance.length-1].payment[bug.finance[bug.finance.length-1].payment.length-1].balance ===0 : "")
    if(data.length !==0){
      for(var i in data){
      total +=  parseFloat( data[i].finance[data[i].finance.length-1].totalfees) + parseFloat( data[i].finance[data[i].finance.length-1].Arrears)
      }
    }
      return total   
    }

    const fullpaymentstud =()=>{
      var data =  students.filter(bug=>bug.finance.length!==0?bug.finance[bug.finance.length-1].payment.length!==0 && bug.finance[bug.finance.length-1].payment[bug.finance[bug.finance.length-1].payment.length-1].balance ===0 && bug.finance[bug.finance.length-1].Arrears ===0:"")
      return data.length    
    }


    const calcoutstanding = () =>{
      var total = 0
      // var data =  students.filter(bug=>bug.finance.length!==0?bug.finance[bug.finance.length-1].payment.length !==0:0 )
    
      for(var i in offlinedata){
        if(offlinedata[i].data.descriptioner !== "School Fees" && offlinedata[i].data.descriptioner !== "Discount"){
           total+= (parseFloat(offlinedata[i].data.amountpaid) )
        }
    }
    
      return total 
  } 
  const totalamountpaid=(input)=>{
    var result = 0
    for(var i in input){
        result+=parseFloat(input[i].amountpaid)

    }
    return result
}

const handleClickOpenimage = (data) => {
    console.log(data)
    setchosen(data)
    setOpenimage(true);
};
   
  const calcdue = () =>{
    var total = 0
    // var data =  students.filter(bug=>bug.finance.length!==0?bug.finance[bug.finance.length-1].payment.length !==0:0 )
  
    for(var i in offlinedata){
      if(offlinedata[i].data.descriptioner === "School Fees"){
         total+= (parseFloat(offlinedata[i].data.amountpaid) )
      }
  }
  
    return total  
} 
    const totalpaid =()=>{
      var total = 0
      // var data =  students.filter(bug=>bug.finance.length!==0?bug.finance[bug.finance.length-1].payment.length !==0:0 )
    
      for(var i in offlinedata){
        // if(students[i].data.descriptioner === "School Fees"){
           total+= (parseFloat(offlinedata[i].data.amountpaid) )
        // }
    }
    
      return total   
    }

    const totalpaidstud =()=>{
      var total = 0
      var data =  students.filter(bug=>bug.finance.length!==0?bug.finance[bug.finance.length-1].payment.length !==0:0 )
      return data.length    
    }


    const fullowing =(students_)=>{
      var total = 0
      var data =  students_.filter(bug=>bug.finance.length!==0?bug.finance[bug.finance.length-1].payment.length!==0 && bug.finance[bug.finance.length-1].payment[bug.finance[bug.finance.length-1].payment.length-1].balance !== 0 :"" )
      var data2 =  students_.filter(bug=>bug.finance.length===0)
    if(data.length !==0){
      for(var i in data){
      total +=  parseFloat( data[i].finance[data[i].finance.length-1].payment[data[i].finance[data[i].finance.length-1].payment.length-1].balance) 
      }
    } 
    if(data2.length !==0){
      for(var i in data2){
      total +=  parseFloat(Class_.filter(bug=>bug.classname===data2[i].class_).length!==0 && Class_.filter(bug=>bug.classname===data2[i].class_)[0].classfees) 
      }
    }
      return total   
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
  


 const owingstudents =(students_)=>{
      var total = 0
      var data =  students_.filter(bug=>bug.finance.length!==0?bug.finance[bug.finance.length-1].payment.length!==0 && bug.finance[bug.finance.length-1].payment[bug.finance[bug.finance.length-1].payment.length-1].balance !== 0 :"" )
      var data2 =  students_.filter(bug=>bug.finance.length===0)
    if(data.length !==0){
      total+=data.length
    } 
    if(data2.length !==0){
      total+=data2.length

    }
      return total   
    }

    const feesdata =()=>{
    var total=[]
      for (var i in classlist){
       total.push(fullowing(students.filter(bug=>bug.class_===classlist[i])))
      }

      return total

    }
 

    const feesdatabynumber =()=>{
    var total=[]
      for (var i in classlist){
       total.push(owingstudents(students.filter(bug=>bug.class_===classlist[i])))
      }

      return total

    }
 

    const fullowingstud =()=>{
      var total = 0
      var data =  students.filter(bug=>bug.finance.length!==0?bug.finance[bug.finance.length-1].payment.length!==0 && bug.finance[bug.finance.length-1].payment[bug.finance[bug.finance.length-1].payment.length-1].balance === bug.finance[bug.finance.length-1].balance:"")
      return data.length    
    }


    const classattendance=(list)=>{
      if(schooldetails_!==undefined){
var data=0
     var useing = students.filter(bug=>bug.class_===list.classname && bug.attendance.length!==0)
    for(var i in useing){
           var li = useing[i].attendance[schooldetails_.terms[schooldetails_.terms.length-1]] !==undefined && useing[i].attendance[schooldetails_.terms[schooldetails_.terms.length-1]]
for(var k in li){
  if(li[k].date===dated_ && li[k].attendance==="present"){
    data+=1
  }

}


    }

return data
  }
    }


    
    const totalattendance=()=>{
var data=0
     var useing = students.filter(bug=> bug.attendance.length!==0)
    for(var i in useing){
      console.log(schooldetails_)
      if(schooldetails_!==undefined){
           var li = useing[i].attendance[schooldetails_.terms && schooldetails_.terms[schooldetails_.terms.length-1]] !==undefined && useing[i].attendance[schooldetails_.terms[schooldetails_.terms.length-1]]
for(var k in li){
  if(li[k].date===dated_ && li[k].attendance==="present"){
    data+=1
  }

}
      }

    }

return data
    }


    
    const dailycharges=(list)=>{
      if(schooldetails_!==undefined){
var data=0
     var useing = students.filter(bug=>bug.class_===list.classname && bug.attendance.length!==0)
    for(var i in useing){
           var li = useing[i].attendance[schooldetails_.terms && schooldetails_.terms[schooldetails_.terms.length-1]] !==undefined && useing[i].attendance[schooldetails_.terms[schooldetails_.terms.length-1]]
for(var k in li){
  if(li[k].date===dated_ && li[k].attendance==="present"){
    data+=parseFloat(useing[i].dailyfee)
  }

}

    }

return data
  }
    }


    
    const totaldailycharges=()=>{
      if(schooldetails_!==undefined){
var data=0
     var useing = students.filter(bug=> bug.attendance.length!==0)
    for(var i in useing){
           var li = useing[i].attendance[schooldetails_.terms[schooldetails_.terms.length-1]] !==undefined && useing[i].attendance[schooldetails_.terms[schooldetails_.terms.length-1]]
for(var k in li){
  if(li[k].date===dated_ && li[k].attendance==="present"){
    data+=parseFloat(useing[i].dailyfee)
  }

}


    }

return data
  }
    }


    

    const partialpayment =()=>{
      var total = 0
      // var data =  students.filter(bug=>bug.finance.length!==0?bug.finance[bug.finance.length-1].payment.length !==0:0 )
    
      for(var i in offlinedata){
        if(offlinedata[i].data.descriptioner === "Discount"){
           total+= (parseFloat(offlinedata[i].data.amountpaid) )
        }
    }
    
      return total    
    }
    
    const partialpaymentstud =()=>{
      var total = 0
      var data =  students.filter(bug=>bug.finance.length!==0? (parseFloat(bug.finance[bug.finance.length-1].balance) !==0) && (parseFloat(bug.finance[bug.finance.length-1].balance) !== (parseFloat(bug.finance[bug.finance.length-1].totalfees) )):"")
      return data.length    
    }


const deleteoffline=(data)=>{

       
  var main = offlinedata.filter(bug=>bug.id!==data.id)
                     
  localStorage.setItem("offline", JSON.stringify(main))

  dispatch(getofflinedata({

      data: main

  }))
  handleClicksnack()
  setmessage("Data deleted successful")
  setstated("success")

}    
const syncoffline=(data,id)=>{
  var put = document.getElementById(id)
  put.innerText="Loading..."
    if(navigator.onLine===true){ 
    
            const doer = async () => {
                await axios.post(data.link,
                    data.data).then(res => {
    
                        if (res.status === 200) {
    
                          put.innerText="Sync All"

                          var main = offlinedata.filter(bug=>bug.id!==data.id)
                     
                          localStorage.setItem("offline", JSON.stringify(main))
  
                          dispatch(getofflinedata({
                  
                              data: main
                  
                          }))
                          handleClicksnack()
                          setmessage("Data syncing successful")
                          setstated("success")
                            
                        } else {
                       
    
                        }
    
                    })
    
            }
    
            doer()
    
        
    }
    else{
              put.innerText="Sync All"

                        handleClicksnack()
                        setmessage("Please connetct to the internet ant try again")
                        setstated("error") 
    
    }
    
    

}


const syncall=()=>{
  var put = document.getElementById("syncall")
  put.innerText="Loading..."
    if(navigator.onLine===true){ 
    for(var i in offlinedata){

        const doer = async () => {
            await axios.post(offlinedata[i].link,
                offlinedata[i].data).then(res => {

                    if (res.status === 200) {

                        var main = offlinedata.filter(bug=>bug.id!==offlinedata[i].id)
                     
                        localStorage.setItem("offline", JSON.stringify(main))

                        dispatch(getofflinedata({
                
                            data: main
                
                        }))
                        handleClicksnack()
                        setmessage("Data syncing successful")
                        setstated("success")
                        put.innerText="Sync All"
                    } else {
                      
                      handleClicksnack()
        setmessage("error syncing data")
        setstated("error")
        put.innerText="Sync All"

                    }

                })

        }

        doer()

    }
}
else{
  put.innerText="Sync All"

                    handleClicksnack()
                    setmessage("Please connetct to the internet ant try again")
                    setstated("error") 

}


}

const syncall2=()=>{
  
    if(navigator.onLine===true){ 
    for(var i in offlinedata){

        const doer = async () => {
            await axios.post(offlinedata[i].link,
                offlinedata[i].data).then(res => {

                    if (res.status === 200) {

                        var main = offlinedata.filter(bug=>bug.id!==offlinedata[i].id)
                     
                        localStorage.setItem("offline", JSON.stringify(main))

                        dispatch(getofflinedata({
                
                            data: main
                
                        }))
                        handleClicksnack()
                        setmessage("Data syncing successful")
                        setstated("success")

                      } else {
                      
                      handleClicksnack()
        setmessage("error syncing data")
        setstated("error")

                    }

                })

        }

        doer()

    }
}
else{
                    handleClicksnack()
                    setmessage("Please connetct to the internet ant try again")
                    setstated("error") 

}


}

if(offlinedata.length!==0){
  if(navigator.onLine===true){
  syncall2()
  }
}

    return ( 
    <Box sx={{ bgcolor: 'background.paper', width: "100%" }}>
    {/* <PrintButton/> */}
   
    <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    { message }
                </Alert>
            </Snackbar>
    <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={openloader}
//   onClick={handleClose}
>
  <CircularProgress color="inherit" />
</Backdrop>

    <Dialog fullWidth maxWidth="sm" onClose={handleCloseview} aria-labelledby="customized-dialog-title" open={openview}>
            <DialogTitle id="customized-dialog-title" >
            <span style={{fontSize:20, marginLeft:30, color:"grey"}}>Announcement views</span>
                    <IconButton aria-label="" onClick={handleCloseview} style={{float:"right"}} >
                      <Close/>
                    </IconButton>
            </DialogTitle>
            <DialogContent>

                 {chosen.map((list,index)=>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt={list.name} src={"https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/"+list.image} />
        </ListItemAvatar>
        <ListItemText
          primary={list.name}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {months[parseInt((list.date.split("-"))[1])-1] +" "+(list.date.split("-"))[2]+", "+(list.date.split("-"))[0] }

              </Typography>
              {" - "+list.time}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
   
    </List>
                 )
}

                </DialogContent>
                <DialogActions style={{textAlign:"center"}}>


                </DialogActions>
            </Dialog>
            


         <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
         <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
            OFFLINE DATA
            </Typography>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Offline Fees Paid" {...a11yProps(0)} />
          {/* <Tab label="Offline Students Added" {...a11yProps(1)} /> */}
          {/* {(admin.positiondescription==="Accountant" || admin.smsID!==undefined) && <Tab label="Finance" {...a11yProps(2)} />} */}
        </Tabs>
      </Box>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction} >
          

        <div style={{height:"900px"}}>
       <Grid container spacing={3}>
          <Grid item xs={3}>
            <div style={{ borderRadius:"10px", height:"150px",backgroundColor:"#f0a499", border:"solid", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{color:"white"}}> Total Paid As School Fees </p>
            <h1 align="center"  style={{color:"white", fontSize:"32px",  margin:"-13px"}}><span style={{fontSize:20}}>GHC </span> {Intl.NumberFormat('en-US').format(parseFloat(calcdue()).toFixed(2))}</h1>
            <p style={{color:"white"}}> Offline</p>
            </div>
          </Grid>

   <Grid item xs={3}>
              <div style={{ borderRadius:"10px", height:"150px", border:"solid",backgroundColor:"#7b8181", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{ color:"white"}}> Total Paid As Discount </p>
            <h1 align="center" style={{color:"white", fontSize:"32px", margin:"-13px"}}><span style={{fontSize:20}}>GHC </span>{Intl.NumberFormat('en-US').format(parseFloat(partialpayment()).toFixed(2))}</h1>
            <p style={{color:"white"}}> Offline</p>
            </div>
          </Grid>

   <Grid item xs={3}>
              <div style={{ borderRadius:"10px", height:"150px", border:"solid",backgroundColor:"#866c86", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{ color:"white"}}> Total Paid As Other Charges</p>
            <h1 align="center" style={{color:"white", fontSize:"32px", margin:"-13px"}}><span style={{fontSize:20}}>GHC </span> {Intl.NumberFormat('en-US').format(parseFloat(calcoutstanding()).toFixed(2))}</h1>
            <p style={{color:"white"}}> Offline</p>
            </div>
          </Grid>

   <Grid item xs={3}>
              <div style={{ borderRadius:"10px", height:"150px", border:"solid",backgroundColor:"#89a7af", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{ color:"white"}}> Total Paid Offline </p>
            <h1 align="center" style={{color:"white", fontSize:"32px",margin:"-13px"}}><span style={{fontSize:20}}>GHC </span> {Intl.NumberFormat('en-US').format((parseFloat(totalpaid()).toFixed(2)))}</h1>
            <p style={{color:"white"}}>Offline</p>
            </div>
          </Grid>



        </Grid>
    
        <br/>
       
       



        <Paper sx={{ width: '100%', mb: 2 }}>



{/* { admin.positiondescription!=="Administrator and Teller" &&

<Grid container style={{margin:0, padding:0, textAlign:"left", marginLeft:20}}>

<Grid lg={3} xs={2} md={2} >
<p><b>Class Fees</b>: {sorter!=="none" && sorter!=="All"? "GHC "+ Intl.NumberFormat('en-US').format(Class_.filter(bug=>bug.classname===sorter)[0].classfees):"Sort by Class"}</p>
</Grid>
<Grid lg={3} xs={2} md={2}>
<p><b>Total Fees Due</b>: GHC {Intl.NumberFormat('en-US').format(calcdue())}</p>
</Grid>
<Grid lg={3} xs={2} md={2}>
<p><b>Total Fees Paid </b>: GHC {Intl.NumberFormat('en-US').format(calcdue()-calcoutstanding())}</p>
</Grid>
<Grid lg={3} xs={2} md={2}>
<p><b>Outstanding</b>: GHC {Intl.NumberFormat('en-US').format(calcoutstanding())}</p>
</Grid>
<Grid lg={3} xs={2} md={2} style={{paddingBottom:5}}>

</Grid>

</Grid>

        } */}

<div style={{textAlign:"center", padding:"10px", marginBottom:"5px"}}><Button variant="contained" id="syncall" onClick={()=>syncall()} startIcon={<CloudSyncIcon/>} size="medium"> Sync All </Button></div>
<Divider />

{/* <Grid container spacing={2}>
    <Grid item lg={4} xs={2} md={6}>

        {debtors===false?
        <>
        <Tooltip title="Add Class Fees">
        <Button
            color="inherit"
            aria-label="open drawer"
                edge="start"
                
                style={{marginTop:10.5, backgroundColor:" #e0eff9" , color:"#1976d2"}}
            onClick={handleClickOpenadd}

        >
                Class Fees
        </Button>

        
    </Tooltip> 
    
    {
        billloading?
        <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
        style={{marginTop:10.5}}
      >
        Sending Bill...
      </LoadingButton>:
    <Tooltip title="Send Next Term Bills">
       
        <Button
            color="inherit"
            aria-label="open drawer"
                edge="start"
                
                style={{marginTop:10.5, backgroundColor:" #e0eff9" , color:"#1976d2", marginLeft:"15px"}}
            onClick={()=>bulkbill()}

        >
               Send Bill
        </Button>

        
    </Tooltip>
}
    </>
    :

<div>
{smsloading ===true?
               <LoadingButton
loading
loadingPosition="start"
startIcon={<SaveIcon />}
variant="outlined"
style={{marginTop:10.5}}
>
Sending bulk SMS...
</LoadingButton>:
<>
    <Tooltip title="Send notification to debtors">
    <Button
        color="inherit"
        aria-label="open drawer"
            edge="start"
            
            style={{marginTop:10.5,backgroundColor:" #e0eff9" , marginLeft:"-30px" , color:"#1976d2"}}
        onClick={debtorsnotification}

    >
            Send Debtors' Notification
    </Button>
    </Tooltip> 
    
    <Tooltip title="Print Debtors list">
    <Button
        color="inherit"
        aria-label="open drawer"
            edge="start"
            
            style={{marginTop:10.5,marginLeft:"5px", backgroundColor:" #e0eff9" , color:"#1976d2"}}
        onClick={()=>printdebtors()}

    >
            Print
    </Button>
    </Tooltip>
    </>
}


</div>
    }
 
    </Grid>

    <Grid item lg={1} xs={2} md={6} style={{textAlign:"center"}}>
        <FormControl style={{ marginRight: 2,marginLeft:"-30px", minWidth: 80, padding: 0, marginTop: 10, borderColor: "#2196f3" }}>
            <Select
                id="Select"
                value={sorter}
                onChange={sorting}
                autoWidth
                varient="primary"
                style={{ outlined:"none", height: "38px", width: "100%", padding: 0,backgroundColor:" #e0eff9" , color:"#1976d2" }}
            >
<Container>
               <p align="center" style={{margin:5}} ><b>Student Type</b></p>
               <Divider/>
               <FormGroup style={{marginTop:10, marginBottom:10}}>
                    <FormControlLabel control={<Switch checked={activestudents} onChange={(e)=>changestudenttype(e,"Active")} />} label="Active" />
                    <FormControlLabel control={<Switch checked={completestudents} onChange={(e)=>changestudenttype(e,"Completed")}/>} label="Completed" />
                    <FormControlLabel control={<Switch checked={withdrawnstudents}  onChange={(e)=>changestudenttype(e,"Withdrawn")}/>} label="Withdrawn" />
                    </FormGroup>
                 
                  

               </Container>
              
               <Container>
               <p align="center" style={{margin:5}} ><b>Fees Payment Type</b></p>
               <Divider/>
               <FormGroup style={{marginTop:10, marginBottom:10}}>
                    <FormControlLabel control={<Switch checked={debtors} onChange={(e)=>changefeetype(e,"debtors")} />} label="Debtors" />
                    <FormControlLabel control={<Switch checked={complete} onChange={(e)=>changefeetype(e,"complete")}/>} label="Complete Payment" />
                    </FormGroup>
                 
                    <p align="center" style={{margin:5}} ><b>Sort by class</b></p>
                    <Divider/>
               </Container>

                <MenuItem value="none"><em>Sort By</em></MenuItem>
                <MenuItem value="All">All</MenuItem>
                {Class_.length!==0?Class_.map((list, index)=>
                <MenuItem value={list.classname}>{list.classname}</MenuItem>
                ):""}


            </Select>
        </FormControl>
    </Grid>

    <Grid item lg={2} xs={10} md={6}>
        <Search id="searchbox" style={{ position: "absolute", marginTop: "18px", marginLeft: "8px", color:"grey" }} /> <input type="search" onChange={(e) => searching(e)} placeholder="Search" style={{ padding: 10, paddingLeft: "38px", marginTop: 10, width: "120%", border: "solid", borderRadius: "5px", borderWidth: "0.5px", backgroundColor: "rgba(65,105,225, 0.05) ", borderColor: "#2196f3" }} />
    </Grid>

    <Grid item lg={5} sm={12} md={12}>
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </Grid>

</Grid> */}

<Divider />
<TableContainer>
    <Table

        sx={{ minWidth: "100%" }}
        aria-labelledby="tableTitle"
        size={dense ? 'small' : 'medium'}
    >

        <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
        />

        <TableBody>
      
            {stableSort(offlinedata, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                        <TableRow
                            hover

                        >
                            <TableCell style={{ textAlign: "center" }}>
                                <span>
                                    {(index + 1 + (page * rowsPerPage)) + "."}
                                </span>
                            </TableCell>
                            <TableCell align="left">{row.data.ID}</TableCell>
                            <TableCell align="left">{row.data.lastname+" "+row.data.othername}</TableCell>
                            <TableCell align="left">{row.date!==undefined && (months[parseInt((row.date.split("-"))[1])-1] +" "+(row.date.split("-"))[2]+", "+(row.date.split("-"))[0] )}</TableCell>
                            {/* <TableCell align="left">{row[0].data.date}</TableCell> */}
                            <TableCell align="left">{"GHC "+row.data.amountpaid}</TableCell>
                            <TableCell align="left">{row.data.descriptioner}</TableCell>
                            <TableCell align="left"><div style={{textAlign:"center"}}><Button id={"sync"+index} onClick={()=>syncoffline(row,"sync"+index)} variant="contained" startIcon={<CloudSyncIcon/>} size="small"> Sync </Button></div></TableCell>
                            <TableCell align="left"><div style={{textAlign:"center"}}><Button onClick={()=>deleteoffline(row)} variant="contained" startIcon={<DeleteIcon/>} size="small" color='error'> Delete </Button></div></TableCell>

                            
                            {/* <TableCell align="left">{row.status==="Active" ? <span style={{padding:8, borderRadius:"20px", backgroundColor:"#13A518", color:"white"}}>{row.status}</span>: row.status==="Completed"?  <span style={{padding:8, borderRadius:"20px", backgroundColor:"blue", color:"white"}}>{row.status}</span> : <span style={{padding:8, borderRadius:"20px", backgroundColor:"red", color:"white"}}>{row.status}</span>}</TableCell>

                            <TableCell align="left">{row.lastname+" "+row.othernames}</TableCell>
                            <TableCell align="left">{row.class_}</TableCell>
                       
                            <TableCell align="left">{Class_.length!==0 && row.finance.length !== 0 ?  row.finance[row.finance.length-1].Arrears !==null && row.finance[row.finance.length-1].term === mainterm ? Intl.NumberFormat('en-US').format((parseFloat(totalamountpaid(row.finance[row.finance.length-1].payment)) + parseFloat(row.finance[row.finance.length-1].balance)).toFixed(2)): Intl.NumberFormat('en-US').format((parseFloat(Class_.filter(bug=>bug.classname===row.class_)[0].classfees)+parseFloat(row.finance[row.finance.length-1].Arrears)+parseFloat(row.finance[row.finance.length-1].balance)).toFixed(2)) : Class_.length!==0 && Intl.NumberFormat('en-US').format(parseFloat(Class_.filter(bug=>bug.classname===row.class_).length!==0 && row !==null && row.length!==0 && (parseFloat(Class_.filter(bug=>bug.classname===row.class_)[0].classfees)).toFixed(2)))}</TableCell>
                            <TableCell align="left">{row.finance.length !== 0 && row.finance[row.finance.length-1].term === mainterm ? "GHC "+ Intl.NumberFormat('en-US').format(totalamountpaid(row.finance[row.finance.length-1].payment)) : <span style={{padding:"6px", borderRadius:"20px", backgroundColor:"orange"}}>No data</span>}</TableCell>
                            <TableCell align="left">{row.finance.length !== 0 && row.finance[row.finance.length-1].term === mainterm ? "GHC "+ Intl.NumberFormat('en-US').format(parseFloat(row.finance[row.finance.length-1].balance).toFixed(2)) : row.finance.length !== 0 ?  row.finance[row.finance.length-1].Arrears !==null ? "GHC "+ Intl.NumberFormat('en-US').format((parseFloat(Class_.filter(bug=>bug.classname===row.class_)[0].classfees) + parseFloat(row.finance[row.finance.length-1].balance)+ parseFloat(row.finance[row.finance.length-1].Arrears)).toFixed(2)):"GHC "+ Intl.NumberFormat('en-US').format(parseFloat(Class_.filter(bug=>bug.classname===row.class_)[0].classfees).toFixed(2)) :"GHC "+ Intl.NumberFormat('en-US').format(parseFloat(Class_.filter(bug=>bug.classname===row.class_).length!==0 && (Class_.filter(bug=>bug.classname===row.class_)[0].classfees)).toFixed(2))}</TableCell>
                          */}
                            {/* <TableCell align="left">{<Menustyle data={row} view={(sender) => handleClickOpenview(sender)} admin={admin}  viewbill={(sender) => handleClickOpenviewbill(sender)} viewcharges={(sender) => handleClickOpenattendance(sender)}  academics={(sender) => handleClickOpensupply(sender)} message={(sender) => handleClickOpenmessage(sender)} editfees={(sender) => handleClickOpenfees(sender)} Arrears={(sender) => handleClickOpenmessagearrears(sender)}  discount={(sender) => handleClickOpenmessagediscount(sender)}  othercharge={(sender) => handleClickOpencharge(sender)}  dailyfee={(sender) => handleClickOpendailyfee(sender)}  weeklyfee={(sender) => handleClickOpenweeklyfee(sender)} monthlyfee={(sender) => handleClickOpenmonthlyfee(sender)}       addsupply={(sender) => handleClickOpensupply(sender)} edit={(sender) => handleClickOpenedit(sender)} delete={(sender) => handleClickOpendelete(sender)}  />}</TableCell> */}

                        </TableRow>
                    );
                })}
            {emptyRows > 0 && (
                <TableRow
                    style={{
                        height: (dense ? 33 : 53) * emptyRows,
                    }}
                >
                    <TableCell colSpan={6} />
                </TableRow>
            )}
        </TableBody>


    </Table>
</TableContainer>

            {/* {exportdata.length===0 && rows.length!==0 && setexportdata(exporter)} */}

</Paper>
<FormControlLabel
control={<Switch checked={dense} onChange={handleChangeDense} />}
label="Dense padding"
/>



      
</div>




   
        </TabPanel>




 <TabPanel value={value} index={1} dir={theme.direction} >
          

         <Grid container spacing={3}>
          <Grid item xs={4}>
            <div style={{ borderRadius:"10px", height:"150px", border:"solid", backgroundColor:"#e9543c", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{marginBottom:"-14px", color:"white"}}> Total Number Of staff</p>
            <h1 align="center" style={{color:"white", fontSize:"45px"}}>{teachers.length}</h1>
            
            </div>
          </Grid>

   <Grid item xs={4}>
            <div style={{ borderRadius:"10px", height:"150px", border:"solid",backgroundColor:"#27948f", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{marginBottom:"-14px", color:"white"}}> Number Of Teaching Staff</p>
            <h1 align="center" style={{color:"white", fontSize:"45px"}}>{teachers.filter(bug=>bug.position==="Teaching Staff").length}</h1>
            
            </div>
          </Grid>

   <Grid item xs={4}>
            <div style={{ borderRadius:"10px", height:"150px", border:"solid",backgroundColor:"#89358a", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{marginBottom:"-14px", color:"white"}}> Number Of nonteaching Staff</p>
            <h1 align="center" style={{color:"white", fontSize:"45px"}}>{teachers.filter(bug=>bug.position==="Non-Teaching Staff").length}</h1>
            
            </div>
          </Grid>



        </Grid>

       
      

        <br/>
        <Grid container spacing={3}>

      <Grid item xs={4}>

 <div style={{padding:10, borderRadius:"10px", height:"300px", border:"solid", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
           <p align="center"><b>Number of Non-Teaching staff against Teacing staff</b></p>

            <Activestaff  active={teachers.filter(bug=>bug.position==="Teaching Staff").length}  inactive={teachers.filter(bug=>bug.position !=="Teaching Staff").length} />
            </div>

      </Grid>



      <Grid item xs={4}>

 <div style={{padding:10, borderRadius:"10px", height:"300px", border:"solid", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
             <p align="center"><b>Number of Male staff against Female staff</b></p>
              <Demography male={teachers.filter(bug=>bug.gender==="Male").length}  female={teachers.filter(bug=>bug.gender ==="Female").length}/>
            </div>

      </Grid>


      
      <Grid item xs={4}>

 <div  style={{ borderRadius:"10px", height:"300px", border:"solid", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{ color:"gray", width:"100%", zIndex:9, margin:5}}> Notice board</p>
            <Divider/>

            <div>

{/* <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Oui Oui"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Sandra Adams
              </Typography>
              {' — Do you have Paris recommendations? Have you ever…'}
              <br/>
              
            <p  style={{marginTop:10, marginBottom:"-8px"}}><small >1st June, 2022</small></p>
            </React.Fragment>
          }
        />
      </ListItem> */}
<div className='scroller3' style={{height:"260px"}}>

{announcement.map((list, index)=>

<div align="left" style={{margin:8}}>
    <div style={{border:"solid", borderWidth:"0.1px", borderColor:"lightgrey", borderRadius:"10px", padding:5, marginTop:8}}>
        <p style={{margin:3}}>{capitalize(list.subject)} <br/> <small>{months[parseInt((list.date.split("-"))[1])-1] +" "+(list.date.split("-"))[2]+", "+(list.date.split("-"))[0]}</small></p>
        <p style={{color:"grey", fontSize:"13px", textOverflow:'inherit'}}> {list.message} </p>
    <Grid container>


                            <Grid item xs={12}> 
                            <AvatarGroup max={2}>
                                {list.views.length!==0?
                                list.views.map((lister,idexer)=>
                                  
      <Avatar alt="Remy Sharp" src={"https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/"+list.views[0].image} onClick={()=>handleClickOpenview(list.views)} />

     ):<p align="right">No Viewer    </p>
                                }
                                </AvatarGroup>
                             </Grid>

    </Grid>
    </div>
</div>

)
}
</div>



       

            </div>
          
            </div>

      </Grid>

        </Grid>
      


        </TabPanel>



 <TabPanel value={value} index={2} dir={theme.direction} >
          

<Grid container spacing={3}>
          <Grid item xs={3}>
            <div style={{ borderRadius:"10px", height:"150px", border:"solid", background: "#3A27CE",  borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
           <div style={{textAlign:"right"}}><IconButton style={{marginTop:7, marginRight:15, marginBottom:"-5px", backgroundColor:"rgba(0,0,0,0.1)"}}><SchoolIcon  style={{color:"white", fontSize:"25px"}} /></IconButton></div> 
            <h1 align="center" style={{color:"white",fontSize:"47px", marginTop:"-6px", marginBottom:8}}>{Intl.NumberFormat('en-US').format(students.length)}</h1>
                        <small align="center" style={{ color:"white", marginBottom:4, marginTop:"-12px"}}> Total Number Of students</small>

            
            </div>
          </Grid>

   <Grid item xs={3}>
            <div style={{ borderRadius:"10px", height:"150px", border:"solid",borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px",/* Created with https://www.css-gradient.com */
/* Created with https://www.css-gradient.com */
background: "#D10F3D",}}> 
                       <div style={{textAlign:"right"}}><IconButton style={{marginTop:7, marginRight:15, marginBottom:"-5px", backgroundColor:"rgba(0,0,0,0.1)"}}><MaleIcon  style={{color:"white", fontSize:"25px"}} /></IconButton></div> 

            <h1 align="center" style={{color:"white",fontSize:"47px", marginTop:"-6px", marginBottom:8}}>{Intl.NumberFormat('en-US').format(students.filter(bug=>bug.gender==="Male").length)}</h1>
            <small align="center"  style={{ color:"white", marginBottom:4, marginTop:"-12px"}}> Number Of Male Students</small>

            </div>
          </Grid>

   <Grid item xs={3}>
            <div style={{ borderRadius:"10px", height:"150px", border:"solid", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px",/* Created with https://www.css-gradient.com */
/* Created with https://www.css-gradient.com */
background: "#13A518",}}> 
 <div style={{textAlign:"right"}}><IconButton style={{marginTop:7, marginRight:15, marginBottom:"-5px", backgroundColor:"rgba(0,0,0,0.1)"}}><FemaleIcon  style={{color:"white", fontSize:"25px"}} /></IconButton></div> 

            <h1 align="center" style={{color:"white",fontSize:"47px", marginTop:"-6px", marginBottom:8}}>{Intl.NumberFormat('en-US').format(students.filter(bug=>bug.gender==="Female").length)}</h1>
            <small align="center"  style={{ color:"white", marginBottom:4, marginTop:"-12px"}}> Number Of Female students</small>

            
            </div>
          </Grid>

   <Grid item xs={3}>
            <div style={{ borderRadius:"10px", height:"150px", border:"solid", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px", background: "#3A27CE",
/* Created with https://www.css-gradient.com */
background: "orange"}}> 
 <div style={{textAlign:"right"}}><IconButton style={{marginTop:7, marginRight:15, marginBottom:"-5px", backgroundColor:"rgba(0,0,0,0.1)"}}><ClassIcon  style={{color:"white", fontSize:"25px"}} /></IconButton></div> 

            <h1 align="center" style={{color:"white",fontSize:"47px", marginTop:"-6px", marginBottom:8}}>{Intl.NumberFormat('en-US').format(Class_.length)}</h1>
            <small align="center"  style={{ color:"white", marginBottom:4, marginTop:"-12px"}}>  Number Of Classes</small>
            
            </div>
          </Grid>


        </Grid>

        <br/>
        <Grid container spacing={3}>

      <Grid item xs={9}>

 <div style={{padding:10, borderRadius:"10px", height:"420px", border:"solid", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p><b>A Graphical Representation of the Number of Students in Each Class</b></p>

            <Studentchart1 data={classdata} categories={classlist}/>
           
            </div>

      </Grid>

      <Grid item xs={3}>

 <div style={{ borderRadius:"10px", height:"420px", border:"solid", backgroundColor:"#e0eff9", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <div style={{padding:"10px", textAlign:"center"}} align="left">
            <b style={{color:"#1976d2"}}>Class Attendance</b>
           
                       <input type="date"  value={dated_} style={{fontSize:"16px", color:"#1976d2" , backgroundColor:"#e0eff9", border:"solid", borderWidth:"0.1px", borderColor:"lightgray",  padding:5, margin:5, borderRadius:5}} onChange={(e)=>setdated__(e.target.value)} />
                         
            </div>

            <Divider/>

            <h1 align="center" style={{margin:"1px", color:"#1976d2"}}>{ Intl.NumberFormat('en-US').format(totalattendance()) }</h1>
            <p style={{marginTop:"-22px", fontSize:"12px", color:"#1976d2", margin:"1px", }}>{months[parseInt((dated_.split("-"))[1])-1] +" "+(dated_.split("-"))[2]+", "+(dated_.split("-"))[0] }</p>
<Divider/>
<div style={{paddingLeft:5,paddingRight:5}}>
<div className='scroller3' style={{height:"255px"}}>
       <List sx={{ width: '100%', maxWidth: 360,  margin:0, padding:1 }}>
     {Class_.map((list,index)=>
        <div>
        <ListItem
        sx={{padding:0}}
          disableGutters
          secondaryAction={
          //  <b>{students.filter(bug=>bug.class_===list.classname && bug.attendance.length!==0?bug.attendance[schooldetails_.terms[schooldetails_.terms.length-1]] !==undefined && bug.attendance[schooldetails_.terms[schooldetails_.terms.length-1]][bug.attendance[schooldetails_.terms[schooldetails_.terms.length-1]].length-1].attendance==="present":0 ).length}</b>
           <b>{Intl.NumberFormat('en-US').format(classattendance(list))}</b>
          }
        >
          <ListItemText primary={list.classname} secondary={<small> {}  {dated_ && months[parseInt((dated_.split("-"))[1])-1] +" "+(dated_.split("-"))[2]+", "+(dated_.split("-"))[0] }</small>} />
        </ListItem>
        <Divider/>
        </div>
     )}
    </List>

    </div>
</div>
        
            </div>

             {/* <Studentchart2/> */}

      </Grid>

        </Grid>
      
        <br/>

        </TabPanel>






        
        <TabPanel value={value} index={9} dir={theme.direction}>
          


           
              

        <Grid container spacing={3}>
          <Grid item xs={3}>
            <div style={{ borderRadius:"10px", height:"150px",backgroundColor:"#e9543c", border:"solid", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{color:"white"}}> Full Payment </p>
            <h1 align="center"  style={{color:"white", fontSize:"32px",  margin:"-13px"}}>GHC {Intl.NumberFormat('en-US').format(fullpayment())}</h1>
            <p style={{color:"white"}}> {fullpaymentstud()} student(s)</p>
            </div>
          </Grid>

   <Grid item xs={3}>
              <div style={{ borderRadius:"10px", height:"150px", border:"solid",backgroundColor:"#27948f", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{ color:"white"}}> Partial Payment </p>
            <h1 align="center" style={{color:"white", fontSize:"32px", margin:"-13px"}}>GHC {Intl.NumberFormat('en-US').format(partialpayment())}</h1>
            <p style={{color:"white"}}> {partialpaymentstud()} student(s)</p>
            </div>
          </Grid>

   <Grid item xs={3}>
              <div style={{ borderRadius:"10px", height:"150px", border:"solid",backgroundColor:"#89358a", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{ color:"white"}}> Outstanding Dept </p>
            <h1 align="center" style={{color:"white", fontSize:"32px", margin:"-13px"}}>GHC {Intl.NumberFormat('en-US').format(fullowing(students))}</h1>
            <p style={{color:"white"}}> {fullowingstud()} student(s)</p>
            </div>
          </Grid>

   <Grid item xs={3}>
              <div style={{ borderRadius:"10px", height:"150px", border:"solid",backgroundColor:"#31a0be", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{ color:"white"}}> Total Paid </p>
            <h1 align="center" style={{color:"white", fontSize:"32px",margin:"-13px"}}>GHC {Intl.NumberFormat('en-US').format(totalpaid())}</h1>
            <p style={{color:"white"}}>{totalpaidstud()} student(s)</p>
            </div>
          </Grid>



        </Grid>
       
        <br/>
        <Grid container spacing={3}>

      <Grid item xs={7}>

 <div style={{ borderRadius:"10px", height:"290px", border:"solid", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{marginBottom:"-8px", color:"gray"}}> class and number students owing</p>
           <Studentchart3   classes={classlist}  data={feesdata()}/>
            
            </div>
<br/>
            <div style={{ borderRadius:"10px", height:"290px", border:"solid", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{marginBottom:"-8px", color:"gray"}}> class and number o students paid</p>
            <Studentchart3   classes={classlist}  data={feesdata()}/>
            
            </div>

      </Grid>

      <Grid item xs={5}>

 <div style={{ borderRadius:"10px", height:"97%", border:"solid", borderColor:"lightgray", borderWidth:"0.01px", width:"100%", margin:"5px"}}> 
            <p align="center" style={{marginBottom:"-8px", color:"gray"}}> pie chart of owing against paid</p>
            <h1 align="center">100</h1>
            <p>total due</p>
            <p>total paid</p>
            <p>outstanding</p>
            </div>

      </Grid>

        </Grid>
      
        </TabPanel>
      
      </SwipeableViews>
      
    </Box>
    );
}

export default App;