import { createSlice } from "@reduxjs/toolkit"
let data = []

const Insider = createSlice({

    name: "todos",
    initialState: data,
    reducers: {
        schooldetails: (state, action) => {
            console.log(action.payload.data)
            state = action.payload.data
            return state
        }

    },

})

export const { schooldetails } = Insider.actions;
export default Insider.reducer;