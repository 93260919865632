import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Printpage from './printbutton';
import Excelformart from './excelformart.xlsx';
import { Offline, Online } from "react-detect-offline";

import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Avatar from '@mui/material/Avatar';
import SupplyProduct from '@mui/icons-material/LocalShipping';
import AddSRV from '@mui/icons-material/PostAdd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Search from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import Logout from '@mui/icons-material/Logout';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Show from './tableshow';
import Imagereplace from './images/Ellipse77.png';
import FormLabel from '@mui/material/FormLabel';
import Tablereport from './tablereport';
import ReactToPrint from 'react-to-print';
import * as Xlsx from 'xlsx';


import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MessageIcon from '@mui/icons-material/Message';
import SendIcon from '@mui/icons-material/Send';
import EmailIcon from '@mui/icons-material/Email';
import Attendance from './date';
import FormGroup from '@mui/material/FormGroup';

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import {DropzoneArea} from 'material-ui-dropzone'

import TextField from '@mui/material/TextField';
import Menustyle from './menuschools';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import CircularProgress from '@mui/material/CircularProgress';
import ImageIcon from '@mui/icons-material/Image';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import Container from '@mui/material/Container';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import axios from 'axios';

import Badge from '@mui/material/Badge';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Person from './images/Ellipse77.png';

import Snack from './snack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { BrowserRouter as Router, Routes, Route, Link, useLocation, BrowserRouter } from "react-router-dom";
import { JsonToExcel } from 'react-json-to-excel';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { login } from './redux/admin';
import { loin, logout, updatestudentdata, getdata } from './redux/students';
import { getdata_ } from './redux/class';
import { schooldetails } from './redux/schooldetails';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function createData(_id,id,lastname,othernames,status,image, class_, gender, dob,hometown,residence, religion, motherlastname, motherothernames, motheroccupation, mothercontact, motheremail, motherresidence, fatherlastname,fatherothernames, fatheroccupation, fathercontact, fatheremail, fatherresidence, guardianlastname,guardianothernames, guardianoccupation, guardiancontact, guardianemail, guardianresidence, guardianrelationship,  academic ,finance, attendance, Awards, behaviour, others, dailycharge, index, specialID, nextclass, housing) 
{
    return {
        _id,
        id,
        lastname,
        othernames,
        status,
        image,
        class_,
        gender,
        dob,
        hometown,
        residence,
        religion,
        motherlastname,
        motherothernames,
        motheroccupation,
        mothercontact,
        motheremail,
        motherresidence,
        fatherlastname,
        fatherothernames,
        fatheroccupation,
        fathercontact,
        fatheremail,
        fatherresidence, 
        guardianlastname,
        guardianothernames,
        guardianoccupation,
        guardiancontact,
        guardianemail,
        guardianresidence,
        guardianrelationship,
        academic,
        finance,
        attendance,
        Awards,
        behaviour,
        others,
        dailycharge,
        index,
        specialID,
        nextclass,
        housing, 
        
    };
}

const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Donut', 452, 25.0, 51, 4.9),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Honeycomb', 408, 3.2, 87, 6.5),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Jelly Bean', 375, 0.0, 94, 0.0),
    createData('KitKat', 518, 26.0, 65, 7.0),
    createData('Lollipop', 392, 0.2, 98, 0.0),
    createData('Marshmallow', 318, 0, 81, 2.0),
    createData('Nougat', 360, 19.0, 9, 37.0),
    createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [

  
  
    {
        id: 'id',
        numeric: false,
        disablePadding: !true,
        label: 'ID',
    },
  
    {
        id: 'name',
        numeric: !true,
        disablePadding: false,
        label: 'Name',
    },
      {
        id: 'status',
        numeric: !true,
        disablePadding: false,
        label: 'Status',
    },
   
    {
        id: 'class',
        numeric: !true,
        disablePadding: false,
        label: 'Class',
    },
    {
        id: 'gender',
        numeric: !true,
        disablePadding: false,
        label: 'Gender',
    }, {
        id: 'dob',
        numeric: !true,
        disablePadding: false,
        label: 'Date of Birth',
    }, {
        id: 'address',
        numeric: !true,
        disablePadding: false,
        label: 'Address',
    },
    {
        id: '',
        numeric: true,
        disablePadding: false,
        label: '',
    }
];


function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell align="center">
                    <b>
                        No.
                    </b>
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <b>{headCell.label}</b>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >

            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                STUDENTS
            </Typography>



        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {

    
    const [exportdata, setexportdata] = React.useState([]);
    var exporter=[]
    const [opensnack, setOpensnack] = React.useState(false);
    const [imageloading, setimageloading] = React.useState(true);
    const [source, setsource] = React.useState("");
    const [pageloader, setpageloader] = React.useState(true);
    const [activestudents, setactivestudents] = React.useState(true);
    const [withdrawnstudents, setwithdrawnstudents] = React.useState(false);
    const [completestudents, setcompletestudents] = React.useState(false);
    const [gettingdatatype, setgettingdatatype] = React.useState("Active");
     
    


    const handleClicksnack = () => {
        setOpensnack(true);
    };

    
    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };

    const dispatch = useDispatch()
    const admin = useSelector((state) => state.admin)


    const stud = useSelector((state) => state.students)
    const schools = admin.class_===undefined? stud:stud.filter(bug=>bug.class_===admin.positiondescription_)
    const classer = useSelector((state) => state.Class_)
    var Class_ = admin.class_===undefined? classer:classer.filter(bug=>bug.classname===admin.positiondescription_)
    // var Class_ = useSelector((state) => state.Class_)

    const schooldetails_ = useSelector((state) => state.schooldetails)

    const schooldata= async () => {
        await axios.post("https://servermain.sisrevolution.com/api/schools/getschooldata/",
            {
                schoolID:admin.schoolID

            }).then(res => {

                if (res.status === 200) {

                    dispatch(schooldetails({

                        data: res.data

                    }))


                }

            })

    }


    const gettingdata_ = async () => {
        await axios.post("https://servermain.sisrevolution.com/api/class/getclassdata/",
            {
                status:gettingdatatype,
                schoolID:admin.schoolID

            }).then(res => {

                if (res.status === 200) {

                    dispatch(getdata_({

                        data: res.data

                    }))


                }

            })

    }


    const gettingdata = async () => {
        await axios.post("https://servermain.sisrevolution.com/api/students/getstudentdata/",
            {
                status:gettingdatatype,
                schoolID:admin.schoolID


            }).then(res => {

                if (res.status === 200) {

                    dispatch(getdata({

                        data: res.data

                    }))


                }

            })

    }
 
  useEffect(()=>{
    schooldata()
    gettingdata()

    gettingdata_()

 
},[gettingdatatype])
    
    var data = [schools.map((list, index) => createData(list._id,list.ID, list.lastname, list.othernames,list.status, list.image, list.class_, list.gender, list.dob, list.hometown, list.residence, list.religion, list.motherlastname, list.motherothernames, list.motheroccupation, list.mothercontact, list.motheremail, list.motherresidence, list.fatherlastname,  list.fatherothernames, list.fatheroccupation, list.fathercontact, list.fatheremail, list.fatherresidence, list.guardianlastname,  list.guardianothernames, list.guardianoccupation, list.guardiancontact, list.guardianemail, list.guardianresidence,list.guardianrelationship,  list.academics, list.finance, list.attendance, list.Awards, list.behaviour, list.others, list.dailyfee, list.studentIndex, list.specialID, list.nextclass, list.housing))]

    const dt = new Date()
    const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
    const day = dt.getUTCDate();
    const month = String(dt.getMonth() + 1).padStart(2, '0');
    const year = dt.getFullYear();
    const date = year + "-" + month + "-" + day;
    var rows = data[0]

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [selected, setSelected] = React.useState([]);
    const [filerjson, setfilerjson] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl_, setAnchorEl_] = React.useState(null);
    const open = Boolean(anchorEl);
    const open_ = Boolean(anchorEl_);
    const [opensupply, setOpensupply] = React.useState(!true);
    const [name, setname] = React.useState(!true);
    const [location, setlocation] = React.useState(!true);
    const [allocation, setallocation] = React.useState(!true);
    const [contact, setcontact] = React.useState(!true);
    const [message, setmessage] = React.useState("");
    const [stated, setstated] = React.useState("");

    const [trialmode, settrialmode] = React.useState(!true);
    const [openadd, setOpenadd] = React.useState(!true);
    const [openaddexcel, setOpenaddexcel] = React.useState(!true);
    const [openview, setOpenview] = React.useState(!true);
    const [openattendance, setOpenattendance] = React.useState(!true);
    const [opensrv, setOpensrv] = React.useState(!true);
    const [openedit, setOpenedit] = React.useState(!true);
    const [opennotify, setOpennotify] = React.useState(!true);
    const [openimage, setOpenimage] = React.useState(!true);
    const [opendelete, setOpendelete] = React.useState(!true);
    const [openstatus, setOpenstatus] = React.useState(!true);
    const [openmessage, setOpenmessage] = React.useState(!true);
    const [age, setAge] = React.useState('');
    const [attendancedata, setattendancedata] = React.useState([]);
    const [guardian, setGuardian] = React.useState('Mother');
    const [admission, setAdmission] = React.useState('new');

    const [guardianedit, setguardianedit] = React.useState('');
    const [sorter, setsorter] = React.useState("none");
    const [chosen, setchosen] = React.useState([]);
    const [filer, setfiler] = React.useState([]);
    const [loading, setloading] = React.useState(false)
    const [housing, sethousing] = React.useState("Day")
    const [search, setsearch] = React.useState("")
    const [selectedclass, setselectedclass] = React.useState("none")
    const [sortclass, setsortclass] = React.useState("none")

    const [selectedclassedit, setselectedclassedit] = React.useState("")
    const [selectedgender, setselectedgender] = React.useState("none")
    const [selectedgenderedit, setselectedgenderedit] = React.useState("")
    const [selectedreligionedit, setselectedreligionedit] = React.useState("")
    const [selectedreligion, setselectedreligion] = React.useState("none")
    const [person, setperson] = React.useState(Person)

    const [loadingguardian, setloadingguardian] = React.useState(false)
    const [loadingemail, setloadingemail] = React.useState(false)
    const [openresults, setOpenresults] = React.useState(false)
    const [reopendate, setreopendate] = React.useState("")
    const [personclass, setpersonclass] = React.useState("")
    const [what, setwhat] = React.useState("back")
    const [replace, setreplace] = React.useState("no")
    const [selectedterm, setselectedterm] = React.useState(schooldetails_.length!==0?schooldetails_[0].terms[schooldetails_[0].terms.length-1]:"none")
    const [selectedpromote, setselectedpromote] = React.useState("none")
    const [whattype, setwhattype] = React.useState("back")
    const [newstudentloading, setnewstudentloading] = React.useState("false")
    const [editstudentloading, seteditstudentloading] = React.useState("false")
    const [deletestudentloading, setdeletestudentloading] = React.useState("false")
    const [smsloading, setsmsloading] = React.useState("false")
    const [status_selector, setstatus_selector] = React.useState("")
    const [emailloading, setemailloading] = React.useState("false")
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const searching = (e) => {
        setsearch(e.target.value)
    }

    if (search !== "") {
        var listed = rows.filter(bug => bug.class_.toLowerCase().includes(search.toLowerCase()) || bug.status.toLowerCase().includes(search.toLowerCase()) || bug.othernames.toLowerCase().includes(search.toLowerCase()) || bug.lastname.toLowerCase().includes(search.toLowerCase()) || bug.id.toLowerCase().includes(search.toLowerCase())|| (bug.specialID !==undefined && bug.specialID.toLowerCase().includes(search.toLowerCase())) )
       rows=listed
    }

    if(sortclass !== "none" && sortclass !== "All"){
        var list = rows.filter(bug => bug.class_ === sortclass)
        rows= list
        // exportdata.length!==0 && rows.length!==0 && setexportdata([])
        exporter=[]
    }

    

    const readUploadFile = (e) => {
        // e.preventDefault();
        if(e.length!==0 && e.target.files[0].name.split(".")[1]==="xlsx"){
           
        if (e.target) {
           
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                
                const workbook = Xlsx.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = Xlsx.utils.sheet_to_json(worksheet);
                console.log(json);
                console.log(json[0]["Guardian Contact"]);
                setfilerjson(json)
            };
            setfiler(e.target.files[0])
            reader.readAsArrayBuffer(e.target.files[0]);
            
        }
    }else{

        handleClicksnack()
        setmessage("Upload Excel Data Only")
        setstated("error")

    }
    }


const uloadsxcel=()=>{

setnewstudentloading(true)

const fd = new FormData()
                  
                    fd.append("files",filer)
                    fd.append("schoolID",admin.schoolID)
                   
                    const uploading = async() => {
                        await axios.post("https://servermain.sisrevolution.com/api/students/importstudents/", fd, {
                        // await axios.post("http://localhost:3001/api/students/importstudents/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }


                        }).then(res => {

                            if (res.status === 200  && res.data.length !== 0) {
                               
                                dispatch(getdata({

                                    data: res.data
            
                                }))
                                handleClicksnack()
                                setmessage("Student Data Imported")
                        setstated("success")
                        setnewstudentloading(!true)

                            }
                            if (res.status === 204) {
                                handleClicksnack()
                        setmessage("Error Importing Student Data")
                        setstated("success")
                        setnewstudentloading(!true)
                            }

                        })

                    }

                    try{

                 uploading()
                }
                catch(err){
                    setmessage("Network Error")
                    setstated("error")
                    setnewstudentloading(!true)
                    handleClicksnack()
                }

}

    //actions

    const grading = (score) => {
     
  if(schooldetails_[0].grades.length===0){

    handleClicksnack()
    setmessage("setup your grades at settings")
    setstated("error")

  }else{


    var value_= schooldetails_[0].grades.filter(bug=>bug.upperlimit>=score && bug.lowerlimit<=score)
   
    if(value_.length===0){
    return "F"
    }else{
        return value_[0].gradepoint
    }
  }

    }



    const remarks = (grade) => {
        if(schooldetails_[0].grades.length===0){

            handleClicksnack()
            setmessage("setup your grades at settings")
            setstated("error")
        
          }else{
        
        var value_= schooldetails_[0].grades.filter(bug=>bug.upperlimit>=grade && bug.lowerlimit<=grade)
        if(value_.length===0){
            return "Fail"
            }else{
                return value_[0].remarks
            }
     
          }
    }
    
    const registerstudent = () => {
                    setnewstudentloading(true)
                    const max = (schooldetails_[0].studentpopulation).split("-")[1]
                    const file = document.getElementById("fileimage").files[0]
                    const image = ""
                    const lastname = document.getElementById("lastname").value
                    const othernames = document.getElementById("othernames").value
                    const specialID = document.getElementById("specialID").value
                    const dob = document.getElementById("dob").value
                    const residence = document.getElementById("residence").value
                    const hometown = document.getElementById("hometown").value
                    const religion = selectedreligion
                    const gender = selectedgender
                    const class_ = selectedclass
                    const motherlastname = document.getElementById("motherlastname").value
                    const motherothernames = document.getElementById("motherothernames").value
                    const motheroccupation = document.getElementById("motheroccupation").value
                    const mothercontact = document.getElementById("mothercontact").value
                    const motheremail = document.getElementById("motheremail").value
                    const motherresidence = document.getElementById("motherresidence").value 
                                
                    const fatherlastname = document.getElementById("fatherlastname").value
                    const fatherothernames = document.getElementById("fatherothernames").value
                    const fatheroccupation = document.getElementById("fatheroccupation").value
                    const fathercontact = document.getElementById("fathercontact").value
                    const fatheremail = document.getElementById("fatheremail").value
                    const fatherresidence = document.getElementById("fatherresidence").value
                    const dailyfee = document.getElementById("dailyfee").value
                    const weeklyfee = document.getElementById("weeklyfee").value
                    const monthlyfee = document.getElementById("monthlyfee").value
                    const index = document.getElementById("index").value
                    
                    var  guardianlastname = ""
                    var  guardianothernames = ""
                    var  guardianoccupation = ""
                    var  guardiancontact = ""
                    var  guardianemail = ""
                    var  guardianresidence = ""
                    var  guardianrelationship = ""
                   
                   

                    var nextclass = class_ !== "none" && class_ !== "" && Class_.filter(bug=>bug.classname === class_)[0].nextclass !== undefined ?Class_.filter(bug=>bug.classname === class_)[0].nextclass:class_
                    if(guardian === "Mother" || guardian === "Parents" ){

                    guardianlastname = document.getElementById("motherlastname").value
                    guardianothernames = document.getElementById("motherothernames").value
                    guardianoccupation = document.getElementById("motheroccupation").value
                    guardiancontact = document.getElementById("mothercontact").value
                    guardianemail = document.getElementById("motheremail").value
                    guardianresidence = document.getElementById("motherresidence").value
                    guardianrelationship = "Mother" 

                    }    

                    if(guardian === "Others" ){

                    guardianlastname = document.getElementById("guardianlastname").value
                    guardianothernames = document.getElementById("guardianothernames").value
                    guardianoccupation = document.getElementById("guardianoccupation").value
                    guardiancontact = document.getElementById("guardiancontact").value
                    guardianemail = document.getElementById("guardianemail").value
                    guardianresidence = document.getElementById("guardianaddress").value
                    guardianrelationship = "Guardian"

                    }
                    
                    if(guardian === "Father" ){

                    guardianlastname = document.getElementById("fatherlastname").value
                    guardianothernames = document.getElementById("fatherothernames").value
                    guardianoccupation = document.getElementById("fatheroccupation").value
                    guardiancontact = document.getElementById("fathercontact").value
                    guardianemail = document.getElementById("fatheremail").value
                    guardianresidence = document.getElementById("fatherresidence").value
                    guardianrelationship = "Father"

                    }
              
                    const fd = new FormData()
                   
                    if (file) {
                   
                        fd.append("file", file)
                        fd.append("image", document.getElementById("fileimage").files[0].name)
                    }
                    
                    fd.append("lastname", lastname)
                    fd.append("schoolID",admin.schoolID)
                    fd.append("smsID",admin.smsID)
                    fd.append("newstudent",admission)
                    fd.append("smsname",admin.smsname)
                    fd.append("schoolname",admin.schoolname)
                    fd.append("othernames", othernames)
                    fd.append("class_", class_)
                    fd.append("nextclass", nextclass)
                    fd.append("gender", gender)
                    fd.append("dob", dob)
                    fd.append("hometown", hometown)
                    fd.append("max", max)
                    fd.append("residence", residence)
                    fd.append("religion", religion)
                    fd.append("motherlastname", motherlastname)
                    fd.append("motherothernames", motherothernames)
                    fd.append("motheroccupation", motheroccupation)
                    fd.append("mothercontact", mothercontact)
                    fd.append("motheremail", motheremail)
                    fd.append("motherresidence", motherresidence)
                    fd.append("fatherresidence", fatherresidence)
                    fd.append("fatherlastname", fatherlastname)
                    fd.append("fatherothernames", fatherothernames)
                    fd.append("fatheroccupation", fatheroccupation)
                    fd.append("fathercontact", fathercontact)
                    fd.append("fatheremail", fatheremail)
                    fd.append("index", index)
                    fd.append("housing", housing)
                    fd.append("guardianresidence", guardianresidence)
                    fd.append("guardianlastname", guardianlastname)
                    fd.append("guardianothernames", guardianothernames)
                    fd.append("guardianoccupation", guardianoccupation)
                    fd.append("guardiancontact", guardiancontact)
                    fd.append("guardianemail", guardianemail)
                    fd.append("spacialID", specialID)
                    fd.append("dailyfee", dailyfee)
                    fd.append("guardianrelationship", guardianrelationship)
                    fd.append("admissiontype", admission)
                    fd.append("status", gettingdatatype)

                    const uploading = async() => {
                        await axios.post("https://servermain.sisrevolution.com/api/students/registerstudent/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }


                        }).then(res => {

                            if (res.status === 200) {

                                dispatch(getdata({

                                    data: res.data
            
                                }))

                               
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("New Student Added")
                        setstated("success")
                                setnewstudentloading(!true)

                            }
                            if (res.status === 208) {
                                handleCloseadd()
                                handleClicksnack()
                                setmessage("Maximum Number Of Active Students Reached")
                                setstated("success")
                                setnewstudentloading(!true)
                            } if (res.status === 204) {
                                handleCloseadd()
                        handleClicksnack()
                        setmessage("Error Adding Student")
                        setstated("success")
                                setnewstudentloading(!true)
                            }

                        })

                    }

try{

    if(class_ === "none" && class_ === ""){
        handleClicksnack()
        setmessage("Please set class")
        setstated("error")
                setnewstudentloading(!true)
        setnewstudentloading(!true)
    }else{
        uploading()
    }
 

  
                    
}catch(err){
    handleClicksnack()
    setmessage("Network Error")
    setstated("error")
            setnewstudentloading(!true)
}



                }

    const editstudent = () => {
                    seteditstudentloading(true)

                    const file = document.getElementById("fileimageedit").files[0]
                    const image = ""
                    const lastname = document.getElementById("lastnameedit").value
                    const othernames = document.getElementById("othernamesedit").value
                    const specialIDedit = document.getElementById("specialIDedit").value
                    const dob = document.getElementById("dobedit").value
                    const residence = document.getElementById("residenceedit").value
                    const hometown = document.getElementById("hometownedit").value
                    const religion = selectedreligionedit
                    const gender = selectedgenderedit
                    const class_ = selectedclassedit
                    const motherlastname = document.getElementById("motherlastnameedit").value
                    const motherothernames = document.getElementById("motherothernamesedit").value
                    const motheroccupation = document.getElementById("motheroccupationedit").value
                    const mothercontact = document.getElementById("mothercontactedit").value
                    const motheremail = document.getElementById("motheremailedit").value
                    const motherresidence = document.getElementById("motherresidenceedit").value 
                                
                    const fatherlastname = document.getElementById("fatherlastnameedit").value
                    const fatherothernames = document.getElementById("fatherothernamesedit").value
                    const fatheroccupation = document.getElementById("fatheroccupationedit").value
                    const fathercontact = document.getElementById("fathercontactedit").value
                    const fatheremail = document.getElementById("fatheremailedit").value
                    const fatherresidence = document.getElementById("fatherresidenceedit").value
                    const dailyfeeedit = document.getElementById("dailyfeeedit").value
                    const index = document.getElementById("indexedit").value
                    var nextclass = Class_.filter(bug=>bug.classname === class_)[0].nextclass !== undefined ?Class_.filter(bug=>bug.classname === class_)[0].nextclass:class_

                    
                    var  guardianlastname = ""
                    var  guardianothernames = ""
                    var  guardianoccupation = ""
                    var  guardiancontact = ""
                    var  guardianemail = ""
                    var  guardianresidence = ""
                    var  guardianrelationship = ""
                    
                    if(guardianedit === "Mother" || guardianedit === "Parents" ){

                    guardianlastname = document.getElementById("motherlastnameedit").value
                    guardianothernames = document.getElementById("motherothernamesedit").value
                    guardianoccupation = document.getElementById("motheroccupationedit").value
                    guardiancontact = document.getElementById("mothercontactedit").value
                    guardianemail = document.getElementById("motheremailedit").value
                    guardianresidence = document.getElementById("motherresidenceedit").value
                    guardianrelationship = "Mother" 

                    }    

                    if(guardianedit === "Others" ){

                    guardianlastname = document.getElementById("guardianlastnameedit").value
                    guardianothernames = document.getElementById("guardianothernamesedit").value
                    guardianoccupation = document.getElementById("guardianoccupationedit").value
                    guardiancontact = document.getElementById("guardiancontactedit").value
                    guardianemail = document.getElementById("guardianemailedit").value
                    guardianresidence = document.getElementById("guardianaddressedit").value
                    guardianrelationship = "Guardian"


                    }
                    
                    if(guardianedit === "Father" ){

                    guardianlastname = document.getElementById("fatherlastnameedit").value
                    guardianothernames = document.getElementById("fatherothernamesedit").value
                    guardianoccupation = document.getElementById("fatheroccupationedit").value
                    guardiancontact = document.getElementById("fathercontactedit").value
                    guardianemail = document.getElementById("fatheremailedit").value
                    guardianresidence = document.getElementById("fatherresidenceedit").value
                    guardianrelationship = "Father"

                    }
              
                    const fd = new FormData()
                   
                    if (file) {
                   
                        fd.append("file", file)
                        fd.append("image", dob+lastname+".jpg")
                    }
                    fd.append("_id", chosen._id)
                    fd.append("schoolID",admin.schoolID)
                    fd.append("lastname", lastname)
                    fd.append("othernames", othernames)
                    fd.append("class_", class_)
                    fd.append("nextclass", nextclass)
                    fd.append("gender", gender)
                    fd.append("dob", dob)
                    fd.append("housing", housing)
                    fd.append("hometown", hometown)
                    fd.append("residence", residence)
                    fd.append("religion", religion)
                    fd.append("motherlastname", motherlastname)
                    fd.append("motherothernames", motherothernames)
                    fd.append("motheroccupation", motheroccupation)
                    fd.append("mothercontact", mothercontact)
                    fd.append("motheremail", motheremail)
                    fd.append("motherresidence", motherresidence)
                    fd.append("fatherresidence", fatherresidence)
                    fd.append("fatherlastname", fatherlastname)
                    fd.append("fatherothernames", fatherothernames)
                    fd.append("fatheroccupation", fatheroccupation)
                    fd.append("fathercontact", fathercontact)
                    fd.append("fatheremail", fatheremail)
                    fd.append("dailyfee", dailyfeeedit)
                    fd.append("specialID", specialIDedit)

                    fd.append("guardianresidence", guardianresidence)
                    fd.append("guardianlastname", guardianlastname)
                    fd.append("guardianothernames", guardianothernames)
                    fd.append("guardianoccupation", guardianoccupation)
                    fd.append("guardiancontact", guardiancontact)
                    fd.append("guardianemail", guardianemail)
                    fd.append("guardianrelationship", guardianrelationship)
                    fd.append("status", gettingdatatype)

                    const uploading = async() => {
                        await axios.post("https://servermain.sisrevolution.com/api/students/editstudent/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }


                        }).then(res => {

                            if (res.status === 200) {
                               
                                dispatch(getdata({

                                    data: res.data
            
                                }))

                        handleCloseedit()
                        handleClicksnack()
                        setmessage("Student Data Edited")
                        setstated("success")
                                seteditstudentloading(!true)

                            }
                            if (res.status === 204) {
                                handleCloseedit()
                        handleClicksnack()
                        setmessage("Error Editting Student")
                        setstated("success")
                                seteditstudentloading(!true)
                            }

                        })

                    }

try{
                    uploading()

                }catch(err){
                    handleClicksnack()
                    setmessage("Network Error")
                    setstated("error")
                    seteditstudentloading(!true)
                }

                }

    const sendsms = () => {
                    setsmsloading(true)

                       
                    const message = document.getElementById("viasms").value
                   
                
                    const fd = new FormData()
                  console.log("list")
                  console.log(chosen)
                    fd.append("message", message)
                    fd.append("schoolID",chosen.schoolID)
                    fd.append("studentID",chosen.ID)
                    fd.append("sender",schooldetails_[0].schoolname)
                    fd.append("smsname",schooldetails_[0].smsname)
                    fd.append("smsID",schooldetails_[0].smsID)
                    fd.append("image",schooldetails_[0].image)
                    fd.append("contact", chosen.guardiancontact)
                    fd.append("guadianname", chosen.guardianlastname)
                    const uploading = async() => {
                        await axios.post("https://servermain.sisrevolution.com/api/students/singlesms/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }

                        }).then(res => {

                            if (res.status === 200) {
                                setsmsloading(!true)
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("SMS sent")
                        setstated("success")
                               

                            }
                           else if (res.status === 204) {
                                setsmsloading(false)
                        handleClicksnack()
                        setmessage("Error Sending SMS")
                        setstated("success")
                                
                            }else{
                                setsmsloading(false)
                                handleClicksnack()
                                setmessage("Error Sending SMS")
                                setstated("success")
                                        
                            }

                        })

                    }
                    try{
                    uploading()
                }catch(err){
                    handleClicksnack()
                    setmessage("Network Error")
                    setstated("error")
                    setsmsloading(false)
                }
                }

    const sendemail = () => {
                    setemailloading(true)
     
                    const messagesms = document.getElementById("viaemail").value
                    const subject = document.getElementById("emailsubject").value
                   
                    const fd = new FormData()
                  
                    fd.append("message", messagesms)
                    fd.append("logo", schooldetails_[0].logo)
                    fd.append("Schoolname", schooldetails_[0].schoolname)
                    fd.append("email", schooldetails_[0].email)
                    fd.append("contact", schooldetails_[0].contact)
                    fd.append("address", schooldetails_[0].address)
                    fd.append("schoolID",admin.schoolID)
                    fd.append("guardianemail", chosen.guardianemail)
                    fd.append("guardian", chosen.guardianlastname)
                    fd.append("subject", subject)

                    const uploading = async() => {
                        await axios.post("https://servermain.sisrevolution.com/api/students/singleemail/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }


                        }).then(res => {

                            if (res.status === 200) {

                                setemailloading(!true)

                        handleCloseadd()
                        handleClicksnack()
                        setmessage("Email sent")
                        setstated("success")

                            }
                            else if (res.status === 204) {
                                setemailloading(!true)
                                handleCloseadd()
                        handleClicksnack()
                        setmessage("Error Sending Email")
                        setstated("success")
                            }else{
                                setemailloading(!true)
                                handleClicksnack()
                                setmessage("Error Sending Email")
                                setstated("success")
                            }

                        })

                    }

try{
                    uploading()
}
                    catch(err){
                        handleClicksnack()
                        setmessage("Network Error")
                        setstated("error")
                        setemailloading(false)
                    }

                }

    const deleter = (data) => {

         setdeletestudentloading(true)

        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/students/deletestudent/",
                {
                   _id:chosen._id,
                   schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {

                        gettingdata()

                        setdeletestudentloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Data has been deleted")
                        setstated("success")

                    } else {
                        setdeletestudentloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Error deleting data")
                        setstated("error")
                    }

                })

        }

        try{
            doer()
        }
        catch(err){
            handleClicksnack()
            setmessage("Network Error")
            setstated("error")
            setdeletestudentloading(false)
        }

    }
 

    const changestatus = (data) => {

         setdeletestudentloading(true)

        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/students/changestatus/",
                {
                   _id:chosen._id,
                   status:status_selector,
                   schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {

                        dispatch(getdata({

                            data: res.data
    
                        }))

                        setdeletestudentloading(!true)
                        handleClosestatus()
                        handleClicksnack()
                        setmessage("student status changed to "+status_selector)
                        setstated("success")

                    } else {
                        setdeletestudentloading(!true)
                        handleClosestatus()
                        handleClicksnack()
                        setmessage("Error changing status")
                        setstated("error")
                    }

                })

        }
try{
            doer()
        }
        catch(err){
            handleClicksnack()
            setmessage("Network Error")
            setstated("error")
            setdeletestudentloading(false)
        }

    }

    
    const sendviatext = (list) => {

        setloadingguardian(true)
        var data = list.academic.filter(bug => bug.term === selectedterm)
        // setsmssend(true)
        var guardianname = (list.guardianlastname).toUpperCase()
        var guardiancontact = list.guardiancontact
        var student = (list.lastname).toUpperCase() + " " + (list.othernames).toUpperCase()
        var id = list._id


        const senddatatext = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/students/sendresultstext/",
                {
                    id: id,
                    data: data,
                    guardianname: guardianname,
                    guardiancontact: guardiancontact,
                    student: student,
                    term: selectedterm,
                    reopening: schooldetails_[0].reopendate,
                    schoolID:admin.schoolID,
                    smsID:schooldetails_[0].smsID,
                    smsname:schooldetails_[0].smsname,
                    position:Class_.filter(bug=>bug.classname === list.class_)[0].position,
                    aggregate:Class_.filter(bug=>bug.classname === list.class_)[0].aggregate


                }).then(res => {

                    if (res.status === 200) {
                            // setsmssend(false)
                            // handleClose()
                            setloadingguardian(false)

                            handleClicksnack()
                            setmessage("Results sent to parent")
                            setstated("success")


                    }else{
                        // setsmssend(false)
                        setloadingguardian(false)

                        handleClicksnack()
                        setmessage("Error sending results")
                        setstated("error")
                    }

                })

        }

        if (selectedterm !== "none") {
            if(schooldetails_[0].payment.length===0 || schooldetails_[0].payment[schooldetails_[0].payment.length-1].amount==="trial" ){

                settrialmode(true)
                setloadingguardian(true)

    
            }else{

                try{
                senddatatext()
                }catch(err){
                    handleClicksnack()
                    setloadingguardian(true)
                    setmessage("Network Error")
                    setstated("error")

                }

            }
        }

    }


    const sendviaemail = (list) => {

        setloadingemail(true)
        var data = list.academic.filter(bug => bug.term === selectedterm)
        // setsmssend(true)
        var guardianname = (list.lastname).toUpperCase()
        var guardiancontact = list.guardiancontact
        var guardianemail = list.guardianemail
        var student = (list.lastname).toUpperCase() + " " + (list.othernames).toUpperCase()
        var id = list._id


        const senddatatext = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/students/sendresultsemail/",
                {
                    id: id,
                    data: data,
                    guardianname: guardianname,
                    guardiancontact: guardiancontact,
                    guardianemail: guardianemail,
                    student: student,
                    term: selectedterm,
                    ID_:list.id,
                    class_:list.class_,
                    reopening: schooldetails_[0].reopendate,
                    schoolname: schooldetails_[0].schoolname,
                    address: schooldetails_[0].address,
                    contact: schooldetails_[0].contact,
                    schoolID:admin.schoolID,
                    smsID:schooldetails_[0].smsID,
                    smsname:schooldetails_[0].smsname,
                    position:Class_.filter(bug=>bug.classname === list.class_)[0].position,
                    aggregate:Class_.filter(bug=>bug.classname === list.class_)[0].aggregate


                }).then(res => {
console.log(res)
                    if (res.status === 200) {
                            // setsmssend(false)
                            // handleClose()
                            setloadingemail(false)

                            handleClicksnack()
                            setmessage("Results sent to parent")
                            setstated("success")


                    }else{
                        // setsmssend(false)
                        setloadingemail(false)

                        handleClicksnack()
                        setmessage("Error sending results")
                        setstated("error")
                    }

                })

        }

        if (selectedterm !== "none") {
            if(schooldetails_[0].payment.length===0 || schooldetails_[0].payment[schooldetails_[0].payment.length-1].amount==="trial" ){

                settrialmode(true)
                setloadingemail(true)

    
            }else{

                try{
                senddatatext()
                }catch(err){
                    handleClicksnack()
                    setloadingemail(true)
                    setmessage("Network Error")
                    setstated("error")

                }

            }
        }

    }

    const handleClickOpenedit = (data) => {
       
        setchosen(data)
        setselectedgenderedit(data.gender)
        sethousing(data.housing===undefined?"Day":data.housing)
        setperson("https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/"+data.image)
        setselectedreligionedit(data.religion)
        setselectedclassedit(data.class_)
        setguardianedit(data.guardianrelationship)
        setOpenedit(true);
    };

    const handleCloseedit = () => {
        setOpenedit(false);
    };

    const handleClickOpenimage = (data) => {
        setchosen(data)
        // var image = new Image();
        // image.src = 'https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/uploads/'+chosen.image
        // image.onload = () => setimageloading(false);
        // image.onerror = () => setimageloading(true);
        setOpenimage(true);
    };

    const handleCloseimage = () => {
        setOpenimage(false);
    };

    const handleClickOpendelete = (data) => {
        console.log(data)
        setchosen(data)
        setOpendelete(true);
    };

    const handleClickOpenstatus = (data) => {
        setchosen(data)
        setstatus_selector(data.status)
        setOpenstatus(true);
    };

    const handleClosedelete = () => {
        setOpendelete(false);
    };

    const handleClosestatus = () => {
        setOpenstatus(false);
    };

    const handleClickOpenadd = (data) => {
       
        setOpenadd(true);
    };

    const handleCloseadd = () => {
        setOpenadd(false);
    };
    const handleClickOpenaddexcel = (data) => {
       
        setOpenaddexcel(true);
    };

    const handleCloseaddexcel = () => {
        setOpenaddexcel(false);
    };

    const handleClickOpensrv = (data) => {
        console.log(data)
        setchosen(data)
        setOpensrv(true);
    };

    const handleClosesrv = () => {
        setOpensrv(false);
    };

    const handleClickOpenview = (data) => {
        console.log(data)
        setchosen(data)
        setOpenview(true);
    };

    const handleCloseview = () => {
        setOpenview(false);
    };
   
    const handleClickOpenattendance = (data) => {
        console.log(data)
        setchosen(data)
        setOpenattendance(true);
    };

    const handleCloseattendance = () => {
        setOpenattendance(false);
    };
    
    const handleClickOpenmessage = (data) => {
        if(schooldetails_[0].payment.length===0 || schooldetails_[0].payment[schooldetails_[0].payment.length-1].amount==="trial" ){

            settrialmode(true)

        }else{
        console.log(data)
        setchosen(data)
        setOpenmessage(true);
        }
    };

    const handleClosemessage = () => {
        setOpenmessage(false);
    }; 
     const handleCloseresults = () => {
        setOpenresults(false);
    };

    const handlesendresults = () => {
        setOpenresults(true);
    };


    const handlenotify = (data) => {
    
        setOpennotify(true);
    };

    const handleClosenotify = () => {
        setOpennotify(false);
    };

    const handleClickOpensupply = (data) => {
        if(schooldetails_[0].payment.length===0 || schooldetails_[0].payment[schooldetails_[0].payment.length-1].amount==="trial" ){

            settrialmode(true)

        }else{
        setchosen(data)
        setpersonclass(data.class_)
        setOpensupply(true);
        }
    };

    const handleClosesupply = () => {
        setOpensupply(false);
    };

    const totalsupplied = (data) => {
        var total = 0
        for (var i in data) {
            total += data[i].quantity
        }


        return total
    }

    const srvrec = (data) => {
        var total = 0
        for (var i in data) {
            total += data[i].SRVrec
        }


        return total
    }

    const imagehandler = (e) => {
        const reader = new FileReader()
        reader.onload = () => {
            if (reader.readyState === 2) {
                setperson(reader.result)
            }
        }

        reader.readAsDataURL(e.target.files[0])
    }

    const handleChange = (event) => {
        setAge(event.target.value);
    };
       
    const handleChangesortclass = (event) => {
        setsortclass(event.target.value);
        setexportdata([])
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChange_gender = (e) => {
        setselectedgender(e.target.value)
    }

    const handleChange_religion = (e) => {
        setselectedreligion(e.target.value)
    }

    const handleChange_class = (e) => {
        setselectedclass(e.target.value)
    }

    const handleChange_genderedit = (e) => {
        setselectedgenderedit(e.target.value)
    }

    const handleChange_religionedit = (e) => {
        setselectedreligionedit(e.target.value)
    }

    const handleChange_classedit = (e) => {
        setselectedclassedit(e.target.value)
    }

    const sorting = (e) => {
        
        setsorter(e.target.value)
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };  

    const handleChangeterm = (e) => {
        setselectedterm(e.target.value);
       setattendancedata(e.target.value!=="none"?chosen.attendance[e.target.value]!==undefined?chosen.attendance[e.target.value]:[]:[])
    
    };

    const handleChangepromote = (e) => {
        setselectedpromote(e.target.value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const editacademic =(data)=>{

        setwhattype(data)
        setreopendate(chosen.reopendate)

    }


   


    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

const setback=(data)=>{
    setwhat(data)
}
        const sendmessage = () =>{

            return(

<div style={{textAlign:"center", margin:8}}>
<p style={{textAlign:"center", margin:12}}>
                        Send a message to Guardian via 
                    </p>
                    {/* <Button  style={{backgroundColor:" #e0eff9" , color:"#1976d2", margin:12, padding:10}} startIcon={  <WhatsAppIcon style={{fontSize:"20px"}} />} aria-label="fingerprint"  >
        <small> Whatsapp</small>
      </Button> */}
           <Button style={{backgroundColor:" #e0eff9" , color:"#1976d2", margin:12, padding:10}} startIcon={ <EmailIcon style={{fontSize:"20px"}} />} onClick={()=>setback("email")} aria-label="fingerprint" >
        <small> Email</small>
      </Button>
   <Button style={{backgroundColor:" #e0eff9" , color:"#1976d2", margin:12, padding:10}} startIcon={ <MessageIcon style={{fontSize:"20px"}} />} onClick={()=>setback("sms")} aria-label="fingerprint" >
        <small> Text Message</small>
      </Button>

 

                    
                
</div>

            )
        }

        const viaSMS = ()=>{
            
            return(
                <div style={{textAlign:"left", marginTop:10}}>
                <Button onClick={()=>setback("back")} style={{margin:2}}>
                <KeyboardBackspaceIcon />
                </Button>
                <div style={{textAlign:"center"}}>
                <p style={{textAlign:"center", marginTop:"-30px", fontWeight:'bolder'}}> Sending Message Via SMS</p>
<Divider/>
<br/>
<br/>
                <textarea id='viasms' rows="5" defaultValue={"Hello "+ chosen.guardianlastname} style={{width:"80%", padding:10, borderColor:"grey", borderRadius:"5px"}} />    
<br/>
 {smsloading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => sendsms()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                   Send
                                </Button>
                            }
                </div>
                </div>


            )

        }
    

        const viaEmail = ()=>{
            
            return(
                <div style={{textAlign:"left", marginTop:10}}>
                <Button onClick={()=>setback("back")} style={{margin:2}}>
                <KeyboardBackspaceIcon />
                </Button>
                <div style={{textAlign:"center"}}>
                <p style={{textAlign:"center", marginTop:"-30px", fontWeight:'bolder'}}> Sending Message Via Email</p>
<Divider/>
<br/>
<br/>
                <textarea id='emailsubject' rows="1" placeholder='Subject' style={{width:"80%", padding:10, borderColor:"grey", borderRadius:"5px"}} />    
                <textarea id='viaemail' rows="5" defaultValue={"Hello "+ chosen.guardianlastname} style={{width:"80%", padding:10, borderColor:"grey", borderRadius:"5px"}} />    
<br/>

 {emailloading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => sendemail()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Send
                                </Button>
                            }
                </div>
                </div>


            )

        }

        const viaEmailandtext = ()=>{
            
            return(
                <div style={{textAlign:"left", marginTop:10}}>
                <Button style={{margin:2}}>
                <KeyboardBackspaceIcon />
                </Button>
                <div style={{textAlign:"center"}}>
                <p style={{textAlign:"center", marginTop:"-30px", fontWeight:'bolder'}}> Sending Message Via Email and SMS</p>
<Divider/>
<br/>
<br/>
                <textarea rows="5" defaultValue="Hello Mr. Ntori" style={{width:"80%", padding:10, borderColor:"grey", borderRadius:"5px"}} />    

                </div>
                </div>


            )

        }

    
        const messenger =(type)=>{
    if(type==="email"){
        return(
            viaEmail()
        )
    }
    if(type==="sms"){
        return(
            viaSMS()
        )
    }  if(type==="back"){
        return(
            sendmessage()
        )
    }
        }


        const mainrecord =()=>{
            var data=[]
            if(selectedterm !== "none" && chosen.length!==0){
            data=chosen.academic.filter(bug=> bug.term === selectedterm)

            }
return(
    <div> 

<DialogTitle id="customized-dialog-title" onClose={handleClosesupply} style={{  }}>
                
                        <FormControl style={{ marginLeft:45, marginRight:5, backgroundColor:" #e0eff9" , color:"#1976d2" }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Term</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={selectedterm}
                            style={{ minWidth: 200, padding: 0, margin: 0 }}
                            onChange={(e)=>handleChangeterm(e)}
                            autoWidth
                            label="Term"
                            size="small"
                        >
                            <MenuItem value="none">
                                <em>Choose Term</em>
                            </MenuItem>
                            {schooldetails_.length!==0 && schooldetails_[0].terms.length!==0?
                            schooldetails_[0].terms.map((list,index)=>
                            <MenuItem value={list}>{list}</MenuItem>

                            ) :""   
                        }
                          
                        </Select>
                    </FormControl>
                    <Button onClick={()=>editacademic("edit")} style={{marginLeft:10,backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                    Add / Edit Results
                    </Button>

                    <Button onClick={handleClosesupply} style={{float:"right"}}> close </Button>
      
                    <Button  onClick={handlesendresults} style={{marginLeft:10,backgroundColor:" #e0eff9" , color:"#1976d2"}}>Send to Guardian</Button>

                    <Button  onClick={()=>editacademic("reopen")}  style={{marginLeft:10,backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                        set re-opening date
                    </Button>

                </DialogTitle>

                <DialogContent dividers style={{ textAlign: "left" }}>

                    <Printpage data={chosen} term={selectedterm} Class_={Class_} students={rows} schooldata={schooldetails_}/>

                </DialogContent>

    </div> 
)

        }


        const positions = (term, data, id,classname) => {
var list = []
var list2 = [data]
var position = "None"
var arrange = []
var value = 0
    for(var i in rows){
        if(rows[i]._id !== id){
        if(rows[i].academic.length !==0){

            console.log(rows[i])

        for(var k in rows[i].academic){
            if(rows[i].academic[k].term===term && rows[i].class_===classname){
               list.push(rows[i].academic[k].totalscore) 
            }
        }
        }
    }

    }
    
    for(var i in list){
        if (list2.includes(list[i])){
        console.log("includes")
        }else(
            list2.push(list[i])
        )
        
    }

    list2= list2.sort((a,b)=>b-a)

    for(var i in list2){
        var sum = parseInt(i)+1
         if((sum).toString()[(sum).toString().length-1] === "1"){
        arrange.push({score:list2[i], position:(sum).toString()+"st"})

    } else if((sum).toString()[(sum).toString().length-1] === "2"){
         arrange.push({score:list2[i], position:(sum).toString()+"nd"})
    }
    else if((sum).toString()[(sum).toString().length-1] === "3"){
        arrange.push({score:list2[i], position:(sum).toString()+"rd"})

    }else{
        arrange.push({score:list2[i], position:(sum).toString()+"th"})
    }
    }


 const doposition = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/students/arrangepositions/",
                {
                    class_: chosen.class_,
                    term: term,
                    data: arrange,
                    schoolID:admin.schoolID
          
                }).then(res => {

                    if (res.status === 200) {

                       
                        
                        // setloading(!true)
                        // handleClosesupply()
                        // handleClicksnack()
                        // setmessage("You have successfully added to supply list")
                        // setstated("success")
                       

                        
                    } else {
                        // setloading(!true)
                        // handleClosesupply()
                        // handleClicksnack()
                        // setmessage("Error adding to supply list")
                        // setstated("error")

                    }

                })

        }

        doposition()



   
    value = list2.findIndex(bug=>bug===data) + 1
    console.log(value.toString()[value.toString().length-1])
    if(value.toString()[value.toString().length-1] === "1"){
        position=value.toString()+"st"

    } else if(value.toString()[value.toString().length-1] === "2"){
        position=value.toString()+"nd"

    }
    else if(value.toString()[value.toString().length-1] === "3"){
        position=value.toString()+"rd"

    }else{
        position=value.toString()+"th"
    }



    return position

        }

        const editrecord =()=>{

            var subjected = Class_.filter(bug=>bug.classname === chosen.class_)
            console.log(subjected)
            var number = subjected[0].subjects[0]
            var results = []
            var conclusion = []
            var totalscore=0
            var aggregatecalc=[]
            var aggregate=0
            var elective=0
            var core=0
            //var promotion = document.getElementById('nextclass').value
            var promotion = selectedpromote
            
            if(promotion === "none"){
                 promotion=chosen.class_
            }

            const submitscore=()=>{
                var interest =  document.getElementById("interest").value
                var conduct =  document.getElementById("conduct").value
                var teacherremarks =  document.getElementById("teacherremarks").value
                var headremarks =  document.getElementById("headremarks").value

                setloading(true)
if(schooldetails_[0].grades.length!==0){
                for(var i in number){
                    var classscore = document.getElementById('class'+subjected[0].subjects[0][i].subject+i).value
                    var examscore =  document.getElementById('exams'+subjected[0].subjects[0][i].subject+i).value
                    
                    if(classscore===""){
                        classscore = 0
                    }  
                    if(examscore===""){
                        examscore = 0
                    }
                    
                   var total =(parseInt(classscore)+parseInt(examscore))
                   totalscore += total
                   aggregatecalc.push([grading(total),subjected[0].subjects[0][i].type])
                   results.push({subject: subjected[0].subjects[0][i].subject, class_scorre: classscore, exam_score:examscore, total:total , grade:grading(total), remarks:remarks(total) })
                    
                }
               
            
                console.log(aggregatecalc)
                for(var i in aggregatecalc){
                    
                if(aggregatecalc[i][1]==="Core" && core < 5){     
                    aggregate += parseInt(aggregatecalc[i])
                    elective+=elective+1
                }
                else if(aggregatecalc[i][1]==="Elective" && elective < 2){
                    aggregate += parseInt(aggregatecalc[i])
                    core+=core+1
                }
            }

    conclusion = {term:selectedterm, position:positions(selectedterm, totalscore, chosen._id, chosen.class_ ), results:results, totalscore:totalscore, promotion:promotion, interest:interest, conduct:conduct, teacherremarks:teacherremarks, headremarks:headremarks,  aggregate:aggregate}

    const doerresult = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/students/addresults/",
                {
                    id: chosen._id,
                    data: conclusion,
                    term: selectedterm,
                    class_: promotion,
                    schoolID:admin.schoolID

                }).then(res => {

                    if (res.status === 200) {

                       gettingdata()
                        
                        setloading(!true)
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("You have successfully added academic records")
                        setstated("success")
                       

                        
                    } else {
                        setloading(!true)
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("Error adding academic records")
                        setstated("error")

                    }

                })

        }

        try{
        doerresult()
        }catch(err){
            setloading(!true)
            handleClicksnack()

                        setmessage("Network Error")
                        setstated("error")
        }

    }else{
        handlenotify()
    }
            }
        



    return(
    <div> 

<DialogTitle id="customized-dialog-title" onClose={handleClosesupply} style={{  }}>
   <Button style={{margin:2}} onClick={()=>editacademic("back")}>
                <KeyboardBackspaceIcon />
                </Button>
                   Add / Edit Academic records

                     <FormControl style={{ marginLeft:10, marginRight:0 }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Term</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={selectedterm}
                            style={{ minWidth: 200, padding: 0, margin: 0 }}
                            onChange={(e)=>handleChangeterm(e)}
                            autoWidth
                            label="Term"
                            size="small"
                        >
                            <MenuItem value="none">
                                <em>Choose Term</em>
                            </MenuItem>
                            {schooldetails_.length!==0 && schooldetails_[0].terms.length!==0?
                            schooldetails_[0].terms.map((list,index)=>
                            <MenuItem value={list}>{list}</MenuItem>

                            ) :""   
                        }
                        </Select>
                    </FormControl>
                  
                    <Button onClick={handleClosesupply} style={{float:"right"}}>
                    close
                    </Button>
                </DialogTitle>

                <DialogContent dividers style={{ textAlign: "left" }}>
                  <ListItem align="left">
                                                    <ListItemAvatar>
                                                        <Avatar src={'https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/'+chosen.image} size="small" alt={chosen.lastname+" "+ chosen.othernames} onClick={() => handleClickOpenimage(chosen)} style={{cursor:"pointer"}}>
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary={chosen.lastname+" "+ chosen.othernames}  />
                                                </ListItem>
                    
               <FormControl style={{ marginLeft:0, marginRight:0, marginTop:10, marginBottom:10 }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Promoted to</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={selectedpromote}
                            style={{ minWidth: 200, padding: 0, margin: 0 }}
                            onChange={(e)=>handleChangepromote(e)}
                            autoWidth
                            label="Promoted to"
                            size="small"
                        >
                            <MenuItem value="none">
                                <em>Promoted to</em>
                            </MenuItem>   
                            <MenuItem value="Repeat">
                                <em>Repeat</em>
                            </MenuItem>

                            {Class_.map((row, index)=>
                            <MenuItem value={row.classname}>{row.classname}</MenuItem>

                            )}
                         

                        </Select>
                    </FormControl>
                   
                    <TableContainer component={Paper}>
      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
                      <TableCell>SUBJECTS</TableCell>
                      <TableCell align="left">CLASS SCORE (50%)</TableCell>
                      <TableCell align="left">EXAM SCORE (50%)</TableCell>
                      

          </TableRow>
        </TableHead>
        <TableBody>
         { chosen.academic.length===0? subjected[0].subjects[0].map((row, index) => (
            <TableRow
                  key={row.subject}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
             
                  <TableCell align="left">{row.subject}</TableCell>
                  <TableCell align="left">  <input  id={'class'+row.subject+index} style={{borderRadius:"5px", padding:"10px", border:"solid", borderWidth:"0.3px", borderColor:"grey" }}  type="number"/> </TableCell>
                  <TableCell align="left">  <input id={'exams'+row.subject+index}   style={{borderRadius:"5px", padding:"10px", border:"solid", borderWidth:"0.3px", borderColor:"grey" }} type="number"/>  </TableCell>

            </TableRow>
          ))
          :
         chosen.academic.filter(bug=> bug.term === selectedterm).length !==0?
          
            chosen.academic.filter(bug=> bug.term === selectedterm)[0].results.map((row,index)=>
            (
                
            <TableRow
                  key={row.subject}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                                
                  <TableCell align="left">{row.subject}</TableCell>
                  <TableCell align="left">  <input  id={'class'+row.subject+index} defaultValue={row.class_scorre} style={{borderRadius:"5px", padding:"10px", border:"solid", borderWidth:"0.3px", borderColor:"grey" }}  type="number"/> </TableCell>
                  <TableCell align="left">  <input id={'exams'+row.subject+index}  defaultValue={row.exam_score}   style={{borderRadius:"5px", padding:"10px", border:"solid", borderWidth:"0.3px", borderColor:"grey" }} type="number"/>  </TableCell>

            </TableRow>
          )
        
            ):
            subjected[0].subjects[0].map((row, index) => (
                <TableRow
                      key={row.subject}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                      <TableCell align="left">{row.subject}</TableCell>
                      <TableCell align="left">  <input  id={'class'+row.subject+index} style={{borderRadius:"5px", padding:"10px", border:"solid", borderWidth:"0.3px", borderColor:"grey" }}  type="number"/> </TableCell>
                      <TableCell align="left">  <input id={'exams'+row.subject+index}   style={{borderRadius:"5px", padding:"10px", border:"solid", borderWidth:"0.3px", borderColor:"grey" }} type="number"/>  </TableCell>
    
                </TableRow>
              ))
          

          

          }
        </TableBody>
      </Table>

      <FormControl variant="standard" style={{ margin: 10 }}>
      {/* <InputLabel id="interest">Interest</InputLabel> */}

<TextField
labelId="interest"
    id="interest"
    placeholder="Interest"
    type="text"
    className="signup"
    variant="outlined"
    defaultValue={chosen.length!==0 && chosen.academic.filter(bug=> bug.term === selectedterm).length!==0 && chosen.academic.filter(bug=> bug.term === selectedterm)[0].interest !==undefined? chosen.academic.filter(bug=> bug.term === selectedterm)[0].interest :""}
    size="small"


/>
</FormControl>

      <FormControl variant="standard" style={{ margin: 10 }}>
      {/* <InputLabel id="conduct">Conduct</InputLabel> */}

<TextField
labelId="conduct"
    id="conduct"
    placeholder="Conduct / Character"
    type="text"
    className="signup"
    variant="outlined"
    defaultValue={chosen.length!==0 && chosen.academic.filter(bug=> bug.term === selectedterm).length!==0 &&  chosen.academic.filter(bug=> bug.term === selectedterm)[0].conduct!==undefined ? chosen.academic.filter(bug=> bug.term === selectedterm)[0].conduct:""}
    size="small"


/>
</FormControl>

      <FormControl variant="standard" style={{ margin: 10 }}>
      {/* <InputLabel id="remark">Teacher's Remarks</InputLabel> */}

<TextField
labelId="remark"
    id="teacherremarks"
    placeholder="Class Teacher's Remarks"
    type="text"
    className="signup"
    variant="outlined"
    defaultValue={chosen.length!==0 && chosen.academic.filter(bug=> bug.term === selectedterm).length!==0 &&  chosen.academic.filter(bug=> bug.term === selectedterm)[0].teacherremarks !== undefined ? chosen.academic.filter(bug=> bug.term === selectedterm)[0].teacherremarks:""}
    size="small"


/>
</FormControl>

      <FormControl variant="standard" style={{ margin: 10 }}>
      {/* <InputLabel id="head">Headteacher's Remarks</InputLabel> */}

<TextField
labelId="head"
    id="headremarks"
    placeholder="Headteacher's Remarks"
    type="text"
    className="signup"
    variant="outlined"
    defaultValue={chosen.length!==0 && chosen.academic.filter(bug=> bug.term === selectedterm).length!==0 &&  chosen.academic.filter(bug=> bug.term === selectedterm)[0].headremarks!== undefined ? chosen.academic.filter(bug=> bug.term === selectedterm)[0].headremarks:""}
    size="small"


/>
</FormControl>

    </TableContainer>
<br/>
    {loading ===true?
    <div align="center"> 
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>
      </div>:
                          
                          <div align="center">   <Button autoFocus onClick={() => submitscore()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Submit
                                </Button></div>
                            }


                </DialogContent>



    </div> 
    )
        }  
        
                
        const reopen=()=>{
          

            const submitdate=()=>{
                setloading(true)

                const dodate = async () => {
                    await axios.post("https://servermain.sisrevolution.com/api/schools/changereopendate/",
                        {
                            _id: schooldetails_[0]._id,
                            reopendate: reopendate,
        
                        }).then(res => {
        
                            if (res.status === 200) {
        
                                dispatch(schooldetails({

                                    data: [res.data]
            
                                }))
                                
                                setloading(!true)
                                handleClosesupply()
                                handleClicksnack()
                                setmessage("You have successfully Changed Re-opening Date")
                                setstated("success")
                               
        
                                
                            } else {
                                setloading(!true)
                                handleClosesupply()
                                handleClicksnack()
                                setmessage("Error changing re-opening date")
                                setstated("error")
        
                            }
        
                        })
        
                }
        
                try{
                dodate()
                }catch(err){
                    setloading(!true)
                    handleClicksnack()

                    setmessage("Network Error")
                    setstated("error")
                }
              
            }
       
    return(
    <div> 

<DialogTitle id="customized-dialog-title" onClose={handleClosesupply} style={{  }}>
   <Button style={{margin:2}} onClick={()=>editacademic("back")}>
                <KeyboardBackspaceIcon />
                </Button>
                    Set Re-Opening Date                  
                    <Button onClick={handleClosesupply} style={{float:"right"}}>
                    close
                    </Button>
                </DialogTitle>

                <DialogContent dividers style={{ textAlign: "center" }}>
                 
                <FormControl variant="standard" style={{ margin: 10, textAlign:"center" }}>

<TextField
    id="reopendate"
    placeholder="Re-Opening Date"
    type="date"
    className="signup"
    variant="outlined"
    value={reopendate}
    onChange={(e)=>setreopendate(e.target.value)}
    // label="Re-Opening Date"
    size="small"



/>
</FormControl>
                   
{loading ===true?
    <div align="center"> 
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>
      </div>:
                           <div align="center"> 
                                <Button autoFocus onClick={() => submitdate()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                   Set Date
                                </Button>
                                </div>
                            }           



                </DialogContent>



    </div> 
    )
        }

        const records =(recordtype)=>{
    if(recordtype==="edit"){
        return(
            editrecord()
        )
    }   
    if(recordtype==="reopen"){
        return(
            reopen()
        )
    }
     if(recordtype==="back"){
        return(
            mainrecord()
        )
    }
        }

        const guardianchange =(e)=>{

            setGuardian(e.target.value)

        } 
         const admissionchange =(e)=>{

            setAdmission(e.target.value)

        } 
        
        const guardianchangeedit =(e)=>{

            setguardianedit(e.target.value)

        }

        const otherguardian = () =>{


            return(

                <div>

<p style={{textAlign:"center"}}>Fill this if Guardian is not a parent </p>

<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="surname"
    placeholder="Surname"
    type="text"
    className="signup"
    variant="outlined"
    
    size="small"


/>
</FormControl>
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="othernames"
    placeholder="Other name(s)"
    type="text"
   
    className="signup"
    variant="outlined"
    label="Othername(s)"
    size="small"



/>
</FormControl>
<br />


<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="occupation"
    placeholder="Occupation"
    type="text"
   
    className="signup"
    variant="outlined"
    label="Occupation"
    size="small"



/>
</FormControl>
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="contact"
    placeholder="Contact"
    type="tel"

    className="signup"
    variant="outlined"
    label="Contact"
    size="small"



/>
</FormControl>
<br />


<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="email"
    placeholder="Email"
    type="email"
    
    className="signup"
    variant="outlined"
    label="Email"
    size="small"



/>
</FormControl>
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="address"
    placeholder="Address"
    type="address"

    className="signup"
    variant="outlined"
    label="Address"
    size="small"



/>
</FormControl>
<br />

                </div>
            )

        }

        const otherguardianedit = () =>{


            return(

                <div>

<p style={{textAlign:"center"}}>Fill this if Guardian is not a parent </p>

<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="guardianlastnameedit"
    placeholder="Surname"
    type="text"
    className="signup"
    variant="outlined"
    defaultValue={chosen.guardianlastname}
    size="small"


/>
</FormControl>
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="guardianothernamesedit"
    placeholder="Other name(s)"
    type="text"
    defaultValue={chosen.guardianothernames}
    className="signup"
    variant="outlined"
    label="Othername(s)"
    size="small"



/>
</FormControl>
<br />


<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="guardianoccupationedit"
    placeholder="Occupation"
    type="text"
    defaultValue={chosen.guardianoccupation}
    className="signup"
    variant="outlined"
    label="Occupation"
    size="small"

/>
</FormControl>
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="guardiancontactedit"
    placeholder="Contact"
    type="tel"
    defaultValue={chosen.guardiancontact}
    className="signup"
    variant="outlined"
    label="Contact"
    size="small"



/>
</FormControl>
<br />


<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="guardianemailedit"
    placeholder="Email"
    type="email"
    defaultValue={chosen.guardianemail}
    className="signup"
    variant="outlined"
    label="Email"
    size="small"



/>
</FormControl>
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="guardianresidenceedit"
    placeholder="Address"
    type="address"
    defaultValue={chosen.guardianresidence}
    className="signup"
    variant="outlined"
    label="Address"
    size="small"



/>
</FormControl>
<br />

                </div>
            )

        }

        if(activestudents===false && withdrawnstudents===false && completestudents===false){

            setactivestudents(true)
            setgettingdatatype("Active")

        }

        const changestudenttype=(e, name)=>{
            setexportdata([])
            console.log(e.target.checked)
            if(name==="Active"){
                setactivestudents(e.target.checked)
                setwithdrawnstudents(false)
                setcompletestudents(false)
                setgettingdatatype("Active")
            }
             else if(name==="Completed"){
                setactivestudents(false)
                setwithdrawnstudents(false)
                setcompletestudents(e.target.checked) 
                setgettingdatatype("Completed")

            }   
            else if(name==="Withdrawn"){
                setactivestudents(false)
                setwithdrawnstudents(e.target.checked)
                setcompletestudents(false) 
                setgettingdatatype("Withdrawn")


            }

            

        }
           
        const status_changer=(e)=>{
            setstatus_selector(e.target.value)
        }


        const settrialmode_=()=>{
            settrialmode(false)
        }


    return (

        <Box sx={{ width: '100%' }}>

            <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    { message }
                </Alert>
            </Snackbar>

            <Dialog maxWidth="lg" onClose={handleClosenotify} aria-labelledby="customized-dialog-title" open={opennotify}>
 <DialogTitle id="customized-dialog-title" onClose={handleClosenotify} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Set-up your grades
                    <IconButton onClick={handleClosenotify} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>
<br/>
                    <p>
                       Go to settings and set-up your grading system first
                    </p>
<br/>
                </DialogContent>
                {/* <DialogActions> */}

 {/* {deletestudentloading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => deleter()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Submit
                                </Button>
                            } */}


                 

                    {/* <Button autoFocus onClick={handleClosedelete} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                        No
                    </Button> */}
                {/* </DialogActions> */}
            </Dialog>

            <Dialog maxWidth="lg" onClose={handleClosedelete} aria-labelledby="customized-dialog-title" open={opendelete}>
 <DialogTitle id="customized-dialog-title" onClose={handleClosedelete} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Delete
                    <IconButton onClick={handleClosedelete} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>

                    <p>
                        Are you sure you want to delete {chosen.lastname+" "+chosen.othernames} from the database?
                    </p>

                </DialogContent>
                <DialogActions>

 {deletestudentloading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => deleter()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Submit
                                </Button>
                            }


                 

                    <Button autoFocus onClick={handleClosedelete} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>



            <Dialog fullWidth maxWidth="sm" onClose={handleCloseresults} aria-labelledby="customized-dialog-title" open={openresults}>
 <DialogTitle id="customized-dialog-title" onClose={handleCloseresults} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Send Results To Guardian
                    <IconButton onClick={handleCloseresults} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
            <br/>
            <br/>
            <Grid container textAlign="center">
                <Grid xs={6}>
                {loadingguardian ===true?
   
   <LoadingButton
loading
loadingPosition="start"
startIcon={<SaveIcon />}
variant="outlined"
style={{marginLeft:10}}
>
Loading...
</LoadingButton>
:

<Button  onClick={()=>sendviatext(chosen)} style={{marginLeft:10,backgroundColor:" #e0eff9" , color:"#1976d2"}}>Send results via sms</Button>

}
</Grid>

<Grid xs={6}>

{loadingemail ===true?
   
   <LoadingButton
loading
loadingPosition="start"
startIcon={<SaveIcon />}
variant="outlined"
style={{marginLeft:10}}
>
Loading...
</LoadingButton>
:

<Button  onClick={()=>sendviaemail(chosen)} style={{marginLeft:10,backgroundColor:" #e0eff9" , color:"#1976d2"}}>Send results via email</Button>

}

</Grid>


</Grid>
<br/>
<br/>


            </Dialog>


            <Dialog maxWidth="lg" onClose={settrialmode_} aria-labelledby="customized-dialog-title" open={trialmode}>
 <DialogTitle id="customized-dialog-title" onClose={settrialmode_}  style={{ backgroundColor: "gray", color: "white" }}>
                    Trial Mode
                    <IconButton onClick={settrialmode_}  style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>

                    <p>

                    You are on a trial mode, go to pricing and settle payment in order to use this function.

                    </p>

                </DialogContent>
                <DialogActions>

                   <Link to="/pricing"><Button autoFocus  style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                        Pricing
                    </Button>
                    </Link> 
                </DialogActions>
            </Dialog>

            <Dialog maxWidth="lg" onClose={handleClosestatus} aria-labelledby="customized-dialog-title" open={openstatus}>
 <DialogTitle id="customized-dialog-title" onClose={handleClosestatus} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Change Student Status
                    <IconButton onClick={handleClosestatus} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>

                  
                <FormControl>
      {/* <FormLabel id="demo-radio-buttons-group-label">Status</FormLabel> */}
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        value={status_selector}
        onChange={status_changer}
        name="radio-buttons-group"
      >
        <FormControlLabel value="Active" control={<Radio />} label="Active" />
        <FormControlLabel value="Completed" control={<Radio />} label="Completed" />
        <FormControlLabel value="Withdrawn" control={<Radio />} label="Withdrawn" />
      </RadioGroup>
    </FormControl>


                </DialogContent>
                <DialogActions>

 {deletestudentloading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => changestatus()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Submit
                                </Button>
                            }

                </DialogActions>
            </Dialog>

            <Dialog fullWidth maxWidth="sm" onClose={handleClosemessage} aria-labelledby="customized-dialog-title" open={openmessage}>
             <DialogTitle id="customized-dialog-title" onClose={handleCloseattendance} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Messages
                    <IconButton onClick={handleClosemessage} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                {messenger(what)}
            
                <DialogActions>

                    <Button>
                    
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog fullWidth maxWidth="sm" onClose={handleCloseattendance} aria-labelledby="customized-dialog-title" open={openattendance}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseattendance} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Attendance
                    <IconButton onClick={handleCloseattendance} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers style={{textAlign:"center"}}>
                <FormControl style={{ marginRight:5 }}>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={selectedterm}
                            style={{ minWidth: 200, padding: 0, margin: 0 }}
                            onChange={(e)=>handleChangeterm(e)}
                            autoWidth
                            size="small"
                        >
                            <MenuItem value="none">
                                <em>Choose Term</em>
                            </MenuItem>
                            {schooldetails_.length!==0 && schooldetails_[0].terms.length!==0?
                            schooldetails_[0].terms.map((list,index)=>
                            <MenuItem value={list}>{list}</MenuItem>

                            ) :""   
                        }
                        </Select>
                    </FormControl>
                    <br/>
                    <h2 ><span style={{color:"blue"}}>{chosen.length !== 0 && selectedterm!=="none"  ? chosen.attendance.length!==0 && chosen.attendance[selectedterm]!==undefined?chosen.attendance[selectedterm].filter(bug=>bug.attendance==="present").length:0:0}</span> / <span  style={{color:"green"}}>{chosen.length!==0 && selectedterm!=="none"?chosen.attendance[selectedterm]!==undefined?chosen.attendance[selectedterm].length:0:0}</span></h2>

                    <Attendance atendance={attendancedata}/>
                
                    <br/>
                    <br/>
                </DialogContent>
                
            </Dialog>

            <Dialog fullWidth maxWidth="sm" onClose={handleCloseview} aria-labelledby="customized-dialog-title" open={openview}>
               <DialogTitle id="customized-dialog-title" onClose={handleCloseview} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Student Information
                    <IconButton onClick={handleCloseview} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>
<div style={{marginLeft:"40%"}}>
<Avatar style={{ width: 100, height: 100, border: "solid", borderColor: "#f0f0f0" }} alt={chosen.lastname} src={'https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/'+chosen.image} />
</div>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <ListItem>
                            <ListItemText primary={chosen.lastname+" "+chosen.othernames} secondary="Name" />
                        </ListItem>
                        <Divider /> 
                        <ListItem>
                            <ListItemText primary={chosen.id} secondary="Student ID" />
                        </ListItem>
                        <Divider /> 
                        <ListItem>
                            <ListItemText primary={chosen.specialID} secondary="Special ID" />
                        </ListItem>
                        <Divider /> 
                        <ListItem>
                            <ListItemText primary={chosen.index===undefined?"No Index Number":chosen.index} secondary="Index Number" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.length !==0? (months[parseInt((chosen.dob.split("-"))[1])-1] +" "+(chosen.dob.split("-"))[2]+", "+(chosen.dob.split("-"))[0] ): 0}    secondary="Date Of Birth" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={"GHC "+chosen.dailycharge} secondary="Daily Charge" />
                        </ListItem>
                        <Divider /> 
                        <ListItem>
                            <ListItemText primary={chosen.gender} secondary="Gender" />
                        </ListItem>
                        <Divider /> 
                        
                        <ListItem>
                            <ListItemText primary={chosen.class_} secondary="Class" />
                        </ListItem>
                        <Divider />  
                        
                        <ListItem>
                            <ListItemText primary={chosen.residence} secondary="Residence" />
                        </ListItem>
                        <Divider />

               
                        <ListItem>
                            <ListItemText primary={chosen.hometown} secondary="Hometown" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.guardianlastname+" "+chosen.guardianothernames} secondary="Guardian's Name" />
                        </ListItem>
                        <Divider />  
                        <ListItem>
                            <ListItemText primary={chosen.guardianrelationship} secondary="Guardian's Relationship" />
                        </ListItem>
                        <Divider />  
                        <ListItem>
                            <ListItemText primary={chosen.guardiancontact} secondary="Guardian's Contact" />
                        </ListItem>
                        <Divider />    
                        <ListItem>
                            <ListItemText primary={chosen.guardianemail} secondary="Guardian's Email" />
                        </ListItem>
                        <Divider />   
                        <ListItem>
                            <ListItemText primary={chosen.guardianresidence} secondary="Guardian's Address" />
                        </ListItem>
                        <Divider />



                    </List>

                    <br />

                    
                </DialogContent>
              
            </Dialog>

            <Dialog fullWidth maxWidth="lg" onClose={handleClosesupply} aria-labelledby="customized-dialog-title" open={opensupply}>
                
            {records(whattype)}

               
            </Dialog>

            <Dialog fullWidth maxWidth="sm" onClose={handleCloseimage} aria-labelledby="customized-dialog-title" open={openimage}>
                 <DialogTitle id="customized-dialog-title" onClose={handleCloseimage} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   {chosen.lastname+" "+chosen.othernames}
                    <IconButton onClick={handleCloseimage} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>

                <DialogContent dividers style={{ textAlign: "center" }}>

                       
                 <img src={'https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/uploads/'+chosen.image} width="100%"/>
                      
                </DialogContent>

            </Dialog>


            <Dialog  maxWidth="md" onClose={handleCloseadd} aria-labelledby="customized-dialog-title" open={openadd}>
                  <DialogTitle id="customized-dialog-title" onClose={handleCloseattendance} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    New Student
                    <IconButton onClick={handleCloseadd} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>

                    <DialogContent dividers>

                <form>

                        <div style={{ width: "100%", textAlign:"center" }}>



                            <input

                                style={{ display: "none" }}
                                id="fileimage"
                                multiple
                                type="file"
                                onChange={(e) => imagehandler(e)}
                            />
                            <label htmlFor="fileimage">
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <CameraAltIcon style={{ cursor:"pointer", backgroundColor: "darkblue", padding: 6, borderRadius: "100%", color: "white", fontSize: "26px" }} />
                                    }
                                >
                                    <Avatar style={{ width: 100, height: 100, border: "solid", borderColor: "#f0f0f0" }} alt="Travis Howard" src={person} />

                                </Badge>

                            </label>

                           
                        </div>
                        <br />

                        <Divider><small> <b>STUDENT'S PERSONAL DETAILS </b></small></Divider>
                    <br/>

                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="lastname"
                                placeholder="Surname"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Surname"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="othernames"
                                placeholder="Other name(s)"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Othername(s)"
                                size="small"



                            />
                        </FormControl>
                        <br />

                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="dob"
                                label="Date of Birth"
                                type="date"
                                defaultValue="2017-05-24"
                               
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="signup"
                                size="small"
                            />
                        </FormControl>

                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="residence"
                                placeholder="Residence Address"
                                type="address"
                                className="signup"
                                label="Residence Address"
                                size="small"



                            />
                        </FormControl>

                        <br />
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="hometown"
                                placeholder="Hometown"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Hometown"
                                size="small"



                            />
                        </FormControl>

                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Religion</InputLabel>
                            <Select
                                id="religion"
                                value={selectedreligion}
                                label="Religion"
                                className="signup"
                                variant="outlined"
                                size="small"

                                onChange={handleChange_religion}
                            >

                                <MenuItem value="none"><em>Choose Religion</em></MenuItem>
                                <MenuItem value="Christianity">Christianity</MenuItem>
                                <MenuItem value="Islam">Islam</MenuItem>
                                <MenuItem value="Jainism">Jainism</MenuItem>
                                <MenuItem value="Judaism">Judaism</MenuItem>
                                <MenuItem value="Shinto">Shinto</MenuItem>

                            </Select>


                        </FormControl>

                        <br />

                        <FormControl  style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Gender</InputLabel>
                            <Select
                                id="gender"
                                value={selectedgender}
                                label="Gender"
                                className="signup"
                                variant="outlined"
                                size="small"
                                style={{padding:0}}

                                onChange={handleChange_gender}
                            >
                                <MenuItem value="none"><em>Choose Gender</em></MenuItem>
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>

                            </Select>


                        </FormControl>

                        
                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Class</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={selectedclass}
                                label="Class"
                                className="signup"
                                variant="outlined"
                                size="small"

                                onChange={handleChange_class}
                            >
                                <MenuItem value="none"> <em>Choose Class</em> </MenuItem>
                              {Class_.length !==0 ? Class_.map((list, index)=>
                                <MenuItem value={list.classname}>{list.classname}</MenuItem>
                                
                                ):""}
                                

                            </Select>


                        </FormControl>

                        <br/>
                        <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="index"
    placeholder="Index Number"
    type="text"
    className="signup"
    variant="outlined"
    label="Index Number"
    size="small"



/>
</FormControl>                 

<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="specialID"
    placeholder="Special ID"
    type="text"
    className="signup"
    variant="outlined"
    label="Special ID"
    size="small"



/>

</FormControl>
<br/>
<FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Housing</InputLabel>
                            <Select
                                id="housing"
                                value={housing}
                                label="Housing"
                                className="signup"
                                variant="outlined"
                                size="small"

                                onChange={(e)=>sethousing(e.target.value)}
                            >

                                <MenuItem value="Day">Day</MenuItem>
                                <MenuItem value="Boarding">Boarding</MenuItem>
                                

                            </Select>


                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="dailyfee"
    placeholder="Daily Charges"
    type="number"
    className="signup"
    variant="outlined"
    defaultValue={0}
    label="Daily Charge"
    size="small"
    InputProps={{
        startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
      }}
/>
                    </FormControl>
                    <br/>
                    <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="weeklyfee"
    placeholder="Weekly Charges"
    type="number"
    className="signup"
    variant="outlined"
    defaultValue={0}
    label="Weekly Charge"
    size="small"
    InputProps={{
        startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
      }}
/>
</FormControl>
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="monthlyfee"
    placeholder="Monthly Charges"
    type="number"
    className="signup"
    variant="outlined"
    defaultValue={0}
    label="Monthly Charge"
    size="small"
    InputProps={{
        startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
      }}
/>
                    </FormControl>
                        <br/>
                        <br/>
                        <Divider><small> <b>STUDENT'S PARENTS DETAILS </b></small></Divider>

                        <p style={{textAlign:"center"}}> <b>Mother</b></p>

                     
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="motherlastname"
                                placeholder="Surname"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Surname"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="motherothernames"
                                placeholder="Other name(s)"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Othername(s)"
                                size="small"



                            />
                        </FormControl>
                        <br />

                      
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="motheroccupation"
                                placeholder="Occupation"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Occupation"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="mothercontact"
                                placeholder="Contact"
                                type="tel"
                                className="signup"
                                variant="outlined"
                                label="Contact"
                                size="small"



                            />
                        </FormControl>
                        <br />

                        
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="motheremail"
                                placeholder="Email"
                                type="email"
                                className="signup"
                                variant="outlined"
                                label="Email"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="motherresidence"
                                placeholder="Address"
                                type="address"
                                className="signup"
                                variant="outlined"
                                label="Address"
                                size="small"



                            />
                        </FormControl>
                        <br />


                        <p style={{ textAlign: "center" }}> <b>Father </b></p>

                    
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="fatherlastname"
                                placeholder="Surname"
                                type="text"
                                className="signup"
                                variant="outlined"

                                size="small"


                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="fatherothernames"
                                placeholder="Other name(s)"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Othername(s)"
                                size="small"



                            />
                        </FormControl>
                        <br />

                      
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="fatheroccupation"
                                placeholder="Occupation"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Occupation"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="fathercontact"
                                placeholder="Contact"
                                type="tel"
                                className="signup"
                                variant="outlined"
                                label="Contact"
                                size="small"

                            />
                        </FormControl>
                        <br />

                        
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="fatheremail"
                                placeholder="Email"
                                type="email"
                                className="signup"
                                variant="outlined"
                                label="Email"
                                size="small"

                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="fatherresidence"
                                placeholder="Address"
                                type="address"
                                className="signup"
                                variant="outlined"
                                label="Address"
                                size="small"

                            />
                        </FormControl>
                        <br />
                        <br />
                        <Divider><small> <b>Admission Type </b></small></Divider>
                        <br />

                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">Admission Type</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                id="guardianedit"
                                value={admission}
                                onChange={(e)=>admissionchange(e)}
                            >
                                <FormControlLabel value="new" control={<Radio />} label="New Student" />
                                <FormControlLabel value="existing" control={<Radio />} label="Existing Student" />
                                
                            </RadioGroup>
                        </FormControl>

                        <Divider><small> <b>GUARDIAN </b></small></Divider>
                        <br />

                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">Guardian Relationship</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                id="guardianedit"
                                value={guardian}
                                onChange={(e)=>guardianchange(e)}
                            >
                                <FormControlLabel value="Mother" control={<Radio />} label="Mother" />
                                <FormControlLabel value="Father" control={<Radio />} label="Father" />
                                <FormControlLabel value="Parents" control={<Radio />} label="Parents" />
                                <FormControlLabel value="Others" control={<Radio />} label="Others" />
                                
                            </RadioGroup>
                        </FormControl>
                        <br/>
                        {guardian === "Others" && 
                        
                        <div>

                        <p style={{textAlign:"center"}}>Fill this if Guardian is not a parent </p>
                        
                        <FormControl variant="standard" style={{ margin: 10 }}>
                        
                        <TextField
                            id="guardianlastname"
                            placeholder="Surname"
                            type="text"
                            className="signup"
                            variant="outlined"
                            
                            size="small"
                        
                        
                        />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>
                        
                        <TextField
                            id="guardianothernames"
                            placeholder="Other name(s)"
                            type="text"
                           
                            className="signup"
                            variant="outlined"
                            label="Othername(s)"
                            size="small"
                        
                        
                        
                        />
                        </FormControl>
                        <br />
                        
                        
                        <FormControl variant="standard" style={{ margin: 10 }}>
                        
                        <TextField
                            id="guardianoccupation"
                            placeholder="Occupation"
                            type="text"
                           
                            className="signup"
                            variant="outlined"
                            label="Occupation"
                            size="small"
                        
                        
                        
                        />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>
                        
                        <TextField
                            id="guardiancontact"
                            placeholder="Contact"
                            type="tel"
                        
                            className="signup"
                            variant="outlined"
                            label="Contact"
                            size="small"
                        
                        
                        
                        />
                        </FormControl>
                        <br />
                        
                        
                        <FormControl variant="standard" style={{ margin: 10 }}>
                        
                        <TextField
                            id="guardianemail"
                            placeholder="Email"
                            type="email"
                            
                            className="signup"
                            variant="outlined"
                            label="Email"
                            size="small"
                        
                        
                        
                        />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>
                        
                        <TextField
                            id="guardianaddress"
                            placeholder="Address"
                            type="address"
                        
                            className="signup"
                            variant="outlined"
                            label="Address"
                            size="small"
                        
                        
                        
                        />
                        </FormControl>
                        <br />
                        
                                        </div>

                        }

                        <br />

                        <div style={{ textAlign: "center" }}>
                           
                           
                        </div>

                        </form>
                    </DialogContent>

                    <DialogActions >
            {newstudentloading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => registerstudent()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Submit
                                </Button>
                            }


                      
                    </DialogActions>
                </Dialog>



            <Dialog fullWidth maxWidth="md" onClose={handleCloseaddexcel} aria-labelledby="customized-dialog-title" open={openaddexcel}>
                  <DialogTitle id="customized-dialog-title" onClose={handleCloseaddexcel} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Import Student Data 
                    <IconButton onClick={handleCloseaddexcel} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>

                    <DialogContent dividers>
<p>Download the excel formart, fill and upload to be imported into the database</p>
              <a download="Import Students' Data" type={Button} href={Excelformart}>Download Excel formart here</a>
              <br/>
              <br/>
<p ><b>Upload the Filled excel data here</b></p>
              <input type="file" onChange={(e)=>readUploadFile(e)}/>
                    
                    {/* <DropzoneArea
        onChange={(e)=>readUploadFile(e)}
        /> */}
              


                    </DialogContent>

                    <DialogActions >
            {newstudentloading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => uloadsxcel()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Import Student Data
                                </Button>
                            }


                      
                    </DialogActions>
                </Dialog>




            <Dialog maxWidth="lg" onClose={handleCloseedit} aria-labelledby="customized-dialog-title" open={openedit}>
                     <DialogTitle id="customized-dialog-title" onClose={handleCloseedit} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Edit Student Information
                    <IconButton onClick={handleCloseedit} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>

                    <DialogContent dividers>



                        <div style={{ width: "100%", textAlign: "center" }}>



                            <input

                                style={{ display: "none" }}
                                id="fileimageedit"
                                multiple
                                type="file"
                                onChange={(e) => imagehandler(e)}
                            />
                            <label htmlFor="fileimageedit">
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <CameraAltIcon style={{ cursor: "pointer", backgroundColor: "darkblue", padding: 6, borderRadius: "100%", color: "white", fontSize: "26px" }} />
                                    }
                                >
                                    <Avatar style={{ width: 100, height: 100, border: "solid", borderColor: "#f0f0f0" }} alt="Travis Howard" src={person} />

                                </Badge>

                            </label>


                        </div>
                        <br />

                        <Divider><small> <b>STUDENT'S PERSONAL DETAILS </b></small></Divider>
                        <br />
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="lastnameedit"
                                placeholder="Lastname"
                                defaultValue={chosen.length!==0 && chosen.lastname}
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Surname"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="othernamesedit"
                                placeholder="Other name(s)"
                                type="text"
                                defaultValue={chosen.length!==0 && chosen.othernames}
                                className="signup"
                                variant="outlined"
                                label="Othername(s)"
                                size="small"



                            />
                        </FormControl>
                        <br />

                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="dobedit"
                                label="Date Of Birth"
                                type="date"
                                defaultValue={chosen.length!==0 && chosen.dob}

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="signup"
                                size="small"
                            />
                        </FormControl>

                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="residenceedit"
                                placeholder="Residence Address"
                                type="address"
                                className="signup"
                                defaultValue={chosen.length!==0 && chosen.residence}
                                label="Residence Address"
                                size="small"



                            />
                        </FormControl>

                        <br />
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="hometownedit"
                                placeholder="Hometown"
                                type="text"
                                className="signup"
                                defaultValue={chosen.length!==0 && chosen.hometown}
                                variant="outlined"
                                label="Hometown"
                                size="small"



                            />
                        </FormControl>

                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Religion</InputLabel>
                            <Select
                                id="demo-simple-select-helperedit"
                                value={selectedreligionedit===""?chosen.religion:selectedreligionedit}
                                label="Religion"
                                className="signup"
                                
                                variant="outlined"
                                size="small"

                                onChange={handleChange_religionedit}
                            >
                                <MenuItem value="none"><em>Choose Religion</em></MenuItem>
                                <MenuItem value="Christianity">Christianity</MenuItem>
                                <MenuItem value="Islam">Islam</MenuItem>
                                <MenuItem value="Jainism">Jainism</MenuItem>
                                <MenuItem value="Judaism">Judaism</MenuItem>
                                <MenuItem value="Shinto">Shinto</MenuItem>

                            </Select>


                        </FormControl>

                        <br />

                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Gender</InputLabel>
                            <Select
                                id="demo-simple-select-helper_edit"
                                value={selectedgenderedit===""?chosen.gender:selectedgenderedit}
                                label="Gender"
                                className="signup"
                                variant="outlined"
                                size="small"
                                style={{ padding: 0 }}

                                onChange={handleChange_genderedit}
                            >
                                <MenuItem value="none"><em>Choose Gender</em></MenuItem>
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>

                            </Select>


                        </FormControl>


                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Class</InputLabel>
                            <Select
                                id="demo-simple-select-helper__edit"
                                value={selectedclassedit===""?chosen.class_:selectedclassedit}
                                label="Class"
                                className="signup"
                                variant="outlined"
                                size="small"
                                onChange={handleChange_classedit}
                            >
                                <MenuItem value="none"> <em>Choose Class</em></MenuItem>
                                
                                {Class_.length !==0 ? Class_.map((list, index)=>
                                <MenuItem value={list.classname}>{list.classname}</MenuItem>
                                
                                ):""}


                              
                            </Select>

                        </FormControl>

                        <br />
                        <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="indexedit"
    placeholder="Index Number"
    type="text"
    className="signup"
    variant="outlined"
    defaultValue={chosen.length!==0 && chosen.index}

    label="Index Number"
    size="small"



/>
</FormControl> 

<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="specialIDedit"
    placeholder="Special ID"
    type="text"
    className="signup"
    variant="outlined"
    defaultValue={chosen.length!==0 && chosen.specialID}

    label="Special ID"
    size="small"



/>
</FormControl>
<br/>
<FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Housing</InputLabel>
                            <Select
                                id="housingedit"
                                value={housing}
                                label="Housing"
                                className="signup"
                                variant="outlined"
                                size="small"

                                onChange={(e)=>sethousing(e.target.value)}
                            >

                                <MenuItem value="Day">Day</MenuItem>
                                <MenuItem value="Boarding">Boarding</MenuItem>
                                

                            </Select>


                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="dailyfeeedit"
    placeholder="Daily Charges"
    type="number"
    className="signup"
    variant="outlined"
    defaultValue={chosen.length!==0 && chosen.dailycharge}
    label="Daily Charge"
    size="small"
    InputProps={{
        startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
      }}
/>
                    </FormControl>

<br/>
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="weeklyfeeedit"
    placeholder="Weekly Charges"
    type="number"
    className="signup"
    variant="outlined"
    defaultValue={chosen.length!==0 && chosen.weeklyfee}
    label="Weekly Charge"
    size="small"
    InputProps={{
        startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
      }}
/>
</FormControl>
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="monthlyfeeedit"
    placeholder="Monthly Charges"
    type="number"
    className="signup"
    variant="outlined"
    defaultValue={chosen.length!==0 && chosen.monthlyfee}
    label="Monthly Charge"
    size="small"
    InputProps={{
        startAdornment: <InputAdornment position="start">GHC</InputAdornment>,
      }}
/>
                    </FormControl>
                        <br />
                        <br />

                        <Divider><small> <b>STUDENT'S PARENTS DETAILS </b></small></Divider>

                        <p style={{ textAlign: "center" }}> <b>Mother</b></p>


                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="motherlastnameedit"
                                placeholder="Surname"
                                type="text"
                                defaultValue={chosen.length!==0 && chosen.motherlastname}
                                className="signup"
                                variant="outlined"
                                label="Surname"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="motherothernamesedit"
                                placeholder="Other name(s)"
                                type="text"
                                defaultValue={chosen.length!==0 && chosen.motherothernames}
                                className="signup"
                                variant="outlined"
                                label="Othername(s)"
                                size="small"



                            />
                        </FormControl>
                        <br />


                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="motheroccupationedit"
                                placeholder="Occupation"
                                type="text"
                                defaultValue={chosen.length!==0 && chosen.motheroccupation}
                                className="signup"
                                variant="outlined"
                                label="Occupation"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="mothercontactedit"
                                placeholder="Contact"
                                type="tel"
                                defaultValue={chosen.length!==0 && chosen.mothercontact}
                                className="signup"
                                variant="outlined"
                                label="Contact"
                                size="small"



                            />
                        </FormControl>
                        <br />


                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="motheremailedit"
                                placeholder="Email"
                                type="email"
                                defaultValue={chosen.length!==0 && chosen.motheremail}
                                className="signup"
                                variant="outlined"
                                label="Email"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="motherresidenceedit"
                                placeholder="Address"
                                type="address"
                                defaultValue={chosen.length!==0 && chosen.motherresidence}
                                className="signup"
                                variant="outlined"
                                label="Address"
                                size="small"



                            />
                        </FormControl>
                        <br />


                        <p style={{ textAlign: "center" }}> <b>Father </b></p>


                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="fatherlastnameedit"
                                placeholder="Surname"
                                type="text"
                                defaultValue={chosen.length!==0 && chosen.fatherlastname}
                                className="signup"
                                variant="outlined"
                                size="small"

                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="fatherothernamesedit"
                                placeholder="Other name(s)"
                                type="text"
                                defaultValue={chosen.length!==0 && chosen.fatherothernames}
                                className="signup"
                                variant="outlined"
                                label="Othername(s)"
                                size="small"
                            />
                        </FormControl>
                        <br />


                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="fatheroccupationedit"
                                placeholder="Occupation"
                                type="text"
                                defaultValue={chosen.length!==0 && chosen.fatheroccupation}
                                className="signup"
                                variant="outlined"
                                label="Occupation"
                                size="small"

                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="fathercontactedit"
                                placeholder="Contact"
                                type="tel"
                                defaultValue={chosen.length!==0 && chosen.fathercontact}
                                className="signup"
                                variant="outlined"
                                label="Contact"
                                size="small"



                            />
                        </FormControl>
                        <br />


                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="fatheremailedit"
                                placeholder="Email"
                                type="email"
                                defaultValue={chosen.length!==0 && chosen.fatheremail}
                                className="signup"
                                variant="outlined"
                                label="Email"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="fatherresidenceedit"
                                placeholder="Address"
                                type="address"
                                defaultValue={chosen.length!==0 && chosen.fatherresidence}
                                className="signup"
                                variant="outlined"
                                label="Address"
                                size="small"



                            />
                        </FormControl>
                        <br />
                        <br />

                        <Divider><small> <b>GUARDIAN </b></small></Divider>
                        <br />

                        <FormControl>
                            {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                id="guardianedit"
                                value={guardianedit===""?chosen.guardianrelationship:guardianedit}
                                onChange={guardianchangeedit}
                            >
                                <FormControlLabel value="Mother" control={<Radio />} label="Mother" />
                                <FormControlLabel value="Father" control={<Radio />} label="Father" />
                                <FormControlLabel value="Parents" control={<Radio />} label="Parents" />
                                <FormControlLabel value="Others" control={<Radio />} label="Others" />
                                
                            </RadioGroup>
                        </FormControl>
                        <br/>
                        {guardianedit === "Others" && 
                        
                        <div>

                        <p style={{textAlign:"center"}}>Fill this if Guardian is not a parent </p>
                        
                        <FormControl variant="standard" style={{ margin: 10 }}>
                        
                        <TextField
                            id="guardianlastnameedit"
                            placeholder="Surname"
                            type="text"
                            className="signup"
                            variant="outlined"
                            defaultValue={chosen.length!==0 && chosen.guardiansurnameedit}

                            size="small"
                        
                        
                        />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>
                        
                        <TextField
                            id="guardianothernamesedit"
                            placeholder="Other name(s)"
                            type="text"
                            defaultValue={chosen.length!==0 && chosen.guardianothernames}

                            className="signup"
                            variant="outlined"
                            label="Othername(s)"
                            size="small"
                        
                        
                        
                        />
                        </FormControl>
                        <br />
                        
                        
                        <FormControl variant="standard" style={{ margin: 10 }}>
                        
                        <TextField
                            id="guardianoccupationedit"
                            placeholder="Occupation"
                            type="text"
                            defaultValue={chosen.length!==0 && chosen.guardianoccupation}

                           
                            className="signup"
                            variant="outlined"
                            label="Occupation"
                            size="small"
                        
                        
                        
                        />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>
                        
                        <TextField
                            id="guardiancontactedit"
                            placeholder="Contact"
                            type="tel"
                            defaultValue={chosen.length!==0 && chosen.guardiancontact}

                            className="signup"
                            variant="outlined"
                            label="Contact"
                            size="small"
                        
                        
                        
                        />
                        </FormControl>
                        <br />
                        
                        
                        <FormControl variant="standard" style={{ margin: 10 }}>
                        
                        <TextField
                            id="guardianemailedit"
                            placeholder="Email"
                            type="email"
                            defaultValue={chosen.length!==0 && chosen.guardianemail}

                            className="signup"
                            variant="outlined"
                            label="Email"
                            size="small"
                        
                        
                        
                        />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>
                        
                        <TextField
                            id="guardianaddressedit"
                            placeholder="Address"
                            type="address"
                            defaultValue={chosen.length!==0 && chosen.guardianaddress}

                            className="signup"
                            variant="outlined"
                            label="Address"
                            size="small"
                        
                        
                        
                        />
                        </FormControl>
                        <br />
                        
                                        </div>
                        }

                        <br />
                        <div style={{ textAlign: "center" }}>

                          


                        </div>
                    </DialogContent>

                    <DialogActions>
                     {editstudentloading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => editstudent()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Submit
                                </Button>
                            }
                       
                    </DialogActions>
                </Dialog>
         

            <Paper sx={{ width: '100%', mb: 2 }}>

                <EnhancedTableToolbar numSelected={selected.length} />
                <JsonToExcel  title="Download Excel"  data={exportdata} fileName="Student Data" btnClassName="custom-classname" />
                
                <Tooltip title="Import Student">
                        <Button
                            color="inherit"
                            aria-label="open drawer"
                                edge="start"
                                variant="outlined"
                                color="primary"
                                style={{marginTop:10.5}}
                            onClick={handleClickOpenaddexcel}
                        >
                                {/* <PersonAddAlt1Icon fontSize="medium" />  */}
                                Import student Data 
                        </Button>
                    </Tooltip>
                <Grid container>
                    <Grid lg={1} xs={2} md={6}>
                        <Tooltip title="Add Student">
                        <Button
                            color="inherit"
                            aria-label="open drawer"
                                edge="start"
                                variant="outlined"
                                color="primary"
                                style={{marginTop:10.5}}
                            onClick={handleClickOpenadd}
                        >
                                <PersonAddAlt1Icon fontSize="medium" />
                        </Button>
                    </Tooltip>
                    </Grid>
                  
                    <Grid lg={2} xs={2} md={6} style={{textAlign:"left"}}>
                        <FormControl style={{ marginRight: 2, minWidth: 80, padding: 0, marginTop: 10, borderColor: "#2196f3" }}>
                            <Select
                                id="demo-simple-select-autowidth"
                                value={sortclass}
                                onChange={handleChangesortclass}
                                autoWidth
                                varient="primary"
                                style={{ height: "38px", width: "100%", padding: 0, color: "grey" }}
                            >
                               <Container>
                               <p align="center" style={{margin:5}} ><b>Student Type</b></p>
                               <Divider/>
                               <FormGroup style={{marginTop:10, marginBottom:10}}>
                                    <FormControlLabel control={<Switch checked={activestudents} onChange={(e)=>changestudenttype(e,"Active")} />} label="Active" />
                                    <FormControlLabel control={<Switch checked={completestudents} onChange={(e)=>changestudenttype(e,"Completed")}/>} label="Completed" />
                                    <FormControlLabel control={<Switch checked={withdrawnstudents}  onChange={(e)=>changestudenttype(e,"Withdrawn")}/>} label="Withdrawn" />
                                    </FormGroup>
                                 
                                    <p align="center" style={{margin:5}} ><b>Sort by class</b></p>
                                    <Divider/>
                               </Container>

                                <MenuItem value="none">Sort Students</MenuItem>
                                <MenuItem value="All">All Classes</MenuItem>
                                {Class_.length !==0 ? Class_.map((list, index)=>
                                <MenuItem value={list.classname}>{list.classname}</MenuItem>
                                
                                ):""}
                                


                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid lg={3} xs={10} md={6}>
                        <Search id="searchbox"  style={{ position: "absolute", marginTop: "18px", marginLeft: "8px", color:"grey" }} /> <input type="search" onChange={(e) => searching(e)} placeholder="Search" style={{ padding: 10, paddingLeft: "38px", marginTop: 10, width: "100%", border: "solid", borderRadius: "5px", borderWidth: "0.5px", backgroundColor: "rgba(65,105,225, 0.05) ", borderColor: "#2196f3" }} />
                    </Grid>

                    <Grid lg={5} sm={12} md={12}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
                <br />
                <Divider />
                <TableContainer>
                    <Table

                        sx={{ minWidth: "100%" }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >

                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />

                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover

                                        >
                                            <TableCell style={{ textAlign: "center" }}>
                                                <span>
                                                    {(index + 1 + (page * rowsPerPage)) + "."}
                                                </span>
                                            </TableCell>
                                            <TableCell align="left">{row.id}</TableCell>

                                            <TableCell   component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                                align="left"
                                            >
                                              
                                                <ListItem align="left">
                                                    <ListItemAvatar>
                                                        <Avatar src={'https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/'+row.image} size="small" alt={row.lastname+" "+ row.othernames} onClick={() => handleClickOpenimage(row)} style={{cursor:"pointer"}}>
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary={row.lastname+" "+ row.othernames}  />
                                                </ListItem>
                                            </TableCell>
                                            <TableCell align="left">{row.status==="Active" ? <span style={{padding:8, borderRadius:"20px", backgroundColor:"#13A518", color:"white"}}>{row.status}</span>: row.status==="Completed"?  <span style={{padding:8, borderRadius:"20px", backgroundColor:"blue", color:"white"}}>{row.status}</span> : <span style={{padding:8, borderRadius:"20px", backgroundColor:"red", color:"white"}}>{row.status}</span>}</TableCell>
                                            <TableCell align="left">{row.class_}</TableCell>
                                            <TableCell align="left">{row.gender}</TableCell>
                                            <TableCell align="left">{row.dob? (months[parseInt((row.dob.split("-"))[1])-1] +" "+(row.dob.split("-"))[2]+", "+(row.dob.split("-"))[0]):0}</TableCell>
                                            <TableCell align="left">{row.residence} </TableCell>
<div style={{display:"none"}}>                                            {exporter.push({Student_ID:row.id, Name:row.lastname+" "+ row.othernames, Class:row.class_, Gender:row.gender, Address:row.residence,Guardian_name:row.guardianlastname+" "+ row.guardianothernames, Guardian_contact:row.guardiancontact })}
</div>
                                            <TableCell align="left">{<Menustyle data={row} view={(sender) => handleClickOpenview(sender)} attendance={(sender) => handleClickOpenattendance(sender)}  academics={(sender) => handleClickOpensupply(sender)} message={(sender) => handleClickOpenmessage(sender)} addsupply={(sender) => handleClickOpensupply(sender)} edit={(sender) => handleClickOpenedit(sender)} delete={(sender) => handleClickOpendelete(sender)}  status={(sender) => handleClickOpenstatus(sender)}  />}</TableCell>

                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>


                    </Table>
                </TableContainer>
                {exportdata.length===0 && rows.length!==0 && setexportdata(exporter)}

            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />

        </Box>


    );
}
