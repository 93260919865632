import { createSlice } from "@reduxjs/toolkit"
let data = { user: "jngissah" }

if (localStorage.getItem("user_sis") !== null && localStorage.getItem("user_sis") !== undefined && localStorage.getItem("user_sis") !== "") {
    data = JSON.parse(localStorage.getItem("user_sis"))
}

const Insider = createSlice({

    name: "todos",
    initialState: data,
    reducers: {
        login: (state, action) => {
            console.log(action.payload.Id)
            return state
        }


    },

})

export const { login } = Insider.actions;
export default Insider.reducer;