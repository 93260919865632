import React, {useEffect} from 'react';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Search from '@mui/icons-material/Search';
import Close from '@mui/icons-material/Close';
import Backimage from './images/anouncement.svg';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';



import Snack from './snack';
import './button.css';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton'
import Imager from './images/Wallet.svg'
import axios from 'axios'
import AlldoneIcon from '@mui/icons-material/TaskAlt';
import io from "socket.io-client";
import { PaystackButton } from 'react-paystack'

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { seedetails, getproducts } from './redux/todoSlice';
import { announcement_ } from './redux/announcement';
import { loin, logout, updateteacherdata, getdatateacher } from './redux/teachers';
import {  admin } from './redux/admin';
import {  schooldetails } from './redux/schooldetails';

import Stack from '@mui/material/Stack';
import { capitalize } from '@mui/material';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const socket = io.connect("https://servermain.sisrevolution.com")




function Profile() {

  const pricer={"50-200":"https://paystack.com/pay/sisrevolution","201-400":"https://paystack.com/pay/sisrevolution201-400", "401-600":"https://paystack.com/pay/sisrevolution401-600","601-800":"https://paystack.com/pay/sisrevolution601-800", "801:1000":"https://paystack.com/pay/sisrevolution801-1000","1001-1200":"https://paystack.com/pay/sisrevolution100-1200","1201-1400":"https://paystack.com/pay/sisrevolution1201-1400", "1401-1600":"https://paystack.com/pay/1401t01600students", "1601-1800":"https://paystack.com/pay/1601to1800students", "1801-2000":"https://paystack.com/pay/1801to2000students","2001-2200":"https://paystack.com/pay/2001to2200students","2201:2400":"https://paystack.com/pay/2201to2400students","2401-2600":"https://paystack.com/pay/2401to2600students","2601-2800":"https://paystack.com/pay/2601to2800students","2801-3000":"https://paystack.com/pay/2801to3000students" }

  const pricing={"50-200":"450","201-400":"750", "401-600":"1110","601-800":"1400", "801-1000":"1700","1001-1200":"2050","1201-1400":"2350", "1401-1600":"2650", "1601-1800":"2950", "1801-2000":"3250", "2001-2200":"3550","2201:2400":"3850","2401-2600":"4150","2601-2800":"4450","2801-3000":"4750"}

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

  const dispatch = useDispatch()
  const [preloading, setpreloading] = React.useState(true);

  const dt = new Date()
  const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
  const day = dt.getUTCDate();
  const month = String(dt.getMonth() + 1).padStart(2, '0');
  const year = dt.getFullYear();
  const date = year + "-" + month + "-" + day;


  const students = useSelector((state) => state.students)
  const admin = useSelector((state) => state.admin)
  const schooldetails_ = useSelector((state) => state.schooldetails)
  const charge = schooldetails_[0].charge!==undefined? parseFloat(schooldetails_[0].charge):1.3
  const schooldata= async () => {
      await axios.post("https://servermain.sisrevolution.com/api/schools/getschooldata/",
          {
              schoolID:admin.schoolID

          }).then(res => {

              if (res.status === 200) {

                  dispatch(schooldetails({

                      data: res.data

                  }))


              }

          })

  }



  const gettingdata = async () => {
    await axios.post("https://servermain.sisrevolution.com/api/announcement/getannouncement/",
        {
            schoolID:admin.schoolID

        }).then(res => {

            if (res.status === 200) {

                dispatch(announcement_({

                    announcement: res.data

                }))


            }

        })

}






useEffect(()=>{
    schooldata()
    gettingdata()
},[preloading])

const announcement = useSelector((state) => state.announcement_)

console.log(students.length)
  const [opensnack, setOpensnack] = React.useState(false);

  const [chat, setchat] = React.useState([]);
  const [prechat, setprechat] = React.useState([]);

  const [username, setusername]= React.useState("Joseph")
  const [room, setroom]= React.useState("room1")
  const [smsloading, setsmsloading]= React.useState(!true)
  const [typer, settyper]= React.useState("")


    const [message, setmessage] = React.useState("");
    const [stated, setstated] = React.useState("");

    // var val = (parseFloat(students.length*charge)*100).toFixed(2)

    const [openannounce, setOpenannounce] = React.useState(!true);
    const [loading, setloading] = React.useState(!true);

    const [openbulk, setOpenbulk] = React.useState(!true);
    const [openview, setOpenview] = React.useState(!true);
    const [chosen, setchosen] = React.useState([]);
    const publicKey = "pk_live_be3b6346afcda4aa307dc2b45ec8eb29e8486944"
    // const amount= (parseFloat(students.length)*100 *charge).toFixed(2)

    var amount_ =(parseFloat(students.length)*100 *charge).toFixed(2)
    var amount = schooldetails_[0].period!==undefined && schooldetails_[0].period!=="" && schooldetails_[0].period!=="monthly"?(amount_*3.5).toFixed(2):amount_
    
    const [email, setEmail] = React.useState(schooldetails_.length!==0?schooldetails_[0].email:"")
    const [name, setName] = React.useState(schooldetails_.length!==0?schooldetails_[0].schoolname:"")
    const [phone, setPhone] = React.useState(schooldetails_.length!==0?schooldetails_[0].contact:"")
    const [currency, setcurrency] = React.useState("GHS")
  
  
  
    const confirmpayment=()=>{
      // e.preventDefault()
      var datepaid=date
      var year_=parseInt(year)
      var month_=parseInt(month)
      var amount_ =(parseFloat(students.length)*100 *charge).toFixed(2)
      var amount = schooldetails_[0].period!==undefined && schooldetails_[0].period!=="" && schooldetails_[0].period!=="monthly"?(amount_*3.5).toFixed(2):amount_
      if(month_===12){
        year_=year_+1
        if(schooldetails_[0].period!==undefined && schooldetails_[0].period!=="" && schooldetails_[0].period!=="monthly"){
          month_=4
        }else{
        month_=1
        }
      }else{
        if(schooldetails_[0].period!==undefined && schooldetails_[0].period!=="" && schooldetails_[0].period!=="monthly"){
          month_=month_+4
        }else{
        month_=month_+1
        }
      }

      var next = year_ + "-" + month_ + "-" + day;
      var datehere=datepaid.split("-")
      var finalamount=(amount/100).toFixed(2)
      var data = {amount:finalamount, month:months[parseInt(datehere[1])-1], year:datehere[0], day:datehere[2],nextmonth:months[parseInt(datehere[1])-1]==="December"?"January":months[parseInt(datehere[1])],nextyear:months[parseInt(datehere[1])-1]==="December"?year+1:year, nextday:datehere[2], next:next, date:date, time:time, transactionID:"", term:schooldetails_[0].terms[schooldetails_[0].terms.length-1]}
      const buyingpolicy = async () => {
  
          await axios.post("https://servermain.sisrevolution.com/api/schools/paysystem/", {
                     
           data:data,
           amount:amount,
          _id:schooldetails_[0]._id
          
                      }).then(res => {
  
                  if (res.status === 200) {
                    schooldata()
                      setloading(!true)
                      // e.target.reset()
                      handleClicksnack()
                      setmessage("Payment made")
                      setstated("success")
                      setsmsloading(!true)
  
                  } else {
                      setloading(!true)
                      handleClicksnack()
                      setmessage("Error making payment")
                      setstated("error")
                  }
  
  
  
              })
  
      }
  
      buyingpolicy()
  
  }
  
  
  

    const componentProps = {
        email,
        amount,
        currency,
        metadata: {
            name,
            phone,
        },
        publicKey,
        text: "MAKE PAYMENT",
        onSuccess: () => confirmpayment(),
        onClose: () => alert("Please make payment before leaving"),
    }
  
  

  
    const handleClickOpenannounce = (data) => {
       
        setOpenannounce(true);
    };

      const handleClicksnack = () => {
        setOpensnack(true);
    };

      const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
                }
        setOpensnack(false);
    };

    const handleCloseannounce = () => {
        setOpenannounce(false);
    };

    const sendannouncement=()=>{

        const subject = document.getElementById("subject").value
        const message_ = document.getElementById("message_").value

        const sender = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/announcement/addannouncement/",
                {
                    message:message_,
                    subject:subject,
                    date:date,
                    time:time,
                    schoolID:admin.schoolID
    
                }).then(res => {
    
                    if (res.status === 200) {
    
                        dispatch(announcement_({

                            announcement: res.data
        
                        }))

                        handleCloseannounce()
                        handleClicksnack()
                        setmessage("Announcement Sent")
                        setstated("success")
                        setsmsloading(!true)
    
    
                    }else{
                        handleClosebulk()
                        handleClicksnack()
                        setmessage("Error sending announcement")
                        setstated("error")
                                setsmsloading(!true)
                    }
    
                })
    
        }
        sender()



        

    }

    const handlebulksms=()=>{

                    setsmsloading(true)
                       
                    const message = document.getElementById("smsdata").value
                   
                
                    const fd = new FormData()
                  
               
                    fd.append("schoolID",admin.schoolID)
                    fd.append("message", message)
                    const uploading = async() => {
                        await axios.post("https://servermain.sisrevolution.com/api/students/bulksms/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }


                        }).then(res => {

                            if (res.status === 200) {
                               
                        handleClosebulk()
                        handleClicksnack()
                        setmessage("SMS sent")
                        setstated("success")
                                setsmsloading(!true)

                            }
                            if (res.status === 204) {
                                handleClosebulk()
                        handleClicksnack()
                        setmessage("Error Sending SMS")
                        setstated("success")
                                setsmsloading(!true)
                            }

                        })

                    }


                    uploading()



                

    }

    const handleview=()=>{

                    setsmsloading(true)
                       
                    const message = document.getElementById("smsdata").value
                   
                
                    const fd = new FormData()
                  
               
                    fd.append("schoolID",admin.schoolID)
                    fd.append("message", message)
                    const uploading = async() => {
                        await axios.post("https://servermain.sisrevolution.com/api/students/bulksms/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }


                        }).then(res => {

                            if (res.status === 200) {
                               
                        handleClosebulk()
                        handleClicksnack()
                        setmessage("SMS sent")
                        setstated("success")
                                setsmsloading(!true)

                            }
                            if (res.status === 204) {
                                handleClosebulk()
                        handleClicksnack()
                        setmessage("Error Sending SMS")
                        setstated("success")
                                setsmsloading(!true)
                            }

                        })

                    }


                    uploading()



                

    }

  const handleClickOpenbulk = (data) => {
       
        setOpenbulk(true);
    };

    const handleClosebulk = () => {
        setOpenbulk(false);
    };


 const handleClickOpenview = (data) => {
       setchosen(data)
        setOpenview(true);
    };

    const handleCloseview = () => {
        setOpenview(false);
    };


    const trying=()=>{
      const data = {  "customer": "customer@email.com", "plan": "PLN_ahtk74jl3tasals" }
      const uploading = async() => {
        await axios.post("https://servermain.sisrevolution.com/api/schools/bill/", {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }

                        }).then(res => {

         console.log(res)

        })

    }

    uploading()

    }


    return (
        <div style={{height:"100%"}} >

            <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    { message }
                </Alert>
            </Snackbar>

            <Dialog fullWidth maxWidth="sm" onClose={handleCloseannounce} aria-labelledby="customized-dialog-title" open={openannounce}>
            <DialogTitle id="customized-dialog-title" >
            <span style={{fontSize:20, marginLeft:30, color:"grey"}}>Announcement</span>
                    <IconButton aria-label="" onClick={handleCloseannounce} style={{float:"right"}} >
                      <Close/>
                    </IconButton>
            </DialogTitle>
            <DialogContent>
      
<div align="center">

<FormControl variant="standard" style={{ margin: 10 , width:"90%"}}>

                            <TextField
                                id="subject"
                                placeholder="Subject"
                                type="text"
                                style={{width:"100%"}}
                                variant="outlined"
                                size="small"



                            />
                        </FormControl>
<br/>
 <FormControl variant="standard" style={{ margin: 10, width:"90%" }}>

                            <textarea
                                id="message_"
                                placeholder="Message"
                                rows={5}
                                style={{width:"100%", padding:8, borderRadius:5, border:"solid", borderColor:"lightgrey", fontSize:18, borderWidth:"0.2px"}}
                                variant="outlined"
                                label="message"
                               



                            />
                        </FormControl>

</div>



                </DialogContent>
                <Divider/>
                <DialogActions style={{textAlign:"center"}}>

 {loading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => sendannouncement()} color="primary">
                                    Submit
                                </Button>
                            }



                </DialogActions>
            </Dialog>
            

            <Dialog fullWidth maxWidth="sm" onClose={handleClosebulk} aria-labelledby="customized-dialog-title" open={openbulk}>
            <DialogTitle id="customized-dialog-title">
            <span style={{fontSize:20, marginLeft:30, color:"grey"}}>Bulk SMS to Guardians</span>
                    <IconButton aria-label="" onClick={handleClosebulk} style={{float:"right"}} >
                      <Close/>
                    </IconButton>
            </DialogTitle>
            <DialogContent> 
                 
<div align="center">


<FormControl variant="standard" style={{ margin: 10, width:"90%" }}>

                            <textarea
                                id="smsdata"
                                placeholder="Enter message"
                                rows={5}
                                style={{width:"100%", padding:8, borderRadius:5, border:"solid", borderColor:"lightgrey", fontSize:18, borderWidth:"0.2px"}}
                                variant="outlined"
                                label="message"
                               



                            />
                        </FormControl>

</div>



                </DialogContent>
                <Divider/>
                <DialogActions style={{textAlign:"center"}}>

                 
 <Button autoFocus onClick={handlebulksms} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                        Submit
                    </Button>
                   
                </DialogActions>
            </Dialog>
            


            <Dialog fullWidth maxWidth="sm" onClose={handleCloseview} aria-labelledby="customized-dialog-title" open={openview}>
            <DialogTitle id="customized-dialog-title" >
            <span style={{fontSize:20, marginLeft:30, color:"grey"}}>Payment Details</span>
                    <IconButton aria-label="" onClick={handleCloseview} style={{float:"right"}} >
                      <Close/>
                    </IconButton>
            </DialogTitle>
            <DialogContent>

                 {/* {chosen.map((list,index)=>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt={list.name} src="/static/images/avatar/1.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary={list.name}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {list.date}
              </Typography>
              {" - "+list.time}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
   
    </List>
                 )
} */}

<Container>


<ListItemText
          primary={chosen.length!==0 && "GHC "+ Intl.NumberFormat('en-US').format(chosen.amount)}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
               
              >
                 Amount Paid
              </Typography>
          
            </React.Fragment>
          }
        />
<Divider/>
<ListItemText
          primary={chosen.length!==0 && months[parseInt((chosen.date.split("-"))[1])-1] +" "+(chosen.date.split("-"))[2]+", "+(chosen.date.split("-"))[0] }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
               
              >
                 Date paid
              </Typography>
          
            </React.Fragment>
          }
        />
        <Divider/>
       
<ListItemText
          primary={chosen.length!==0 && months[parseInt((chosen.date.split("-"))[1])-1] +" "+(chosen.date.split("-"))[2]+", "+(chosen.date.split("-"))[0] }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
               
              >
                 Next Payment Date
              </Typography>
          
            </React.Fragment>
          }
        />

</Container>

                </DialogContent>
                <DialogActions style={{textAlign:"center"}}>


                </DialogActions>
            </Dialog>
            


           <Container style={{backgroundColor:"white", }}>
            <br/>
           <Typography
                sx={{ flex: '1 1 100%', }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
               PRICING
            </Typography>
            <br/>
<Grid container spacing={2} >
 
<Grid item xs={7}>
    <div style={{border:"none", borderWidth:"0.1px", borderColor:"lightgrey", borderRadius:"10px"}}>

        <div style={{paddingLeft:"12%", paddingRight:"12%"}}>

<div style={{backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, textAlign:"center", color:"white", borderRadius:"8px", padding:"8px"}}>
  
<div align="center">

{/* <Button onClick={trying} style={{color:"white"}}>try</Button> */}

{/* <Button onClick={handleClickOpenannounce} style={{margin:6,backgroundColor:" #e0eff9" , color:"#1976d2" }}> View Payment Method </Button> */}

{/* <a target="_blank" href={schooldetails_.length!==0 && pricer[schooldetails_[0].studentpopulation+""]}><Button style={{margin:6,backgroundColor:" #e0eff9" , color:"#1976d2"}}>Payment Subscription </Button></a> */}

<PaystackButton {...componentProps }    />

</div>
<div style={{textAlign:"center"}}>

  <img  src={Imager} width="60%" />

</div>


  <h1 style={{margin:0, marginTop:"-10px"}}> GHC {schooldetails_.length!==0 && Intl.NumberFormat('en-US').format(schooldetails_[0].period!==undefined && schooldetails_[0].period!=="" && schooldetails_[0].period!=="monthly"?(students.length*charge)*3.5:students.length*charge)} <span style={{fontSize:"14px"}}>/ {schooldetails_[0].period!==undefined && schooldetails_[0].period!=="" && schooldetails_[0].period!=="monthly"?"Term":"Month"}</span></h1>
<Divider/>
<div style={{textAlign:"left"}}>
  <p>
  
       
       
            <List dense={true}>
              
                <ListItem>
                  <ListItemIcon>
                 <AlldoneIcon style={{color:"lightgray"}}/>
                  </ListItemIcon>
                  <ListItemText
                  style={{marginLeft:"-20px"}}
                    primary="Signup with SIS Revolution and pay monthly or termly"
                    // secondary={'Secondary text'}
                  />
                </ListItem>   
                
                <ListItem>
                  <ListItemIcon>
                 <AlldoneIcon style={{color:"lightgray"}}/>
                  </ListItemIcon>
                  <ListItemText
                  style={{marginLeft:"-20px"}}
                    primary="Signup with your Visa Card, Mastercard or Mobile Money"
                    // secondary={'Secondary text'}
                  />
                </ListItem>  
                <ListItem>
                  <ListItemIcon>
                 <AlldoneIcon style={{color:"lightgray"}}/>
                  </ListItemIcon>
                  <ListItemText
                  style={{marginLeft:"-20px"}}
                    primary="Get access to all the features"
                    // secondary={'Secondary text'}
                  />
                </ListItem> 
                <ListItem>
                  <ListItemIcon>
                 <AlldoneIcon style={{color:"lightgray"}}/>
                  </ListItemIcon>
                  <ListItemText
                  style={{marginLeft:"-20px"}}
                    primary="Get free router"
                    // secondary={'Secondary text'}
                  />
                </ListItem> 
               
            
            </List>
         
       
    

  </p>



</div>

</div>

</div>

    <div>

<br/>
<br/>
    </div>
        




    </div>



</Grid>

<Grid item xs={5}>
    <div  style={{border:"solid", borderWidth:"0.1px", borderColor:"lightgrey", borderRadius:"10px", marginBottom:40}}>
    <p style={{}}><b>PAYMENT HISTORY</b></p>
<Divider/>
   <Search style={{marginRight:"-40px", paddingLeft:10, marginTop:5, position:"absolute", fontSize:"32px", color:"gray" }}/><input type="search" placeholder='search' style={{padding:10,paddingLeft:40, fontSize:"18px", color:'gray', borderRadius:"10px",border:"none", borderWidth:"0.1px", borderColor:"lightgrey", width:"90%" }}/>
    <Divider/>


    <div className='scroller3' style={{overflowY:"scroll", height:"450px"}}>





<div align="left" style={{margin:8}}>
    <div style={{border:"none", borderWidth:"0.1px", borderColor:"lightgrey", borderRadius:"10px", padding:5, marginTop:8}}>
       
    <Grid container>


                            <Grid item xs={12}> 
                            {schooldetails_.length!==0 && schooldetails_[0].payment.map((list, index)=>
                            list.amount!=="trial"?
                                  <div onClick={()=>handleClickOpenview(list)} style={{cursor:"pointer"}}>
                                <ListItemText
                                primary= {"GHC "+ Intl.NumberFormat('en-US').format(list.amount)}
                                secondary= {months[parseInt((list.date.split("-"))[1])-1] +" "+(list.date.split("-"))[2]+", "+(list.date.split("-"))[0] }
                               
                              />
                               <Divider/>
                                </div>
                                :""
                            )
                                }
                              
                             </Grid>

    </Grid>
    </div>
</div>





    </div>





    
    
    </div>
</Grid>
</Grid>
</Container>
        </div>
    );
}

export default Profile;
