import * as React from 'react';
import Table from './tableworkers';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Tablereport from './tablereportprint';
import Person from './images/Ellipse77.png';
import Logo from './images/FS229201First School.jpg';
import Avatar from '@mui/material/Avatar';
import Imagereplace from './images/Ellipse77.png';
import { useSelector } from 'react-redux';

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";


class Printpage extends React.Component {

    render(){

  const choose = this.props.data
  const students = this.props.students
  const selectedterm = this.props.term
  const Class_ = this.props.Class_
  const schooldata = this.props.schooldata[0]
  const dt = new Date()
  const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
  const day = dt.getUTCDate();
  const month = String(dt.getMonth() + 1).padStart(2, '0');
  const year = dt.getFullYear();
  const date = year + "-" + month + "-" + day;
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    
  var totalothers=0

  const adder=(value)=>{
totalothers += parseFloat(value)
  }
  
  return (
        <div>

{students.map((chosen, index)=>
        <div >
        <Container style={{padding:0}} className='pagebreak' >
     
                <div style={{marginTop:"10px"}}> 

                <div style={{textAlign:"center"}}>
             <br/>
                <Grid container style={{marginBottom:"10px"}}>
<Grid xs={8}>
  
<img src={"https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/"+schooldata.image} width="80px" alt="schoologo"/> <br/>
    <h4 style={{margin:4}}>  {(schooldata.schoolname).toUpperCase()} </h4>
    <h5 style={{margin:4}}>  {(schooldata.address).toUpperCase()} </h5>
    <h5 style={{margin:4}}>  {(schooldata.contact)} </h5>


</Grid>
<Grid xs={4} style={{borderLeft:"solid", borderWidth:"0.1px", borderColor:"lightgray", textAlign:"center"}}>
    <div style={{textAlign:"center",  alignItems:"center", display:"flex", justifyContent:"center", width:"100%"}}>
{/* <Avatar style={{  width:100, height:100, border: "solid", borderWidth:"0px", borderColor: "white", marginTop:5, marginBottom:"8px" }} alt={chosen.lastname} src={"https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/"+chosen.image} /> */}
<Avatar style={{  width:100, height:100, border: "solid", borderWidth:"0px", borderColor: "white", marginTop:5, marginBottom:"8px" }} alt={chosen.lastname} src={"https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/"+chosen.image ? "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/"+chosen.image:Imagereplace} />
</div>
<div>
 <small> 
            <b>{chosen.lastname&&(chosen.lastname).toUpperCase()+" "+chosen.othernames && (chosen.othernames).toUpperCase()}</b> <br/>
          
            <b>ID : {chosen.ID}</b>
            </small>
            </div>
          
</Grid>
                </Grid>
<Divider/>
<br/>
            <Grid container spacing={0}>
            <Grid item xs={7} align="left" style={{paddingLeft:0}}>
            <span><b>Class: </b>{chosen.class_} </span> <br/>
            <span><b>Number On Roll: </b>{students.length!==0?students.filter(bug=>bug.class_===chosen.class_).length:0} </span> <br/>
           <span><b>Date: </b>{months[parseInt((date.split("-"))[1])-1] +" "+(date.split("-"))[2]+", "+(date.split("-"))[0] }</span>
            </Grid>  

            <Grid item xs={5} align="left">
          
            
            <span><b>Term: </b>{selectedterm}</span> <br/>
            <span><b>Re-opening Date:</b> {schooldata.reopendate!==""?months[parseInt((schooldata.reopendate.split("-"))[1])-1] +" "+(schooldata.reopendate.split("-"))[2]+", "+(schooldata.reopendate.split("-"))[0]:"Set Re-opening Date"} </span>
            </Grid>

            </Grid>

                </div>

                   </div> 
                   {console.log(chosen)}
                    <Tablereport data={selectedterm !== "none" ?chosen!==undefined?chosen.academics.filter(bug=> bug.term === selectedterm):[]:[]} />

    <Divider/>
<br/>
<Grid container spacing={0}>

<Grid item xs={8}>
<b>Total Score: {chosen!==undefined && chosen.length!==0?selectedterm !== "none" && selectedterm !== undefined ? chosen.academic!==undefined &&  chosen.academics.length!==0 && chosen.academics.filter(bug=> bug.term === selectedterm).length !==0? chosen.academics.length!==0 && chosen.academics.filter(bug=> bug.term === selectedterm)[0].totalscore:0:0:0}</b><br/>
{console.log("filter")}
{console.log(Class_)}
{console.log(chosen)}
{Class_.filter(bug=>bug.classname === chosen.class_).length!==0 ? Class_.filter(bug=>bug.classname === chosen.class_)[0].aggregate===true && <b>Aggregate: {chosen!==undefined?selectedterm !== "none" && selectedterm !== undefined && chosen.academics.length!==0 && chosen.academics.filter(bug=> bug.term === selectedterm).length !==0 ? selectedterm !== undefined &&  chosen.academics.filter(bug=> bug.term === selectedterm)[0].aggregate :0:0}</b>:0}
</Grid>

<Grid item xs={4}>
{Class_.filter(bug=>bug.classname === chosen.class_).length!==0 && Class_.filter(bug=>bug.classname === chosen.class_)[0].position===true && <b style={{color:"red"}}>Position in class: { chosen!==undefined?selectedterm !== "none" && selectedterm !== undefined && chosen.academics.length!==0 && chosen.academics.length!==0 && chosen.academics.filter(bug=> bug.term === selectedterm).length !==0 ?  chosen.academics.filter(bug=> bug.term === selectedterm)[0].position:"-":0} <br/></b>}

<b>Promoted to: {chosen!==undefined? selectedterm !== "none" && selectedterm !== undefined && chosen.academics.length!==0 && chosen.academics.filter(bug=> bug.term === selectedterm).length !==0 ? chosen.academics.length!==0 && chosen.academics.filter(bug=> bug.term === selectedterm)[0].promotion:"-":0}</b><br/>
</Grid>

</Grid>
<br/>
<Divider/>
<br/>
{/* <p align="center"> NEXT TERM FEES </p> */}
<Grid container spacing={0} >

<Grid item xs={4}>
<b>Interest : </b>
</Grid> 
<Grid item xs={8}>
{chosen!==undefined?selectedterm !== "none" && selectedterm !== undefined ? chosen.academics.length!==0 && chosen.academics.filter(bug=> bug.term === selectedterm).length !==0? chosen.academics.length!==0 && chosen.academics.filter(bug=> bug.term === selectedterm)[0].interest:"":"":""} 
</Grid>



<Grid item xs={4}>
<b>Conduct / Character : </b> 
</Grid> 
<Grid item xs={8}>
{chosen!==undefined?selectedterm !== "none" && selectedterm !== undefined ? chosen.academics.length!==0 && chosen.academics.filter(bug=> bug.term === selectedterm).length !==0? chosen.academics.length!==0 && chosen.academics.filter(bug=> bug.term === selectedterm)[0].conduct:"":"":""}
</Grid>


<Grid item xs={4}>
<b>Class Teacher's Remarks : </b> 
</Grid> 
<Grid item xs={8}>
{chosen!==undefined?selectedterm !== "none" && selectedterm !== undefined ? chosen.academics.length!==0 && chosen.academics.filter(bug=> bug.term === selectedterm).length !==0? chosen.academics.length!==0 && chosen.academics.filter(bug=> bug.term === selectedterm)[0].teacherremarks:"":"":""}
</Grid>


<Grid item xs={4}>
<b>Head Teacher's Remarks : </b>
</Grid> 
<Grid item xs={8}>
{chosen!==undefined?selectedterm !== "none" && selectedterm !== undefined ? chosen.academics.length!==0 && chosen.academics.filter(bug=> bug.term === selectedterm).length !==0? chosen.academics.length!==0 && chosen.academics.filter(bug=> bug.term === selectedterm)[0].headremarks:"":"":""}
</Grid>


<br/> 



 
<Grid item xs={8}>


<p style={{marginTop:40}}>..................................................</p>

<p style={{fontWeight:"bold", margin:2, fontStyle:'italic'}}>Class Teacher : {Class_.filter(bug=>bug.classname === chosen.class_).length!==0 && Class_.filter(bug=>bug.classname === chosen.class_)[0].classteacher}</p>
{/* <p style={{fontWeight:"bold", margin:2, fontStyle:'italic'}}>Class Teacher</p> */}
</Grid>     

<Grid item xs={4}>

</Grid>

  


</Grid>
<Divider/>
<small>
<p style={{textAlign:"center"}}>INTERPRETATION OF GRADING SYSTEM</p>
<Grid container>
{schooldata.grades.map((list, index)=>

<Grid item xs={4}>{list.remarks+" = "+list.upperlimit+" - "+list.lowerlimit} </Grid>

)}
</Grid>
</small>


        </Container>
        </div>
        )
    }
    
       

        </div>
    );
     
    }
}

export default Printpage;
