import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Printpage from './printbutton';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import Plus from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Avatar from '@mui/material/Avatar';
import SupplyProduct from '@mui/icons-material/LocalShipping';
import AddSRV from '@mui/icons-material/PostAdd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Search from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import Logout from '@mui/icons-material/Logout';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Show from './tableshow';
import FormLabel from '@mui/material/FormLabel';
import Tablereport from './tablereport';
import ReactToPrint from 'react-to-print';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MessageIcon from '@mui/icons-material/Message';
import SendIcon from '@mui/icons-material/Send';
import EmailIcon from '@mui/icons-material/Email';
import Attendance from './date';

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import TextField from '@mui/material/TextField';
import Menustyle from './menuschools';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import CircularProgress from '@mui/material/CircularProgress';
import ImageIcon from '@mui/icons-material/Image';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import Container from '@mui/material/Container';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import axios from 'axios';

import Badge from '@mui/material/Badge';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Person from './images/Ellipse77.png';


import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// import swal from 'sweetalert';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { schooldetails } from './redux/schooldetails';
import { admin } from './redux/admin';
import { loin, logout, updatestudentdata, getdata } from './redux/students';
import {  getdata_ } from './redux/class';
import { BrowserRouter as Router, Routes, Route, Link, useLocation, BrowserRouter } from "react-router-dom";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



function createData(_id,id,lastname,othernames,status,image, class_, gender, dob,hometown,residence, religion, motherlastname, motherothernames, motheroccupation, mothercontact, motheremail, motherresidence, fatherlastname,fatherothername, fatheroccupation, fathercontact, fatheremail, fatherresidence, guardianlastname,guardianothername, guardianoccupation, guardiancontact, guardianemail, guardianresidence, guardianrelationship,  academic ,finance, attendance, Awards, behaviour, others) 
{
    return {
        _id,
        id,
        lastname,
        othernames,
        status,
        image,
        class_,
        gender,
        dob,
        hometown,
        residence,
        religion,
        motherlastname,
        motherothernames,
        motheroccupation,
        mothercontact,
        motheremail,
        motherresidence,
        fatherlastname,
        fatherothername,
        fatheroccupation,
        fathercontact,
        fatheremail,
        fatherresidence, 
        guardianlastname,
        guardianothername,
        guardianoccupation,
        guardiancontact,
        guardianemail,
        guardianresidence,
        guardianrelationship,
      
        academic,
        finance,
        attendance,
        Awards,
        behaviour,
        others,
        
    };
}

const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Donut', 452, 25.0, 51, 4.9),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Honeycomb', 408, 3.2, 87, 6.5),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Jelly Bean', 375, 0.0, 94, 0.0),
    createData('KitKat', 518, 26.0, 65, 7.0),
    createData('Lollipop', 392, 0.2, 98, 0.0),
    createData('Marshmallow', 318, 0, 81, 2.0),
    createData('Nougat', 360, 19.0, 9, 37.0),
    createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [

  
  
    {
        id: 'id',
        numeric: false,
        disablePadding: !true,
        label: 'ID',
    },
  
    {
        id: 'name',
        numeric: !true,
        disablePadding: false,
        label: 'Name',
    },
      {
        id: 'status',
        numeric: !true,
        disablePadding: false,
        label: 'Status',
    },
   
    {
        id: 'class',
        numeric: !true,
        disablePadding: false,
        label: 'Class',
    },
    {
        id: 'gender',
        numeric: !true,
        disablePadding: false,
        label: 'Gender',
    }, {
        id: 'dob',
        numeric: !true,
        disablePadding: false,
        label: 'Date of Birth',
    }, {
        id: 'address',
        numeric: !true,
        disablePadding: false,
        label: 'Address',
    },
    {
        id: '',
        numeric: true,
        disablePadding: false,
        label: '',
    }
];


function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell align="center">
                    <b>
                        No.
                    </b>
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <b>{headCell.label}</b>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >

            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
               SETTINGS
            </Typography>



        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {

    const [opensnack, setOpensnack] = React.useState(false);
    const [pageloader, setpageloader] = React.useState(true);
                   
    const handleClicksnack = () => {
        setOpensnack(true);
    };

    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };

    const dispatch = useDispatch()
    const schools = useSelector((state) => state.students)
    const admin = useSelector((state) => state.admin)
    const schooldetails_ = useSelector((state) => state.schooldetails)
    var Class_ = useSelector((state) => state.Class_)
    console.log(admin)
    console.log(schooldetails_)

    const gettingdata_ = async () => {
        await axios.post("https://servermain.sisrevolution.com/api/schools/getschooldata/",
            {
                schoolID:admin.schoolID

            }).then(res => {

                if (res.status === 200) {

                    dispatch(schooldetails({

                        data: res.data

                    }))


                }

            })

    }

 
    const gettingdata = async () => {
        await axios.post("https://servermain.sisrevolution.com/api/students/getstudentdata/",
            {
                schoolID:admin.schoolID


            }).then(res => {

                if (res.status === 200) {

                    dispatch(getdata({

                        data: res.data

                    }))


                }

            })

    }
 
  useEffect(()=>{


//  gettingdata()
 gettingdata_()


 
},[pageloader])
    
    var data = [schools.map((list, index) => createData(list._id,list.ID, list.lastname, list.othernames,list.status, list.image, list.class_, list.gender, list.dob, list.hometown, list.residence, list.religion, list.motherlastname, list.motherothernames, list.motheroccupation, list.mothercontact, list.motheremail, list.motherresidence, list.fatherlastname,  list.fatherothername, list.fatheroccupation, list.fathercontact, list.fatheremail, list.fatherresidence, list.guardianlastname,  list.guardianothername, list.guardianoccupation, list.guardiancontact, list.guardianemail, list.guardianresidence,list.guardianrelationship,  list.academics, list.finance, list.attendance, list.Awards, list.behaviour, list.others))]

    const dt = new Date()
    const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
    const day = dt.getUTCDate();
    const month = String(dt.getMonth() + 1).padStart(2, '0');
    const year = dt.getFullYear();
    const date = year + "-" + month + "-" + day;
    var rows = data[0]
    const [reopendate, setreopendate] = React.useState("")

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [trialmode, settrialmode] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl_, setAnchorEl_] = React.useState(null);
    const open = Boolean(anchorEl);
    const open_ = Boolean(anchorEl_);
    const [opensupply, setOpensupply] = React.useState(!true);
    const [name, setname] = React.useState(!true);
    const [location, setlocation] = React.useState(!true);
    const [allocation, setallocation] = React.useState(!true);
    const [contact, setcontact] = React.useState(!true);
    const [message, setmessage] = React.useState("");
    const [stated, setstated] = React.useState("");

    const [changeterm, setchangeterm] = React.useState("none");
    const [openadd, setOpenadd] = React.useState(!true);
    const [openview, setOpenview] = React.useState(!true);
    const [openattendance, setOpenattendance] = React.useState(!true);
    const [opensrv, setOpensrv] = React.useState(!true);
    const [openedit, setOpenedit] = React.useState(!true);
    const [openimage, setOpenimage] = React.useState(!true);
    const [opendelete, setOpendelete] = React.useState(!true);
    const [openmessage, setOpenmessage] = React.useState(!true);
    const [age, setAge] = React.useState('');
    const [guardian, setGuardian] = React.useState('Mother');

    const [guardianedit, setguardianedit] = React.useState('');
    const [sorter, setsorter] = React.useState("none");
    const [chosen, setchosen] = React.useState([]);
    const [loading, setloading] = React.useState(false)
    const [search, setsearch] = React.useState("")
    const [selectedclass, setselectedclass] = React.useState("none")
    const [sortclass, setsortclass] = React.useState("none")

    const [selectedclassedit, setselectedclassedit] = React.useState("")
    const [selectedgender, setselectedgender] = React.useState("none")
    const [selectedgenderedit, setselectedgenderedit] = React.useState("")
    const [selectedreligionedit, setselectedreligionedit] = React.useState("")
    const [person, setperson] = React.useState(schooldetails_.length!==0 && 'https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/'+schooldetails_[0].image)

    const [personclass, setpersonclass] = React.useState("")
    const [what, setwhat] = React.useState("back")
    const [replace, setreplace] = React.useState("no")
    const [selectedterm, setselectedterm] = React.useState("none")
    const [selectedpromote, setselectedpromote] = React.useState("none")
    const [whattype, setwhattype] = React.useState("back")
    const [newstudentloading, setnewstudentloading] = React.useState("false")
    const [editstudentloading, seteditstudentloading] = React.useState("false")
    const [deletestudentloading, setdeletestudentloading] = React.useState("false")
    const [smsloading, setsmsloading] = React.useState("false")
    const [emailloading, setemailloading] = React.useState("false")
    const [amount, setamount] = React.useState(0)
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const pricing=[[50,200,550],[201,400,950], [401,600,1450],[601,800,1850], [801,1000,2250],[1001,1200,2750],[1201,1400,3150],[1401,1600,3550],[1601,1800,3950],[1801,2000,4350],[2001,2200,4750],[2201,2400,5150],[2401,2600,5550],[2601,2800,5950],[2801,3000,6350],[3001,3200,6750],[3201,3400,7150],[3401,3600,7550],[3601,3800,7950] ]

    const [selectedreligion, setselectedreligion] = React.useState("")

    const [indexnumber, setindexnumber] = React.useState("")
    const [schoolname, setschoolname] = React.useState("")
    const [schooladdress, setschooladdress] = React.useState("")
    const [schoolcontact, setschoolcontact] = React.useState("")
    const [yearestablished, setyearestablished] = React.useState("")
    const [studentpopulation, setstudentpopulation] = React.useState("")
    const [staffpopulation, setstaffpopulation] = React.useState("")
    const [email, setemail] = React.useState("")

    const searching = (e) => {
        setsearch(e.target.value)
    }

    useEffect(()=>{
        console.log(schooldetails_)
        if(schooldetails_.length!==0){
        setselectedreligion(schooldetails_[0].studentpopulation)
        setperson('https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/'+schooldetails_[0].image)
        setschoolname( schooldetails_[0].schoolname)
        setschooladdress(schooldetails_[0].address)
        setschoolcontact(schooldetails_[0].contact)
        setyearestablished(schooldetails_[0].yearestablished)
        setstudentpopulation(schooldetails_[0].studentpopulation)
        setstaffpopulation(schooldetails_[0].staffpopulation)
        setemail(schooldetails_[0].email)
        }
    },[schooldetails_])

    useEffect(()=>{

 
        for(var i in pricing){
    
            if(pricing[i][0]=== parseInt(studentpopulation.split("-")[0]) && pricing[i][1]===parseInt(studentpopulation.split("-")[1])){
                setamount(pricing[i][2])
               
            }
        
        }
        
    
    },[studentpopulation])

    if (search !== "") {
        console.log(search)
        var listed = rows.filter(bug => bug.class_.toLowerCase().includes(search.toLowerCase()) || bug.status.toLowerCase().includes(search.toLowerCase()) || bug.othernames.toLowerCase().includes(search.toLowerCase()) || bug.lastname.toLowerCase().includes(search.toLowerCase()) || bug.id.toLowerCase().includes(search.toLowerCase()) )
       rows=listed
    }

    if(sortclass !== "none" && sortclass !== "All"){
        var list = rows.filter(bug => bug.class_ === sortclass)
        rows= list
    }

    //actions

    const grading = (score) => {
        var grade = 0
        if (score > 74) {
            grade = 1
            return grade
        }
        else if (score > 69 && score < 75) {
            grade = 2
            return grade
        }
        else if (score > 64 && score < 70) {
            grade = 3
            return grade
        }
        else if (score > 59 && score < 65) {
            grade = 4
            return grade
        }
        else if (score > 54 && score < 60) {
            grade = 5
            return grade
        }
        else if (score > 49 && score < 56) {
            grade = 6
            return grade
        }
        else if (score > 44 && score < 50) {
            grade = 7
            return grade
        }
        else if (score > 39 && score < 45) {
            grade = 8
            return grade
        } else {
            grade = 9
            return grade
        }
    }


    const remarks = (grade) => {
        var remark = "Excellent"
        if (grade > 74) {
            remark = "Excellent"
            return remark
        }
        else if (grade > 69 && grade < 75) {
            remark = "Very Good"
            return remark
        }
        else if (grade > 64 && grade < 70) {
            remark = "Good"
            return remark
        }
        else if (grade > 59 && grade < 65) {
            remark = "Credit"
            return remark
        }
        else if (grade > 54 && grade < 60) {
            remark = "Credit"
            return remark
        }
        else if (grade > 49 && grade < 56) {
            remark = "Credit"
            return remark
        }
        else if (grade > 46 && grade < 50) {
            remark = "Pass"
            return remark
        }
        else if (grade > 39 && grade < 45) {
            remark = "Pass"
            return remark
        } else {
            remark = "Fail"
            return remark
        }
    }

    const registerstudent = () => {
                    setnewstudentloading(true)

                    const file = document.getElementById("fileimage").files[0]
                    const image = ""
                    const lastname = document.getElementById("lastname").value
                    const othernames = document.getElementById("othernames").value
                    const dob = document.getElementById("dob").value
                    const residence = document.getElementById("residence").value
                    const hometown = document.getElementById("hometown").value
                    const religion = selectedreligion
                    const gender = selectedgender
                    const class_ = selectedclass
                    const motherlastname = document.getElementById("motherlastname").value
                    const motherothernames = document.getElementById("motherothernames").value
                    const motheroccupation = document.getElementById("motheroccupation").value
                    const mothercontact = document.getElementById("mothercontact").value
                    const motheremail = document.getElementById("motheremail").value
                    const motherresidence = document.getElementById("motherresidence").value 
                                
                    const fatherlastname = document.getElementById("fatherlastname").value
                    const fatherothernames = document.getElementById("fatherothernames").value
                    const fatheroccupation = document.getElementById("fatheroccupation").value
                    const fathercontact = document.getElementById("fathercontact").value
                    const fatheremail = document.getElementById("fatheremail").value
                    const fatherresidence = document.getElementById("fatherresidence").value
                    
                    var  guardianlastname = ""
                    var  guardianothernames = ""
                    var  guardianoccupation = ""
                    var  guardiancontact = ""
                    var  guardianemail = ""
                    var  guardianresidence = ""
                    var  guardianrelationship = ""
                    
                    if(guardian === "Mother" || guardian === "Parents" ){

                    guardianlastname = document.getElementById("motherlastname").value
                    guardianothernames = document.getElementById("motherothernames").value
                    guardianoccupation = document.getElementById("motheroccupation").value
                    guardiancontact = document.getElementById("mothercontact").value
                    guardianemail = document.getElementById("motheremail").value
                    guardianresidence = document.getElementById("motherresidence").value
                    guardianrelationship = "Mother" 

                    }    

                    if(guardian === "Others" ){

                    guardianlastname = document.getElementById("guardianlastname").value
                    guardianothernames = document.getElementById("guardianothernames").value
                    guardianoccupation = document.getElementById("guardianoccupation").value
                    guardiancontact = document.getElementById("guardiancontact").value
                    guardianemail = document.getElementById("guardianemail").value
                    guardianresidence = document.getElementById("guardianresidence").value
                    guardianrelationship = "Guardian"

                    }
                    
                    if(guardian === "Father" ){

                    guardianlastname = document.getElementById("fatherlastname").value
                    guardianothernames = document.getElementById("fatherothernames").value
                    guardianoccupation = document.getElementById("fatheroccupation").value
                    guardiancontact = document.getElementById("fathercontact").value
                    guardianemail = document.getElementById("fatheremail").value
                    guardianresidence = document.getElementById("fatherresidence").value
                    guardianrelationship = "Father"

                    }
              
                    const fd = new FormData()
                   
                    if (file) {
                   
                        fd.append("file", file)
                        fd.append("image", document.getElementById("fileimage").files[0].name)
                    }
                    
                    fd.append("lastname", lastname)
                    fd.append("schoolID",admin.schoolID)
                    fd.append("othernames", othernames)
                    fd.append("class_", class_)
                    fd.append("gender", gender)
                    fd.append("dob", dob)
                    fd.append("hometown", hometown)
                    fd.append("residence", residence)
                    fd.append("religion", religion)
                    fd.append("motherlastname", motherlastname)
                    fd.append("motherothernames", motherothernames)
                    fd.append("motheroccupation", motheroccupation)
                    fd.append("mothercontact", mothercontact)
                    fd.append("motheremail", motheremail)
                    fd.append("motherresidence", motherresidence)
                    fd.append("fatherresidence", fatherresidence)
                    fd.append("fatherlastname", fatherlastname)
                    fd.append("fatherothernames", fatherothernames)
                    fd.append("fatheroccupation", fatheroccupation)
                    fd.append("fathercontact", fathercontact)
                    fd.append("fatheremail", fatheremail)

                    fd.append("guardianresidence", guardianresidence)
                    fd.append("guardianlastname", guardianlastname)
                    fd.append("guardianothernames", guardianothernames)
                    fd.append("guardianoccupation", guardianoccupation)
                    fd.append("guardiancontact", guardiancontact)
                    fd.append("guardianemail", guardianemail)
                    fd.append("guardianrelationship", guardianrelationship)
console.log(fd)
                    const uploading = async() => {
                        await axios.post("https://servermain.sisrevolution.com/api/students/registerstudent/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }


                        }).then(res => {

                            if (res.status === 200) {
                              gettingdata()

                               
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("New Student Added")
                        setstated("success")
                                setnewstudentloading(!true)

                            }
                            if (res.status === 204) {
                                handleCloseadd()
                        handleClicksnack()
                        setmessage("Error Adding Student")
                        setstated("success")
                                setnewstudentloading(!true)
                            }

                        })

                    }


                    uploading()



                }

    const editstudent = () => {
                    seteditstudentloading(true)

                    const file = document.getElementById("fileimageedit").files[0]
                    const image = ""
                    const lastname = document.getElementById("lastnameedit").value
                    const othernames = document.getElementById("othernamesedit").value
                    const dob = document.getElementById("dobedit").value
                    const residence = document.getElementById("residenceedit").value
                    const hometown = document.getElementById("hometownedit").value
                    const religion = selectedreligionedit
                    const gender = selectedgenderedit
                    const class_ = selectedclassedit
                    const motherlastname = document.getElementById("motherlastnameedit").value
                    const motherothernames = document.getElementById("motherothernamesedit").value
                    const motheroccupation = document.getElementById("motheroccupationedit").value
                    const mothercontact = document.getElementById("mothercontactedit").value
                    const motheremail = document.getElementById("motheremailedit").value
                    const motherresidence = document.getElementById("motherresidenceedit").value 
                                
                    const fatherlastname = document.getElementById("fatherlastnameedit").value
                    const fatherothernames = document.getElementById("fatherothernamesedit").value
                    const fatheroccupation = document.getElementById("fatheroccupationedit").value
                    const fathercontact = document.getElementById("fathercontactedit").value
                    const fatheremail = document.getElementById("fatheremailedit").value
                    const fatherresidence = document.getElementById("fatherresidenceedit").value
                      
                    
                    var  guardianlastname = ""
                    var  guardianothernames = ""
                    var  guardianoccupation = ""
                    var  guardiancontact = ""
                    var  guardianemail = ""
                    var  guardianresidence = ""
                    var  guardianrelationship = ""
                    
                    if(guardianedit === "Mother" || guardianedit === "Parents" ){

                    guardianlastname = document.getElementById("motherlastnameedit").value
                    guardianothernames = document.getElementById("motherothernamesedit").value
                    guardianoccupation = document.getElementById("motheroccupationedit").value
                    guardiancontact = document.getElementById("mothercontactedit").value
                    guardianemail = document.getElementById("motheremailedit").value
                    guardianresidence = document.getElementById("motherresidenceedit").value
                    guardianrelationship = "Mother" 

                    }    

                    if(guardianedit === "Others" ){

                    guardianlastname = document.getElementById("guardianlastnameedit").value
                    guardianothernames = document.getElementById("guardianothernamesedit").value
                    guardianoccupation = document.getElementById("guardianoccupationedit").value
                    guardiancontact = document.getElementById("guardiancontactedit").value
                    guardianemail = document.getElementById("guardianemailedit").value
                    guardianresidence = document.getElementById("guardianresidenceedit").value
                    guardianrelationship = "Guardian"


                    }
                    
                    if(guardianedit === "Father" ){

                    guardianlastname = document.getElementById("fatherlastnameedit").value
                    guardianothernames = document.getElementById("fatherothernamesedit").value
                    guardianoccupation = document.getElementById("fatheroccupationedit").value
                    guardiancontact = document.getElementById("fathercontactedit").value
                    guardianemail = document.getElementById("fatheremailedit").value
                    guardianresidence = document.getElementById("fatherresidenceedit").value
                    guardianrelationship = "Father"

                    }
              
                    const fd = new FormData()
                   
                    if (file) {
                   
                        fd.append("file", file)
                        fd.append("image", dob+lastname+".jpg")
                    }
                    fd.append("_id", chosen._id)
                    fd.append("schoolID",admin.schoolID)
                    fd.append("lastname", lastname)
                    fd.append("othernames", othernames)
                    fd.append("class_", class_)
                    fd.append("gender", gender)
                    fd.append("dob", dob)
                    fd.append("hometown", hometown)
                    fd.append("residence", residence)
                    fd.append("religion", religion)
                    fd.append("motherlastname", motherlastname)
                    fd.append("motherothernames", motherothernames)
                    fd.append("motheroccupation", motheroccupation)
                    fd.append("mothercontact", mothercontact)
                    fd.append("motheremail", motheremail)
                    fd.append("motherresidence", motherresidence)
                    fd.append("fatherresidence", fatherresidence)
                    fd.append("fatherlastname", fatherlastname)
                    fd.append("fatherothernames", fatherothernames)
                    fd.append("fatheroccupation", fatheroccupation)
                    fd.append("fathercontact", fathercontact)
                    fd.append("fatheremail", fatheremail)

                    fd.append("guardianresidence", guardianresidence)
                    fd.append("guardianlastname", guardianlastname)
                    fd.append("guardianothernames", guardianothernames)
                    fd.append("guardianoccupation", guardianoccupation)
                    fd.append("guardiancontact", guardiancontact)
                    fd.append("guardianemail", guardianemail)
                    fd.append("guardianrelationship", guardianrelationship)
console.log(fd)
                    const uploading = async() => {
                        await axios.post("https://servermain.sisrevolution.com/api/students/editstudent/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }


                        }).then(res => {

                            if (res.status === 200) {
                               
                                gettingdata()

                        handleCloseedit()
                        handleClicksnack()
                        setmessage("Student Data Edited")
                        setstated("success")
                                seteditstudentloading(!true)

                            }
                            if (res.status === 204) {
                                handleCloseedit()
                        handleClicksnack()
                        setmessage("Error Editting Student")
                        setstated("success")
                                seteditstudentloading(!true)
                            }

                        })

                    }


                    uploading()



                }

    const sendsms = () => {
                    setsmsloading(true)

                       
                    const message = document.getElementById("viasms").value
                   
                
                    const fd = new FormData()
                  
               
                    fd.append("schoolID",admin.schoolID)
                    fd.append("message", message)
                    fd.append("contact", chosen.guardiancontact)
                    const uploading = async() => {
                        await axios.post("https://servermain.sisrevolution.com/api/students/singlesms/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }


                        }).then(res => {

                            if (res.status === 200) {
                               
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("SMS sent")
                        setstated("success")
                                setsmsloading(!true)

                            }
                            if (res.status === 204) {
                                handleCloseadd()
                        handleClicksnack()
                        setmessage("Error Sending SMS")
                        setstated("success")
                                setsmsloading(!true)
                            }

                        })

                    }


                    uploading()



                }

    const sendemail = () => {
                    setemailloading(true)

                       
                    const messagesms = document.getElementById("viaemail").value
                   
                
                    const fd = new FormData()
                  
               
                    fd.append("schoolID",admin.schoolID)
                    fd.append("message", messagesms)
                    fd.append("guardianemail", chosen.guardianemail)
                    const uploading = async() => {
                        await axios.post("https://servermain.sisrevolution.com/api/students/singleemail/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }


                        }).then(res => {

                            if (res.status === 200) {
                               
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("Email sent")
                        setstated("success")
                                setemailloading(!true)

                            }
                            if (res.status === 204) {
                                handleCloseadd()
                        handleClicksnack()
                        setmessage("Error Sending Email")
                        setstated("success")
                                setnewstudentloading(!true)
                            }

                        })

                    }


                    uploading()



                }

    const addtolist = (data) => {
        setloading(true)
        console.log(data)
        var quantity = document.getElementById("Quantity").value
        var SRV = document.getElementById("SRVrec").value
        var By = document.getElementById("supplier").value
        var term = age
        var done = [...data.data, { date: date, sem: term, quantity: quantity, SRVrec: SRV, SRVsent: 0, SRVcon: 0, SRVpaid: 0, suppliedby: By }]

        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/school/updateschooldata/",
                {
                    id: data.id,
                    data: done,
                    schoolID:admin.schoolID




                }).then(res => {

                    if (res.status === 200) {

                        dispatch(updatestudentdata({

                            data: res.data

                        }))
                        setloading(!true)
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("You have successfully added to supply list")
                        setstated("success")
                       

                        
                    } else {
                        setloading(!true)
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("Error adding to supply list")
                        setstated("error")

                    }

                })

        }

            doer()

        }

    const changeedit = (changer,e) => {
        changer(e.target.value)
        console.log(e.target.value)
    }

    const setchanges= (e) => {
        setloading(true)
        e.preventDefault()

        const file = document.getElementById("fileimage").files[0]

        const fd = new FormData()
          if (file) {
                        fd.append("file", file)
                        fd.append("image", schooldetails_[0].schoolID+schoolname+".jpg")
                    }
                    fd.append("_id",schooldetails_[0]._id)
                    fd.append("id",schooldetails_[0].schoolID)
                    fd.append("schoolname",schoolname)
                    fd.append("contact",schoolcontact)
                    fd.append("amount",amount)
                    fd.append("email",email)
                    fd.append("address",schooladdress)
                    fd.append("yearestablished",yearestablished)
                    fd.append("studentpopulation",studentpopulation)
                    fd.append("staffpopulation",staffpopulation)
                    fd.append("schoolIndex",indexnumber)
                    
        const buyingpolicy = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/schools/editschool/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }

                        }).then(res => {

                    if (res.status === 200) {

                        dispatch(schooldetails({

                            data: [res.data]
    
                        }))


                        // e.target.reset()
                        setloading(!true)
                        handleClicksnack()
                        setmessage("School data updated")
                        setstated("success")
                       

                    } else {
                        setloading(!true)
                        handleClicksnack()
                        setmessage("Error updating school data")
                        setstated("error")
                       
                    }

                })

        }

        buyingpolicy()

    }


    const deleter = (data) => {

         setdeletestudentloading(true)

        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/students/deletestudent/",
                {
                   _id:chosen._id,
                   schoolID:admin.schoolID


                }).then(res => {

                    if (res.status === 200) {

                        gettingdata()

                        setdeletestudentloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Data has been deleted")
                        setstated("success")

                    } else {
                        setdeletestudentloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Error deleting data")
                        setstated("error")
                    }

                })

        }

            doer()

    }


    const handleCloseedit = () => {
        setOpenedit(false);
    };

    const handleClickOpenimage = () => {
        if((schooldetails_[0].period===undefined || schooldetails_[0].period==="monthly" ? (schooldetails_[0].payment.length===0 || schooldetails_[0].payment[schooldetails_[0].payment.length-1].amount==="trial") :  (schooldetails_[0].period!==undefined && schooldetails_[0].period==="termly" && (schooldetails_[0].payment.length===0 || schooldetails_[0].payment[schooldetails_[0].payment.length-1].amount==="trial" || schooldetails_[0].payment[schooldetails_[0].payment.length-1].term!==schooldetails_[0].terms[schooldetails_[0].terms.length-1] ) ))  ){

            settrialmode(true)

        }else{
        setOpenimage(true);
        }
    };

    const handleCloseimage = () => {
        setOpenimage(false);
    };

    const handleClickOpendelete = (data) => {
        console.log(data)
        setchosen(data)
        setOpendelete(true);
    };

    const handleClosedelete = () => {
        setOpendelete(false);
    };


    const handleCloseadd = () => {
        setOpenadd(false);
    };

 
    const handleCloseview = () => {
        setOpenview(false);
    };
    const handleOpenview = () => {
        setOpenview(true);
    };
   

    const handleCloseattendance = () => {
        setOpenattendance(false);
    };

    const handleClosemessage = () => {
        setOpenmessage(false);
    };

    const handleClosesupply = () => {
        setOpensupply(false);
    };

   
    const imagehandler = (e) => {
        const reader = new FileReader()
        reader.onload = () => {
            if (reader.readyState === 2) {
                setperson(reader.result)
            }
        }

        reader.readAsDataURL(e.target.files[0])
    }


    const handleChange_gender = (e) => {
        setselectedgender(e.target.value)
    }

    const handleChange_religion = (e) => {
        setselectedreligion(e.target.value)
    }

    const handleChange_class = (e) => {
        setselectedclass(e.target.value)
    }

    const handleChange_genderedit = (e) => {
        setselectedgenderedit(e.target.value)
    }
    const handleChange_religionedit = (e) => {
        setselectedreligionedit(e.target.value)
    }
    const handleChange_classedit = (e) => {
        setselectedclassedit(e.target.value)
    }


    const handleChangeterm = (e) => {
        setselectedterm(e.target.value);
    };

  const handleChangepromote = (e) => {
        setselectedpromote(e.target.value);
    };

  






    const submitdate=()=>{
        setloading(true)

        const dodate = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/schools/changereopendate/",
                {
                    _id: schooldetails_[0]._id,
                    reopendate: reopendate,

                }).then(res => {

                    if (res.status === 200) {

                        dispatch(schooldetails({

                            data: [res.data]
    
                        }))
                        
                        setloading(!true)
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("You have successfully Changed Re-opening Date")
                        setstated("success")
                       

                        
                    } else {
                        setloading(!true)
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("Error changing re-opening date")
                        setstated("error")

                    }

                })

        }

        try{
        dodate()
        }catch(err){
            setloading(!true)
            handleClicksnack()

            setmessage("Network Error")
            setstated("error")
        }
      
    }







    const editacademic =(data)=>{

        setwhattype(data)

    }
    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

const setback=(data)=>{
    setwhat(data)
}
        const sendmessage = () =>{

            return(



<div style={{textAlign:"center", margin:8}}>


<Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>



<p style={{textAlign:"center", margin:12}}>
                        Send a message to Guardian via 
                    </p>
                    <Button  style={{backgroundColor:" #e0eff9" , color:"#1976d2", margin:12, padding:10}} startIcon={  <WhatsAppIcon style={{fontSize:"20px"}} />} aria-label="fingerprint"  >
        <small> Whatsapp</small>
      </Button>
           <Button style={{backgroundColor:" #e0eff9" , color:"#1976d2", margin:12, padding:10}} startIcon={ <EmailIcon style={{fontSize:"20px"}} />} onClick={()=>setback("email")} aria-label="fingerprint" >
        <small> Email</small>
      </Button>
   <Button style={{backgroundColor:" #e0eff9" , color:"#1976d2", margin:12, padding:10}} startIcon={ <MessageIcon style={{fontSize:"20px"}} />} onClick={()=>setback("sms")} aria-label="fingerprint" >
        <small> Text Message</small>
      </Button>

 

                    
                
</div>

            )
        }

        const viaSMS = ()=>{
            
            return(
                <div style={{textAlign:"left", marginTop:10}}>
                <Button onClick={()=>setback("back")} style={{margin:2}}>
                <KeyboardBackspaceIcon />
                </Button>
                <div style={{textAlign:"center"}}>
                <p style={{textAlign:"center", marginTop:"-30px", fontWeight:'bolder'}}> Sending Message Via SMS</p>
<Divider/>
<br/>
<br/>
                <textarea id='viasms' rows="5" defaultValue={"Hello "+ chosen.guardianlastname} style={{width:"80%", padding:10, borderColor:"grey", borderRadius:"5px"}} />    
<br/>
 {smsloading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => sendsms()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                   Send
                                </Button>
                            }
                </div>
                </div>


            )

        }
    

        const viaEmail = ()=>{
            
            return(
                <div style={{textAlign:"left", marginTop:10}}>
                <Button onClick={()=>setback("back")} style={{margin:2}}>
                <KeyboardBackspaceIcon />
                </Button>
                <div style={{textAlign:"center"}}>
                <p style={{textAlign:"center", marginTop:"-30px", fontWeight:'bolder'}}> Sending Message Via Email</p>
<Divider/>
<br/>
<br/>
                <textarea id='viaemail' rows="5" defaultValue={"Hello "+ chosen.guardianlastname} style={{width:"80%", padding:10, borderColor:"grey", borderRadius:"5px"}} />    
<br/>

 {emailloading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => sendemail()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Send
                                </Button>
                            }
                </div>
                </div>


            )

        }

        const viaEmailandtext = ()=>{
            
            return(
                <div style={{textAlign:"left", marginTop:10}}>
                <Button style={{margin:2}}>
                <KeyboardBackspaceIcon />
                </Button>
                <div style={{textAlign:"center"}}>
                <p style={{textAlign:"center", marginTop:"-30px", fontWeight:'bolder'}}> Sending Message Via Email and SMS</p>
<Divider/>
<br/>
<br/>
                <textarea rows="5" defaultValue="Hello Mr. Ntori" style={{width:"80%", padding:10, borderColor:"grey", borderRadius:"5px"}} />    

                </div>
                </div>


            )

        }

    
        const messenger =(type)=>{
    if(type==="email"){
        return(
            viaEmail()
        )
    }
    if(type==="sms"){
        return(
            viaSMS()
        )
    }  if(type==="back"){
        return(
            sendmessage()
        )
    }
        }


        const mainrecord =()=>{
            var data=[]
            if(selectedterm !== "none"){
            data=chosen.academic.filter(bug=> bug.term === selectedterm)

            }
return(
    <div> 

<DialogTitle id="customized-dialog-title" onClose={handleClosesupply} style={{  }}>
                
                        <FormControl style={{ marginLeft:45, marginRight:5 }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Term</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={selectedterm}
                            style={{ minWidth: 200, padding: 0, margin: 0 }}
                            onChange={(e)=>handleChangeterm(e)}
                            autoWidth
                            label="Term"
                            size="small"
                        >
                            <MenuItem value="none">
                                <em>Choose Term</em>
                            </MenuItem>
                            <MenuItem value={"1st Term / "+year}>{"1st Term / "+year}</MenuItem>
                            <MenuItem value={"2nd Term / "+year}>{"2nd Term / "+year}</MenuItem>
                            <MenuItem value={"3rd Term / "+year}>{"3rd Term / "+year}</MenuItem>

                        </Select>
                    </FormControl>
                    <Button onClick={()=>editacademic("edit")}>
                    Add
                    </Button>

                    <Button onClick={handleClosesupply} style={{float:"right"}}>
                    close
                    </Button>
                </DialogTitle>

                <DialogContent dividers style={{ textAlign: "left" }}>
                                <Printpage data={chosen} term={selectedterm} Class_={Class_}/>

                </DialogContent>

    </div> 
)

        }


const positions = (term, data, id,classname) => {
var list = []
var list2 = [data]
var position = "None"
var arrange = []
var value = 0
    for(var i in rows){
        if(rows[i]._id !== id){
        if(rows[i].academics.length !==0){
            console.log(rows[i])
        for(var k in rows[i].academics){
            if(rows[i].academics[k].term===term && rows[i].class_===classname){
               list.push(rows[i].academics[k].totalscore) 
            }
        }
        }
    }

    }
    
    for(var i in list){
        if (list2.includes(list[i])){
        console.log("includes")
        }else(
            list2.push(list[i])
        )
        
    }

    list2= list2.sort((a,b)=>b-a)

    for(var i in list2){
        var sum = parseInt(i)+1
         if((sum).toString()[(sum).toString().length-1] === "1"){
        arrange.push({score:list2[i], position:(sum).toString()+"st"})

    } else if((sum).toString()[(sum).toString().length-1] === "2"){
         arrange.push({score:list2[i], position:(sum).toString()+"nd"})
    }
    else if((sum).toString()[(sum).toString().length-1] === "3"){
        arrange.push({score:list2[i], position:(sum).toString()+"rd"})

    }else{
        arrange.push({score:list2[i], position:(sum).toString()+"th"})
    }
    }


 const doposition = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/students/arrangepositions/",
                {
                    class_: chosen.class_,
                    term: term,
                    data: arrange,
                    schoolID:admin.schoolID

          
                }).then(res => {

                    if (res.status === 200) {

                       
                        
                        // setloading(!true)
                        // handleClosesupply()
                        // handleClicksnack()
                        // setmessage("You have successfully added to supply list")
                        // setstated("success")
                       

                        
                    } else {
                        // setloading(!true)
                        // handleClosesupply()
                        // handleClicksnack()
                        // setmessage("Error adding to supply list")
                        // setstated("error")

                    }

                })

        }

        doposition()


    console.log(arrange)

    console.log(list2)
    value = list2.findIndex(bug=>bug===data) + 1
    console.log(value.toString()[value.toString().length-1])
    if(value.toString()[value.toString().length-1] === "1"){
        position=value.toString()+"st"

    } else if(value.toString()[value.toString().length-1] === "2"){
        position=value.toString()+"nd"

    }
    else if(value.toString()[value.toString().length-1] === "3"){
        position=value.toString()+"rd"

    }else{
        position=value.toString()+"th"
    }



    return position

}

        const editrecord =()=>{

            var subjected = Class_.filter(bug=>bug.classname === chosen.class_)
            console.log(subjected)
            var number = subjected[0].subjects[0]
            var results = []
            var conclusion = []
            var totalscore=0
            var aggregatecalc=[]
            var aggregate=0
            //var promotion = document.getElementById('nextclass').value
            var promotion = selectedpromote
            
            if(promotion === "none"){
                 promotion=chosen.class_
            }

            const submitscore=()=>{

                for(var i in number){
                    console.log(i)
                    console.log(subjected[0].subjects[0][i].subject)
                    var classscore = document.getElementById('class'+subjected[0].subjects[0][i].subject+i).value
                    var examscore =  document.getElementById('exams'+subjected[0].subjects[0][i].subject+i).value
                    
                    if(classscore===""){
                        classscore = 0
                    }  
                    if(examscore===""){
                        examscore = 0
                    }
                    
                    var total =(parseInt(classscore)+parseInt(examscore))
                    totalscore += total
                    aggregatecalc.push(grading(total))
                   results.push({subject: subjected[0].subjects[0][i].subject, class_scorre: classscore, exam_score:examscore, total:total , grade:grading(total), remarks:remarks(total) })
                   console.log(results)
                    

                }
               
            
                for(var i in aggregatecalc){
                if(i<=6){     
                    aggregate += aggregatecalc[i]
                }
            }

    conclusion = {term:selectedterm, position:positions(selectedterm, totalscore, chosen._id, chosen.class_ ), results:results, totalscore:totalscore, promotion:promotion,  aggregate:aggregate}

    const doerresult = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/students/addresults/",
                {
                    id: chosen._id,
                    data: conclusion,
                    term: selectedterm,
                    class_: promotion,
                    schoolID:admin.schoolID


                }).then(res => {

                    if (res.status === 200) {

                       gettingdata()
                        
                        setloading(!true)
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("You have successfully added academic records")
                        setstated("success")
                       

                        
                    } else {
                        setloading(!true)
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("Error adding academic records")
                        setstated("error")

                    }

                })

        }

        doerresult()


            }
        

    return(
    <div> 

<DialogTitle id="customized-dialog-title" onClose={handleClosesupply} style={{  }}>
   <Button style={{margin:2}} onClick={()=>editacademic("back")}>
                <KeyboardBackspaceIcon />
                </Button>
                   Add / Edit Academic records

                     <FormControl style={{ marginLeft:10, marginRight:0 }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Term</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={selectedterm}
                            style={{ minWidth: 200, padding: 0, margin: 0 }}
                            onChange={(e)=>handleChangeterm(e)}
                            autoWidth
                            label="Term"
                            size="small"
                        >
                            <MenuItem value="none">
                                <em>Choose Term</em>
                            </MenuItem>
                            <MenuItem value={"1st Term / "+year}>{"1st Term / "+year}</MenuItem>
                            <MenuItem value={"2nd Term / "+year}>{"2nd Term / "+year}</MenuItem>
                            <MenuItem value={"3rd Term / "+year}>{"3rd Term / "+year}</MenuItem>

                        </Select>
                    </FormControl>
                  
                    <Button onClick={handleClosesupply} style={{float:"right"}}>
                    close
                    </Button>
                </DialogTitle>

                <DialogContent dividers style={{ textAlign: "left" }}>
                  <ListItem align="left">
                                                    <ListItemAvatar>
                                                        <Avatar src={'https://servermain.sisrevolution.com/routes/thumbnail/'+chosen.image} size="small" alt={chosen.lastname+" "+ chosen.othernames} onClick={() => handleClickOpenimage(chosen)} style={{cursor:"pointer"}}>
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary={chosen.lastname+" "+ chosen.othernames}  />
                                                </ListItem>
                    
               <FormControl style={{ marginLeft:0, marginRight:0, marginTop:10, marginBottom:10 }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Promoted to</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={selectedpromote}
                            style={{ minWidth: 200, padding: 0, margin: 0 }}
                            onChange={(e)=>handleChangepromote(e)}
                            autoWidth
                            label="Promoted to"
                            size="small"
                        >
                            <MenuItem value="none">
                                <em>Promoted to</em>
                            </MenuItem>   
                            <MenuItem value="Repeat">
                                <em>Repeat</em>
                            </MenuItem>

                            {Class_.map((row, index)=>
                            <MenuItem value={row.classname}>{row.classname}</MenuItem>

                            )}
                         

                        </Select>
                    </FormControl>
                   
                    <TableContainer component={Paper}>
      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
                      <TableCell>SUBJECTS</TableCell>
                      <TableCell align="left">CLASS SCORE (40%)</TableCell>
                      <TableCell align="left">EXAM SCORE (60%)</TableCell>
                      

          </TableRow>
        </TableHead>
        <TableBody>
        
         { chosen.academic.length===0? subjected[0].subjects[0].map((row, index) => (
            <TableRow
                  key={row.subject}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
             
                  <TableCell align="left">{row.subject}</TableCell>
                  <TableCell align="left">  <input  id={'class'+row.subject+index} style={{borderRadius:"5px", padding:"10px", border:"solid", borderWidth:"0.3px", borderColor:"grey" }}  type="number"/> </TableCell>
                  <TableCell align="left">  <input id={'exams'+row.subject+index}   style={{borderRadius:"5px", padding:"10px", border:"solid", borderWidth:"0.3px", borderColor:"grey" }} type="number"/>  </TableCell>

            </TableRow>
          ))
          :
         chosen.academic.filter(bug=> bug.term === selectedterm).length !==0?
          
            chosen.academic.filter(bug=> bug.term === selectedterm)[0].results.map((row,index)=>
            (
                
            <TableRow
                  key={row.subject}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                                
                  <TableCell align="left">{row.subject}</TableCell>
                  <TableCell align="left">  <input  id={'class'+row.subject+index} defaultValue={row.class_scorre} style={{borderRadius:"5px", padding:"10px", border:"solid", borderWidth:"0.3px", borderColor:"grey" }}  type="number"/> </TableCell>
                  <TableCell align="left">  <input id={'exams'+row.subject+index}  defaultValue={row.exam_score}   style={{borderRadius:"5px", padding:"10px", border:"solid", borderWidth:"0.3px", borderColor:"grey" }} type="number"/>  </TableCell>

            </TableRow>
          )
        
            ):""
          

          

          }
        </TableBody>
      </Table>
    </TableContainer>

    <div align="center"> <Button onClick={()=>submitscore()}>submit</Button> </div>


                </DialogContent>



    </div> 
    )
        }

    
        const records =(recordtype)=>{
    if(recordtype==="edit"){
        return(
            editrecord()
        )
    }
     if(recordtype==="back"){
        return(
            mainrecord()
        )
    }
        }

    
        const guardianchange =(e)=>{

            setGuardian(e.target.value)

        } 
        

        const guardianchangeedit =(e)=>{

            setguardianedit(e.target.value)

        }


        const otherguardian = () =>{


            return(

                <div>

<p style={{textAlign:"center"}}>Fill this if Guardian is not a parent </p>

<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="surname"
    placeholder="Surname"
    type="text"
    className="signup"
    variant="outlined"
    
    size="small"


/>
</FormControl>
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="othernames"
    placeholder="Other name(s)"
    type="text"
   
    className="signup"
    variant="outlined"
    label="Othername(s)"
    size="small"



/>
</FormControl>
<br />


<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="occupation"
    placeholder="Occupation"
    type="text"
   
    className="signup"
    variant="outlined"
    label="Occupation"
    size="small"



/>
</FormControl>
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="contact"
    placeholder="Contact"
    type="tel"

    className="signup"
    variant="outlined"
    label="Contact"
    size="small"



/>
</FormControl>
<br />


<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="email"
    placeholder="Email"
    type="email"
    
    className="signup"
    variant="outlined"
    label="Email"
    size="small"



/>
</FormControl>
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="address"
    placeholder="Address"
    type="address"

    className="signup"
    variant="outlined"
    label="Address"
    size="small"



/>
</FormControl>
<br />

                </div>
            )

        }


        const otherguardianedit = () =>{


            return(

                <div>

<p style={{textAlign:"center"}}>Fill this if Guardian is not a parent </p>

<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="guardianlastnameedit"
    placeholder="Surname"
    type="text"
    className="signup"
    variant="outlined"
    defaultValue={chosen.guardianlastname}
    size="small"


/>
</FormControl>
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="guardianothernamesedit"
    placeholder="Other name(s)"
    type="text"
    defaultValue={chosen.guardianothernames}
    className="signup"
    variant="outlined"
    label="Othername(s)"
    size="small"



/>
</FormControl>
<br />


<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="guardianoccupationedit"
    placeholder="Occupation"
    type="text"
    defaultValue={chosen.guardianoccupation}
    className="signup"
    variant="outlined"
    label="Occupation"
    size="small"

/>
</FormControl>
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="guardiancontactedit"
    placeholder="Contact"
    type="tel"
    defaultValue={chosen.guardiancontact}
    className="signup"
    variant="outlined"
    label="Contact"
    size="small"



/>
</FormControl>
<br />


<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="guardianemailedit"
    placeholder="Email"
    type="email"
    defaultValue={chosen.guardianemail}
    className="signup"
    variant="outlined"
    label="Email"
    size="small"



/>
</FormControl>
<FormControl variant="standard" style={{ margin: 10 }}>

<TextField
    id="guardianresidenceedit"
    placeholder="Address"
    type="address"
    defaultValue={chosen.guardianresidence}
    className="signup"
    variant="outlined"
    label="Address"
    size="small"



/>
</FormControl>
<br />

                </div>
            )

        }


        const changer=(e,setter)=>{
setter(e.target.value)
        }


        const addscore=()=>{

            var upperlimit=document.getElementById("upperlimit").value
            var lowerlimit=document.getElementById("lowerlimit").value
            var grade=document.getElementById("grade").value
            var gradepoint=document.getElementById("gradepoint").value
            var remarks=document.getElementById("remarks").value
            var realscore = [...schooldetails_[0].grades,{id:new Date(),upperlimit:upperlimit,lowerlimit:lowerlimit,grade:grade,gradepoint:gradepoint,remarks:remarks}]

            
 const addscore_= async () => {
    await axios.post("https://servermain.sisrevolution.com/api/schools/updategrade/",
        {

            _id:schooldetails_[0]._id,
            grade:realscore,
            schoolID:admin.schoolID

            
        }).then(res => {

            if (res.status === 200) {

                dispatch(schooldetails({

                    data: [res.data]

                }))


                
                // setloading(!true)
                handleClosesupply()
                handleClicksnack()
                setmessage("Score added")
                setstated("success")
               

                
            } else {
                // setloading(!true)
                handleClosesupply()
                handleClicksnack()
                setmessage("Error adding score")
                setstated("error")

            }

        })

}

addscore_()


        }


        const removegrade=(data)=>{

        var score = [...schooldetails_[0].grades]
        var realscore = score.filter(bug=>bug.id!==data.id)

 const addscore__= async () => {
    await axios.post("https://servermain.sisrevolution.com/api/schools/updategrade/",
    {

        _id:schooldetails_[0]._id,
        grade:realscore,
        schoolID:admin.schoolID

        
    }).then(res => {

        if (res.status === 200) {

            dispatch(schooldetails({

                data: [res.data]

            }))


                // setloading(!true)
                handleClosesupply()
                handleClicksnack()
                setmessage("Score removed")
                setstated("success")
               

                
            } else {
                // setloading(!true)
                handleClosesupply()
                handleClicksnack()
                setmessage("Error adding score")
                setstated("error")

            }

        })

}

addscore__()


        }

       
        const updateterm=(e)=>{
            setloading(true)
        e.preventDefault()

    const addscore__= async () => {
    await axios.post("https://servermain.sisrevolution.com/api/schools/updateterm/",
    {

        _id:schooldetails_[0]._id,
        term:changeterm,
        schoolID:admin.schoolID

        
    }).then(res => {

        if (res.status === 200) {

            dispatch(schooldetails({

                data: [res.data]

            }))


                setloading(!true)
                handleClosesupply()
                handleClicksnack()
                setmessage("term updated")
                setstated("success")
               

                
            } else {
                setloading(!true)
                handleClosesupply()
                handleClicksnack()
                setmessage("Error adding score")
                setstated("error")

            }

        })

}

addscore__()


        }
     
        const removeterm=(data)=>{
        var termer= schooldetails_[0].terms
        var removed = termer.filter(bug=>bug!==data)

    const addscore__= async () => {
    await axios.post("https://servermain.sisrevolution.com/api/schools/removeterm/",
    {

        _id:schooldetails_[0]._id,
        term:removed,
        schoolID:admin.schoolID

        
    }).then(res => {

        if (res.status === 200) {

            dispatch(schooldetails({

                data: [res.data]

            }))


                setloading(!true)
                handleClosesupply()
                handleClicksnack()
                setmessage("term removed")
                setstated("success")
               

                
            } else {
                setloading(!true)
                handleClosesupply()
                handleClicksnack()
                setmessage("Error adding score")
                setstated("error")

            }

        })

}

addscore__()


        }


        const changetermfunction=(e)=>{
        setchangeterm(e.target.value)
        }
        
        
        const settrialmode_=()=>{
            settrialmode(false)
        }

    return (

        <div sx={{ width: '100%' }}>

            <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    { message }
                </Alert>
            </Snackbar>

            <Dialog maxWidth="lg" onClose={handleClosedelete} aria-labelledby="customized-dialog-title" open={opendelete}>
 <DialogTitle id="customized-dialog-title" onClose={handleCloseattendance} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Delete
                    <IconButton onClick={handleClosedelete} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>

                    <p>
                        Are you sure you want to delete {chosen.lastname+" "+chosen.othernames} from the database?
                    </p>

                </DialogContent>
                <DialogActions>

 {deletestudentloading === true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => deleter()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Submit
                                </Button>
                            }


                 

                    <Button autoFocus onClick={handleClosedelete} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog maxWidth="lg" onClose={settrialmode_} aria-labelledby="customized-dialog-title" open={trialmode}>
 <DialogTitle id="customized-dialog-title" onClose={settrialmode_}  style={{ backgroundColor: "gray", color: "white" }}>
                    Trial Mode
                    <IconButton onClick={settrialmode_}  style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>

                    <p>

                    You are on a trial mode, go to pricing and settle payment in order to use this function.

                    </p>

                </DialogContent>
                <DialogActions>




                 

                   <Link to="/pricing"><Button autoFocus  style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                        Pricing
                    </Button>
                    </Link> 
                </DialogActions>
            </Dialog>




            <Dialog fullWidth maxWidth="sm" onClose={handleClosemessage} aria-labelledby="customized-dialog-title" open={openmessage}>
             <DialogTitle id="customized-dialog-title" onClose={handleCloseattendance} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Messages
                    <IconButton onClick={handleClosemessage} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                {messenger(what)}
            
                <DialogActions>


                     
                    

                    <Button>
                    
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog fullWidth maxWidth="sm" onClose={handleCloseattendance} aria-labelledby="customized-dialog-title" open={openattendance}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseattendance} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Attendance
                    <IconButton onClick={handleCloseattendance} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers style={{textAlign:"center"}}>
                <FormControl style={{ marginRight:5 }}>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={selectedterm}
                            style={{ minWidth: 200, padding: 0, margin: 0 }}
                            onChange={(e)=>handleChangeterm(e)}
                            autoWidth
                            size="small"
                        >
                            <MenuItem value="none">
                                <em>Choose Term</em>
                            </MenuItem>
                            <MenuItem value={"1st Term / "+year}>{"1st Term / "+year}</MenuItem>
                            <MenuItem value={"2nd Term / "+year}>{"2nd Term / "+year}</MenuItem>
                            <MenuItem value={"3rd Term / "+year}>{"3rd Term / "+year}</MenuItem>

                        </Select>
                    </FormControl>
                    <br/>
                    <br/>
                    <Attendance atendance={selectedterm!=="none"?chosen.attendance[selectedterm]!==undefined?chosen.attendance[selectedterm]:[]:[]}/>
                
                    
                </DialogContent>
                
            </Dialog>

            <Dialog fullWidth maxWidth="xs" onClose={handleCloseview} aria-labelledby="customized-dialog-title" open={openview}>
               <DialogTitle id="customized-dialog-title" onClose={handleCloseview} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Student Information
                    <IconButton onClick={handleCloseview} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>

    {schooldetails_.length!==0 && schooldetails_[0].terms.length!==0?
    schooldetails_[0].terms.map((list,index)=>              
<div >
                    <Grid container style={{padding:15}}>
                        <Grid item xs={10}>{list}</Grid>
                        <Grid item xs={2} align="center"> 
                        <IconButton style={{color:"red", backgroundColor:"rgba(255,0,0,0.1)",marginTop:"-5px"}} >
    <CloseIcon style={{color:"red"}} onClick={()=>removeterm(list)} />
</IconButton> </Grid>
                    </Grid>
                    <Divider/>
                    </div>
    ):""
}
                    
                </DialogContent>
              
            </Dialog>

            <Dialog fullWidth maxWidth="lg" onClose={handleClosesupply} aria-labelledby="customized-dialog-title" open={opensupply}>
                
            {records(whattype)}

               
            </Dialog>

            <Dialog fullWidth maxWidth="sm" onClose={handleCloseimage} aria-labelledby="customized-dialog-title" open={openimage}>
            <DialogTitle id="customized-dialog-title" onClose={handleCloseview} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                   Add Term
                    <IconButton onClick={handleCloseimage} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers style={{ textAlign: "center" }}>
                    <br/>
                    <form onSubmit={updateterm}>
                <FormControl style={{ marginLeft:45, marginRight:5 }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Term</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={changeterm}
                            style={{ minWidth: 200, padding: 0, margin: 0 }}
                            onChange={changetermfunction}
                            autoWidth
                            label="Term"
                            size="small"
                        >
                            <MenuItem value="none">
                                <em>Choose Term</em>
                            </MenuItem>
                            <MenuItem value={"1st Term "+(year-1)+" / "+year}>{"1st Term "+(year-1)+" / "+(year)}</MenuItem>
                            <MenuItem value={"2nd Term "+(year-1)+" / "+year}>{"2nd Term "+(year-1)+" / "+(year)}</MenuItem>
                            <MenuItem value={"3rd Term "+(year-1)+" / "+year}>{"3rd Term "+(year-1)+" / "+(year)}</MenuItem>
                            <MenuItem value={"1st Term "+year+" / "+(year+1)}>{"1st Term "+year+" / "+(year+1)}</MenuItem>
                            <MenuItem value={"2nd Term "+year+" / "+(year+1)}>{"2nd Term "+year+" / "+(year+1)}</MenuItem>
                            <MenuItem value={"3rd Term "+year+" / "+(year+1)}>{"3rd Term "+year+" / "+(year+1)}</MenuItem>

                        </Select>
<br/>
                        {!loading?
          <input type="submit" value="SUBMIT" style={{padding:13.5, cursor:"pointer", borderRadius:5, outline:"none", border:"none", backgroundColor:" #e0eff9" , color:"#1976d2", width:"35ch", marginTop:"10px", marginBottom:"40px"}}/>
          :
          <Button size="large" disabled startIcon={<CircularProgress style={{color:"#062333", width:"20px", height:"20px"}} />}  style={{ backgroundColor:"lightgrey", color:"#062333", width:"35ch", marginTop:"10px", marginBottom:"8px"}} >Loading...</Button>
          }
       
                    </FormControl>
                    </form>
                </DialogContent>

            </Dialog>

            <Dialog  maxWidth="md" onClose={handleCloseadd} aria-labelledby="customized-dialog-title" open={openadd}>
                  <DialogTitle id="customized-dialog-title" onClose={handleCloseattendance} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    New Student
                    <IconButton onClick={handleCloseadd} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>

                    <DialogContent dividers>

                <form>

                        <div style={{ width: "100%", textAlign:"center" }}>



                            <input

                                style={{ display: "none" }}
                                id="fileimage_"
                                multiple
                                type="file"
                                onChange={(e) => imagehandler(e)}
                            />
                            <label htmlFor="fileimage_">
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <CameraAltIcon style={{ cursor:"pointer", backgroundColor: "darkblue", padding: 6, borderRadius: "100%", color: "white", fontSize: "26px" }} />
                                    }
                                >
                                    <Avatar style={{ width: 100, height: 100, border: "solid", borderColor: "#f0f0f0" }} alt="Travis Howard" src={person} />

                                </Badge>

                            </label>

                           
                        </div>
                        <br />

                        <Divider><small> <b>STUDENT'S PERSONAL DETAILS </b></small></Divider>
                    <br/>

                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="lastname"
                                placeholder="Surname"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Surname"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="othernames"
                                placeholder="Other name(s)"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Othername(s)"
                                size="small"



                            />
                        </FormControl>
                        <br />

                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="dob"
                                label="Date of Birth"
                                type="date"
                                defaultValue="2017-05-24"
                               
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="signup"
                                size="small"
                            />
                        </FormControl>

                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="residence"
                                placeholder="Residence Address"
                                type="address"
                                className="signup"
                                label="Residence Address"
                                size="small"



                            />
                        </FormControl>

                        <br />
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="hometown"
                                placeholder="Hometown"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Hometown"
                                size="small"



                            />
                        </FormControl>

                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Religion</InputLabel>
                            <Select
                                id="religion"
                                value={selectedreligion}
                                label="Religion"
                                className="signup"
                                variant="outlined"
                                size="small"

                                onChange={handleChange_religion}
                            >

                                <MenuItem value="none"><em>Choose Religion</em></MenuItem>
                                <MenuItem value="Christianity">Christianity</MenuItem>
                                <MenuItem value="Islam">Islam</MenuItem>
                                <MenuItem value="Jainism">Jainism</MenuItem>
                                <MenuItem value="Judaism">Judaism</MenuItem>
                                <MenuItem value="Shinto">Shinto</MenuItem>

                            </Select>


                        </FormControl>

                        <br />

                        <FormControl  style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Gender</InputLabel>
                            <Select
                                id="gender"
                                value={selectedgender}
                                label="Gender"
                                className="signup"
                                variant="outlined"
                                size="small"
                                style={{padding:0}}

                                onChange={handleChange_gender}
                            >
                                <MenuItem value="none"><em>Choose Gender</em></MenuItem>
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>

                            </Select>


                        </FormControl>

                        
                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Class</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={selectedclass}
                                label="Class"
                                className="signup"
                                variant="outlined"
                                size="small"

                                onChange={handleChange_class}
                            >
                                <MenuItem value="none"> <em>Choose Class</em> </MenuItem>
                              {Class_.length !==0 ? Class_.map((list, index)=>
                                <MenuItem value={list.classname}>{list.classname}</MenuItem>
                                
                                ):""}
                                

                            </Select>


                        </FormControl>

                        <br/>
                        <br/>
                        <Divider><small> <b>STUDENT'S PARENTS DETAILS </b></small></Divider>

                        <p style={{textAlign:"center"}}> <b>Mother</b></p>

                     
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="motherlastname"
                                placeholder="Surname"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Surname"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="motherothernames"
                                placeholder="Other name(s)"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Othername(s)"
                                size="small"



                            />
                        </FormControl>
                        <br />

                      
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="motheroccupation"
                                placeholder="Occupation"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Occupation"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="mothercontact"
                                placeholder="Contact"
                                type="tel"
                                className="signup"
                                variant="outlined"
                                label="Contact"
                                size="small"



                            />
                        </FormControl>
                        <br />

                        
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="motheremail"
                                placeholder="Email"
                                type="email"
                                className="signup"
                                variant="outlined"
                                label="Email"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="motherresidence"
                                placeholder="Address"
                                type="address"
                                className="signup"
                                variant="outlined"
                                label="Address"
                                size="small"



                            />
                        </FormControl>
                        <br />


                        <p style={{ textAlign: "center" }}> <b>Father </b></p>

                    
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="fatherlastname"
                                placeholder="Surname"
                                type="text"
                                className="signup"
                                variant="outlined"

                                size="small"


                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="fatherothernames"
                                placeholder="Other name(s)"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Othername(s)"
                                size="small"



                            />
                        </FormControl>
                        <br />

                      
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="fatheroccupation"
                                placeholder="Occupation"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Occupation"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="fathercontact"
                                placeholder="Contact"
                                type="tel"
                                className="signup"
                                variant="outlined"
                                label="Contact"
                                size="small"



                            />
                        </FormControl>
                        <br />

                        
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="fatheremail"
                                placeholder="Email"
                                type="email"
                                className="signup"
                                variant="outlined"
                                label="Email"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="fatherresidence"
                                placeholder="Address"
                                type="address"
                                className="signup"
                                variant="outlined"
                                label="Address"
                                size="small"



                            />
                        </FormControl>
                        <br />
                        <br />

                        <Divider><small> <b>GUARDIAN </b></small></Divider>
                        <br />

                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">Guardian Relationship</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                id="guardianedit"
                                value={guardian}
                                onChange={(e)=>guardianchange(e)}
                            >
                                <FormControlLabel value="Mother" control={<Radio />} label="Mother" />
                                <FormControlLabel value="Father" control={<Radio />} label="Father" />
                                <FormControlLabel value="Parents" control={<Radio />} label="Parents" />
                                <FormControlLabel value="Others" control={<Radio />} label="Others" />
                                
                            </RadioGroup>
                        </FormControl>
                        <br/>
                        {guardian === "Others" ?  otherguardian():""}

                        <br />

                        <div style={{ textAlign: "center" }}>
                           
                           
                        </div>

                        </form>
                    </DialogContent>

                    <DialogActions >
            {newstudentloading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => registerstudent()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Submit
                                </Button>
                            }


                      
                    </DialogActions>
                </Dialog>

            <Dialog maxWidth="lg" onClose={handleCloseedit} aria-labelledby="customized-dialog-title" open={openedit}>
                     <DialogTitle id="customized-dialog-title" onClose={handleCloseedit} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Edit Student Information
                    <IconButton onClick={handleCloseedit} style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>

                    <DialogContent dividers>



                        <div style={{ width: "100%", textAlign: "center" }}>



                            <input

                                style={{ display: "none" }}
                                id="fileimageedit"
                                multiple
                                type="file"
                                onChange={(e) => imagehandler(e)}
                            />
                            <label htmlFor="contained-button-file">
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <CameraAltIcon style={{ cursor: "pointer", backgroundColor: "darkblue", padding: 6, borderRadius: "100%", color: "white", fontSize: "26px" }} />
                                    }
                                >
                                    <Avatar style={{ width: 100, height: 100, border: "solid", borderColor: "#f0f0f0" }} alt="Travis Howard" src={person} />

                                </Badge>

                            </label>


                        </div>
                        <br />

                        <Divider><small> <b>STUDENT'S PERSONAL DETAILS </b></small></Divider>
                        <br />
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="lastnameedit"
                                placeholder="Lastname"
                                defaultValue={chosen.lastname}
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Surname"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="othernamesedit"
                                placeholder="Other name(s)"
                                type="text"
                                defaultValue={chosen.othernames}
                                className="signup"
                                variant="outlined"
                                label="Othername(s)"
                                size="small"



                            />
                        </FormControl>
                        <br />

                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="dobedit"
                                label="Date Of Birth"
                                type="date"
                              defaultValue={chosen.dob}

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="signup"
                                size="small"
                            />
                        </FormControl>

                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="residenceedit"
                                placeholder="Residence Address"
                                type="address"
                                className="signup"
                                defaultValue={chosen.residence}
                                label="Residence Address"
                                size="small"



                            />
                        </FormControl>

                        <br />
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="hometownedit"
                                placeholder="Hometown"
                                type="text"
                                className="signup"
                                defaultValue={chosen.hometown}
                                variant="outlined"
                                label="Hometown"
                                size="small"



                            />
                        </FormControl>

                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Religion</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={selectedreligionedit===""?chosen.religion:selectedreligionedit}
                                label="Religion"
                                className="signup"
                                
                                variant="outlined"
                                size="small"

                                onChange={handleChange_religionedit}
                            >
                                <MenuItem value="none"><em>Choose Religion</em></MenuItem>
                                <MenuItem value="Christian">Christian</MenuItem>
                                <MenuItem value="Muslim">Muslim</MenuItem>

                            </Select>


                        </FormControl>

                        <br />

                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Gender</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={selectedgenderedit===""?chosen.gender:selectedgenderedit}
                                label="Gender"
                                className="signup"
                                variant="outlined"
                                size="small"
                                style={{ padding: 0 }}

                                onChange={handleChange_genderedit}
                            >
                                <MenuItem value="none"><em>Choose Gender</em></MenuItem>
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>

                            </Select>


                        </FormControl>


                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Class</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={selectedclassedit===""?chosen.class_:selectedclassedit}
                                label="Class"
                                className="signup"
                                variant="outlined"
                                size="small"
                                onChange={handleChange_classedit}
                            >
                                <MenuItem value=""> <em>Choose Class</em></MenuItem>
                                
                                {Class_.length !==0 ? Class_.map((list, index)=>
                                <MenuItem value={list.classname}>{list.classname}</MenuItem>
                                
                                ):""}


                              
                            </Select>

                        </FormControl>

                        <br />
                        <br />

                        <Divider><small> <b>STUDENT'S PARENTS DETAILS </b></small></Divider>

                        <p style={{ textAlign: "center" }}> <b>Mother</b></p>


                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="motherlastnameedit"
                                placeholder="Surname"
                                type="text"
                                defaultValue={chosen.motherlastname}
                                className="signup"
                                variant="outlined"
                                label="Surname"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="motherothernamesedit"
                                placeholder="Other name(s)"
                                type="text"
                                defaultValue={chosen.motherothernames}
                                className="signup"
                                variant="outlined"
                                label="Othername(s)"
                                size="small"



                            />
                        </FormControl>
                        <br />


                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="motheroccupationedit"
                                placeholder="Occupation"
                                type="text"
                                defaultValue={chosen.motheroccupation}
                                className="signup"
                                variant="outlined"
                                label="Occupation"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="mothercontactedit"
                                placeholder="Contact"
                                type="tel"
                                defaultValue={chosen.mothercontact}
                                className="signup"
                                variant="outlined"
                                label="Contact"
                                size="small"



                            />
                        </FormControl>
                        <br />


                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="motheremailedit"
                                placeholder="Email"
                                type="email"
                                defaultValue={chosen.otheremail}
                                className="signup"
                                variant="outlined"
                                label="Email"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="motherresidenceedit"
                                placeholder="Address"
                                type="address"
                                defaultValue={chosen.motherresidence}
                                className="signup"
                                variant="outlined"
                                label="Address"
                                size="small"



                            />
                        </FormControl>
                        <br />


                        <p style={{ textAlign: "center" }}> <b>Father </b></p>


                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="fatherlastnameedit"
                                placeholder="Surname"
                                type="text"
                                defaultValue={chosen.fatherlastname}
                                className="signup"
                                variant="outlined"

                                size="small"


                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="fatherothernamesedit"
                                placeholder="Other name(s)"
                                type="text"
                                defaultValue={chosen.fatherothernames}
                                className="signup"
                                variant="outlined"
                                label="Othername(s)"
                                size="small"
                            />
                        </FormControl>
                        <br />


                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="fatheroccupationedit"
                                placeholder="Occupation"
                                type="text"
                                defaultValue={chosen.fatheroccupation}
                                className="signup"
                                variant="outlined"
                                label="Occupation"
                                size="small"

                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="fathercontactedit"
                                placeholder="Contact"
                                type="tel"
                                defaultValue={chosen.fathercontact}
                                className="signup"
                                variant="outlined"
                                label="Contact"
                                size="small"



                            />
                        </FormControl>
                        <br />


                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="fatheremailedit"
                                placeholder="Email"
                                type="email"
                                defaultValue={chosen.fatheremail}
                                className="signup"
                                variant="outlined"
                                label="Email"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="fatherresidenceedit"
                                placeholder="Address"
                                type="address"
                                defaultValue={chosen.fatherresidence}
                                className="signup"
                                variant="outlined"
                                label="Address"
                                size="small"



                            />
                        </FormControl>
                        <br />
                        <br />

                        <Divider><small> <b>GUARDIAN </b></small></Divider>
                        <br />

                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                id="guardianedit"
                                value={guardianedit===""?chosen.guardianrelationship:guardianedit}
                                onChange={guardianchangeedit}
                            >
                                <FormControlLabel value="Mother" control={<Radio />} label="Mother" />
                                <FormControlLabel value="Father" control={<Radio />} label="Father" />
                                <FormControlLabel value="Parents" control={<Radio />} label="Parents" />
                                <FormControlLabel value="Others" control={<Radio />} label="Others" />
                                
                            </RadioGroup>
                        </FormControl>
                        <br/>
                        {guardianedit === "Others"? otherguardianedit():"" }

                        <br />
                        <div style={{ textAlign: "center" }}>

                          


                        </div>
                    </DialogContent>

                    <DialogActions>
                     {editstudentloading ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => editstudent()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                    Submit
                                </Button>
                            }
                       
                    </DialogActions>
                </Dialog>
         

            <Paper sx={{ width: '100%', mb: 2 }}>

            <EnhancedTableToolbar  />
              
<Container>
<form onSubmit={setchanges}>

<div style={{ width: "100%", textAlign:"center" }}>



    <input

        style={{ display: "none" }}
        id="fileimage"
        multiple
        type="file"
        onChange={(e) => imagehandler(e)}
    />
    <label htmlFor="fileimage">
        <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
                <CameraAltIcon style={{ cursor:"pointer", backgroundColor: "darkblue", padding: 6, borderRadius: "100%", color: "white", fontSize: "26px" }} />
            }
        >
            <Avatar style={{ width: 130, height: 130, border: "solid", borderColor: "#f0f0f0" }} alt="Travis Howard" src={person} />

        </Badge>

    </label>
    <p align="center"><b>School Logo</b></p>

   
</div>
<Divider> <b>School Information</b> </Divider>
<br/>

<FormControl variant="standard" style={{ margin: 10 }}>

    <TextField
        id="schoolname"
        placeholder="School Name"
        type="text"
        className="signup"
        variant="outlined"
        label="School Name"
        size="small"
        onChange={(e)=>changer(e,setschoolname)}
        value={schoolname}



    />
</FormControl>
<FormControl variant="standard" style={{ margin: 10 }}>

    <TextField
        id="address"
        placeholder="Address"
        type="address"
        className="signup"
        variant="outlined"
        label="Address"
        size="small"
        onChange={(e)=>changer(e,setschooladdress)}
        value={schooladdress}



    />
</FormControl>
<br />


<FormControl variant="standard" style={{ margin: 10 }}>

    <TextField
        id="contact"
        placeholder="contact"
        type="tel"
        className="signup"
        label="Contact"
        size="small"
        onChange={(e)=>changer(e,setschoolcontact)}
        value={schoolcontact}



    />
</FormControl>

<FormControl variant="standard" style={{ margin: 10 }}>

    <TextField
        id="year"
        placeholder="Year Established"
        type="number"
        className="signup"
        label="Year Established"
        size="small"
        onChange={(e)=>changer(e,setyearestablished)}
        value={yearestablished}



    />
</FormControl>

<br/>

<FormControl style={{ margin: 10 }}>
    <InputLabel id="demo-simple-select-helper-label">School Size</InputLabel>
    <Select
        id="students"
        value={studentpopulation}
        label="School Size"
        className="signup"
        variant="outlined"
        size="small"
        style={{textAlign:"left"}}

        onChange={(e)=>setstudentpopulation(e.target.value)}
    >

        <MenuItem value="none"><em>Choose School Size</em></MenuItem>
        <MenuItem value="50-200">50-200</MenuItem>
        <MenuItem value="201-400">201-400</MenuItem>
        <MenuItem value="401-600">401-600</MenuItem>
        <MenuItem value="601-800">601-800</MenuItem>
        <MenuItem value="801-1000">801-1000</MenuItem>
        <MenuItem value="1001-1200">1001-1200</MenuItem>
        <MenuItem value="1201-1400">1201-1400</MenuItem>
        <MenuItem value="1401-1600">1401-1600</MenuItem>
        <MenuItem value="1601-1800">1601-1800</MenuItem>
        <MenuItem value="1801-2000">1801-2000</MenuItem>
 

    </Select>


</FormControl>


<FormControl  style={{ margin: 10 }}>
    <TextField
        id="staff"
        label="Staff Size"
        className="signup"
        variant="outlined"
        size="small"
        type="number"
        onChange={(e)=>changer(e,setstaffpopulation)}
        value={staffpopulation}

    />
      
</FormControl>
<br/>
{/* <FormControl  style={{ margin: 10 }}>

    <TextField

        id="index"
        label="email"
        className="signup"
        variant="outlined"
        size="small"
        type="email"
        onChange={(e)=>changer(e,setindexnumber)}
        value={indexnumber}

    />
      
</FormControl> */}

<FormControl  style={{ margin: 10 }}>
    <TextField

        id="email"
        label="email"
        className="signup"
        variant="outlined"
        size="small"
        type="email"
        onChange={(e)=>changer(e,setemail)}
        value={email}

    />
      
</FormControl>

<br/>
{!loading?
          <input type="submit" value="SUBMIT" style={{padding:13.5, cursor:"pointer", borderRadius:5, outline:"none", border:"none", backgroundColor:" #e0eff9" , color:"#1976d2", width:"35ch", marginTop:"10px", marginBottom:"40px"}}/>
          :
          <Button size="large" disabled startIcon={<CircularProgress style={{color:"#062333", width:"20px", height:"20px"}} />}  style={{ backgroundColor:"lightgrey", color:"#062333", width:"35ch", marginTop:"10px", marginBottom:"8px"}} >Loading...</Button>
          }

</form>
<br/>

<Divider> <b>Set School Re-opening Date</b> </Divider>
<p><b>Current Re-opening Date:</b> {schooldetails_.length!==0 && schooldetails_[0].reopendate!==""?months[parseInt((schooldetails_[0].reopendate.split("-"))[1])-1] +" "+(schooldetails_[0].reopendate.split("-"))[2]+", "+(schooldetails_[0].reopendate.split("-"))[0]:"Set Re-opening Date"} </p>

<FormControl variant="standard" style={{ margin: 10, textAlign:"center" }}>

<TextField
    id="reopendate"
    placeholder="Re-Opening Date"
    type="date"
    className="signup"
    variant="outlined"
    value={reopendate}
    onChange={(e)=>setreopendate(e.target.value)}
    // label="Re-Opening Date"
    size="small"



/>
</FormControl>
                   
{loading ===true?
    <div align="center"> 
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>
      </div>:
                           <div align="center"> 
                                <Button autoFocus onClick={() => submitdate()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                                   Set Date
                                </Button>
                                </div>
                            } 
<br/>

<br/>

<Divider> <b>Grading System</b> </Divider>
<br/>

<Container maxWidth="md">

<Grid container align="center" style={{marginBottom:"5px"}}>

<Grid xs={4}>
<b>SCORE</b>
</Grid>

<Grid xs={2}>
<b>GRADE</b>
</Grid>
<Grid xs={2}>
<b>GRADE Point</b>
</Grid>

<Grid xs={2}>
<b>REMARKS</b>
</Grid>

<Grid xs={2}>
<b>ACTIONS</b>
</Grid>

</Grid>
<Divider/>

<Grid container align="center" style={{marginTop:"15px"}}>

<Grid xs={4}>
<input type="number" id='upperlimit' placeholder='0' style={{border:"solid", borderWidth:"0.1px", borderColor:"lightgrey", borderRadius:"5px", width:"36%", margin:"5px", padding:"5px", textAlign:"center", fontSize:"20px" }} /> <b>-</b> <input placeholder='0' id='lowerlimit' type="number" style={{border:"solid", borderWidth:"0.1px", borderColor:"lightgrey", borderRadius:"5px", width:"36%", margin:"5px", padding:"5px", textAlign:"center", fontSize:"20px" }} />
</Grid>

<Grid xs={2}>
<select  id='grade' style={{border:"solid", color:"gray", borderWidth:"0.1px", borderColor:"lightgrey", borderRadius:"5px", width:"80%", margin:"5px", padding:"5px", textAlign:"center", fontSize:"20px" }}>
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6">6</option>
    <option value="7">7</option>
    <option value="8">8</option>
    <option value="9">9</option>
    <Divider style={{color:"gray", margin:5}}/>
    <option value="A+">A+</option>
    <option value="A">A</option>
    <option value="A-">A-</option>
    <option value="B+">B+</option>
    <option value="B-">B-</option>
    <option value="B">B</option>
    <option value="C">C</option>
    <option value="D">D</option>
    <option value="E">E</option>
    <option value="F">F</option>

</select>
</Grid>


<Grid xs={2}>
<select  id='gradepoint' style={{border:"solid", color:"gray", borderWidth:"0.1px", borderColor:"lightgrey", borderRadius:"5px", width:"80%", margin:"5px", padding:"5px", textAlign:"center", fontSize:"20px" }}>
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6">6</option>
    <option value="7">7</option>
    <option value="8">8</option>
    <option value="9">9</option>
   

</select>
</Grid>

<Grid xs={2}>
<select id='remarks' style={{border:"solid", color:"gray", borderWidth:"0.1px", borderColor:"lightgrey", borderRadius:"5px", width:"90%", margin:"5px", padding:"5px", textAlign:"center", fontSize:"20px" }}>
    <option value="Distinction">Distinction</option>
    <option value="Excellent">Excellent</option>
    <option value="Very Good">Very Good</option>
    <option value="Good">Good</option>
    <option value="High Average">High Average</option>
    <option value="Average">Average</option>
    <option value="Low Average">Low Average</option>
    <option value="Pass">Pass</option>
    <option value="Weak">Weak</option>
    <option value="Satisfactory">Satisfactory</option>
    <option value="Fail">Fail</option>
    

</select>
</Grid>

<Grid xs={2}>
<IconButton onClick={addscore} style={{color:"red", backgroundColor:"rgba(0,255,0,0.2)", marginTop:"3px"}}>
    <Plus style={{color:"green"}} />
</IconButton>
</Grid>

</Grid>
<br/>
<Divider/>


{
schooldetails_.length!==0 && schooldetails_[0].grades.length!==0?
schooldetails_[0].grades.map((list,index)=>
<Grid container align="center" style={{marginTop:"7px"}}>

<Grid xs={4} style={{marginTop:10}}>
<span style={{ width:"36%", margin:"5px", padding:"10px", textAlign:"center"}}>{list.upperlimit}</span> <b>-</b> <span style={{ width:"36%", margin:"5px", padding:"10px", textAlign:"center" }}>{list.lowerlimit}</span>
</Grid>

<Grid xs={2} style={{marginTop:10}}>
{list.grade}
</Grid>
<Grid xs={2} style={{marginTop:10}}>
{list.gradepoint}
</Grid>
<Grid xs={2} style={{marginTop:10}}>
{list.remarks}
</Grid>

<Grid xs={2}>
<IconButton style={{color:"red", backgroundColor:"rgba(255,0,0,0.1)", marginTop:"3px"}}>
    <CloseIcon style={{color:"red"}} onClick={()=>removegrade(list)} />
</IconButton>
</Grid>

</Grid>
)
:<p align="center">No data found</p>
}

{/* <Button style={{backgroundColor:"rgba(0,255,0,0.2)" , color:"green", marginTop:15, marginLeft:10}}>Add Grades</Button> */}
{/* <Button style={{backgroundColor:" #e0eff9" , color:"#1976d2", marginTop:20}}>Submit changes</Button> */}

</Container>
<br/>
<br/>
<Divider> <b>Term Settings</b> </Divider>

<p align="center"><b>Current Term: </b>{schooldetails_.length !==0 && schooldetails_[0].terms.length!==0? schooldetails_[0].terms[schooldetails_[0].terms.length-1]  : "No recorded term"}</p>
<p align="center"><b>Number of recorded terms: </b>{schooldetails_.length !==0 && schooldetails_[0].terms.length!==0? schooldetails_[0].terms.length : 0}</p>
<Button size="large" onClick={handleOpenview} style={{  backgroundColor:" #e0eff9" , color:"#1976d2",  marginTop:"10px", marginBottom:"8px"}} >View Recorded Terms</Button>
<Button size="large" onClick={handleClickOpenimage} style={{ marginLeft:20, backgroundColor:" #e0eff9" , color:"#1976d2",  marginTop:"10px", marginBottom:"8px"}} >Add Term</Button>

<br/>
<br/>
<br/>


</Container>

            </Paper>
         
        </div>


    );
}
