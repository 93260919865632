import { createSlice } from "@reduxjs/toolkit"
//import axios from 'axios';

const dt = new Date()
const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
const day = dt.getUTCDate();
const month = String(dt.getMonth() + 1).padStart(2, '0');
const year = dt.getFullYear();
const date = year + "-" + month + "-" + day;


let data = []

if (localStorage.getItem("student") !== null && localStorage.getItem("student") !== undefined && localStorage.getItem("student") !== "") {

    data = [JSON.parse(localStorage.getItem("student"))]

}


const Insider = createSlice({

    name: "todos",
    initialState: data,
    reducers: {

        getdata: (state, action) => {

            state = action.payload.data
            return state

        },

        logout_: (state, action) => {


            localStorage.clear()
            state = []
            return state


        },

        updatestudentdata: (state, action) => {


            state = action.payload.data
            return state

        },


    }
})

export const { logout_, updatestudentdata, login, getdata } = Insider.actions;
export default Insider.reducer;