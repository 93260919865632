import * as React from 'react';
import Table from './tablestudent';


import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

function Profile() {
    return (
        <div >
            <Table />

        </div>
    );
}

export default Profile;
