import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Avatar from '@mui/material/Avatar';
import SupplyProduct from '@mui/icons-material/LocalShipping';
import AddSRV from '@mui/icons-material/PostAdd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Search from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Show from './tableshow';
import FormLabel from '@mui/material/FormLabel';

import TextField from '@mui/material/TextField';
import Menustyle from './menuteachers';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import CircularProgress from '@mui/material/CircularProgress';
import ImageIcon from '@mui/icons-material/Image';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import Container from '@mui/material/Container';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import axios from 'axios';

import Badge from '@mui/material/Badge';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Person from './images/Ellipse77.png';

import Stack from '@mui/material/Stack';
import Snack from './snack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { seedetails, getproducts } from './redux/todoSlice';
import { admin } from './redux/admin';
import { schooldetails } from './redux/schooldetails';
import { loin, logout, updateschooldata, getdata } from './redux/schools';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



function createData(date, attendance, student) {
    return {
        date,
        attendance,
        student

    };
}

const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Donut', 452, 25.0, 51, 4.9),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Honeycomb', 408, 3.2, 87, 6.5),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Jelly Bean', 375, 0.0, 94, 0.0),
    createData('KitKat', 518, 26.0, 65, 7.0),
    createData('Lollipop', 392, 0.2, 98, 0.0),
    createData('Marshmallow', 318, 0, 81, 2.0),
    createData('Nougat', 360, 19.0, 9, 37.0),
    createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [



    {
        id: 'date',
        numeric: !true,
        disablePadding: false,
        label: 'Date',
    },
    {
        id: 'students',
        numeric: !true,
        disablePadding: false,
        label: 'Students',
    },
    {
        id: 'attendance',
        numeric: !true,
        disablePadding: false,
        label: 'Attendance',
    }
    
    
];


function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell align="center">
                    <b>
                        No.
                    </b>
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <b>{headCell.label}</b>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >

            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                ATTENDANCE
            </Typography>



        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable(props) {
    const dt = new Date()
    const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
    const day = dt.getUTCDate();
    const month = String(dt.getMonth() + 1).padStart(2, '0');
    const year = dt.getFullYear();
    const date = year + "-" + month + "-" + day;

    const [opensnack, setOpensnack] = React.useState(false);
    const [preload, setpreload] = React.useState(true);
    const [selecteddate, setselecteddate] = React.useState(date)
  
    const handleClicksnack = () => {
        setOpensnack(true);
    };

    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };

    const dispatch = useDispatch()
    const schools = useSelector((state) => state.students).filter(bug=>bug.class_===props.data.classname)
    
    const admin = useSelector((state) => state.admin)
    const schooldetails_ = useSelector((state) => state.schooldetails)

    const term = (schooldetails_.length!==0?schooldetails_[0].terms[schooldetails_[0].terms.length-1]:"none")

    const schooldata= async () => {
        await axios.post("https://servermain.sisrevolution.com/api/schools/getschooldata/",
            {
                schoolID:admin.schoolID

            }).then(res => {

                if (res.status === 200) {

                    dispatch(schooldetails({

                        data: res.data

                    }))


                }

            })

    }

    const gettingdata = async () => {
        await axios.post("https://servermain.sisrevolution.com/api/school/getschooldata/",
            {


            }).then(res => {

                if (res.status === 200) {

                    dispatch(getdata({

                        data: res.data

                    }))


                }

            })

    }

    const gettingdata_ = async () => {
        await axios.post("https://servermain.sisrevolution.com/api/students/getstudentdata/",
            {
                status:"Active",
                schoolID:admin.schoolID


            }).then(res => {

                if (res.status === 200) {

                    dispatch(getdata({

                        data: res.data

                    }))


                }

            })

    }

  React.useEffect(()=>{
        gettingdata()
        gettingdata_()
        schooldata()
  },[preload])
    const data = [schools.map((list, index) => createData(list.date, list.attendance, list.lastname+" "+list.othernames))]
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

   
    var rows = data[0]


    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl_, setAnchorEl_] = React.useState(null);
    const open = Boolean(anchorEl);
    const open_ = Boolean(anchorEl_);
    const [opensupply, setOpensupply] = React.useState(!true);
    const [name, setname] = React.useState(!true);
    const [location, setlocation] = React.useState(!true);
    const [allocation, setallocation] = React.useState(!true);
    const [contact, setcontact] = React.useState(!true);
    const [message, setmessage] = React.useState("");
    const [stated, setstated] = React.useState("");


    const [openadd, setOpenadd] = React.useState(!true);
    const [openview, setOpenview] = React.useState(!true);
    const [opensrv, setOpensrv] = React.useState(!true);
    const [openedit, setOpenedit] = React.useState(!true);
    const [openimage, setOpenimage] = React.useState(!true);
    const [opendelete, setOpendelete] = React.useState(!true);
    const [age, setAge] = React.useState('');
    const [sorter, setsorter] = React.useState("none");
    const [chosen, setchosen] = React.useState('');
    const [loading, setloading] = React.useState(false)
    const [search, setsearch] = React.useState("")
    const [selectedclass, setselectedclass] = React.useState("")
    const [selectedgender, setselectedgender] = React.useState("")
    const [selectedreligion, setselectedreligion] = React.useState("")
    const [person, setperson] = React.useState(Person)
 var totaler=0
    const searching = (e) => {
        setsearch(e.target.value)
    }

    if (search !== "") {
        var listed = rows.filter(bug => bug.student.toLowerCase().includes(search.toLowerCase()))
        rows = listed
    }

    console.log(rows)
    
    const changedate=(e)=>{
        setselecteddate(e.target.value)
        console.log(selecteddate)
    }


    //actions
    const addtolist = (data) => {
        setloading(true)
        console.log(data)
        var quantity = document.getElementById("Quantity").value
        var SRV = document.getElementById("SRVrec").value
        var By = document.getElementById("supplier").value
        var term = age
        var done = [...data.data, { date: date, sem: term, quantity: quantity, SRVrec: SRV, SRVsent: 0, SRVcon: 0, SRVpaid: 0, suppliedby: By }]

        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/school/updateschooldata/",
                {
                    id: data.id,
                    data: done,



                }).then(res => {

                    if (res.status === 200) {

                        dispatch(updateschooldata({

                            data: res.data

                        }))
                        setloading(!true)
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("You have successfully added to supply list")
                        setstated("success")



                    } else {
                        setloading(!true)
                        handleClosesupply()
                        handleClicksnack()
                        setmessage("Error adding to supply list")
                        setstated("error")

                    }

                })

        }

        doer()

    }

    const changeedit = (changer, e) => {
        changer(e.target.value)
        console.log(e.target.value)
    }

    const editschool = (data) => {
        setloading(true)

        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/school/editschool/",
                {
                    id: chosen.id,
                    school: name,
                    location: location,
                    allocation: allocation,
                    contact: contact,



                }).then(res => {

                    if (res.status === 200) {

                        dispatch(updateschooldata({

                            data: res.data

                        }))
                        setloading(!true)
                        handleCloseedit()
                        handleClicksnack()
                        setmessage("data has been edited")
                        setstated("sucess")

                    } else {
                        setloading(!true)
                        handleCloseedit()
                        handleClicksnack()
                        setmessage("Error editting data")
                        setstated("error")
                    }

                })

        }

        doer()

    }

    const addschool = (data) => {
        setloading(true)
        var name = document.getElementById("schoolname").value
        var location = document.getElementById("schoollocation").value
        var allocation = document.getElementById("schoolallocation").value
        var contact = document.getElementById("schoolcontact").value

        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/school/addschool/",
                {
                    school: name,
                    location: location,
                    allocation: allocation,
                    contact: contact,



                }).then(res => {

                    if (res.status === 200) {

                        dispatch(updateschooldata({

                            data: res.data

                        }))

                        setloading(!true)
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("You have added to school list")
                        setstated("success")

                    } else {
                        setloading(!true)
                        handleCloseadd()
                        handleClicksnack()
                        setmessage("Error adding to school list")
                        setstated("error")
                    }

                })

        }

        doer()

    }

    const deleter = (data) => {

        setloading(true)

        const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/school/deleteschool/",
                {
                    id: chosen.id

                }).then(res => {

                    if (res.status === 200) {

                        dispatch(updateschooldata({

                            data: res.data

                        }))

                        setloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Data has been deleted")
                        setstated("success")

                    } else {
                        setloading(!true)
                        handleClosedelete()
                        handleClicksnack()
                        setmessage("Error deleting data")
                        setstated("error")
                    }

                })

        }

        doer()

    }

    const handleClickOpenedit = (data) => {
        console.log(data)
        setchosen(data)
        setOpenedit(true);
    };

    const handleCloseedit = () => {
        setOpenedit(false);
    };

    const handleClickOpenimage = (data) => {
        console.log(data)
        setchosen(data)
        setOpenimage(true);
    };
    const handleCloseimage = () => {
        setOpenimage(false);
    };

    const handleClickOpendelete = (data) => {
        console.log(data)
        setchosen(data)
        setOpendelete(true);
    };
    const handleClosedelete = () => {
        setOpendelete(false);
    };

    const handleClickOpenadd = (data) => {
        setchosen(data)
        setOpenadd(true);
    };
    const handleCloseadd = () => {
        setOpenadd(false);
    };

    const handleClickOpensrv = (data) => {
        console.log(data)
        setchosen(data)
        setOpensrv(true);
    };
    const handleClosesrv = () => {
        setOpensrv(false);
    };

    const handleClickOpenview = (data) => {
        console.log(data)
        setchosen(data)
        setOpenview(true);
    };
    const handleCloseview = () => {
        setOpenview(false);
    };
    const handleClickOpensupply = (data) => {
        console.log(data)
        setchosen(data)
        setOpensupply(true);
    };
    const handleClosesupply = () => {
        setOpensupply(false);
    };

    const imagehandler = (e) => {
        const reader = new FileReader()
        reader.onload = () => {
            if (reader.readyState === 2) {
                setperson(reader.result)
            }
        }

        reader.readAsDataURL(e.target.files[0])
    }

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChange_gender = (e) => {
        setselectedgender(e.target.value)
    }
    const handleChange_religion = (e) => {
        setselectedreligion(e.target.value)
    }
    const handleChange_class = (e) => {
        setselectedclass(e.target.value)
    }

    const sorting = (e) => {

        setsorter(e.target.value)
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (

        <Box sx={{ width: '100%' }}>

            <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>

            <Dialog maxWidth="lg" onClose={handleClosedelete} aria-labelledby="customized-dialog-title" open={opendelete}>

                <DialogContent dividers>

                    <p>
                        Are you sure you want to delete this ?
                    </p>

                </DialogContent>
                <DialogActions>

                    {loading === false ?
                        <Button autoFocus onClick={() => deleter(chosen)} color="primary">
                            Yes
                        </Button>
                        :
                        <Button disabled={loading} >

                            <CircularProgress size={20} /> Loading...

                        </Button>
                    }


                    <Button autoFocus onClick={handleClosedelete} color="primary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog fullWidth width="sm" onClose={handleCloseview} aria-labelledby="customized-dialog-title" open={openview}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseview} style={{ backgroundColor: "darkblue", color: "white" }}>
                    View Staff Details
                </DialogTitle>
                <DialogContent dividers>
                    <div style={{textAlign:"center", marginLeft:"40%"}}>
                    <Avatar style={{ width: 100, height: 100, border: "solid", borderColor: "#f0f0f0" }} alt="Travis Howard" src={person} />
                    </div>

                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <ListItem>
                            <ListItemText primary="Name here" secondary="Name" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.location} secondary="Contact" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.location} secondary="Email" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.contact} secondary="Residence Address" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.contact} secondary="Gender" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.contact} secondary="Date of Birth" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.contact} secondary="Religion" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={chosen.contact} secondary="Status" />
                        </ListItem>
                        <Divider />



                    </List>

                    <br />

              
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCloseview} color="primary">
                        close
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog maxWidth="lg" onClose={handleClosesupply} aria-labelledby="customized-dialog-title" open={opensupply}>
                <DialogTitle id="customized-dialog-title" onClose={handleClosesupply} style={{ backgroundColor: "darkblue", color: "white" }}>
                    Add to Supply List
                </DialogTitle>

                <DialogContent dividers style={{ textAlign: "center" }}>


                    <FormControl variant="standard" style={{ margin: 20 }}>

                        <Input
                            id="Quantity"
                            placeholder="Quantity Supplied"
                            type="number"
                            className="signup"


                        />
                    </FormControl>
                    <FormControl variant="standard" style={{ margin: 20 }}>

                        <Input
                            id="SRVrec"
                            placeholder="SRVs Received"
                            type="number"
                            className="signup"


                        />
                    </FormControl>
                    <br />
                    <FormControl variant="standard" style={{ margin: 20 }}>

                        <Input
                            id="supplier"
                            placeholder="Supplied By..."
                            type="text"
                            className="signup"


                        />
                    </FormControl>

                    <FormControl style={{ margin: 20 }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Semester</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            value={age}
                            style={{ minWidth: 200, padding: 0, margin: 0 }}
                            onChange={handleChange}
                            autoWidth
                            label="Semester"
                        >
                            <MenuItem value="">
                                <em>Choose semester</em>
                            </MenuItem>
                            <MenuItem value={year + "/Semester 1"}>{year + "/Semester 1"}</MenuItem>
                            <MenuItem value={year + "/Semester 2"}>{year + "/Semester 2"}</MenuItem>

                        </Select>
                    </FormControl>

                    <br />


                </DialogContent>

                <DialogActions>
                    {loading === false ?
                        <Button autoFocus onClick={() => addtolist(chosen)} color="primary">
                            Submit
                        </Button>
                        :
                        <Button disabled={loading} >

                            <CircularProgress size={20} /> Loading...

                        </Button>
                    }

                    <Button autoFocus onClick={handleClosesupply} color="primary">
                        close
                    </Button>
                </DialogActions>
            </Dialog>



            <Dialog fullWidth maxWidth="sm" onClose={handleCloseimage} aria-labelledby="customized-dialog-title" open={openimage}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseimage} style={{ backgroundColor: "darkblue", color: "white" }}>
                    Student Name
                </DialogTitle>

                <DialogContent dividers style={{ textAlign: "center" }}>


                    <h1>
                        Image
                    </h1>

                </DialogContent>

                <DialogActions>

                    <Button autoFocus onClick={handleCloseimage} color="primary">
                        close
                    </Button>
                </DialogActions>
            </Dialog>


            <div>

                <Dialog maxWidth="lg" onClose={handleCloseadd} aria-labelledby="customized-dialog-title" open={openadd}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseadd} style={{ backgroundColor: "darkblue", color: "white" }}>
                        Add Staff
                    </DialogTitle>

                    <DialogContent dividers>



                        <div style={{ width: "100%", textAlign: "center" }}>



                            <input

                                style={{ display: "none" }}
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={(e) => imagehandler(e)}
                            />
                            <label htmlFor="contained-button-file">
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <CameraAltIcon style={{ cursor: "pointer", backgroundColor: "darkblue", padding: 6, borderRadius: "100%", color: "white", fontSize: "26px" }} />
                                    }
                                >
                                    <Avatar style={{ width: 100, height: 100, border: "solid", borderColor: "#f0f0f0" }} alt="Travis Howard" src={person} />

                                </Badge>

                            </label>


                        </div>

                        <br />
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="surname"
                                placeholder="Surname"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Surname"
                                size="small"



                            />
                        </FormControl>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="othernames"
                                placeholder="Other name(s)"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Othername(s)"
                                size="small"



                            />
                        </FormControl>
                        <br />

                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="date"
                                label="Date o Birth"
                                type="date"
                                defaultValue="2017-05-24"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="signup"
                                size="small"
                            />
                        </FormControl>

                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="residence"
                                placeholder="Residence Address"
                                type="address"
                                className="signup"
                                label="Residence Address"
                                size="small"



                            />
                        </FormControl>

                        <br />
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="hometown"
                                placeholder="Hometown"
                                type="text"
                                className="signup"
                                variant="outlined"
                                label="Hometown"
                                size="small"



                            />
                        </FormControl>

                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Religion</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={selectedreligion}
                                label="Religion"
                                className="signup"
                                variant="outlined"
                                size="small"

                                onChange={handleChange_religion}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>

                            </Select>


                        </FormControl>

                        <br />

                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Gender</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={selectedgender}
                                label="Gender"
                                className="signup"
                                variant="outlined"
                                size="small"
                                style={{ padding: 0 }}

                                onChange={handleChange_gender}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>

                            </Select>


                        </FormControl>


                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Position</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={selectedclass}
                                label="Position"
                                className="signup"
                                variant="outlined"
                                size="small"

                                onChange={handleChange_class}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="Class 1">Class 1</MenuItem>
                                <MenuItem value="Class 2">Class 2</MenuItem>
                                <MenuItem value="Class 3">Class 3</MenuItem>
                                <MenuItem value="Class 4">Class 4</MenuItem>
                                <MenuItem value="Class 5">Class 5</MenuItem>


                            </Select>


                        </FormControl>
<br/>
                        <FormControl style={{ margin: 10 }}>
                            <InputLabel id="demo-simple-select-helper-label">Position Description</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={selectedclass}
                                label="Position Description"
                                className="signup"
                                variant="outlined"
                                size="small"

                                onChange={handleChange_class}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="Class 1">Class 1</MenuItem>
                                <MenuItem value="Class 2">Class 2</MenuItem>
                                <MenuItem value="Class 3">Class 3</MenuItem>
                                <MenuItem value="Class 4">Class 4</MenuItem>
                                <MenuItem value="Class 5">Class 5</MenuItem>


                            </Select>


                        </FormControl>

                        
                      

                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="contact"
                                placeholder="Contact"
                                type="tel"
                                className="signup"
                                variant="outlined"
                                label="Contact"
                                size="small"



                            />
                        </FormControl>

<br/>
                        <FormControl variant="standard" style={{ margin: 10 }}>

                            <TextField
                                id="email"
                                placeholder="Email"
                                type="email"
                                className="signup"
                                variant="outlined"
                                label="Email"
                                size="small"



                            />
                        </FormControl>

                        
                       



                        <FormControl style={{ marginLeft: 10 }}>
                            <FormLabel id="demo-row-radio-buttons-group-label">Marital Status</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value="Single" control={<Radio />} label="Single" />
                                <FormControlLabel value="Married" control={<Radio />} label="Married" />
                                
                            </RadioGroup>
                        </FormControl>

                        <br />
                        <div style={{ textAlign: "center" }}>

                            {loading === false ?
                                <Button autoFocus onClick={() => addschool()} color="primary">
                                    Submit
                                </Button>
                                :
                                <Button disabled={loading} >

                                    <CircularProgress size={20} /> Loading...

                                </Button>
                            }


                        </div>
                    </DialogContent>

                    <DialogActions>
                        <Button autoFocus onClick={handleCloseadd} color="primary">
                            close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>


            <div>

                <Dialog  maxWidth="lg" onClose={handleCloseedit} aria-labelledby="customized-dialog-title" open={openedit}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseedit} style={{ backgroundColor: "darkblue", color: "white" }}>
                        Edit Staff
                    </DialogTitle>

                    <DialogContent dividers>



<div style={{ width: "100%", textAlign: "center" }}>



    <input

        style={{ display: "none" }}
        id="contained-button-file"
        multiple
        type="file"
        onChange={(e) => imagehandler(e)}
    />
    <label htmlFor="contained-button-file">
        <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
                <CameraAltIcon style={{ cursor: "pointer", backgroundColor: "darkblue", padding: 6, borderRadius: "100%", color: "white", fontSize: "26px" }} />
            }
        >
            <Avatar style={{ width: 100, height: 100, border: "solid", borderColor: "#f0f0f0" }} alt="Travis Howard" src={person} />

        </Badge>

    </label>


</div>

<br />
<FormControl variant="standard" style={{ margin: 10 }}>

    <TextField
        id="surname"
        placeholder="Surname"
        type="text"
        className="signup"
        variant="outlined"
        label="Surname"
        size="small"



    />
</FormControl>
<FormControl variant="standard" style={{ margin: 10 }}>

    <TextField
        id="othernames"
        placeholder="Other name(s)"
        type="text"
        className="signup"
        variant="outlined"
        label="Othername(s)"
        size="small"



    />
</FormControl>
<br />

<FormControl variant="standard" style={{ margin: 10 }}>

    <TextField
        id="date"
        label="Date o Birth"
        type="date"
        defaultValue="2017-05-24"

        InputLabelProps={{
            shrink: true,
        }}
        className="signup"
        size="small"
    />
</FormControl>

<FormControl variant="standard" style={{ margin: 10 }}>

    <TextField
        id="residence"
        placeholder="Residence Address"
        type="address"
        className="signup"
        label="Residence Address"
        size="small"



    />
</FormControl>

<br />
<FormControl variant="standard" style={{ margin: 10 }}>

    <TextField
        id="hometown"
        placeholder="Hometown"
        type="text"
        className="signup"
        variant="outlined"
        label="Hometown"
        size="small"



    />
</FormControl>

<FormControl style={{ margin: 10 }}>
    <InputLabel id="demo-simple-select-helper-label">Religion</InputLabel>
    <Select
        id="demo-simple-select-helper"
        value={selectedreligion}
        label="Religion"
        className="signup"
        variant="outlined"
        size="small"

        onChange={handleChange_religion}
    >
        <MenuItem value="">
            <em>None</em>
        </MenuItem>
        <MenuItem value="Male">Male</MenuItem>
        <MenuItem value="Female">Female</MenuItem>

    </Select>


</FormControl>

<br />

<FormControl style={{ margin: 10 }}>
    <InputLabel id="demo-simple-select-helper-label">Gender</InputLabel>
    <Select
        id="demo-simple-select-helper"
        value={selectedgender}
        label="Gender"
        className="signup"
        variant="outlined"
        size="small"
        style={{ padding: 0 }}

        onChange={handleChange_gender}
    >
        <MenuItem value="">
            <em>None</em>
        </MenuItem>
        <MenuItem value="Male">Male</MenuItem>
        <MenuItem value="Female">Female</MenuItem>

    </Select>


</FormControl>


<FormControl style={{ margin: 10 }}>
    <InputLabel id="demo-simple-select-helper-label">Position</InputLabel>
    <Select
        id="demo-simple-select-helper"
        value={selectedclass}
        label="Position"
        className="signup"
        variant="outlined"
        size="small"

        onChange={handleChange_class}
    >
        <MenuItem value="">
            <em>None</em>
        </MenuItem>
        <MenuItem value="Class 1">Class 1</MenuItem>
        <MenuItem value="Class 2">Class 2</MenuItem>
        <MenuItem value="Class 3">Class 3</MenuItem>
        <MenuItem value="Class 4">Class 4</MenuItem>
        <MenuItem value="Class 5">Class 5</MenuItem>


    </Select>


</FormControl>
<br/>
<FormControl style={{ margin: 10 }}>
    <InputLabel id="demo-simple-select-helper-label">Position Description</InputLabel>
    <Select
        id="demo-simple-select-helper"
        value={selectedclass}
        label="Position Description"
        className="signup"
        variant="outlined"
        size="small"

        onChange={handleChange_class}
    >
        <MenuItem value="">
            <em>None</em>
        </MenuItem>
        <MenuItem value="Class 1">Class 1</MenuItem>
        <MenuItem value="Class 2">Class 2</MenuItem>
        <MenuItem value="Class 3">Class 3</MenuItem>
        <MenuItem value="Class 4">Class 4</MenuItem>
        <MenuItem value="Class 5">Class 5</MenuItem>


    </Select>


</FormControl>




<FormControl variant="standard" style={{ margin: 10 }}>

    <TextField
        id="contact"
        placeholder="Contact"
        type="tel"
        className="signup"
        variant="outlined"
        label="Contact"
        size="small"



    />
</FormControl>

<br/>
<FormControl variant="standard" style={{ margin: 10 }}>

    <TextField
        id="email"
        placeholder="Email"
        type="email"
        className="signup"
        variant="outlined"
        label="Email"
        size="small"



    />
</FormControl>






<FormControl style={{ marginLeft: 10 }}>
    <FormLabel id="demo-row-radio-buttons-group-label">Marital Status</FormLabel>
    <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
    >
        <FormControlLabel value="Single" control={<Radio />} label="Single" />
        <FormControlLabel value="Married" control={<Radio />} label="Married" />
        
    </RadioGroup>
</FormControl>

<br />
<div style={{ textAlign: "center" }}>

    {loading === false ?
        <Button autoFocus onClick={() => addschool()} color="primary">
            Submit
        </Button>
        :
        <Button disabled={loading} >

            <CircularProgress size={20} /> Loading...

        </Button>
    }


</div>
</DialogContent>


                    <DialogActions>
                        <Button autoFocus onClick={handleCloseedit} color="primary">
                            close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>



            <Paper sx={{ width: '100%', mb: 2 }}>
            <EnhancedTableToolbar numSelected={selected.length} />
          
<p style={{margin:2, fontSize:"30px", fontWeight:"bolder"}}> {rows.filter(bug=>bug.attendance.length!==0 && bug.attendance[term]!==undefined).length!==0 ? rows.filter(bug=> bug.attendance[term].filter(buger=>buger.date===selecteddate ).length!==0 && bug.attendance[term].filter(buger=>buger.date===selecteddate )[0].attendance==="present").length:0}  / {rows.filter(bug=>bug.attendance.length!==0 && bug.attendance[term]!==undefined).length!==0 ? rows.filter(bug=>bug.attendance[term].filter(buger=>console.log(buger))).length:0}</p>

<Grid container align="center">
    
    
<Grid lg={3} xs={3} md={5} style={{ textAlign: "center" }}>
                        <FormControl style={{ marginLeft: 10, maxWidth: 150, padding: 0, marginTop: 10, borderColor: "#2196f3" }}>
                            <TextField
                                id="demo-simple-select-autowidth"
                                value={selecteddate}
                                onChange={(e)=>changedate(e)}
                                autoWidth
                                size='small'
                                type="date"
                                style={{ height: "38px", width: "100%", padding: 0, color: "grey", borderColor: "#2196f3", outline: "none" }}
                            />
                        </FormControl>
                    </Grid>

   
    <Grid lg={9} xs={9} md={7}>
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </Grid>

    <Grid lg={12} xs={12} md={12}>
        <Search id="searchbox" style={{ position: "absolute", marginTop: "18px", marginLeft: "8px", color: "grey" }} /> <input type="search" onChange={(e) => searching(e)} placeholder="Search" style={{ padding: 10, paddingLeft: "38px", marginTop: 10, width: "95%", border: "solid", borderRadius: "5px", borderWidth: "0.5px", backgroundColor: "rgba(65,105,225, 0.05) ", borderColor: "#2196f3" }} />
    </Grid>

</Grid>
                <br />
                <Divider />
                <TableContainer>
                    <Table

                        sx={{ minWidth: "100%" }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >

                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />

                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover

                                        >
                                         
                                            <TableCell align="left">{index+1+"."}</TableCell>
                                            <TableCell align="left">{months[parseInt((selecteddate.split("-"))[1])-1] +" "+(selecteddate.split("-"))[2]+", "+(selecteddate.split("-"))[0] }</TableCell>
                                            <TableCell align="left">{row.student}</TableCell>
                                            <TableCell align="left">{row.attendance.length!==0 &&  row.attendance[term] && row.attendance[term].filter(bug=>bug.date===selecteddate).length!==0?row.attendance[term].filter(bug=>bug.date===selecteddate)[0].attendance:"No attendance records"}</TableCell>
                                            


                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>


                    </Table>
                </TableContainer>

            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />
        </Box>


    );
}
