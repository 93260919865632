import { createSlice } from "@reduxjs/toolkit"
//import axios from 'axios';


const dt = new Date()
const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
const day = dt.getUTCDate();
const month = String(dt.getMonth() + 1).padStart(2, '0');
const year = dt.getFullYear();
const date = year + "-" + month + "-" + day;

let data = []

if (localStorage.getItem("Expenses") !== null && localStorage.getItem("Expenses") !== undefined && localStorage.getItem("Expenses") !== "") {

    data = [JSON.parse(localStorage.getItem("Expenses"))]

}


const Insider = createSlice({

    name: "todos",
    initialState: data,
    reducers: {

        getdataexpenses: (state, action) => {

            state = action.payload.data
            return state

        },

        logoutexpenses: (state, action) => {


            localStorage.clear()
            state = []
            return state


        },

        updateexpensesdata: (state, action) => {


            state = action.payload.data
            return state

        },


    }
})

export const { logoutexpenses, updateexpensesdata, getdataexpenses } = Insider.actions;
export default Insider.reducer;