import * as React from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import AccountCircle from '@mui/icons-material/AccountCircle';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKey';
import Container from '@mui/material/Container';
import CopytRightIcon from '@mui/icons-material/Copyright';
import FacebookIcon from '@mui/icons-material/FacebookOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import IgIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
// import YoutubeIcon from '@mui/icons-material/Youtube';
import ComputerIcon from './images/computer.svg';
import Slider from './slider';
import Loginmain from './loginmain';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Login from './loginmain';
import Particles from 'react-tsparticles' 

import Sislogo from './images/sislogoblue.png'
import Sislogo_ from './images/sislogo.png'



import Home from './loginhome';
import Loader from './loader';
import Aboutus from './aboutus';
import Password from './forgottenpassword';
// import { useRoute } from '@react-navigation/native';



import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Grid, Button } from '@mui/material';

function Profile() {
 
    return (
      <div>
       
    <div style={{margin:0, padding:0, backgroundColor:"white", width:"100%", height:"100vh"}}>
   
<Grid container style={{ height:"100vh" }}>

<Grid xs={7} sm={12} lg={7} md={6}style={{backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
}}>
<div>
  
  <img src={Sislogo_} width="38%"/><br/>
  <p style={{textAlign:"center", color:"white", marginTop:"-20px", fontSize:"14px"}}>Harnessing Technology To Safeguard The Education System</p>

</div>
<div>
  <Slider/>
{/* <br/>
<br/> */}
  
  {/* <IconButton style={{backgroundColor:"white", marginLeft:"15px"}}> <FacebookIcon style={{color:"#1976d2"}}/> </IconButton>
  <IconButton style={{backgroundColor:"white", marginLeft:"15px"}}> <LinkedInIcon style={{color:"#1976d2"}}/> </IconButton>
  <IconButton style={{backgroundColor:"white", marginLeft:"15px"}}> <TwitterIcon style={{color:"#1976d2"}}/> </IconButton> */}

</div>

</Grid>
<Grid xs={5} sm={12} lg={5} md={6}>

<h1 align="center"  style={{  fontSize: "40px", background: "linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)",  webkitBackgroundClip:" text",  webkitTextFillColor: "transparent"}}>WELCOME</h1>
<p style={{ background: "linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)",  webkitBackgroundClip:" text",  webkitTextFillColor: "transparent"}}><b>The best school information system</b></p>

  <div style={{marginTop:"10%"}}>

    <Login />

    
  </div>
  <p style={{position:"relative", marginTop:"70px", textAlign:"center", width:"100%"}}> 
  
  <small style={{ background: "linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)",  webkitBackgroundClip:" text",  webkitTextFillColor: "transparent"}}><b>Copyright SIS Revolution 2022</b><br/>
 <a  style={{color:"black"}} href='https://www.ravinleadtechhub.com'>Powered by RavinLead Tech Hub</a>
  </small>
   </p>
   <br/>
</Grid>

</Grid>

    </div>
    </div>
    );
}

export default Profile;