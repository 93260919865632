import React, { useCallback, useState } from 'react';

import { Calendar } from '@natscale/react-calendar';

import '@natscale/react-calendar/dist/main.css';


import Teacher from '@mui/icons-material/LocalLibrary';
import Subject from '@mui/icons-material/MenuBook';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SchoolIcon from '@mui/icons-material/School';
import ClassIcon from '@mui/icons-material/Class';



export default function App(props) {

    const att = props.atendance
    const [value, setValue] = useState(new Date());

    const onChange = useCallback(
        (val) => {
            setValue(val);
        }, [setValue],
    );


    const isDisabled = useCallback((date) => {
        for (var i in att) {
            if (date.getDate() === parseInt(att[i].date.split("-")[2]) && date.getMonth() === parseInt(att[i].date.split("-")[1]) - 1 && att[i].attendance === "present") {
                return true;
            }
        }
    }, [att]);


    return <Calendar isDisabled = { isDisabled }
    value = { value }
    onChange = { onChange }
    />;
}