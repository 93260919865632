import React, {useEffect, useRef} from 'react';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Search from '@mui/icons-material/Search';
import Close from '@mui/icons-material/Close';
import CloseIcon from '@mui/icons-material/Close';
import Backimage from './images/anouncement.svg';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { capitalize } from '@mui/material';
import ReactToPrint from 'react-to-print';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import Nodata from './images/nodata.svg';
import Snack from './snack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton'
import axios from 'axios'

import io from "socket.io-client";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { seedetails, getproducts } from './redux/todoSlice';
import announcement, { announcement_ } from './redux/announcement';
import { loin, logout, updateteacherdata, getdatateacher } from './redux/teachers';
import {  admin } from './redux/admin';
import {  schooldetails } from './redux/schooldetails';

import Stack from '@mui/material/Stack';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


// const socket = io.connect("https://servermain.sisrevolution.com")
// console.log("connect 2 "+ socket)


  function Profile() {

  const dispatch = useDispatch()
  const [preloading, setpreloading] = React.useState(true);

  const dt = new Date()
  const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
  const day = dt.getUTCDate();
  const month = String(dt.getMonth() + 1).padStart(2, '0');
  const year = dt.getFullYear();
  const date = year + "-" + month + "-" + day;
  const [searching, setsearching] = React.useState("");
  const componentRefunsent = useRef()

  const [unsent, setunsent] = React.useState([]);
  const [openunsent, setopenunsent] = React.useState(!true);

  const admin = useSelector((state) => state.admin)
  const schooldetails_ = useSelector((state) => state.schooldetails)
  const schooldata= async () => {
      await axios.post("https://servermain.sisrevolution.com/api/schools/getschooldata/",
          {
              schoolID:admin.schoolID

          }).then(res => {

              if (res.status === 200) {

                  dispatch(schooldetails({

                      data: res.data

                  }))


              }

          })

  }



  const gettingdata = async () => {
    await axios.post("https://servermain.sisrevolution.com/api/announcement/getannouncement/",
        {
            schoolID:admin.schoolID

        }).then(res => {

            if (res.status === 200) {

                dispatch(announcement_({

                    announcement: res.data.filter(bug=>bug.to===undefined || bug.to==="All")

                }))


            }

        })

}

useEffect(()=>{
    schooldata()
    gettingdata()
},[preloading])

const announcement__ = useSelector((state) => state.announcement_)
var announcement=announcement__

const searcher=(e)=>{
setsearching(e.target.value)
}
if(searching!==""){
 var newer = announcement.filter(bug=>(bug.subject).toUpperCase().includes(searching.toUpperCase()) || (bug.message).toUpperCase().includes(searching.toUpperCase()))
 announcement=newer
}




  const [opensnack, setOpensnack] = React.useState(false);

  const [chat, setchat] = React.useState([]);
  const [prechat, setprechat] = React.useState([]);

  const [username, setusername]= React.useState("Joseph")
  const [room, setroom]= React.useState("room1")
  const [smsloading, setsmsloading]= React.useState(false)
  const [smsloading_, setsmsloading_]= React.useState(false)
  const [smsloading__, setsmsloading__]= React.useState(false)
  const [typer, settyper]= React.useState("")

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const [message, setmessage] = React.useState("");
    const [stated, setstated] = React.useState("");



    const [trialmode, settrialmode] = React.useState(!true);
    const [openannounce, setOpenannounce] = React.useState(!true);
    const [loading, setloading] = React.useState(!true);

    const [openbulk, setOpenbulk] = React.useState(!true);
    const [openview, setOpenview] = React.useState(!true);
    const [chosen, setchosen] = React.useState([]);


  
    const handleClickOpenannounce = (data) => {
        if(schooldetails_[0].payment.length===0 || schooldetails_[0].payment[schooldetails_[0].payment.length-1].amount==="trial" ){

            settrialmode(true)

        }else{
        setOpenannounce(true);
        }
    };

      const handleClicksnack = () => {
        setOpensnack(true);
    };

      const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
                }
        setOpensnack(false);
    };

    const handleCloseannounce = () => {
        setOpenannounce(false);
    };

    const sendannouncement=()=>{
        setsmsloading__(true) 
        const subject = document.getElementById("subject").value
        const message_ = document.getElementById("message_").value
        const sender_ = admin.lastname !==undefined ? admin.lastname :"School Admin"
        const sender = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/announcement/addannouncement/",
                {
                    message:message_,
                    subject:subject,
                    date:date,
                    time:time,
                    sender:sender_,
                    to:"All",
                    image:admin.image,
                    schoolID:schooldetails_[0].schoolID
    
                }).then(res => {
    
                    if (res.status === 200) {
    
                        dispatch(announcement_({

                            announcement: res.data
        
                        }))

                        handleCloseannounce()
                        handleClicksnack()
                        setmessage("Announcement Sent")
                        setstated("success")
                        setsmsloading__(!true) 

    
    
                    }else{
                        handleClosebulk()
                        handleClicksnack()
                        setmessage("Error sending announcement")
                        setstated("error")
                        setsmsloading__(!true) 

                    }
    
                })
    
        }

        try{
        sender()
    }
    catch(err){
        setmessage("Network Error")
        setstated("error")
        setsmsloading__(!true) 

    }



        

    }


    const handlebulksms=()=>{

        setsmsloading_(true)
                       
                    const message = document.getElementById("smsdata").value
                   
                    const fd = new FormData()
                  
                    fd.append("schoolID",schooldetails_[0].schoolID)
                    fd.append("smsname",schooldetails_[0].smsname)
                    fd.append("schoolname",schooldetails_[0].schoolname)
                    fd.append("image",schooldetails_[0].image)
                    fd.append("smsID",schooldetails_[0].smsID)
                    fd.append("message", message)
                    const uploading = async() => {
                        await axios.post("https://servermain.sisrevolution.com/api/students/bulksms/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }


                        }).then(res => {

                            if (res.status === 200) {
                               
                        handleClosebulk()
                        handleClicksnack()
                        setmessage("Bulk SMS sent")
                        setstated("success")
                        setsmsloading(!true)
                        handleClosebulk()

                            }
                            if (res.status === 204) {
                                handleClosebulk()
                        handleClicksnack()
                        setmessage("Error Sending SMS")
                        setstated("success")
                        setsmsloading(!true)
                        handleClosebulk()
                            }

                            if(res.data.length!==0){
                                setunsent(res.data)
                                setopenunsent(true)
            
                            }

                        })

                    }

                    try{

                 uploading()
                }
                catch(err){
                    setmessage("Network Error")
                    setstated("error")
                    setsmsloading(!true)
                    handleClosebulk()
                }
            



                

    }

    const handleview=()=>{

                    setsmsloading(true)
                       
                    const message = document.getElementById("smsdata").value
                   
                
                    const fd = new FormData()
                  
               
                    fd.append("schoolID",admin.schoolID)
                    fd.append("message", message)
                    const uploading = async() => {
                        await axios.post("https://servermain.sisrevolution.com/api/students/bulksms/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }


                        }).then(res => {

                            if (res.status === 200) {
                               
                        handleClosebulk()
                        handleClicksnack()
                        setmessage("SMS sent")
                        setstated("success")
                                setsmsloading(!true)

                            }
                            if (res.status === 204) {
                                handleClosebulk()
                        handleClicksnack()
                        setmessage("Error Sending SMS")
                        setstated("success")
                                setsmsloading(!true)
                            }

                            

                        })

                    }

try{
                    uploading()
                }
                catch(err){
                    setmessage("Network Error")
                    setstated("error")
                    setsmsloading(!true)
                }
            



                

    }

    const handleClickOpenbulk = (data) => {
        if(schooldetails_[0].payment.length===0 || schooldetails_[0].payment[schooldetails_[0].payment.length-1].amount==="trial" ){

            settrialmode(true)

        }else{
        setOpenbulk(true);
        }
    };

    const handleClosebulk = () => {
        setOpenbulk(false);
    };


 const handleClickOpenview = (data) => {
       setchosen(data)
        setOpenview(true);
    };

    const handleCloseview = () => {
        setOpenview(false);
    };

    const settrialmode_= () => {
        settrialmode(false);
    };

       

    const closeunsent = () => {
        setopenunsent(false);
    };


    return (
        <div style={{height:"100%"}} >

            <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    { message }
                </Alert>
            </Snackbar>

            <Dialog maxWidth="lg" onClose={settrialmode_} aria-labelledby="customized-dialog-title" open={trialmode}>
 <DialogTitle id="customized-dialog-title" onClose={settrialmode_}  style={{ backgroundColor: "gray", color: "white" }}>
                    Trial Mode
                    <IconButton onClick={settrialmode_}  style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>

                    <p>

                    You are on a trial mode, go to pricing and settle payment in order to use this function.

                    </p>

                </DialogContent>
                <DialogActions>


                   <Link to="/pricing"><Button autoFocus  style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                        Pricing
                    </Button>
                    </Link> 
                </DialogActions>
            </Dialog>



            <Dialog fullWidth maxWidth="sm" onClose={handleCloseannounce} aria-labelledby="customized-dialog-title" open={openannounce}>
            <DialogTitle id="customized-dialog-title" >
            <span style={{fontSize:20, marginLeft:30, color:"grey"}}>Announcement</span>
                    <IconButton aria-label="" onClick={handleCloseannounce} style={{float:"right"}} >
                      <Close/>
                    </IconButton>
            </DialogTitle>
            <DialogContent>
      
<div align="center">

<FormControl variant="standard" style={{ margin: 10 , width:"90%"}}>

                            <TextField
                                id="subject"
                                placeholder="Subject"
                                type="text"
                                style={{width:"100%"}}
                                variant="outlined"
                                size="small"



                            />
                        </FormControl>
<br/>
 <FormControl variant="standard" style={{ margin: 10, width:"90%" }}>

                            <textarea
                                id="message_"
                                placeholder="Message"
                                rows={5}
                                style={{width:"100%", padding:8, borderRadius:5, border:"solid", borderColor:"lightgrey", fontSize:18, borderWidth:"0.2px"}}
                                variant="outlined"
                                label="message"
                               



                            />
                        </FormControl>

</div>



                </DialogContent>
                <Divider/>
                <DialogActions style={{textAlign:"center"}}>

 {smsloading__ ===true?
                               <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton>:
                          
                                <Button autoFocus onClick={() => sendannouncement()} color="primary">
                                    Submit
                                </Button>
                            }



                </DialogActions>
            </Dialog>
            



            <Dialog fullWidth maxWidth="sm" onClose={handleClosebulk} aria-labelledby="customized-dialog-title" open={openbulk}>
            <DialogTitle id="customized-dialog-title" >
            <span style={{fontSize:20, marginLeft:30, color:"grey"}}>Bulk SMS to Guardians</span>
                    <IconButton aria-label="" onClick={handleClosebulk} style={{float:"right"}} >
                      <Close/>
                    </IconButton>
            </DialogTitle>
            <DialogContent>

                 
                 
<div align="center">



 <FormControl variant="standard" style={{ margin: 10, width:"90%" }}>

                            <textarea
                                id="smsdata"
                                placeholder="Enter message"
                                rows={5}
                                style={{width:"100%", padding:8, borderRadius:5, border:"solid", borderColor:"lightgrey", fontSize:18, borderWidth:"0.2px"}}
                                variant="outlined"
                                label="message"
                               



                            />
                        </FormControl>

</div>



                </DialogContent>
                <Divider/>
                <DialogActions style={{textAlign:"center"}}>

        { smsloading_ === true?  <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Loading...
      </LoadingButton> :        
 <Button autoFocus onClick={()=>handlebulksms()} style={{backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                        Submit
                    </Button>
  }
                   
                </DialogActions>
            </Dialog>
            

            
            <Dialog fullWidth maxWidth="lg" onClose={closeunsent} aria-labelledby="customized-dialog-title" open={openunsent}>
 <DialogTitle id="customized-dialog-title" onClose={closeunsent}  style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Print Unsent SMS List
                    <IconButton onClick={closeunsent}  style={{float:"right"}}><CloseIcon style={{color:"white"}}/></IconButton>
                </DialogTitle>
                <DialogContent dividers>

                <ReactToPrint
             pageStyle='@page { size: A4; margin: 0mm; } @media print { body { scale:1; font-size:8pt; font-family:georgia, times, serif; } }'
           
           trigger={()=><Button>Print Unsent List</Button>}
            content ={()=>componentRefunsent.current}
            />

<div ref={componentRefunsent} >
<div style={{textAlign:"center"}}>
<img src={schooldetails_.length!==0 && "https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/logothumb/"+schooldetails_[0].image} width="80px" alt="schoologo"/> <br/>
    <h4 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].schoolname).toUpperCase()} </h4>
    <h5 style={{margin:4}}>  {schooldetails_.length!==0 &&( schooldetails_[0].address).toUpperCase()} </h5>
    <h3 style={{margin:4}}> Unsent SMS List </h3>
    <p style={{color:"red"}}>The list below did not received the SMS sent to all students, please make sure the guardian's contacts are intact and resend the SMS individually</p>
</div>


              {unsent.length!==0 &&
                <TableContainer>
      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>

                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>No. </TableCell>
                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>Student Name </TableCell>
                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>Class</TableCell>
                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>Guardian Name</TableCell>
                      <TableCell style={{ fontWeight:"bolder", margin:3, padding:3}}>Guardian Contact</TableCell>
                    

          </TableRow>
        </TableHead>
        <TableBody>
          {unsent.map((row, indexer) => (

 <TableRow
                  key={row.date}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell style={{margin:3, padding:3}} component="th" scope="row">
                      {indexer+1 +"."}
              </TableCell> <TableCell  style={{margin:3, padding:3}}component="th" scope="row">
                      {row.lastname+" "+row.othernames}
              </TableCell>
              <TableCell  style={{margin:3, padding:3}}component="th" scope="row">
                      {row.class_}
              </TableCell>
              <TableCell  style={{margin:3, padding:3}}component="th" scope="row">
                      {row.guardianlastname+" "+row.guardianothername}
              </TableCell> 
              <TableCell  style={{margin:3, padding:3}}component="th" scope="row">
                      {row.guardiancontact}
              </TableCell>

          </TableRow>))} 
        </TableBody>
      </Table>
    </TableContainer>
}


</div>          

                </DialogContent>
                <DialogActions>




                 

                  
                </DialogActions>
            </Dialog>




            <Dialog fullWidth maxWidth="sm" onClose={handleCloseview} aria-labelledby="customized-dialog-title" open={openview}>
            <DialogTitle id="customized-dialog-title" >
            <span style={{fontSize:20, marginLeft:30, color:"grey"}}>Announcement views</span>
                    <IconButton aria-label="" onClick={handleCloseview} style={{float:"right"}} >
                      <Close/>
                    </IconButton>
            </DialogTitle>
            <DialogContent>

                 {chosen.map((list,index)=>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt={list.name} src={'https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/'+list.image} />
        </ListItemAvatar>
        <ListItemText
          primary={list.name}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {months[parseInt((list.date.split("-"))[1])-1] +" "+(list.date.split("-"))[2]+", "+(list.date.split("-"))[0] }
              </Typography>
              {" - "+list.time}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
   
    </List>
                 )
}

                </DialogContent>
                <DialogActions style={{textAlign:"center"}}>


                </DialogActions>
            </Dialog>
            


           <Container style={{backgroundColor:"white", }}>
            <br/>
           <Typography
                sx={{ flex: '1 1 100%', }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
               ANNOUNCEMENT
            </Typography>
            <br/>
<Grid container spacing={2} >
 
<Grid item xs={7}>
    <div style={{border:"solid", borderWidth:"0.1px", borderColor:"lightgrey", borderRadius:"10px"}}>

        <img  src={Backimage} onPlay  width="65%" style={{ borderRadius:"10px"}} />

    <div>
<br/>
<Button onClick={handleClickOpenannounce} style={{margin:6,backgroundColor:" #e0eff9" , color:"#1976d2" }}>Send announcement to Teachers</Button>
<Button onClick={handleClickOpenbulk} style={{margin:6,backgroundColor:" #e0eff9" , color:"#1976d2"}}>Send bulk sms to guardian</Button>

<br/>
<br/>
    </div>
    
    </div>
</Grid>

<Grid item xs={5}>
    <div  style={{border:"solid", borderWidth:"0.1px", borderColor:"lightgrey", borderRadius:"10px", marginBottom:40}}>
   <Search style={{marginRight:"-40px", paddingLeft:10, marginTop:5, position:"absolute", fontSize:"32px", color:"gray" }}/> <input type="search" onChange={searcher} placeholder='search' style={{padding:10,paddingLeft:40, fontSize:"18px", color:'gray', borderRadius:"10px",border:"none", borderWidth:"0.1px", borderColor:"lightgrey", width:"90%" }}/>
    <Divider/>


    <div className='scroller3' style={{overflowY:"scroll", height:"500px"}}>


{announcement.length!==0 ? announcement.map((list, index)=>

<div align="left" style={{margin:8}}>
    <div style={{border:"solid", borderWidth:"0.1px", borderColor:"lightgrey", borderRadius:"10px", padding:5, marginTop:8}}>
        <p style={{margin:3}}>{capitalize(list.subject)} <br/> <small>{months[parseInt((list.date.split("-"))[1])-1] +" "+(list.date.split("-"))[2]+", "+(list.date.split("-"))[0] }</small></p>
        <p style={{color:"grey", fontSize:"13px"}}> {list.message} </p>
    <Grid container>

                            <Grid item xs={12}> 
                            <AvatarGroup style={{cursor:"pointer"}} max={2}>
                                {list.views.length!==0?
                                list.views.map((lister,idexer)=>
                                  
      <Avatar alt="Remy Sharp" src={"https://thesis1995revolution0268005074.sisrevolution.com/sisrevolution1995/routes/thumbnail/"+list.views[0].image} onClick={()=>handleClickOpenview(list.views)} />

     ):<p align="right">No Viewer    </p>
                                }
                                </AvatarGroup>
                             </Grid>

    </Grid>
    </div>
</div>

):
<div style={{textAlign:"center"}}><img src={Nodata} width="60%" style={{marginTop:"30%"}} /> <br/> <p style={{textAlign:"center", color:"grey", margin:0}}>No data found</p></div>

}



    </div>





    
    
    </div>
</Grid>
</Grid>
</Container>
        </div>
    );
}

export default Profile;
