import React, {useEffect,useRef} from 'react'
import ReactToPrint from 'react-to-print';

import Timetable from 'react-timetable-events'
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Close from '@mui/icons-material/Close';

import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import axios from 'axios';


import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { seedetails, getproducts } from './redux/todoSlice';
import { admin } from './redux/admin';
import { schooldetails } from './redux/schooldetails';
import { loin, logout, updateschooldata, getdata_ } from './redux/class';
import { Divider } from '@mui/material';



export default function Write (){


    const componentRef = useRef()

     const dispatch = useDispatch()

     const admin = useSelector((state) => state.admin)
     const schooldetails_ = useSelector((state) => state.schooldetails)
     const schooldata= async () => {
         await axios.post("https://servermain.sisrevolution.com/api/schools/getschooldata/",
             {
                 schoolID:admin.schoolID
 
             }).then(res => {
 
                 if (res.status === 200) {
 
                     dispatch(schooldetails({
 
                         data: res.data
 
                     }))
 
 
                 }
 
             })
 
     }
 
 
 
   const gettingdata = async () => {
        await axios.post("https://servermain.sisrevolution.com/api/class/getclassdata/",
            {
                schoolID:admin.schoolID
            }).then(res => {
                if (res.status === 200) {
                    dispatch(getdata_({
                        data: res.data
                    }))
                }
            })
    }

    
    const classer = useSelector((state) => state.Class_)
     var main= admin.class_===undefined? classer:classer.filter(bug=>bug.classname===admin.positiondescription_)
    //  var Class_ = admin.class_===undefined? classer:classer.filter(bug=>bug.classname===admin.positiondescription_)

    // var main = useSelector((state) => state.Class_)
    var rows = main

    const [classname, setclassname] = React.useState("none")
    const [selectedday, setselectedday] = React.useState("none")
    const [changesubject, setchangesubject] = React.useState("none")
    const [openview, setOpenview] = React.useState(!true);
    const [chosen, setchosen] = React.useState("");
    const [loadpage, setloadpage] = React.useState(true);
    const [maintable, setmaintable] = React.useState({});


    useEffect(()=>{

         gettingdata()
         setloadpage(!true)



},[loadpage])
    
    

    useEffect(()=>{

        chosen!=="" && classname!=="none"?setmaintable((rows.filter(bug=>bug.classname === chosen[0].classname)[0].timetable)[0] ):setchosen("")

    },[chosen,main])
    
    
      const addtable = (data) => {

         //setloading(true)

        var starttime_ = document.getElementById("starttime").value
        var endtime_ = document.getElementById("endtime").value
        
        var tablemain = rows.filter(bug=>bug.classname === chosen[0].classname)[0].timetable 
        var id = rows.findIndex(bug=>bug.classname === chosen[0].classname)

        var data =   {
        
        name: changesubject,
        type: changesubject!=="Break"? "custom":"error",
        startTime:  new Date("2018-02-23  "+starttime_+"").getTime(),
        endTime: new Date("2018-02-23  "+endtime_+"").getTime(),
      }
  var newday={}
if(tablemain[0][selectedday.toLowerCase()] !== undefined){
newday = {...tablemain.filter(bug=>bug!==selectedday.toLowerCase())[0], [selectedday.toLowerCase()]:[... tablemain[0][selectedday.toLowerCase()],data]}
}else{
   newday = [{...tablemain, [selectedday]:[data]}]

}

console.log(newday)

const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/class/addtimetable/",
                {
                   _id:chosen[0]._id,
                   data:newday,
                   schoolID:admin.schoolID
                }).then(res => {

                    if (res.status === 200) {

                         dispatch(getdata_({
                        data: res.data
                    }))
                        //setloading(!true)
                        //handleClosedelete()
                        //handleClicksnack()
                        //setmessage("Data has been deleted")
                        //setstated("success")

                    } else {

                        //setloading(!true)
                        //handleClosedelete()
                        //handleClicksnack()
                        //setmessage("Error deleting data")
                        //setstated("error")
                    }

                })

        }

        try{
            doer()
        }catch(err){
                        
                        
        }

    }

      
      const deletetable = (data, day_) => {

         //setloading(true)

            
       var tablemain = rows.filter(bug=>bug.classname === chosen[0].classname)[0].timetable 
       console.log(tablemain)
       console.log(day_)
       var newday = tablemain[0][day_]
       var newer = {...tablemain[0]}
      
       console.log(data)
       console.log(newer[day_])
       var new2 = newer[day_].filter(bug=>bug.endTime!==data.endTime && bug.startTime!==data.startTime)
       
       newer[day_]=new2

       console.log(newer)

       console.log(new2)

       var main_ = [{...newer}]
       console.log(main_)

     
const doer = async () => {
            await axios.post("https://servermain.sisrevolution.com/api/class/addtimetable/",
                {
                   _id:chosen[0]._id,
                   data:main_,
                   schoolID:admin.schoolID
                }).then(res => {

                    if (res.status === 200) {
                 dispatch(getdata_({
                        data: res.data
                    }))
                        
                        //setloading(!true)
                        //handleClosedelete()
                        //handleClicksnack()
                        //setmessage("Data has been deleted")
                        //setstated("success")

                    } else {

                        //setloading(!true)
                        //handleClosedelete()
                        //handleClicksnack()
                        //setmessage("Error deleting data")
                        //setstated("error")
                    }

                })

        }
try{
            doer()
} catch{
    
    // setmessage("Error deleting data")
    // setstated("error")
}
    }

    
    
    const clearsubject = ()=>{

        var subjects = rows.subjects
        console.log(subjects)

    }

const handleChangeclass = (e) =>{

setclassname(e.target.value)
var newer = rows.filter(bug=> bug.classname === e.target.value)
setchosen(newer)
}

const handleChangeday = (e) =>{
setselectedday(e.target.value)
}

const handleChangesubject = (e) =>{
setchangesubject(e.target.value)
}

const handleClickOpenview = () => {
    setOpenview(true);
};

const handleCloseview = () => {
  setOpenview(false);
};

    return(

<div style={{backgroundColor:"white", borderRadius:"5px"}}>
<br/>
<Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                TIMETABLE
            </Typography>
        
<FormControl style={{ margin: 20, textAlign:"left", backgroundColor:" #e0eff9" , color:"#1976d2"}}>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            align="left"
                            value={classname}
                            style={{ minWidth: 150, padding: 0}}
                            onChange={handleChangeclass}
                            size="small"
                        >
                         <MenuItem value="none">select class</MenuItem>

                        {rows.map((list,index)=>
                         <MenuItem value={list.classname} >{list.classname}</MenuItem>
                        
                        )}
                           
                        </Select>
                    </FormControl>
{classname!=="none"?
<span>
<Button onClick={handleClickOpenview} style={{backgroundColor:" #e0eff9" , color:"#1976d2", marginTop:20}}>Add / Edit Time Table </Button>

<ReactToPrint
           trigger={()=><Button style={{marginTop:20, marginLeft:10, backgroundColor:" #e0eff9" , color:"#1976d2"}}>Print Timetable</Button>}
            content ={()=>componentRef.current}
            />
</span>
:
""
}
<Dialog  maxWidth="md" onClose={handleCloseview} aria-labelledby="customized-dialog-title" open={openview}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseview} style={{ backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"
, color: "white" }}>
                    Set Time Table
                    <IconButton style={{float:"right", color:"white"}} autoFocus onClick={handleCloseview} color="primary">
                        <Close/>
                    </IconButton>
                </DialogTitle>
               
                <DialogContent dividers>

                <FormControl style={{ margin: 10, textAlign:"left"}}>
               <small>Day</small>
                        <Select
                            labelId="input-with-icon-adornment"
                            id="selectedday"
                            align="left"
                            value={selectedday}
                            style={{ minWidth: 150, padding: 0}}
                            onChange={(e)=>handleChangeday(e)}
                            className="signup"
                            size="small"
                        >
                            <MenuItem value="none"><em>Choose Day</em></MenuItem>
                            <MenuItem value="Monday">Monday</MenuItem>
                            <MenuItem value="Tuesday">Tuesday</MenuItem>
                            <MenuItem value="Wednesday">Wednesday</MenuItem>
                            <MenuItem value="Thursday">Thursday</MenuItem>
                            <MenuItem value="Friday">Friday</MenuItem>
                            
                        </Select>
                    </FormControl>
 <FormControl style={{ margin: 10, textAlign:"left"}}>
 <small>Subject</small>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id=""
                            align="left"
                            value={changesubject}
                            style={{ minWidth: 150, padding: 0}}
                            onChange={handleChangesubject}
                            className="signup"

                            size="small"
                        >
                    
                        {chosen !== "" && classname!=="none"? 
                        chosen[0].subjects[0].map((list, index)=>

                            <MenuItem value={list.subject}> {list.subject} </MenuItem>
                        ):""
                        }
                        <MenuItem value="Break">Break</MenuItem>


                        </Select>
                    </FormControl>

                <br/>

                <FormControl variant="standard" style={{ margin: 10 }}>
                <small>Start Time</small>
                    <TextField
                        id="starttime"
                        type="time"
                        className="signup"
                        variant="outlined"
                        size="small"



                    />
                    </FormControl>
                
                <FormControl variant="standard" style={{ margin: 10 }}>
                <small>End Time</small>
                    <TextField
                        id="endtime"
                        type="time"
                        className="signup"
                        variant="outlined"
                        size="small"



                    />
                    </FormControl>
                
                <Divider/>

                <TableContainer component={Paper}>
      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
                      <TableCell style={{fontWeight:"bolder"}} >Day</TableCell>
                      <TableCell style={{fontWeight:"bolder"}} align="left">Subject</TableCell>
                      <TableCell style={{fontWeight:"bolder"}} align="left">Sarting time</TableCell>
                      <TableCell style={{fontWeight:"bolder"}} align="left">Ending time</TableCell>
                      <TableCell style={{fontWeight:"bolder"}} align="left">Action </TableCell>

          </TableRow>
        </TableHead>
        {console.log("rows")}
        {console.log(rows)}
          {classname!=="none" && chosen!==""? rows.filter(bug=>bug.classname===classname).length!==0 && rows.filter(bug=>bug.classname===classname)[0].timetable.map((rower) => 
          <TableBody>
       
{rower.monday !==undefined && rower.monday.map((row)=>
            <TableRow
                  key={row.date}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
             
                  <TableCell align="left">Monday</TableCell>
                   <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{new Date(row.startTime).toLocaleTimeString()}</TableCell>
                  <TableCell align="left">{new Date(row.endTime).toLocaleTimeString()}</TableCell>
                  <TableCell align="left"> <Button onClick={()=>deletetable(row, "monday")}>delete</Button></TableCell>

            </TableRow>
            )
}


{rower.tuesday !==undefined && rower.tuesday.map((row)=>
            <TableRow
                  key={row.date}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
             {console.log(row)}
                  <TableCell align="left">Tuesday</TableCell>
                 <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{new Date(row.startTime).toLocaleTimeString()}</TableCell>
                  <TableCell align="left">{new Date(row.endTime).toLocaleTimeString()}</TableCell>
                  <TableCell align="left"> <Button onClick={()=>deletetable(row, "tuesday")}>delete</Button></TableCell>

            </TableRow>
            )
}


{rower.wednesday !==undefined && rower.wednesday.map((row)=>
            <TableRow
                  key={row.date}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
             
                  <TableCell align="left">Wednesday</TableCell>
                   <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{new Date(row.startTime).toLocaleTimeString()}</TableCell>
                  <TableCell align="left">{new Date(row.endTime).toLocaleTimeString()}</TableCell>
                  <TableCell align="left"> <Button onClick={()=>deletetable(row, "wednesday")}>delete</Button></TableCell>

            </TableRow>
            )
}


{rower.thursday !==undefined && rower.thursday.map((row)=>
            <TableRow
                  key={row.date}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
             
                  <TableCell align="left">Thursday</TableCell>
                   <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{new Date(row.startTime).toLocaleTimeString()}</TableCell>
                  <TableCell align="left">{new Date(row.endTime).toLocaleTimeString()}</TableCell>
                  <TableCell align="left"> <Button onClick={()=>deletetable(row, "thursday")}>delete</Button></TableCell>

            </TableRow>
            )
}


{rower.friday !==undefined && rower.friday.map((row)=>
            <TableRow
                  key={row.date}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
             
                  <TableCell align="left">Friday</TableCell>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{new Date(row.startTime).toLocaleTimeString()}</TableCell>
                  <TableCell align="left">{new Date(row.endTime).toLocaleTimeString()}</TableCell>
                  <TableCell align="left"> <Button onClick={()=>deletetable(row, "friday")}>delete</Button></TableCell>

            </TableRow>
            )
}




            </TableBody>
          ):""}
      
      </Table>
    </TableContainer>

                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>addtable()} color="primary">
                        add timetable
                    </Button>
                    
                    <Button autoFocus onClick={handleCloseview} color="primary">
                        close
                    </Button>
                </DialogActions>
            </Dialog>



<div  ref={componentRef} >
<p align="center"><b>{classname.toUpperCase()}</b></p>
{chosen!==""?
<Timetable 
   bodyAttributes={{
    className: 'styles-module__my_custom_day_body__JnVZR'
  }}


  events={maintable}

  headerAttributes={{
    className: 'styles-module__my_custom_day_header__ah3Tn'
  }}
//   renderDayHeader={function noRefCheck(){}}
//   renderEvent={function noRefCheck(){}}
//   renderHour={function noRefCheck(){}}
  style={{
    height: '100%',
    color:"red"
  }}
  timeLabel=""


/>:<Timetable
 headerAttributes={{
    className: 'tablehead'
  }}
    bodyAttributes={{
      
    className: 'tablebody'
  }}
  

events={
    {
    monday:[],
    tuesday:[],
    wednesday: [],
    thursday: [],
    friday: [],
  }
  }
  />}

</div>

</div>

    )
}