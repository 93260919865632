import { createSlice } from "@reduxjs/toolkit"
//import axios from 'axios';


const dt = new Date()
const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
const day = dt.getUTCDate();
const month = String(dt.getMonth() + 1).padStart(2, '0');
const year = dt.getFullYear();
const date = year + "-" + month + "-" + day;

let data = []

// if (localStorage.getItem("chat") !== null && localStorage.getItem("chat") !== undefined && localStorage.getItem("chat") !== "") {

//     data = JSON.parse(localStorage.getItem("chat"))

// }

const Insider = createSlice({

    name: "todos",
    initialState: data,
    reducers: {

        getchat: (state, action) => {
console.log("doing")
            state = action.payload.data
            // localStorage.setItem("chat",JSON.stringify(state))
            return state

        },

    

    }
})

export const { getchat } = Insider.actions;
export default Insider.reducer;