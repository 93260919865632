import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button, Container } from '@mui/material'
import Computer from './images/computer.svg'
import Addinfo from './images/Wallet.svg'
import Finance from './images/lessonnotes.svg'
import Statistics from './images/grade.svg'
import Mark from './images/attendance.svg'

function Example(props) {
    var items = [
        {
            name: <img src={Finance} width="60%" />,
            description: "Computerize your school's finances to ensure accuracy."
        }, 
        {
            name: <img src={Addinfo} width="60%" />,
            description: "Add, remove and edit data on SIS Revolution."
        }, {
            name:<img src={Statistics} width="60%" />,
            description: "Get your school's statistics to better assessment."
        },  {
            name:<img src={Mark} width="60%" />,
            description: "Mark class attendance on SIS Revolution."
        }

    ]

    return (
        <Carousel swipe={true} animation="slide" indicators={false} >
            {
                items.map((item, i) => <Item  key={i} item={item} />)
            }
        </Carousel>
    )
}

function Item(props) {
    return (
        <div style={{height:"80vh", marginTop:"5px"}}>
             
        {props.item.name}
        <p style={{color:"white"}}><small>{props.item.description}</small></p>
        </div>
    )
}

export default Example