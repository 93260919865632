import React from 'react';
import Grid from '@mui/material/Grid';
// import '../index.css'
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import AccountCircle from '@mui/icons-material/AccountCircle';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKey';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Slider from './slider';
//import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
// import Logo2 from '../images/photo_2022-06-02_02-37-50.jpg';
import PersonOutlineIcon from '@mui/icons-material/Person';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
// import { update_accident } from '../redux/accident';
// import { update_home } from '../redux/home';
// import { update_motor } from '../redux/motor';
// import { update_travel } from '../redux/travel';



// import Logo from '../images/logo.png';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams
} from "react-router-dom";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function BurcyHome() {
    const dispatch = useDispatch()

    const [values, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });
    const [opensnack, setOpensnack] = React.useState(false);
    const [changer, setchanger] = React.useState("main");
    const [loading, setloading] = React.useState(false)
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const [message, setmessage] = React.useState("");
    const [stated, setstated] = React.useState("");
    const handleClicksnack = () => {
        setOpensnack(true);
    };

    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const login = (e) => {
        e.preventDefault()
        const password = document.getElementById("password").value
        const username = document.getElementById("username").value

        const doer = async () => {
            setloading(true)

            await axios.post("https://servermain.sisrevolution.com/api/schools/login/",
                {
                    email: username,
                    password: password,

                }).then(res => {

                    if (res.status === 200) {
                        localStorage.setItem("user_sis", JSON.stringify(res.data))
                        window.location.reload()
                

                    } else if(res.status === 201){
                        setloading(!true)
                         handleClicksnack()
                        setmessage("You have been suspended")
                        setstated("warning")
                    } else if(res.status === 203){
                        setloading(!true)
                         handleClicksnack()
                        setmessage("You do not have access to this service")
                        setstated("warning")
                    }else if(res.status === 206){
                        setloading(!true)
                         handleClicksnack()
                        setmessage("You do not have access to this service")
                        setstated("warning")
                    } else {
                        setloading(!true)
                        handleClicksnack()
                        setmessage("Login Error, please check your login credentials")
                        setstated("error")
                    }

                })

        }

        try{
       doer()
        }catch(err){
            setloading(!true)
            handleClicksnack()
            setmessage("Network error")
            setstated("error")
        }


    }

    const resetmain = (e) => {
        e.preventDefault()
        const email = document.getElementById("resetemail").value

        const doer = async () => {
            setloading(true)

            await axios.post("https://servermain.sisrevolution.com/api/schools/resetpassword/",
                {
                    email: email,

                }).then(res => {
                    if (res.status === 200) {
                        setloading(!true)
                        handleClicksnack()
                       setmessage("Password Reset has been sent to your email")
                       setstated("success")

                    } else if(res.status === 201){
                        setloading(!true)
                         handleClicksnack()
                        setmessage("Your account have been suspended")
                        setstated("warning")
                    } else if(res.status === 203){
                        setloading(!true)
                         handleClicksnack()
                        setmessage("You do not have access to this service")
                        setstated("warning")
                    }else if(res.status === 204){
                        setloading(!true)
                         handleClicksnack()
                        setmessage("You do not have access to this service")
                        setstated("warning")
                    } else {
                        setloading(!true)
                        handleClicksnack()
                        setmessage("Reset Error, please check your credentials")
                        setstated("error")
                    }

                })

        }
try{


       doer()
}catch(err){
    setloading(!true)
    handleClicksnack()
    setmessage("Password Reset Error")
    setstated("error")
}


    }


    const mainlogin=()=>{


        return(


            

        <Container maxWidth="lg" >
          
                

        {/* <p className='kkk'></p> */}
             <h1 style={{ marginTop:16 , background: "linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)",  webkitBackgroundClip:" text",  webkitTextFillColor: "transparent" }}> LogIn </h1>
           
<form onSubmit={login}>
             <FormControl variant="standard">

                 <TextField
                 required
                     id="username"
                     placeholder="Username"
                     type="username"
                     className="credentials"
                     // variant="standard"
                     size='small'
                     InputProps={{  startAdornment:
                         <InputAdornment position="start">
                             <PersonOutlineIcon style={{ marginRight: 4, color:"#1976d2" }} />   |

                         </InputAdornment>
                     }}
                     endAdornment={
                         <InputAdornment position="end">

                         </InputAdornment>
                     }

                 />
             </FormControl>
             <br />
             <br />
             
             <FormControl variant="standard">

                 <TextField
                 required
                     id="password"
                     placeholder="Password"
                     size="small"
                     // type="password"
                     className="credentials"
                     type={values.showPassword ? 'text' : 'password'}
                     value={values.password}
                     onChange={handleChange('password')}
                      
                    InputProps={{ 
                     startAdornment:<InputAdornment position="start"> <VpnKeyOutlinedIcon style={{ marginRight: 4, color:"#1976d2" }} />   |     <Divider orientation="vertical" />            </InputAdornment>,
                      endAdornment:<InputAdornment position="end">
                             <IconButton
                                 aria-label="toggle password visibility"
                                 onClick={handleClickShowPassword}
                                 onMouseDown={handleMouseDownPassword}
                             >
                                 {values.showPassword ? <VisibilityOff /> : <Visibility style={{color:"#1976d2"}} />}
                             </IconButton>
                         </InputAdornment>
                     }}
                    
                 />
             </FormControl>

             <Grid container style={{ textAlign: "left" }}>

             <Grid lg={5} xs={12} md={6}>

                 </Grid>
             <Grid lg={7} xs={12} md={6} style={{ textAlign:"center" ,marginTop:7 }}>
                 
                 <a onClick={()=>setchanger("reset")} style={{ color:"blue", cursor:"pointer", fontSize: 15, marginTop: 20, marginBottom: 40, marginRight:"0px"  }}>
                         forgotten password?
                     </a>
                 </Grid>

             </Grid>



         <br />

         {loading === false ?
             <input type="submit" value="SUBMIT" style={{backgroundColor:" #e0eff9" , color:"#1976d2",cursor:"pointer", padding:"15px", borderRadius:"5px", border:"none", outline:"none"}}  size="large" />
                
             :
             <Button variant="contained" disabled={loading} size="large">

                 <CircularProgress size={20} />  Loading...

             </Button>
         }


            
             <br />
           </form>
<br/>
         </Container>




        )

    }



    const resetpassword=()=>{


        return(


            

        <Container maxWidth="lg" >
          
                

        {/* <p className='kkk'></p> */}
             <h1 style={{ marginTop:16 , background: "linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)",  webkitBackgroundClip:" text",  webkitTextFillColor: "transparent" }}> Reset Password </h1>
           
<form onSubmit={resetmain}>
             <FormControl variant="standard">

                 <TextField
                 required
                     id="resetemail"
                     placeholder="Email"
                     type="email"
                     className="credentials"
                     // variant="standard"
                     size='small'
                     InputProps={{  startAdornment:
                         <InputAdornment position="start">
                             <EmailOutlinedIcon style={{ marginRight: 4, color:"#1976d2" }} />   |

                         </InputAdornment>
                     }}
                     endAdornment={
                         <InputAdornment position="end">

                         </InputAdornment>
                     }

                 />
             </FormControl>
             <br />
             <br />

{loading === false ?
    <input type="submit" value="SUBMIT" style={{backgroundColor:" #e0eff9" , color:"#1976d2",cursor:"pointer", padding:"15px", borderRadius:"5px", border:"none", outline:"none"}}  size="large" />
       
    :
    <Button variant="contained" disabled={loading} size="large">

        <CircularProgress size={20} />  Loading...

    </Button>
}
             
           

             <Grid container style={{ textAlign: "left" }}>

           
             <Grid lg={12} xs={12} md={12} style={{ textAlign:"center" ,marginTop:7 }}>
                 
                I have already registered, <a onClick={()=>setchanger("main")} style={{ color:"blue", cursor:"pointer", fontSize: 15, marginTop: 20, marginBottom: 40, marginRight:"0px"  }}>
                        Login
                     </a>
                 </Grid>

             </Grid>



       


            
             <br />
           </form>
<br/>
         </Container>




        )

    }




    return (

       <Box className='loginbox' sx={{ boxShadow: 3}} style={{paddingTop:5}} >
       {/* <Online> */}
       <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>


{changer==="main"? mainlogin() : resetpassword()}

            {/* </Online> */}
        

</Box>
    );
}
